import { PinInput, PinInputField, Button } from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import AuthContext from "../../context/auth/context";
import { useTranslation } from "react-i18next";
interface IProps {
  onContinue: (value: number) => void;
  isRegisterCode?: boolean;
}
const CodePage = ({ onContinue, isRegisterCode }: IProps) => {
  const {
    otpCode,
    phone,
    email,
    loading,
    actions: { registerCode, resendCode, checkPasswordResetCode },
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const [panCode, setPanCode] = useState<string>("");
  const pinInputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const handlePinInputChange = (index: number, value: string) => {
    const newPinCode = panCode.split("");
    newPinCode[index] = value;
    setPanCode(newPinCode.join(""));
    if (value && index < 5) {
      // Move focus to the next input field if a value is entered
      pinInputRefs.current[index + 1]?.focus();
    }
  };

  const handlePinInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Backspace" && index > 0 && !panCode[index]) {
      // Move focus to the previous input field if backspace is pressed and the current input field is empty
      pinInputRefs.current[index - 1]?.focus();
    }
  };

  const onSubmit = async () => {
    if (isRegisterCode) {
      await registerCode({
        email_or_phone: email ?? phone,
        otp_code: parseInt(panCode),
      });
    } else {
      await checkPasswordResetCode(
        {
          email_or_phone: email ?? "",
          otp_code: parseInt(panCode),
        },
        () => onContinue(2)
      );
      // onContinue(2);
    }
  };
  const onResend = () => {
    resendCode({ email_or_phone: email ?? phone });
  };
  return (
    <div className=" flex flex-col  gap-[50px] ">
      <div>
        <div
          className=" text-[#545454] leading-[30px] whitespace-normal lg:w-[461px] md:w-[350px] sm:w-[100px]"
          style={{ fontWeight: "normal" }}
        >
          {t("codeSent")}
        </div>
        <div className="flex content-center items-center justify-center lg:mt-[60px] xs:mt-[30px]">
          <PinInput defaultValue={""} value={panCode} onChange={setPanCode}>
            {[...Array(6)].map((_, index) => (
              <PinInputField
                key={index}
                ref={(el) => (pinInputRefs.current[index] = el)}
                value={panCode[index] || ""}
                onChange={(e) => handlePinInputChange(index, e.target.value)}
                onKeyDown={(e) => handlePinInputKeyDown(e, index)}
                mx={"25px"}
                w={{ base: "50px", md: "70px", lg: "75.5px" }}
                _focus={{
                  border: "none",
                  boxShadow: "none",
                  borderBottom: "1px",
                  borderColor: "black",
                }}
                sx={{ mx: "5px" }}
                borderRadius={"0px"}
                borderColor={"black"}
                _hover={{
                  border: "none",
                  borderBottom: "1px",
                  borderColor: "black",
                }}
                border={"none"}
                borderBottom={"1px solid "}
                fontSize={"40px"}
                py={"30px"}
                className=" border-b border-solid border-[1px]  ] "
              />
            ))}
          </PinInput>
        </div>
      </div>
      <div className="flex flex-col lg:gap-[70px] xs:gap-[35px]">
        <Button
          backgroundColor={"black"}
          color={"white"}
          borderRadius={"0px"}
          className="lg:w-[100%] md:w-[350px]"
          _hover={{ bgColor: "#333131" }}
          isLoading={loading.includes("verify_password_code")}
          onClick={() => {
            // onContinue();
            onSubmit();
          }}
        >
          {t("continue")}
        </Button>
        <Button
          backgroundColor={"white"}
          color={"black"}
          borderRadius={"0px"}
          borderColor={"black"}
          className=" border border-solid text-[16px] lg:w-[100%] md:w-[350px]   "
          fontWeight={"normal"}
          onClick={() => {
            onResend();
          }}
        >
          {t("resendCode")}
        </Button>
      </div>
    </div>
  );
};

export default CodePage;
