// Import necessary modules and libraries for social media sharing
import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { OpenAPI } from "../../../api/gen";
import { Flex, IconButton } from "@chakra-ui/react";

interface IProps {
  productId: number | undefined;
  imageUrl: any;
}
// Inside your ProductDetails component
const ShareButtons = (props: IProps) => {
  // Other component code...

  // Handle share action
  const handleShare = () => {
    // Implement share logic here, such as constructing shareable URLs or opening share dialogs
  };
  return (
    <Flex justifyContent={"space-around"} alignItems={"center"}>
      <Flex>
        <FacebookShareButton url={OpenAPI.LINK + "/product/" + props.productId}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </Flex>
      <Flex marginInlineStart={"0.5em"}>
        <WhatsappShareButton url={OpenAPI.LINK + "/product/" + props.productId}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </Flex>
      <Flex marginInlineStart={"0.5em"}>
        <TwitterShareButton url={OpenAPI.LINK + "/product/" + props.productId}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </Flex>
      <Flex marginInlineStart={"0.5em"}>
        <PinterestShareButton
          url={OpenAPI.LINK + "/product/" + props.productId}
          media={props.imageUrl}
        >
          <PinterestIcon size={32} round={true} />
        </PinterestShareButton>
      </Flex>
    </Flex>
  );
};

export default ShareButtons;
