import React, { useEffect } from "react";
import ProductCard from "../../general/product-card/ProductCard";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import ImageSlider from "../../general/image-slider/ImageSlider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface Product {
  id: number;
  thumbnail: string;
}
interface IProps {
  home: any | null;
  images?: any | null;
  handleChooseProduct: (product: any) => void;
}
const Offer = (props: IProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <Box mt={"20px"}>
      <Flex justifyContent={"space-between"} padding={"0 20px"}>
        <Text
          className="font-bold "
          fontSize={{ base: "20px", md: "20px", lg: "40px" }}
          letterSpacing={i18n.language === "ar" ? "0px" : "5px"}
        >
          {" "}
          {t("offer")}
        </Text>
        <Link
          onClick={() => navigate("/offer")}
          fontSize={{ base: "16px", md: "16px", lg: "24px" }}
          type="true"
        >
          {t("see_all")}
        </Link>
      </Flex>

      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr 1fr",
          lg: `1fr 1fr 1fr`,
        }}
        width={"100%"}
        gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
        mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
        justifyItems="center" // Center items horizontally
      >
        {props.home.offers &&
          props?.home?.offers.slice(0, 8).map((product: Product) => {
            return (
              <GridItem
                width={{ base: "90%", md: "100%", lg: "100%" }}
                key={product.id}
              >
                <ProductCard
                  url={product.thumbnail}
                  height="274px"
                  product={product}
                  selectProduct={() => {}}
                  handleChooseProduct={props.handleChooseProduct}
                />
              </GridItem>
            );
          })}
      </Grid>
    </Box>
  );
};

export default Offer;
