import React from "react";
import {
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Select,
  SelectProps,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { FiGift } from "react-icons/fi";

const ObjectTest = () => {
  return (
    <Box
      maxW={{ base: "3xl", lg: "9xl" }}
      mx="auto"
      px={{ base: "4", md: "8", lg: "8" }}
      py={{ base: "6", md: "8", lg: "8" }}
    >
      <Stack
        direction={{ base: "column", lg: "row" }}
        align={{ lg: "flex-start" }}
        spacing={{ base: "8", md: "16" }}
      >
        {" "}
        <Stack
          direction={{ base: "column", lg: "row" }}
          align={{ lg: "flex-start" }}
          spacing={{ base: "8", md: "16" }}
        >
          {" "}
          <Stack spacing="6">
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              align="center"
            >
              <Stack direction="row" spacing="5" width="full">
                <Image
                  rounded="lg"
                  width="120px"
                  height="120px"
                  fit="cover"
                  src={
                    "https://sadaf-group.s3.me-south-1.amazonaws.com/products/7373568580/A03u6Jpd0Hb6Ddc0FgHt20YT3WKCDU53N5PP9GsM.png"
                  }
                  // alt={name}
                  draggable="false"
                  loading="lazy"
                />
                <Box pt="4">
                  <Stack spacing="0.5">
                    <Text fontWeight="medium">Test</Text>
                  </Stack>

                  <HStack
                    spacing="1"
                    mt="3"
                    color={mode("gray.600", "gray.400")}
                  >
                    <Icon as={FiGift} boxSize="4" />
                    <Link fontSize="sm" textDecoration="underline">
                      Add gift wrapping
                    </Link>
                  </HStack>
                </Box>
              </Stack>

              {/* Desktop */}
              <Flex
                width="full"
                justify="space-between"
                display={{ base: "none", md: "flex" }}
              >
                <Flex maxW="64px">
                  {/* <IconButton
                    icon={<FaMinus />}
                    aria-label="Decrease Quantity"
                    // onClick={decreaseProductCart}
                    size="sm"
                    variant="outline"
                    color="white"
                    bgColor="#969696"
                    // marginInlineEnd="1em"
                  />
                  <Text mx="2">5</Text>
                  <IconButton
                    icon={<FaPlus />}
                    aria-label="Increase Quantity"
                    // onClick={increaseProductCart}
                    size="sm"
                    variant="outline"
                    color="white"
                    bgColor="#969696"
                    // marginInlineStart="1em"
                  /> */}
                </Flex>
                <HStack spacing="1">
                  <Text
                    as="span"
                    fontWeight="medium"
                    // color={color}
                    textDecoration={"none"}
                    // {...textProps}
                  >
                    $5000
                  </Text>
                  {/* {salePrice && (
            <SalePrice {...salePriceProps}>
              {formatPrice(salePrice, { currency })}
            </SalePrice>
          )} */}
                </HStack>
                <CloseButton
                  aria-label={`Delete  from cart`}
                  // onClick={onClickDelete}
                />
              </Flex>

              {/* Mobile */}
              <Flex
                mt="4"
                align="center"
                width="full"
                justify="space-between"
                display={{ base: "flex", md: "none" }}
              >
                <Link fontSize="sm" textDecor="underline">
                  Delete
                </Link>
                <Select
                  maxW="64px"
                  aria-label="Select quantity"
                  focusBorderColor={mode("blue.500", "blue.200")}
                  // {...props}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </Select>
                {/* <PriceTag price={price} currency={currency} /> */}
              </Flex>
            </Flex>{" "}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ObjectTest;
