import React, { useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer/Footer";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import RTLWrapper from "../../RTLWrapper";
import { Flex } from "@chakra-ui/react";
const UserLayout = () => {
  const location = useLocation();

  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <RTLWrapper>
      <Flex
        display={"flex"}
        position={"fixed"}
        top={0}
        zIndex={10}
        width={"100%"}
        backgroundColor="rgba(0, 0, 0, 0.7)" // Transparent white background
        backdropFilter="blur(8px)" // Blur effect
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)" // Optional shadow
      >
        <Navbar isBlack={false} />
      </Flex>
      <Flex display={"flex"} flexDirection={"column"} maxW={"100%"}>
        <Outlet />
      </Flex>
    </RTLWrapper>
  );
};

export default UserLayout;
