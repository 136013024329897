import { HStack, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React from "react";

interface Props {
  navigateUrl: string;
  category: {
    id: number;
    name: string;
    image: string;
    icon: string;
  };
}
const CategoryCard = ({ navigateUrl, category }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(navigateUrl);
      }}
      className="rounded-lg"
    >
      <div className="relative rounded-lg flex justify-center cursor-pointer lg:h-[220px] lg:w-[220px] xs:h-[120px] xs:w-[120px]">
        {category?.image && (
          <img
            alt="category photo"
            className="bg-cover w-full rounded-lg"
            src={category?.image}
          />
        )}

        {/* <div className="h-[270px]  w-[270px] top-0  absolute opacity-50 bg-black hover:opacity-30 transition-opacity "></div> */}

        <div
          className={`absolute rounded-lg font-bold  text-center z-2 lg:text-[16px] xs:text-[10px] ${
            category?.image
              ? "bg-black bg-opacity-50 hover:bg-opacity-30 ease-in-out transition text-white "
              : "text-black shadow-md"
          }  w-full h-full `}
        >
          <VStack
            className="relative lg:top-[70px] xs:top-[35px]"
            direction={"column"}
          >
            <img
              className="block lg:w-[50px] lg:h-[50px] xs:h-[30px] xs:w-[30px]"
              alt="bathroom"
              src={category?.icon}
            />
            <Text className="block">{category?.name}</Text>
          </VStack>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
