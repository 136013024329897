import React, { useState } from "react";
import {
  Flex,
  Box,
  Card,
  CardBody,
  Image,
  Text,
  CardFooter,
  Button,
  SimpleGrid,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { FaStar, FaHeart } from "react-icons/fa"; // Import your desired icons

interface IProps {}

const Test: React.FC<IProps> = (props) => {
  const textStyle = {
    fontSize: "14px",
    fontWeight: "bold",
  };

  const smallPrice = {
    fontSize: "10px",
    fontWeight: "bold",
  };

  const [isHovered, setIsHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    "https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    "https://sadaf-group.s3.me-south-1.amazonaws.com/products/4230607609/nu3iSAgt1EQmHKoBDY8gg2oUZbP33DobEIBguS7T.jpg",
    "https://sadaf-group.s3.me-south-1.amazonaws.com/products/8552232218/w9Im3tenZ1D3K9gfQOrAZl5QbYqEI3dxG5yfIsbG.jpg",
    // Add more image URLs here
  ];

  const handleImageChange = (index: number) => {
    setCurrentImageIndex(index);
  };
  return (
    <Flex justifyContent="center" alignItems="center">
      <Grid
        templateColumns={["1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]} // Adjust column layout for responsiveness
        gap={6} // Adjust gap between grid items as needed
      >
        {[0, 1, 2, 4, 5].map((index) => (
          <GridItem width="15em" key={index}>
            <Card
              width="100%"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                src={images[currentImageIndex]}
                alt="Green double couch with wooden legs"
                maxW="100%"
                maxH="100%"
                height={"325px"}
                objectFit="cover"
              />
              {isHovered && (
                <Box position="absolute" top="0" right="0" padding="2">
                  <Box>
                    <Flex flexDirection="row" alignItems="center">
                      <Box marginRight="2">
                        <Box
                          backgroundColor="white"
                          borderRadius="full"
                          padding="2"
                          boxShadow="md"
                          display="inline-block"
                        >
                          <FaHeart size={24} color="red" />
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          backgroundColor="white"
                          borderRadius="full"
                          padding="2"
                          boxShadow="md"
                          display="inline-block"
                        >
                          <FaStar size={24} color="gold" />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              )}
              <CardBody maxH="65px">
                <Flex
                  width={"100%"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box overflow="hidden" width={"7em"} minWidth={"7em"}>
                    <Text style={textStyle} noOfLines={2}>
                      This sofa is perfect for modern tropical spaces, baroque
                      inspired spaces, earthy toned spaces and for people who
                      love a chic design with a sprinkle of vintage design.
                    </Text>
                  </Box>
                  {!isHovered ? (
                    <Flex flexDirection={"column"}>
                      <Text color="#910202" fontSize="2xl" style={textStyle}>
                        $45000
                      </Text>
                      <Text color="#969696" fontSize="md" style={smallPrice}>
                        $45000
                      </Text>
                    </Flex>
                  ) : (
                    images.map((image, dashIndex) => (
                      <Box
                        key={dashIndex}
                        w="40"
                        h="1"
                        borderRadius="full"
                        backgroundColor={
                          dashIndex === currentImageIndex
                            ? "blue.500"
                            : "gray.300"
                        }
                        marginX="1"
                        onClick={() => handleImageChange(dashIndex)}
                        cursor="pointer"
                      />
                    ))
                  )}
                </Flex>
              </CardBody>
              {isHovered ? (
                <CardFooter maxH="65px">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width={"100%"}
                  >
                    <Flex flexDirection={"column"}>
                      <Text color="#910202" fontSize="2xl" style={textStyle}>
                        $45000
                      </Text>
                      <Text color="#969696" fontSize="md" style={smallPrice}>
                        $45000
                      </Text>
                    </Flex>
                    <Flex minWidth={"2em"}>
                      <Button
                        size="sm"
                        variant="solid"
                        bgColor="#171717"
                        color={"white"}
                      >
                        Add To Cart
                      </Button>
                    </Flex>
                  </Flex>
                </CardFooter>
              ) : null}
            </Card>
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );
};

export default Test;
