import React, { useState } from "react";
import {
  Flex,
  Box,
  Card,
  CardBody,
  Image,
  Text,
  CardFooter,
  Button,
  SimpleGrid,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
  Input,
} from "@chakra-ui/react";
import { FaStar, FaHeart } from "react-icons/fa"; // Import your desired icons

interface IProps {}

const PayCard: React.FC<IProps> = (props) => {
  const textStyle = {
    color: "white",
    fontSize: "16px",
  };

  const smallPrice = {
    fontSize: "10px",
    fontWeight: "bold",
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Card maxW="sm" width={"425px"} bgColor={"#707070"}>
        <CardBody>
          <Stack mt="6" spacing="3">
            <Heading size="md">
              <Text color="white">Payment Summary</Text>
            </Heading>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              width={"100%"}
              mt="2.5em"
              mb="2.5em"
            >
              <Flex flexDirection={"column"}>
                <Input placeholder="Coupon Code" size="sm" />
              </Flex>
              <Flex flexDirection={"column"}>
                <Button bgColor={"white"} color="#171717" size="sm">
                  Apply
                </Button>
              </Flex>
            </Flex>
            <Box mb="5em">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width={"100%"}
                padding={5}
              >
                <Text style={textStyle}>Subtotal</Text>
                <Text style={textStyle}>IQD 4.000.000</Text>
              </Flex>
              <Divider />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width={"100%"}
                padding={5}
              >
                <Text style={textStyle}>Coupon</Text>
                <Text style={textStyle}>IQD 4.000.000</Text>
              </Flex>
              <Divider />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width={"100%"}
                padding={5}
              >
                <Text style={textStyle}>Delivery price</Text>
                <Text style={textStyle}>IQD 4.000.000</Text>
              </Flex>
              <Divider />
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width={"100%"}
                padding={5}
              >
                <Text style={textStyle}>Total price</Text>
                <Text style={textStyle}>IQD 4.000.000</Text>
              </Flex>
              <Divider />
            </Box>
          </Stack>
        </CardBody>
        {/* <Divider /> */}
        <CardFooter padding={0}>
          <Button
            borderRadius={0}
            size="lg"
            width="100%"
            variant="ghost"
            bgColor={"#171717"}
            color={"white"}
            padding={10}
            // colorScheme="#171717"
          >
            Checkout
          </Button>
        </CardFooter>
      </Card>
    </Flex>
  );
};

export default PayCard;
