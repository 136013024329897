import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import error from "../svg/error.svg";

interface IProps {
  onNext: () => void;
}
const RequestIsDone = ({ onNext }: IProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box width={"100%"}>
      <Grid templateColumns="1fr">
        <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
          {window.location.href.includes("error") ? (
            <img
              src={error}
              style={{ width: "100%", height: "20em" }}
              // className="w-[200px] h-[200px] cursor-pointer"
              alt=""
            />
          ) : (
            <img
              src="/images/shoppingCart/success_order.png"
              // className="w-[200px] h-[200px] cursor-pointer"
              style={{ width: "20em", height: "20em" }}
              alt=""
            />
          )}
        </Flex>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          {window.location.href.includes("error") ? (
            <Text
              style={{ fontWeight: "normal" }}
              fontSize={{ base: "20px", md: "20px", lg: "40px" }}
            >
              {" "}
              error
            </Text>
          ) : (
            <Text
              style={{ fontWeight: "normal" }}
              fontSize={{ base: "20px", md: "20px", lg: "40px" }}
            >
              {" "}
              {t("successfully")}
            </Text>
          )}
          <div style={{ fontWeight: "normal" }} className=" text-[px] ">
            {" "}
            {t("new_check_email")}
          </div>
        </Flex>
        <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <a href="/">
            <Button
              backgroundColor={"black"}
              color={"white"}
              _hover={{ bgColor: "#333131" }}
              borderRadius={"0px"}
              width={"200px"}
              className="mt-[30px] "
              onClick={() => {}}
            >
              {t("done")}
            </Button>
          </a>{" "}
        </Flex>
      </Grid>
    </Box>
  );
};

export default RequestIsDone;
