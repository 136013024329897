import { InputGroup, Input, Button, InputRightElement } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/context";
import { IRegisterPayload } from "../../api/gen/models/AuthService/models";
import Controller from "../../components/form-components/controller";
import RTLWrapper from "../../RTLWrapper";
import { useTranslation } from "react-i18next";

const SignUpPage = () => {
  const [passwordActive, setPasswordActive] = useState({
    main: false,
    confirm: false,
  });
  const navigate = useNavigate();

  const {
    actions: { register, setEmail },
    loading,
  } = useContext(AuthContext);
  const methods = useForm<IRegisterPayload>({
    defaultValues: {
      dob: "2020-10-20",
    },
  });
  const [dob, setDob] = useState({ day: "", month: "", year: "" });

  const [samePasswords, setSamePasswords] = useState(true);
  const { handleSubmit, control } = methods;

  const onSignUp = (data: IRegisterPayload) => {
    if (password === password1) {
      register({ ...data, dob: `${dob.year}-${dob.month}-${dob.day}` });
    } else {
      setSamePasswords(false);
    }
  };
  const email = useWatch({
    control,

    name: "email_or_phone",
  });
  const password = useWatch({
    control,

    name: "password",
  });

  //@ts-ignore
  const password1 = useWatch({
    control,
    //@ts-ignore
    name: "password1",
  });

  const { t } = useTranslation();

  useEffect(() => {
    setEmail(email);
  }, [email]);
  useEffect(() => {
    if (!samePasswords) setSamePasswords(true);
  }, [password, password1]);
  return (
    <RTLWrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSignUp)}>
          <div>
            <div className=" lg:hidden md:flex justify-between p-[15px] bg-black">
              <img
                className="cursor-pointer h-[41px]"
                width={"200px"}
                src="/images/login/logo.png"
                onClick={() => {
                  navigate("/");
                }}
                alt=""
              />
              <img
                className="cursor-pointer h-[32px]"
                width={"122px"}
                onClick={() => {
                  navigate(-1);
                }}
                src="/images/login/backicon.png"
                alt=""
              />
            </div>
            <div className="flex flex-row w-full overflow-y-scroll  h-full  ">
              {/* first part */}
              <div
                className="xl:block lg:block md:hidden sm:hidden xs:hidden w-1/2 h-screen  bg-no-repeat bg-cover"
                style={{
                  backgroundImage: "url(/images/login/login.png)",
                  backgroundSize: "cover",
                }}
              >
                <div className="flex justify-between p-[15px]">
                  <img
                    className="cursor-pointer h-[41px]"
                    width={"200px"}
                    src="/images/login/logo.png"
                    onClick={() => {
                      navigate("/");
                    }}
                    alt=""
                  />
                  <img
                    className="cursor-pointer h-[32px]"
                    width={"122px"}
                    onClick={() => {
                      navigate(-1);
                    }}
                    src="/images/login/backicon.png"
                    alt=""
                  />
                </div>
              </div>
              {/* second part */}
              <div className=" lg:w-1/2 md:w-full sm:w-full xs:w-full w-1/2 h-full flex flex-col justify-center content-center items-center lg:px-0 md:px-[0px] sm:px-[0px]  xs:px-[10px]  py-[47px]">
                <div className="flex flex-col gap-[30px]">
                  <div className="flex flex-col gap-[30px]">
                    <div className="font-bold p-0 lg:text-[36px] xs:text-[20px] ">
                      {t("signUp")}
                    </div>
                    <Controller
                      name="name"
                      //@ts-ignore
                      control={control}
                      rules={{ required: t(`fieldIsRequired`) }}
                      render={({ field: { ...field } }) => {
                        return (
                          <InputGroup
                            {...field}
                            className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px] "
                          >
                            <Input
                              type="text"
                              placeholder={t("fullName")}
                              border={"none"}
                              _focus={{ border: "none", boxShadow: "none" }}
                            />
                          </InputGroup>
                        );
                      }}
                    />
                    <Controller
                      name="email_or_phone"
                      //@ts-ignore
                      control={control}
                      rules={{ required: t(`fieldIsRequired`) }}
                      render={({ field: { ...field } }) => {
                        return (
                          <InputGroup
                            {...field}
                            className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px]  "
                          >
                            <Input
                              type="text"
                              placeholder={t("phone") + ": 07********"}
                              border={"none"}
                              _focus={{ border: "none", boxShadow: "none" }}
                            />
                          </InputGroup>
                        );
                      }}
                    />
                    <div className=" flex  items-center justify-between gap-2">
                      <div className=" font-[600] ">{t("birthday")} </div>
                      <div className=" flex   gap-3 ">
                        <InputGroup
                          w={"60px"}
                          h={"60px"}
                          px={"5px"}
                          className=" flex justify-center items-center bg-[#F8F8F8]    "
                        >
                          <Input
                            type="number"
                            sx={{ fontWeight: "500", textAlign: "center" }}
                            w={"40px"}
                            onChange={(val) => {
                              setDob({ ...dob, day: val.target.value });
                            }}
                            placeholder={t("day")}
                            border={"none"}
                            _focus={{ border: "none", boxShadow: "none" }}
                            p={"0px"}
                            _placeholder={{
                              px: "5px",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          />
                        </InputGroup>
                        <InputGroup
                          w={"60px"}
                          h={"60px"}
                          px={"5px"}
                          className=" w-[75px] flex justify-center items-center bg-[#F8F8F8]   "
                        >
                          <Input
                            type="number"
                            sx={{ fontWeight: "500", textAlign: "center" }}
                            onChange={(val) => {
                              setDob({ ...dob, month: val.target.value });
                            }}
                            placeholder={t("month")}
                            border={"none"}
                            _focus={{ border: "none", boxShadow: "none" }}
                            p={"0px"}
                            _placeholder={{
                              px: "5px",
                              fontSize: "9px",
                              fontWeight: "500",
                            }}
                          />
                        </InputGroup>
                        <InputGroup
                          w={"60px"}
                          h={"60px"}
                          px={"5px"}
                          className=" flex justify-center items-center bg-[#F8F8F8]  "
                        >
                          <Input
                            sx={{ fontWeight: "500", textAlign: "center" }}
                            _placeholder={{
                              px: "5px",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                            type="number"
                            onChange={(val) => {
                              setDob({ ...dob, year: val.target.value });
                            }}
                            placeholder={t("year")}
                            border={"none"}
                            _focus={{ border: "none", boxShadow: "none" }}
                            p={"0px"}
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <Controller
                      name="password"
                      //@ts-ignore
                      control={control}
                      rules={{
                        required: t(`fieldIsRequired`),
                        minLength: { message: t("atLeast8Char"), value: 8 },
                      }}
                      render={({ field: { ...field } }) => {
                        return (
                          <InputGroup
                            {...field}
                            className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px]  "
                          >
                            <Input
                              type={!passwordActive.main ? "password" : "text"}
                              placeholder={t("password")}
                              border={"none"}
                              _focus={{ border: "none", boxShadow: "none" }}
                            />
                            <img
                              className="cursor-pointer"
                              onClick={() => {
                                setPasswordActive({
                                  ...passwordActive,
                                  main: !passwordActive.main,
                                });
                              }}
                              src={
                                !passwordActive.main
                                  ? "/images/login/passwordUnActive.png"
                                  : "/images/login/passwordActive.png"
                              }
                              alt=""
                            />
                          </InputGroup>
                        );
                      }}
                    />
                    <Controller
                      name="password1"
                      //@ts-ignore
                      control={control}
                      rules={{
                        required: t(`fieldIsRequired`),
                        minLength: { message: t("atLeast8Char"), value: 8 },
                      }}
                      render={({ field: { ...field } }) => {
                        return (
                          <InputGroup
                            {...field}
                            className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px] content-center "
                          >
                            <Input
                              type={
                                !passwordActive.confirm ? "password" : "text"
                              }
                              placeholder={t("password")}
                              border={"none"}
                              _focus={{ border: "none", boxShadow: "none" }}
                            />
                            <img
                              className="cursor-pointer "
                              onClick={() => {
                                setPasswordActive({
                                  ...passwordActive,
                                  confirm: !passwordActive.confirm,
                                });
                              }}
                              src={
                                !passwordActive.confirm
                                  ? "/images/login/passwordUnActive.png"
                                  : "/images/login/passwordActive.png"
                              }
                              alt=""
                            />
                          </InputGroup>
                        );
                      }}
                    />
                    {!samePasswords && (
                      <p
                        style={{
                          content: "⚠ ",
                        }}
                        className="text-[red] "
                      >
                        كلمات السر غير متطابقة
                      </p>
                    )}
                  </div>
                  <Button
                    borderRadius={"0px"}
                    backgroundColor={"black"}
                    _hover={{ bgColor: "#000000" }}
                    color={"white"}
                    type="submit"
                    isDisabled={loading.includes("register")}
                    isLoading={loading.includes("register")}
                  >
                    {t("signUp")}
                  </Button>

                  {/* <div className="flex flex-row content-between justify-between ">
                    <div className="flex gap-[23px]">
                      <div className=""> Help</div>
                      <img
                        width={"19px"}
                        height={"19px"}
                        src="/images/login/helpIcon.png"
                        alt=""
                      />
                    </div>
                    <div className="flex gap-[23px]">
                      <div className=""> Privacy</div>
                      <img
                        width={"19px"}
                        height={"19px"}
                        src="/images/login/privecyIcon.png"
                        alt=""
                      />
                    </div>{" "}
                    <div className="flex gap-[23px]">
                      <div className=""> {t("termsAndConditions")}</div>
                      <img
                        width={"19px"}
                        height={"19px"}
                        src="/images/login/termsIcon.png"
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </RTLWrapper>
  );
};

export default SignUpPage;
