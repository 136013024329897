import React, { useEffect, useRef, useState } from "react";
import RTLWrapper from "../../../RTLWrapper";
import {
  Box,
  Button,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Rating } from "react-simple-star-rating";
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  InstapaperShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";
import { ACCESS_TOKEN } from "../../../utils/helpers/constants";
import { OpenAPI } from "../../../api/gen";
import { useNavigate } from "react-router-dom";
import ThreeDComponent from "../../../pages/product-details/3d-product/ThreeDComponent";
import { CloseIcon } from "@chakra-ui/icons";
import { FaHeart, FaInstagram, FaRegHeart } from "react-icons/fa6";

interface Props {
  productId: number;
  spinner: any;
  product: any;
  cartSpinner: string | null;
  selectedProduct?: any;
  onClick: () => void;
  onClose: () => void;
  onAddFavorite: any;
  onDeleteFavorite: any;
  isFavorite: boolean;
  setIsFavorite: any;
}
const QuickViewProduct = (props: Props) => {
  const storedValue = localStorage.getItem("currency");
  const finalRef = React.useRef(null);
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isRead, setIsRead] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [isMoreThanThreeLines, setIsMoreThanThreeLines] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredWhatsapp, setIsHoveredWhatsapp] = useState(false);
  const [isHoveredTwitter, setIsHoveredTwitter] = useState(false);
  const [isHoveredInstagram, setIsHoveredInstagram] = useState(false);
  useEffect(() => {
    if (descriptionRef.current && props.selectedProduct?.description) {
      const lineHeight = parseInt(
        getComputedStyle(descriptionRef.current).lineHeight
      );
      const descriptionHeight = descriptionRef.current.clientHeight;
      const threeLinesHeight = lineHeight * 3;

      setIsMoreThanThreeLines(descriptionHeight > threeLinesHeight);
    }
  }, [props.selectedProduct?.description]);

  const onClickFavorite = (productId: number) => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      if (props.isFavorite) {
        props.setIsFavorite(false);
        props.onDeleteFavorite(productId);
      } else {
        props.onAddFavorite(productId);
        props.setIsFavorite(true);
      }
    } else {
      navigate("/login");
    }
  };

  const handleInstagramShare = () => {
    const url = `${OpenAPI.LINK}/product/${props.selectedProduct?.id}`;
    // Generate the share URL (you might want to use a service like bit.ly to shorten this)
    const shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  return (
    <RTLWrapper>
      {props.selectedProduct?.threed_images &&
      props.selectedProduct?.threed_images.length > 0 ? (
        isOpen ? (
          <Box
            position="fixed"
            zIndex={9999}
            top={0}
            left={0}
            width="100%"
            height="100%"
            backgroundColor="rgba(0, 0, 0, 0.6)"
            backdropFilter="blur(10px)"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex justifyContent="flex-end" width="100%">
              <IconButton
                aria-label="Close"
                icon={<CloseIcon />}
                onClick={onClose}
                bg="transparent"
                border="none"
                _hover={{ bg: "transparent", color: "gray.500" }}
                _active={{ bg: "transparent" }}
              />
            </Flex>
            <ThreeDComponent file={props.selectedProduct?.threed_images[0]} />
          </Box>
        ) : null
      ) : null}

      <DrawerContent>
        {/* <DrawerCloseButton /> */}
        {/* <DrawerHeader>Create your account</DrawerHeader> */}
        <DrawerHeader>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex
              className="cursor-pointer"
              onClick={() => {
                setIsRead(false);
                props.onClose();
              }}
            >
              <Image
                src={
                  direction === "rtl"
                    ? "/images/quick/right.png"
                    : "/images/quick/left.png"
                }
                alt="left"
                width={{ base: "18px", md: "18px", lg: "36px" }}
              />
            </Flex>
            <Text fontSize={{ base: "10px", md: "10px", lg: "20px" }}>
              {t("details")}
            </Text>
            <Flex
              className="cursor-pointer"
              onClick={() => {
                localStorage.getItem(ACCESS_TOKEN)
                  ? navigate("/shopping-cart")
                  : navigate("/login");
              }}
            >
              <Image
                src="/images/quick/cart.png"
                alt="cart"
                height={{ base: "14px", md: "14px", lg: "27px" }}
                width={{ base: "14px", md: "14px", lg: "27px" }}
              />
            </Flex>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <Flex flexDirection={"column"}>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Image
                width="100%"
                height={{ base: "8em", md: "6em", lg: "12em" }}
                maxHeight="12em"
                src={props.selectedProduct?.thumbnail}
                alt="product"
                mb={"1em"}
                borderRadius={"10px"}
              />
            </Flex>
            {/* ssss */}
            <Flex justifyContent={"flex-end"}>
              {props?.selectedProduct?.threed_images ? (
                props?.selectedProduct?.threed_images.length > 0 ? (
                  <Image
                    src="/images/product/3d.png"
                    alt="3d"
                    className="cursor-pointer"
                    width={{ base: "18px", md: "18px", lg: "36px" }}
                    height={{ base: "18px", md: "18px", lg: "36px" }}
                    onClick={() => {
                      onOpen();
                    }}
                  />
                ) : null
              ) : null}
            </Flex>
          </Flex>
          {/* <div className="flex justify-center gap-2 mt-2">
    <Image src="/images/quick/left.svg" alt="left" />
    <Image src="/images/quick/right.svg" alt="right" />
  </div> */}{" "}
          <Flex flexDirection={"column"}>
            <Flex flexDirection={"column"}>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text
                  style={{
                    color: "#171717",
                    fontWeight: "normal",
                  }}
                  fontSize={{ base: "0.75rem", md: "0.75rem", lg: "1.5rem" }}
                >
                  {props.selectedProduct?.name}
                </Text>
                {localStorage.getItem(ACCESS_TOKEN) ? (
                  props.isFavorite ? (
                    <Box
                      cursor={"pointer"}
                      width={{ base: "18px", md: "18px", lg: "36px" }}
                      height={{ base: "18px", md: "18px", lg: "36px" }}
                      onClick={() =>
                        props.spinner != "loading"
                          ? onClickFavorite(props.productId)
                          : null
                      }
                    >
                      <FaHeart fontSize={"large"} />
                    </Box>
                  ) : (
                    <Box
                      cursor={"pointer"}
                      width={{ base: "18px", md: "18px", lg: "36px" }}
                      height={{ base: "18px", md: "18px", lg: "36px" }}
                      onClick={() =>
                        props.spinner != "loading"
                          ? onClickFavorite(props.productId)
                          : null
                      }
                    >
                      <FaRegHeart fontSize={"large"} />
                    </Box>
                  )
                ) : (
                  <Box
                    cursor={"pointer"}
                    width={{ base: "18px", md: "18px", lg: "36px" }}
                    height={{ base: "18px", md: "18px", lg: "36px" }}
                    onClick={() =>
                      props.spinner != "loading"
                        ? onClickFavorite(props.productId)
                        : null
                    }
                  >
                    <FaRegHeart fontSize={"large"} />
                  </Box>
                )}
              </Flex>
              <Flex justifyContent={"flex-start"}>
                <Rating
                  initialValue={props.selectedProduct?.rating}
                  fillColor="#85550B"
                  readonly
                  size={22}
                />
                <Text
                  style={{
                    color: "#7B7B7B",
                  }}
                  fontSize={{ base: "0.5rem", md: "0.5rem", lg: "0.75rem" }}
                  marginInlineStart={"10px"}
                  mt="10px"
                >
                  {" "}
                  {props.selectedProduct?.reviews?.length} {t("reviews")}
                </Text>
              </Flex>
              <Flex
                justifyContent={"flex-start"}
                mt="1em"
                flexDirection={"column"}
              >
                <Text
                  color="#910202"
                  style={{ fontWeight: "bold" }}
                  fontSize={{ base: "8px", md: "10px", lg: "12px" }}
                >
                  {storedValue === "USD" ? "$ " : "IQD "}
                  {props.selectedProduct?.price ?? null}
                </Text>
                {props.selectedProduct?.discount_price &&
                  props.selectedProduct?.discount_price != 0 && (
                    <Text
                      color="#969696"
                      style={{ fontWeight: "bold" }}
                      fontSize={{ base: "6px", md: "6px", lg: "10px" }}
                    >
                      {storedValue === "USD" ? "$ " : "IQD "}
                      {props.selectedProduct?.discount_price}
                    </Text>
                  )}
              </Flex>
            </Flex>

            <Flex flexDirection={"column"} mt="1em">
              <Text fontSize={{ base: "12px", md: "12px", lg: "16px" }}>
                {t("description")}
              </Text>
              <Text
                ref={descriptionRef}
                mt="0.5em"
                fontSize={{ base: "10px", md: "10px", lg: "14px" }}
                style={
                  isMoreThanThreeLines && !isRead
                    ? {
                        color: "#7B7B7B",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3, // Limiting to 3 lines
                        textOverflow: "ellipsis",
                      }
                    : {
                        color: "#7B7B7B",
                      }
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.selectedProduct?.description ?? "",
                  }}
                  // className="text-[#7B7B7B] ms-5 lg:text-[14px] xs:text-[10px]"
                ></div>
              </Text>
              {isMoreThanThreeLines ? (
                <Flex justifyContent={"center"} alignItems={"center"}>
                  <Text
                    mt="5px"
                    style={{
                      color: "#7B7B7B",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    fontSize={{ base: "10px", md: "10px", lg: "14px" }}
                    onClick={() => {
                      setIsRead(!isRead);
                    }}
                  >
                    {isRead ? "read less" : "read more"}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </DrawerBody>

        <DrawerFooter>
          {/* Add to cart */}

          <Flex width={"100%"} flexDirection={"column"}>
            <Button
              bg="black"
              mt={0}
              borderRadius={"unset"}
              width={"100%"}
              fontWeight={"medium"}
              _hover={{ bg: "rgba(0, 0, 0, 0.748)" }}
              textColor={"white"}
              h={{ base: "30px", md: "30px", lg: "50px" }}
              size="sm"
              fontSize={{ base: "10px", md: "10px", lg: "14px" }}
              isLoading={props.cartSpinner === "loading" ? true : false}
              onClick={props.onClick}
            >
              {t("add_to_cart")}
            </Button>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              mt="1em"
            >
              <FacebookShareButton
                url={OpenAPI.LINK + "/product/" + props.selectedProduct?.id}
              >
                <Image
                  onMouseEnter={() => setIsHoveredFacebook(true)} // Set hover state to true
                  onMouseLeave={() => setIsHoveredFacebook(false)}
                  src={
                    isHoveredFacebook
                      ? "/images/quick/facebook-hover.png"
                      : "/images/quick/facebook.png"
                  }
                  alt="facebook"
                />
              </FacebookShareButton>
              <WhatsappShareButton
                url={OpenAPI.LINK + "/product/" + props.selectedProduct?.id}
              >
                <Image
                  onMouseEnter={() => setIsHoveredWhatsapp(true)} // Set hover state to true
                  onMouseLeave={() => setIsHoveredWhatsapp(false)}
                  src={
                    isHoveredWhatsapp
                      ? "/images/quick/whatsapp-hover.png"
                      : "/images/quick/whatsapp.png"
                  }
                  alt="whatsapp"
                />
              </WhatsappShareButton>
              {/* <TwitterShareButton
                url={OpenAPI.LINK + "/product/" + props.selectedProduct?.id}
              >
                <Image
                  onMouseEnter={() => setIsHoveredTwitter(true)} // Set hover state to true
                  onMouseLeave={() => setIsHoveredTwitter(false)}
                  src={
                    isHoveredTwitter
                      ? "/images/quick/twitter-hover.png"
                      : "/images/quick/twitter.png"
                  }
                  alt="twitter"
                />
              </TwitterShareButton> */}
              <div
                onClick={handleInstagramShare}
                style={{
                  background: isHoveredInstagram ? "#000" : undefined,
                  padding: "10px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onMouseEnter={() => setIsHoveredInstagram(true)}
                onMouseLeave={() => setIsHoveredInstagram(false)}
              >
                <FaInstagram
                  style={{
                    color: isHoveredInstagram ? "#fff" : "#777",
                    fontSize: "larger",
                  }}
                />
              </div>
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </RTLWrapper>
  );
};

export default QuickViewProduct;
