import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RTLWrapper from "../../RTLWrapper";
import { useTranslation } from "react-i18next";
import CodePage from "../forgot-password/CodePage";
import NewPasswordPage from "../forgot-password/NewPasswordPage";
import VerificationType from "../forgot-password/VerificationType";
import PhoneNumber from "./PhoneNumber";
import { useForm, useWatch } from "react-hook-form";
import AuthContext from "../../context/auth/context";

const NumberVerification = ({
  isRegisterCode,
}: {
  isRegisterCode?: boolean;
}) => {
  const [active, setActive] = useState(0);
  const { t } = useTranslation();
  const onContinue = () => {
    if (active > 1) setActive(0);
    else setActive(active + 1);
  };
  const navigate = useNavigate();
  const {
    actions: { setEmail, resendCode },
    loading,
  } = useContext(AuthContext);

  return (
    <RTLWrapper>
      <div className=" lg:hidden md:flex justify-between p-[15px] bg-black">
        <img
          className="cursor-pointer h-[41px]"
          width={"200px"}
          src="/images/login/logo.png"
          onClick={() => {
            navigate("/");
          }}
          alt=""
        />
        <img
          className="cursor-pointer h-[32px]"
          width={"122px"}
          onClick={() => {
            navigate(-1);
          }}
          src="/images/login/backicon.png"
          alt=""
        />
      </div>
      <div className="flex flex-row w-full   h-screen  ">
        {/* first part */}
        <div
          className="xl:block lg:block md:hidden sm:hidden xs:hidden w-1/2 h-full  p-[15px]s bg-no-repeat bg-cover"
          style={{
            backgroundImage: "url(/images/login/login.png)",
            backgroundSize: "cover",
          }}
        >
          <div className="flex justify-between p-[15px]">
            <img
              className="cursor-pointer h-[41px]"
              width={"200px"}
              src="/images/login/logo.png"
              onClick={() => {
                navigate("/");
              }}
              alt=""
            />
            <img
              className="cursor-pointer h-[32px]"
              width={"122px"}
              onClick={() => {
                navigate(-1);
              }}
              src="/images/login/backicon.png"
              alt=""
            />
          </div>
        </div>
        {/* second part */}
        <div className=" lg:w-1/2 md:w-full sm:w-full xs:w-full w-1/2 h-full flex flex-col justify-center content-center items-center lg:px-0 md:px-[0px] sm:px-[0px]  xs:px-[10px]  py-[47px]">
          <div className="flex flex-col gap-[60px]">
            <div className="flex flex-col gap-[50px]">
              {active == 0 && <PhoneNumber onContinue={setActive} />}
              {active == 1 && (
                <CodePage
                  isRegisterCode={isRegisterCode}
                  onContinue={setActive}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </RTLWrapper>
  );
};

export default NumberVerification;
