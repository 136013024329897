// Notification.tsx
import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";

interface NotificationProps {
  message: string;
}

const NotificationsTest: React.FC<NotificationProps> = ({ message }) => {
  const priceStyle = {
    color: "#171717",
    fontSize: "1rem",
    fontWeight: "bold",
  };
  const titleStyle = {
    color: "#171717",
    fontSize: "1rem",
    fontWeight: "normal",
  };
  const productNameStyle = {
    color: "#969696",
    fontSize: "1rem",
    fontWeight: "bold",
  };
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      p="4"
      height="25%"
      bgColor="#FFFFFF"
      color="white"
      zIndex="999"
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={{ base: 4, md: 8 }}
      >
        <GridItem colSpan={1}>
          {/* Content for the first grid item */}
          {/* Add your content here */}
          <Flex justifyContent={"space-around"}>
            <Text style={titleStyle}>
              {" "}
              <CheckIcon boxSize={5} color="#171717" />
              Add to cart
            </Text>
            <img src="" />
            <Image
              src={
                "https://sadaf-group.s3.me-south-1.amazonaws.com/products/8241359518/8PKK3htflkivIkFWpFLyMytae5hhbQhcmIP8s9VD.png"
              }
              alt="Green double couch with wooden legs"
              maxW="100%"
              maxH="100%"
              height={"9.37em"}
              objectFit="cover"
              // onClick={() => navigate(`/product/${product.id}`)}
            />
            <Flex>
              <Flex flexDirection={"column"} mt="2.5em">
                <Text style={productNameStyle}>table modern</Text>
                <Text
                  // color="#969696"
                  // fontSize="md"
                  mt="0.5em"
                  style={priceStyle}
                >
                  10.000
                </Text>
              </Flex>
            </Flex>{" "}
          </Flex>
        </GridItem>
        {/* <Divider orientation="vertical" /> */}
        <GridItem colSpan={1}>
          {/* Content for the second grid item */}
          {/* Add your content here */}
          <Flex justifyContent={"space-around"}>
            <Flex flexDirection={"column"} mt={"1em"}>
              <Text style={titleStyle}>Total Cart</Text>
              <Button
                variant="outline"
                size="sm"
                mt="2em"
                padding={3}
                borderColor={"#171717"}
              >
                View Cart
              </Button>
            </Flex>
            <Flex flexDirection={"column"} mt={"1em"}>
              <Text style={priceStyle}>$1500.00</Text>
              <Button
                size="sm"
                mt="2em"
                padding={3}
                colorScheme="#171717"
                bgColor={"#171717"}
              >
                Checkout
              </Button>
            </Flex>
            <Flex>
              {" "}
              <CloseButton color={"#171717"} />
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default NotificationsTest;
