import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  useCategoryParamsId,
  useParamsId,
} from "../../utils/hooks/useParamsId";
import { useTranslation } from "react-i18next";

interface Props {
  categoryList?: any;
  subCategoryList?: any;
}

function getCategoryName(categoriesList: any, id: number | undefined) {
  if (categoriesList)
    for (let i = 0; i < categoriesList.data.length; i++)
      if (categoriesList.data[i].id === id) return categoriesList.data[i].name;
}

export default function BreadCrumbComponent(props: Props) {
  const location = useLocation();
  // const crumbs = location.pathname
  //   .split("/")
  //   .filter((crumb) => crumb !== "")
  //   .map((crumb, index, array) => {
  //     // Construct the path up to the current crumb
  //     const path = "/" + array.slice(0, index + 1).join("/");
  //     // Check if the current crumb represents a link with an ID
  //     const crumbWithId = crumb.match(/^(\w+)_(\d+)$/); // Matches "word_id" pattern
  //     if (crumbWithId) {
  //       const [, entity, id] = crumbWithId; // Destructure the matched parts
  //       return (
  //         <div key={crumb}>
  //           <BreadcrumbItem>
  //             <BreadcrumbLink>
  //               <Link to={path}>
  //                 {entity} {id}
  //               </Link>
  //             </BreadcrumbLink>
  //           </BreadcrumbItem>
  //           {index !== array.length - 1 ? (
  //             <ChevronRightIcon color="gray.500" />
  //           ) : null}
  //         </div>
  //       );
  //     }
  //     console.log("props.categoryList", props.categoryList);
  //     console.log("crumb", crumb);
  //     return (
  //       <div key={crumb}>
  //         <BreadcrumbItem
  //           fontFamily={"Makro XM"}
  //           fontSize={{ base: "8px", md: "8px", lg: "16px" }}
  //         >
  //           <BreadcrumbLink>
  //             <Link to={path}>
  //               {getCategoryName(props.categoryList, crumb)}
  //             </Link>
  //           </BreadcrumbLink>
  //         </BreadcrumbItem>
  //         {index !== array.length - 1 ? (
  //           <ChevronRightIcon
  //             color="gray.500"
  //             fontSize={{ base: "8px", md: "8px", lg: "16px" }}
  //           />
  //         ) : null}
  //       </div>
  //     );
  //   });
  const { t } = useTranslation();
  const subCategoryId = useParamsId();
  const categoryId = useCategoryParamsId();
  return (
    <Flex justifyContent={"flex-start"} alignItems={"center"}>
      <Breadcrumb spacing="8px">
        <BreadcrumbItem fontSize={{ base: "8px", md: "8px", lg: "16px" }}>
          <BreadcrumbLink>
            <Link to={`/`}>{t("home")}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {/* <ChevronRightIcon
          color="gray.500"
          fontSize={{ base: "8px", md: "8px", lg: "16px" }}
        /> */}
        <BreadcrumbItem fontSize={{ base: "8px", md: "8px", lg: "16px" }}>
          <BreadcrumbLink>
            <Link to={`/${categoryId}`}>
              {getCategoryName(props.categoryList, categoryId)}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {subCategoryId ? (
          <BreadcrumbItem fontSize={{ base: "8px", md: "8px", lg: "16px" }}>
            <BreadcrumbLink>
              <Link to={`/${categoryId}/${subCategoryId}`}>
                {getCategoryName(props.subCategoryList, subCategoryId)}
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
        ) : null}
      </Breadcrumb>
    </Flex>
  );
}
