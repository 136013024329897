import { Box, Button, Flex, Image, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination]);

interface Props {
  heightImage: string;
  subTitle?: string;
  images?: any;
}
const ImageSlider = ({ images }: Props) => {
  const swiperRef = useRef<any>(null);
  const handleNext = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 7000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Swiper
      loop={true}
      autoplay={{ delay: 7000 }}
      className="mySwiper w-full"
      ref={swiperRef}
      navigation={false}
      pagination={{ clickable: true }} // Enable pagination dots
    >
      {images.map((image: any, index: number) => (
        <SwiperSlide key={index}>
          <Box position="relative" aspectRatio={4 / 1.2} cursor="pointer">
            <Image
              src={image.image}
              alt="Background Image"
              height="100%"
              width="100%"
              loading="lazy"
              borderRadius="20px"
              border="0.5px solid #A5A5A5"
              onClick={
                image.action
                  ? () => {
                      window.open(image.action);
                    }
                  : () => {}
              }
            />
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageSlider;
