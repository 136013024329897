import { Box, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import ProductContainer from "./product-container/ProductContainer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FlashBanner from "./flashBanner";

export interface Action {
  type: string;
  res?: any;
  err?: any;
}
interface IProps {
  home: any | null;
  videos: any | null;
  handleChooseProduct: (product: any) => void;
  sliders: any | null;
}

const textStyle = {
  color: "#171717",
  fontWeight: "bold",
};
const FlashDeal: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  if (props?.home?.flash_deals)
    return (
      <Box mt="20px">
        <Flex justifyContent={"space-between"} padding={"0 20px"}>
          <Text
            className="font-bold"
            fontSize={{ base: "20px", md: "20px", lg: "40px" }}
            letterSpacing={i18n.language === "ar" ? "0px" : "5px"}
          >
            {t("flash_deal")}
          </Text>
          <Link
            onClick={() => navigate("/flash-deal")}
            fontSize={{ base: "16px", md: "16px", lg: "24px" }}
            type="true"
          >
            {t("see_all")}
          </Link>
        </Flex>
        <FlashBanner
          flashDeals={props.home.flash_deals}
          sliders={props.sliders}
        />
        <Flex justifyContent={"center"} alignItems={"center"}>
          <ProductContainer
            relatedProducts={props.home.flash_deals}
            handleChooseProduct={props.handleChooseProduct}
          />
        </Flex>
      </Box>
    );
  else return null;
};

export default FlashDeal;
