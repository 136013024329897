import { Dispatch } from "redux";
import { OpenAPI } from "../../api/gen/core/OpenAPI";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { ApiRequestOptions } from "../../api/gen/core/ApiRequestOptions";
import { RootState } from "../reducers/rootReducer";

export interface Action {
  type: string;
  res?: any;
  err?: any;
}

const storedValue = localStorage.getItem("currency");
const language = localStorage.getItem("language");

export const getHomePage = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined | null = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_HOME_SPINNER" });
    if (token != "null") {
      axios
        .get(`${OpenAPI.BASE}/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_HOME_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_HOME_ERROR", err });
        });
    } else {
      axios
        .get(`${OpenAPI.BASE}/home`, {
          headers: { lang: language, currency: storedValue },
        })
        .then((res) => {
          dispatch({ type: "GET_HOME_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_HOME_ERROR", err });
        });
    }
  };
};

export const getNavCoverImages = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined | null = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_SLIDERS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/ads/sliders`, {
        headers: { lang: language, currency: storedValue },
      })
      .then((res) => {
        dispatch({ type: "GET_SLIDERS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_SLIDERS_ERROR", err });
      });
  };
};

export const getVideos = (): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_VIDEOS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/ads/videos`, {
        headers: { lang: language, currency: storedValue },
      })
      .then((res) => {
        dispatch({ type: "GET_VIDEOS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_VIDEOS_ERROR", err });
      });
  };
};

export const getFlashSliders = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_Flashes_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/ads/flashes`, {
        headers: { lang: language, currency: storedValue },
      })
      .then((res) => {
        dispatch({ type: "GET_Flashes_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_Flashes_ERROR", err });
      });
  };
};

export const getHomeBanners = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_Banners_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/ads/banners`, {
        headers: { lang: language, currency: storedValue },
      })
      .then((res) => {
        dispatch({ type: "GET_Banners_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_Banners_ERROR", err });
      });
  };
};

export const getNotifications = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_NOTIFICATIONS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_NOTIFICATIONS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_NOTIFICATIONS_ERROR", err });
      });
  };
};
