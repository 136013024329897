import React, { useRef, useContext, useEffect } from "react";
import CategoryCard from "./category-card/CategoryCard";
import { motion } from "framer-motion";
import "./style.css";
import { useDraggable } from "react-use-draggable-scroll";
import CategoriesContext from "../../../context/categories/context";

interface Props {
  navigateUrl: string;
}

const CategoryContainer = ({ navigateUrl }: Props) => {
  const { list } = useContext(CategoriesContext);

  const ref = useRef<HTMLDivElement>(null!);
  const { events } = useDraggable(ref);

  useEffect(() => {
    // Ensure ref.current is not null before accessing its properties
    if (ref.current) {
      // Set the scroll position to the top when the component mounts
      ref.current.scrollTop = 0;
    }
  }, []);

  return (
    <div
      className="flex mt-[20px] scroll overflow-x-scroll gap-[30px] p-[0px] xs:gap-[15px]"
      {...events}
      ref={ref}
      style={{ padding: "50px 30px" }}
    >
      {list?.data?.map((category) => (
        <CategoryCard
          key={category.id}
          navigateUrl={`/${category?.id}`}
          category={category}
        />
      ))}
    </div>
  );
};

export default CategoryContainer;
