import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

import ProductCard from "../../components/general/product-card/ProductCard";
import SubCategoryContainer from "../../components/sub-category-container/SubCategoryContainer";

import CategoriesContext from "../../context/categories/context";
import ProductsContext from "../../context/products/context";

import {
  useCategoryParamsId,
  useParamsId,
} from "../../utils/hooks/useParamsId";
import CartNotificaiton from "../shopping-cart/CartNotification";
import {
  Action,
  removeLoadingElements,
} from "../../store/actions/orderActions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store/reducers/rootReducer";
import { useLocation } from "react-router-dom";
import PaginationButtons from "../../components/pagination-buttons/PaginattioButtons";
import BreadCrumbComponent from "../../components/bread-crumb/BreadCrumbComponent";
import { useTranslation } from "react-i18next";
import { pageView } from "../../config/FirebaseConfig";

interface StateProps {
  cartSpinner: string | null;
  showNotification: boolean;
}

interface DispatchProps {
  onRemoveLoadingElements: () => void;
}

interface IProps {}
const Category: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const categoryId = useCategoryParamsId();
  const didMountRef = useRef(false);
  const { list: categoryList } = useContext(CategoriesContext);
  const {
    products: productList,
    currentPage: currentPage,
    totalPages: totalPages,
    actions: productActions,
    loading,
  } = useContext(ProductsContext);
  const { pathname } = useLocation();
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollPosition = scrollTop + windowHeight;

      // You can adjust the threshold value as needed
      const threshold = 0.5;

      if (scrollPosition >= documentHeight * threshold) {
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      // Call your pagination function here
      // For example: fetchMoreData();
      handleChangePage(currentPage + 1);
    }
  }, [shouldLoadMore]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { t } = useTranslation();

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>();

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  const handleChangePage = (page: number) => {
    if (productList)
      if (page > 0 && page <= totalPages)
        productActions.getProducts({ category_id: categoryId, page: page });
  };

  useEffect(() => {
    if (!didMountRef.current) {
      productActions.getProducts({ category_id: categoryId, page: 1 });
      didMountRef.current = true;
    }
  }, [categoryId, productActions]);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  const renderProducts = () => {
    if (!productList || loading.includes("products")) {
      return (
        <Center height="100vh">
          <Spinner size="xl" />
        </Center>
      );
    }
    if (productList && productList.length > 0)
      return (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: `1fr 1fr 1fr`,
          }}
          width={"100%"}
          gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
          mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
          justifyItems={"center"}
        >
          {" "}
          {productList.map((product: any) => (
            <GridItem
              width={{ base: "90%", md: "100%", lg: "100%" }}
              key={product.id}
            >
              <ProductCard
                key={product.id}
                id={product.id}
                url={product.thumbnail}
                name={product.name}
                discountPrice={product.discount_price}
                price={product.price}
                product={product}
                selectProduct={() => productActions.getProductById(product.id)}
                handleChooseProduct={handleChooseProduct}
              />
            </GridItem>
          ))}
          {showNotification ? (
            <CartNotificaiton
              message="Item added to cart!"
              product={product}
              handleCloseNotification={handleCloseNotification}
            />
          ) : null}
        </Grid>
      );
    else
      return (
        <Grid templateColumns={["1fr"]} width="100%" mt="50px">
          <Center width={"100%"}>
            <img src="/images/empty/empty-products.png" />
          </Center>{" "}
          <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
            <Text fontSize={"16px"} fontWeight={"bold"}>
              {t("emptyProducts")}
            </Text>
          </Flex>
        </Grid>
      );
  };
  return (
    <Box
      px={{ base: "1rem", md: "none", lg: "120px" }}
      mt={{ base: "4em", md: "4em", lg: "9.375em" }}
    >
      {/* <div className="pl-[140px] mt-[150px]"> */}
      <BreadCrumbComponent categoryList={categoryList} />
      <SubCategoryContainer />
      {/* </div> */}

      {/* <div className="w-full flex-col flex justify-center items-center p-[140px] pt-[0px] pb-[0px]"> */}
      {/* <ProductContainer /> */}

      {/* <Flex justifyContent="center" alignItems="center"> */}
      {renderProducts()}
      {/* <Flex justifyContent={"center"} alignItems={"center"}>
        <PaginationButtons
          currentPage={productList?.meta.current_page ?? 1}
          totalPages={productList?.meta.total ?? 1}
          handleChangePage={handleChangePage}
        />
      </Flex> */}
      {/* </Flex> */}
      {/* </div> */}
      {/* <div className="p-[140px]"> */}
      {/* </div> */}
    </Box>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    cartSpinner: state.orders.spinner,
    showNotification: state.orders.showNotification,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
