import { Flex, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import VideoPlayer from "../../videos/VideoPlayer";
import { useDraggable } from "react-use-draggable-scroll";
import styles from "./style.module.css";

interface IProps {
  videos: any | null;
}

const ProductVideos = (props: IProps) => {
  const ref = useRef<HTMLDivElement>(null!);
  const { events } = useDraggable(ref);

  useEffect(() => {
    // Ensure ref.current is not null before accessing its properties
    if (ref.current) {
      // Set the scroll position to the top when the component mounts
      ref.current.scrollTop = 0;
    }
  }, []);
  // if (urls.length > 0)
  return (
    <div
      className="flex mt-[20px] scroll overflow-x-scroll gap-[30px] grid-container width-[100%] xs:mt-[10px]"
      {...events}
      ref={ref}
    >
      {props.videos &&
        props.videos.length > 0 &&
        props.videos.map((url: any) => {
          const updatedUrl = url.action.replace("watch?v=", "embed/");
          // if (url.position != "main")
          return (
            // <GridItem colSpan={1}>
            <div key={url.id}>
              <VideoPlayer url={url.action} />
            </div>
            // </GridItem>
          );
        })}
    </div>
  );
  // else return null;
};

export default ProductVideos;
