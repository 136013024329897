import { ArrowRightIcon, ChevronRightIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  Modal,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store/reducers/rootReducer";
import styles from "./style.module.css";
import ProductContainer from "../../components/main-layout/flash-deal/product-container/ProductContainer";
import { Rating } from "react-simple-star-rating";
import ProductDetailsItemReview from "./product-details-item-review/ProductDetailsItemReview";
import ProductsContext from "../../context/products/context";
import { useParamsId } from "../../utils/hooks/useParamsId";
import CartContext from "../../context/cart/context";
import {
  addFavorite,
  Action,
  deleteFavorite,
  updateFavorite,
} from "../../store/actions/productActions";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import { useLocation, useNavigate } from "react-router-dom";
import RTLWrapper from "../../RTLWrapper";
import Countdown from "../../components/general/countdown/Countdown";
import { useTranslation } from "react-i18next";
import CartNotificaiton from "../shopping-cart/CartNotification";
import ShareButtons from "../../components/main-layout/social-media-share/ShareButtons";
import VideoPlayer from "../../components/videos/VideoPlayer";
import ThreeDComponent from "./3d-product/ThreeDComponent";
import {
  addToCart,
  removeLoadingElements,
} from "../../store/actions/orderActions";
import { pageEvent, pageView } from "../../config/FirebaseConfig";
import ProductImages from "./product-images/ProductImages";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { FiZoomIn } from "react-icons/fi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdKeyboardArrowRight,
  MdOutlineZoomOutMap,
} from "react-icons/md";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
interface StateProps {
  spinner: string | null;
  favorite: string | null;
  cartSpinner: string | null;
  showNotification: boolean;
}

interface ProductType {
  id: number;
  name: string;
  thumbnail: string;
  in_stock: boolean;
  price: string;
  discount_price: string;
  discount_end_at: string;
  color: any;
  size: string;
  created_at: string;
  description: string;
  images: string[];
  threed_images: string[];
}

interface OptionType {
  id: number;
  thumbnail: string;
  price: string;
  discount_price: string;
  discount_end_at: string;
  size: string;
  color: any;
  currency: string;
  images?: string[];
}

interface colorType {
  id: number;
  color: any;
}

interface sizeType {
  id: number;
  size: string;
}

interface dateType {
  id: number;
  date: string;
}

interface imageType {
  id: number;
  image: string;
}

interface DispatchProps {
  onAddFavorite: (productId: number | undefined) => void;
  onDeleteFavorite: (productId: number | undefined) => void;
  onUpdateFavorite: (isFavorite: boolean | null) => void;
  onAddToCart: (productId: number) => void;
  onRemoveLoadingElements: () => void;
}
interface IProps {}
const ProductDetails: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  const {
    productDetails,
    actions: { getProductById },
  } = useContext(ProductsContext);
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  const storedValue = localStorage.getItem("currency");
  const id = useParamsId();
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };
  const [productsArray, setProductsArray] = useState<
    (ProductType | OptionType)[]
  >([]);
  const [colorsArray, setColorsArray] = useState<colorType[]>(
    [] as colorType[]
  );
  const [sizesArray, setSizesArray] = useState<sizeType[] | null>(
    [] as sizeType[]
  );
  const [datesArray, setDatesArray] = useState<dateType[]>([] as dateType[]);
  const [isBoxVisible, setBoxVisible] = useState<boolean>(false);
  const [chooseProduct, setChooseProduct] = useState<any>(false);
  const [mainProduct, setMainProduct] = useState<number>(
    productDetails?.data?.id ?? 0
  );
  const [selectedProduct, setSelectedProduct] = useState<number>(
    productDetails?.data?.id ?? 0
  );
  const [selectedOption, setSelectedOption] = useState<number>();
  const [selectedColor, setSelectedColor] = useState<string>();
  const [selectedSize, setSelectedSize] = useState<string>();
  const [selectedVideo, setSelectedVideo] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<number>(-1);
  const [isFavorite, setIsFavorite] = React.useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });
  const {
    loading,
    actions: { addProductToCart, removeLoadingElements },
  } = useContext(CartContext);
  let COUNDDOWN_TARGET: Date = new Date("2023-11-31T23:59:59");
  useEffect(() => {
    id && getProductById(id);
  }, [id]);

  function groupBy<T, K extends keyof any>(
    list: T[] | undefined,
    getKey: (item: T) => K
  ): Record<K, T[]> | undefined {
    if (list)
      return list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
      }, {} as Record<K, T[]>);
  }
  useEffect(() => {
    if (productDetails?.data) {
      const newArray: (ProductType | OptionType)[] = [
        {
          id: productDetails.data.id,
          name: productDetails.data.name,
          thumbnail: productDetails.data.thumbnail,
          in_stock: productDetails.data.in_stock,
          price: productDetails.data.price,
          discount_price: productDetails.data.discount_price,
          discount_end_at: productDetails.data.discount_end_at,
          color: productDetails.data.color,
          size: productDetails.data.size,
          created_at: productDetails.data.created_at,
          description: productDetails.data.description,
          images: productDetails.data.images,
          threed_images: productDetails.data.threed_images,
        },
        // Add the options from the options array
        ...productDetails.data.options.map((option) => ({
          id: option.id,
          thumbnail: option.thumbnail,
          price: option.price,
          discount_price: option.discount_price,
          discount_end_at: option.discount_end_at,
          size: option.size,
          color: option.color,
          currency: option.currency,
        })),
      ];
      // Separate arrays for thumbnails, colors, and sizes
      const thumbnailsArray: imageType[] = [
        ...newArray
          .slice(1)
          .map((option) => ({ id: option.id, image: option.thumbnail })),
      ];

      const colorsArray: colorType[] = [
        { id: newArray[0].id, color: newArray[0].color },
        ...newArray
          .slice(1)
          .map((option) => ({ id: option.id, color: option.color })),
      ];

      const sizesArray: sizeType[] | null = newArray[0].size
        ? [
            { id: newArray[0].id, size: newArray[0].size },
            ...newArray.slice(1).map((option) => ({
              id: option.id,
              size: option.size,
            })),
          ]
        : null;

      const datesArray: dateType[] = [
        { id: newArray[0].id, date: newArray[0].discount_end_at },
        ...newArray.slice(1).map((option) => ({
          id: option.id,
          date: option.discount_end_at,
        })),
      ];

      // Set state values
      setProductsArray(newArray);
      setColorsArray(colorsArray);
      setSizesArray(sizesArray);
      setDatesArray(datesArray);
      setSelectedProduct(newArray[0].id);
      setIsFavorite(productDetails?.data?.is_favorite);
      setMainProduct(newArray[0].id);
      if (productDetails?.data?.video_links)
        if (productDetails?.data?.video_links.length > 0)
          setSelectedVideo(productDetails?.data?.video_links[0]);
    }
    if (groupBy(productDetails?.data?.options, (i) => i.color.hex)) {
    }
  }, [productDetails]);
  const { pathname } = useLocation();
  const handleChooseProduct = (product: any) => {
    setChooseProduct(product);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const shareBoxRef = useRef<HTMLDivElement>(null);

  const toggleBoxVisibility = () => {
    setBoxVisible(!isBoxVisible);
  };

  useEffect(() => {
    pageView(window.location.pathname);
    const handleOutsideClick = (event: any) => {
      if (!shareBoxRef.current || shareBoxRef.current.contains(event.target))
        return;
      setBoxVisible(false);
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  // is Review
  const [isReview, setIsReview] = useState(false);
  const navigate = useNavigate();
  const onClick = () => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      setChooseProduct(null);
      productDetails &&
        props.onAddToCart(
          selectedProduct ? selectedProduct : productDetails?.data?.id
        );
    } else navigate("/login");
  };

  const onClickFavorite = (productId: number, is_favorite: boolean) => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      if (isFavorite) {
        setIsFavorite(false);
        props.onDeleteFavorite(productId);
      } else {
        props.onAddFavorite(productId);
        setIsFavorite(true);
      }
    } else {
      navigate("/login");
    }
  };
  //lightbox state
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const setNextImage = () => {
    if (selectedImage == -1) {
      if (
        productDetails?.data?.images &&
        productDetails?.data?.images?.length > 0
      )
        setSelectedImage(0);
    } else {
      // Find the current selected image index
      const currentIndex = productDetails?.data?.images.findIndex(
        (item, index) => index == selectedImage
      );
      // Calculate the next index
      if (
        currentIndex != -1 &&
        currentIndex != undefined &&
        productDetails?.data?.images &&
        productDetails?.data?.images?.length > 0
      ) {
        const nextIndex =
          (currentIndex + 1) % productDetails?.data?.images?.length!;
        setSelectedImage(nextIndex);
      }
    }
  };

  const setPreviousImage = () => {
    if (selectedImage == -1) {
      if (
        productDetails?.data?.images &&
        productDetails?.data?.images?.length! > 0
      ) {
        setSelectedImage(productDetails?.data?.images?.length! - 1);
      }
    } else {
      // Find the current selected image index
      const currentIndex = productDetails?.data?.images.findIndex(
        (item, index) => index == selectedImage
      );
      // Calculate the next index
      if (
        currentIndex &&
        productDetails?.data?.images &&
        productDetails?.data?.images?.length > 0
      ) {
        const nextIndex =
          (currentIndex - 1) % productDetails?.data?.images?.length!;
        setSelectedImage(nextIndex);
      } else if (
        productDetails?.data?.images &&
        productDetails?.data?.images?.length! > 0
      ) {
        setSelectedImage(productDetails?.data?.images?.length! - 1);
      }
    }
  };

  if (productDetails?.data)
    return (
      <RTLWrapper>
        {productDetails?.data?.threed_images &&
        productDetails?.data?.threed_images.length > 0 ? (
          isOpen ? (
            <Box
              position="fixed"
              zIndex={100}
              top={0}
              left={0}
              width="100%"
              height="100%"
              backgroundColor="rgba(0, 0, 0, 0.6)"
              backdropFilter="blur(10px)"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Flex justifyContent="flex-end" width="100%">
                <IconButton
                  aria-label="Close"
                  icon={<CloseIcon />}
                  onClick={onClose}
                  bg="transparent"
                  border="none"
                  _hover={{ bg: "transparent", color: "gray.500" }}
                  _active={{ bg: "transparent" }}
                />
              </Flex>
              <ThreeDComponent file={productDetails?.data?.threed_images[0]} />
            </Box>
          ) : null
        ) : null}

        <Lightbox
          open={lightboxOpen}
          index={selectedImage ? selectedImage : 0}
          plugins={[Zoom]}
          close={() => setLightboxOpen(false)}
          slides={
            productDetails?.data?.images?.length > 0
              ? productDetails?.data?.images?.map((item) => ({
                  src: item,
                }))
              : [{ src: productDetails?.data?.thumbnail }]
          }
        />

        {showNotification ? (
          <CartNotificaiton
            message="Item added to cart!"
            product={
              chooseProduct
                ? chooseProduct
                : selectedProduct
                ? productsArray.find((item) => item.id === selectedProduct)
                : productDetails?.data
            }
            handleCloseNotification={handleCloseNotification}
          />
        ) : null}
        <Box
          mt={{ base: "6em", md: "6em", lg: "9.375em" }}
          px={{ base: "1rem", md: "none", lg: "120px" }}
        >
          {/* <BreadCrumbComponent /> */}

          {/* product */}
          <Flex
            flexDirection="column"
            justifyContent={"center"}
            alignItems={"center"}
            // mx="8em"
            mt="3em"
            bgColor={"white"}
          >
            <Grid
              templateColumns={{ base: "1fr", md: "1.3fr 0.7fr" }}
              width={"100%"}
              gap={3}
            >
              <GridItem colSpan={1} overflowX={"hidden"}>
                <Flex flexDirection={"column"}>
                  <Box /*p={{ base: 2, md: 7, lg: 10 }}*/ maxW={"100%"}>
                    <Flex flexDirection={"column"}>
                      {!selectedVideo ? (
                        selectedProduct == mainProduct ? (
                          <Box
                            position={"relative"}
                            width={{ base: "100%", md: "100%", lg: "100%" }}
                          >
                            <Box
                              position={"relative"}
                              paddingBottom="66.7%" // 16:9 aspect ratio, adjust this value according to your image's aspect ratio
                            >
                              <Image
                                src={
                                  selectedImage < 0
                                    ? productDetails?.data?.thumbnail
                                    : productDetails?.data?.images[
                                        selectedImage
                                      ]
                                }
                                position="absolute"
                                top="0"
                                left="0"
                                width="100%"
                                height="100%"
                                objectFit="contain"
                                alt=""
                                background={"#fff"}
                              />
                            </Box>
                            {productDetails?.data?.images &&
                              productDetails?.data?.images.length > 0 && (
                                <Flex
                                  position={"absolute"}
                                  top="50%"
                                  right={{
                                    base: "10px",
                                    md: "10px",
                                    lg: "30px",
                                  }}
                                  transform="translateY(-50%)"
                                  zIndex={1}
                                  gap={"15px"}
                                  flexDirection={"column"}
                                  justifyContent={"flex-start"}
                                >
                                  <Box
                                    onClick={() => setNextImage()}
                                    cursor={"pointer"}
                                    fontSize={{
                                      base: "30px",
                                      md: "60px",
                                      lg: "60px",
                                    }}
                                    border={"1px solid transparent"}
                                    borderRadius="50%"
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    padding={"10px"}
                                    bg={"#aaa"}
                                    _hover={{
                                      borderColor: {
                                        base: "transparent",
                                        md: "transparent",
                                        lg: "#fff",
                                      },
                                    }}
                                  >
                                    <MdArrowForwardIos color="#fff" />
                                  </Box>
                                </Flex>
                              )}
                            {productDetails?.data?.images &&
                              productDetails?.data?.images.length > 0 && (
                                <Flex
                                  position={"absolute"}
                                  top="50%"
                                  left={{
                                    base: "10px",
                                    md: "10px",
                                    lg: "30px",
                                  }}
                                  transform="translateY(-50%)"
                                  zIndex={1}
                                  gap={"15px"}
                                  flexDirection={"column"}
                                  justifyContent={"flex-start"}
                                >
                                  <Box
                                    onClick={() => setPreviousImage()}
                                    cursor={"pointer"}
                                    fontSize={{
                                      base: "30px",
                                      md: "60px",
                                      lg: "60px",
                                    }}
                                    border={"1px solid transparent"}
                                    borderRadius="50%"
                                    display={"flex"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    padding={"10px 0px 10px 20px"}
                                    bg={"#aaa"}
                                    _hover={{
                                      borderColor: {
                                        base: "transparent",
                                        md: "transparent",
                                        lg: "#fff",
                                      },
                                    }}
                                  >
                                    <MdArrowBackIos color="#fff" />
                                  </Box>
                                </Flex>
                              )}
                          </Box>
                        ) : null
                      ) : (
                        <Flex maxWidth={"100%"}>
                          <VideoPlayer
                            height={
                              isLargeScreen
                                ? !productDetails?.data?.discount_end_at
                                  ? "400px"
                                  : "400px"
                                : "300px"
                            }
                            width={"100%"}
                            url={selectedVideo}
                          />
                        </Flex>
                      )}
                      <ProductImages
                        product={productDetails}
                        setSelectedProduct={setSelectedProduct}
                        setSelectedImage={setSelectedImage}
                        setSelectedVideo={setSelectedVideo}
                      />

                      <Flex
                        position={"absolute"}
                        top={{ base: "105px", md: "120px", lg: "180px" }}
                        marginInlineStart={{
                          base: "15px",
                          md: "15px",
                          lg: "10px",
                        }}
                        // left="5%"
                        // transform={"translateY(-50%)"}
                        zIndex={1}
                        gap={"15px"}
                        justifyContent={"flex-start"}
                        flexDir={{ base: "row", md: "column", lg: "column" }}
                      >
                        <Box
                          className="cursor-pointer bg-white rounded-full flex items-center justify-center"
                          onClick={() =>
                            onClickFavorite(
                              productDetails?.data?.id,
                              productDetails?.data?.is_favorite
                            )
                          }
                          background={"#fff"}
                          h={{ base: "25px", md: "25px", lg: "30px" }}
                          w={{ base: "25px", md: "25px", lg: "30px" }}
                          fontSize={{
                            base: "small",
                            md: "small",
                            lg: "large",
                          }}
                        >
                          {localStorage.getItem(ACCESS_TOKEN) ? (
                            isFavorite ? (
                              <FaHeart />
                            ) : (
                              <FaRegHeart />
                            )
                          ) : (
                            <FaRegHeart />
                          )}
                        </Box>
                        {productDetails?.data?.threed_images &&
                        productDetails?.data?.threed_images.length > 0 ? (
                          <Image
                            src="/images/product/3d-background-icon.png"
                            alt=""
                            className="cursor-pointer"
                            onClick={() => {
                              // window.open("/test3d", "_blank");
                              onOpen();
                            }}
                            h={{ base: "25px", md: "25px", lg: "30px" }}
                            w={{ base: "25px", md: "25px", lg: "30px" }}
                            fontSize={{
                              base: "small",
                              md: "small",
                              lg: "large",
                            }}
                          />
                        ) : null}
                        <Image
                          src={
                            direction === "rtl"
                              ? "/images/product/share-background-icon-rtl.png"
                              : "/images/product/share-background-icon.png"
                          }
                          alt=""
                          className="cursor-pointer"
                          onClick={toggleBoxVisibility}
                          h={{ base: "25px", md: "25px", lg: "30px" }}
                          w={{ base: "25px", md: "25px", lg: "30px" }}
                          fontSize={{
                            base: "small",
                            md: "small",
                            lg: "large",
                          }}
                        />
                        {!selectedVideo && (
                          <Box
                            className="cursor-pointer bg-white rounded-full flex items-center justify-center"
                            onClick={() => setLightboxOpen(true)}
                            h={{ base: "25px", md: "25px", lg: "30px" }}
                            w={{ base: "25px", md: "25px", lg: "30px" }}
                            fontSize={{
                              base: "small",
                              md: "small",
                              lg: "large",
                            }}
                          >
                            <MdOutlineZoomOutMap color="#000" />
                          </Box>
                        )}
                        <Box marginInlineStart={"0.5em"}>
                          {isBoxVisible && (
                            // <Flex >
                            <ShareButtons
                              productId={
                                selectedProduct
                                  ? selectedProduct
                                  : productDetails?.data?.id
                              }
                              imageUrl={productDetails?.data?.thumbnail}
                            />
                            // </Flex>
                          )}
                        </Box>{" "}
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </GridItem>
              <GridItem colSpan={1}>
                <Flex justifyContent="center" alignItems="center">
                  <Card maxW="md" width={"100%"} boxShadow={"none"}>
                    <Flex flexDirection={"column"} p={5}>
                      {/* Product name */}
                      <Text
                        style={{
                          fontWeight: "bold",
                        }}
                        fontSize={{
                          base: "1rem",
                          md: "1rem",
                          lg: "1.5rem",
                        }}
                      >
                        {productDetails?.data?.name}
                      </Text>
                      {/* Rating */}
                      {productDetails?.data?.reviews?.length > 0 && (
                        <Flex
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Flex justifyContent={"center"} alignItems={"center"}>
                            <Rating
                              initialValue={productDetails?.data?.rating}
                              fillColor="#85550B"
                              readonly
                              size={22}
                            />
                          </Flex>
                          <Text
                            mt="8px"
                            marginInlineStart={{
                              base: "1em",
                              md: "1em",
                              lg: "2em",
                            }}
                            style={{
                              fontWeight: "regular",
                            }}
                            fontSize={{
                              base: "0.5rem",
                              md: "0.5rem",
                              lg: "0.75rem",
                            }}
                          >
                            {productDetails?.data?.reviews?.length + " "}{" "}
                            {t("reviews")}
                          </Text>
                        </Flex>
                      )}

                      {/* Price */}
                      <Flex
                        mt="20px"
                        justifyContent={"flex-start"}
                        alignItems={{
                          base: "flex-start",
                          md: "center",
                          lg: "center",
                        }}
                        flexWrap={"wrap"}
                        flexDirection={{ base: "column", md: "row", lg: "row" }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                          }}
                          fontSize={{
                            base: "0.6rem",
                            md: "0.6rem",
                            lg: "1.5rem",
                          }}
                        >
                          {storedValue === "USD" ? "$ " : "IQD "}
                          {selectedOption
                            ? productDetails?.data?.options?.filter(
                                (op) => op.id === selectedOption
                              )[0]?.price
                            : productDetails?.data?.price}
                        </Text>
                        {selectedOption ? (
                          productDetails?.data?.options?.filter(
                            (op) => op.id === selectedOption
                          )[0]?.discount_price !== "0" ? (
                            <Text
                              marginInlineStart={{
                                sm: "0",
                                md: "1em",
                                lg: "2em",
                              }}
                              style={{
                                fontWeight: "bold",
                                color: "#AAAAAA",
                              }}
                              fontSize={{
                                base: "0.6rem",
                                md: "0.6rem",
                                lg: "0.875rem",
                              }}
                            >
                              {storedValue === "USD" ? "$ " : "IQD "}
                              {selectedOption
                                ? productDetails?.data?.options?.filter(
                                    (op) => op.id === selectedOption
                                  )[0]?.discount_price
                                : productDetails?.data?.discount_price}
                            </Text>
                          ) : null
                        ) : productDetails?.data?.discount_price !== "0" ? (
                          <Text
                            marginInlineStart={{
                              sm: "0",
                              md: "1em",
                              lg: "2em",
                            }}
                            style={{
                              fontWeight: "bold",
                              color: "#AAAAAA",
                            }}
                            fontSize={{
                              base: "0.6rem",
                              md: "0.6rem",
                              lg: "0.875rem",
                            }}
                          >
                            {storedValue === "USD" ? "$ " : "IQD "}
                            {selectedOption
                              ? productDetails?.data?.options?.filter(
                                  (op) => op.id === selectedOption
                                )[0]?.discount_price
                              : productDetails?.data?.discount_price}
                          </Text>
                        ) : null}
                      </Flex>

                      {/* Flash Section */}
                      {/* {productDetails?.data?.discount_end_at ? ( */}
                      {/* <Flex mt="1em"> */}

                      <Flex mt="38px">
                        {productDetails?.data?.discount_end_at ? (
                          <Text
                            style={{
                              fontWeight: "regular",
                              color: "#171717",
                            }}
                            fontSize={{
                              base: "14px",
                              md: "14px",
                              lg: "16px",
                            }}
                          >
                            {t("flash_deal")}
                          </Text>
                        ) : null}
                      </Flex>
                      {productDetails?.data?.discount_end_at ? (
                        <Flex mt="1em" width="100%">
                          <Countdown
                            endDate={productDetails?.data?.discount_end_at}
                          />
                        </Flex>
                      ) : null}
                      {/* </Flex> */}
                      {/* ) : null} */}

                      {/* Colors */}

                      <Text
                        mt={{ base: "0.5em", md: "0.5em", lg: "20px" }}
                        style={{
                          fontWeight: "regular",

                          color: "#171717",
                        }}
                        fontSize={{
                          base: "14px",
                          md: "14px",
                          lg: "16px",
                        }}
                      >
                        {t("colors")}
                      </Text>
                      <Grid
                        templateColumns={`1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr`}
                        width={"100%"} // Adjust gap between grid items as needed
                        mt="20px"
                      >
                        {colorsArray &&
                          colorsArray.map((option) => {
                            return (
                              <div
                                key={option.id}
                                onClick={() => {
                                  setSelectedProduct(option.id);
                                  setSelectedVideo(null);
                                  if (option.id === mainProduct)
                                    setSelectedImage(-1);
                                }}
                                style={{
                                  backgroundColor: option.color.hex,
                                  marginInlineStart: "5px",
                                }}
                                className={` cursor-pointer ${
                                  selectedProduct == option.id
                                    ? " border-[2px] "
                                    : ""
                                } border-solid  border-black  h-[30px] w-[30px] rounded-full bg-[${
                                  option.color.hex
                                }] `}
                              ></div>
                            );
                          })}
                      </Grid>

                      {/* Descrription */}
                      {productDetails?.data?.description && (
                        <Text
                          style={{
                            fontWeight: "regular",
                            color: "#171717",
                          }}
                          fontSize={{
                            base: "14px",
                            md: "14px",
                            lg: "16px",
                          }}
                          mt="20px"
                        >
                          {t("descriptionOfSizes")}
                        </Text>
                      )}
                      <Text
                        mt="20px"
                        style={{
                          fontWeight: "regular",
                          color: "#7B7B7B",
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3, // Limiting to 3 lines
                          textOverflow: "ellipsis",
                        }}
                        fontSize={{
                          base: "12px",
                          md: "12px",
                          lg: "14px",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: productDetails?.data?.description ?? "",
                          }}
                          // className="text-[#7B7B7B] ms-5 lg:text-[14px] xs:text-[10px]"
                        ></div>
                      </Text>
                      {sizesArray && (
                        <Text
                          mt="20px"
                          style={{
                            fontWeight: "regular",

                            color: "#171717",
                          }}
                          fontSize={{
                            base: "14px",
                            md: "14px",
                            lg: "16px",
                          }}
                        >
                          {t("sizes")}
                        </Text>
                      )}
                      <Flex
                        gap={2}
                        width={"100%"} // Adjust gap between grid items as needed
                        mt="20px"
                      >
                        {sizesArray &&
                          sizesArray.map((option) => {
                            return option.size ? (
                              <div
                                key={option.id}
                                onClick={() => {
                                  setSelectedProduct(option.id);
                                  setSelectedVideo(null);
                                  if (option.id === mainProduct)
                                    setSelectedImage(-1);
                                }}
                                className={
                                  selectedProduct
                                    ? selectedProduct === option.id
                                      ? "cursor-pointer rounded-md text-[12px] h-fit font-bold text-white bg-black w-fit py-[8px] px-[13px] whitespace-nowrap xs:text-[10px]"
                                      : "cursor-pointer rounded-md text-[12px] h-fit font-bold   w-fit py-[8px] px-[13px] whitespace-nowrap xs:text-[10px]"
                                    : "cursor-pointer rounded-md text-[12px] h-fit font-bold   w-fit py-[8px] px-[13px] whitespace-nowrap xs:text-[10px]"
                                }
                                // className="cursor-pointer rounded-md text-[12px] h-fit font-bold   w-fit py-[8px] px-[13px] whitespace-nowrap"
                              >
                                {option?.size}
                              </div>
                            ) : null;
                          })}
                      </Flex>

                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        mt="20px"
                      >
                        <Button
                          bg="black"
                          borderRadius={"unset"}
                          width={"100%"}
                          fontWeight={"medium"}
                          _hover={{ bg: "rgba(0, 0, 0, 0.748)" }}
                          textColor={"white"}
                          h="56px"
                          size="sm"
                          isLoading={
                            props.cartSpinner === "loading" ? true : false
                          }
                          onClick={onClick}
                        >
                          {t("add_to_cart")}
                        </Button>
                      </Flex>
                    </Flex>
                  </Card>
                </Flex>
              </GridItem>
            </Grid>

            {/*apo*/}
          </Flex>
          {/* Description + review */}
          <Flex
            flexDirection="column"
            justifyContent={"center"}
            alignItems={"center"}
            // mx="8em"
            mt="3em"
          >
            <Grid
              templateColumns={{ base: "1fr", md: "1fr 2fr" }}
              width={"100%"}
              gap={1}
            >
              <GridItem colSpan={1}>
                {/* Description + Review Section */}
                <div
                  onClick={() => setIsReview(false)}
                  className={`font-bold cursor-pointer ${
                    isReview
                      ? "text-[#C4C4C4] hover:text-black transition"
                      : "text-black"
                  }`}
                >
                  <Text fontSize={{ base: "12px", md: "12px", lg: "20px" }}>
                    {" "}
                    {t("description")}
                  </Text>
                </div>
                <div
                  onClick={() => setIsReview(true)}
                  className={`font-bold cursor-pointer ${
                    isReview
                      ? "text-black"
                      : "text-[#C4C4C4] hover:text-black transition"
                  }`}
                >
                  <Text fontSize={{ base: "12px", md: "12px", lg: "20px" }}>
                    {t("reviews")}
                  </Text>
                </div>
              </GridItem>
              <GridItem colSpan={1}>
                {isReview ? (
                  <div dir="rtl" className={styles.productDetailsReview}>
                    {productDetails?.data?.reviews &&
                      productDetails?.data?.reviews.map((review) => {
                        return <ProductDetailsItemReview review={review} />;
                      })}
                  </div>
                ) : (
                  <div className={styles.productDetailsDescription}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: productDetails?.data?.description ?? "",
                      }}
                      className="text-[#7B7B7B] lg:ms-5 lg:text-[14px] xs:text-[10px]"
                    ></div>
                  </div>
                )}
              </GridItem>
            </Grid>{" "}
          </Flex>
          {productDetails?.data?.related_products ? (
            productDetails?.data?.related_products.length > 0 ? (
              <Flex
                flexDirection="column"
                justifyContent={"flex-start"}
                // alignItems={"center"}
                // mx="8em"
                mt={{ base: "1em", md: "1em", lg: "3em" }}
              >
                <Text
                  fontWeight={"bold"}
                  fontSize={{ base: "12px", md: "12px", lg: "24px" }}
                >
                  {" "}
                  {t("related_products")}
                </Text>

                <ProductContainer
                  relatedProducts={productDetails?.data?.related_products}
                  handleChooseProduct={handleChooseProduct}
                />
              </Flex>
            ) : null
          ) : null}
        </Box>
      </RTLWrapper>
    );
  else return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.user.spinner,
    favorite: state.products.spinner,
    cartSpinner: state.orders.spinner,
    showNotification: state.orders.showNotification,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onAddFavorite: (productId: number | undefined) =>
    dispatch(addFavorite(productId)),
  onDeleteFavorite: (productId: number | undefined) =>
    dispatch(deleteFavorite(productId)),
  onUpdateFavorite: (isFavorite: boolean | null) =>
    dispatch(updateFavorite(isFavorite)),
  onAddToCart: (productId: number) => dispatch(addToCart(productId)),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
