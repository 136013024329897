// Notification.tsx
import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import { useContext } from "react";
import CartContext from "../../context/cart/context";
import { useTranslation } from "react-i18next";

interface NotificationProps {
  message: string;
  product: any | null;
  handleCloseNotification: () => void;
}

const CartNotificaiton: React.FC<NotificationProps> = ({
  message,
  product,
  handleCloseNotification,
}) => {
  const storedValue = localStorage.getItem("currency");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    loading,
    cartDetails,

    actions: { deleteProductCart },
  } = useContext(CartContext);
  const priceStyle = {
    color: "#171717",
    fontWeight: "bold",
  };
  const titleStyle = {
    color: "#171717",
    fontWeight: "normal",
  };
  const productNameStyle = {
    color: "#969696",
    fontWeight: "bold",
  };
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      py={{ base: "10px", md: "10px", lg: "35px" }}
      // p={{ base: "1", md: "1", lg: "4" }}
      // height={{ base: "15%", md: "15%", lg: "220px" }}
      bgColor="#FFFFFF"
      color="white"
      zIndex="999"
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid
        templateColumns={{ base: "1fr 1fr", md: "1fr  1fr" }}
        gap={{ base: 2, md: 0 }}
        width={{ base: "100%", md: "100%", lg: "80%" }}
      >
        <GridItem colSpan={1}>
          {/* Content for the first grid item */}
          {/* Add your content here */}
          <Flex
            justifyContent={{
              base: "center",
              md: "center",
              lg: "space-around",
            }}
          >
            <Text
              style={titleStyle}
              fontSize={{ base: "0.5rem", md: "0.5rem", lg: "1rem" }}
              display={["none", "none", "inherit"]}
            >
              <CheckIcon
                boxSize={{ base: 2.5, md: 2.5, lg: 5 }}
                color="#171717"
              />{" "}
              {t("addedToCart")}
            </Text>
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Image
                src={product.thumbnail}
                alt="Green double couch with wooden legs"
                maxW={{ base: "75px", md: "75px", lg: "150px" }}
                maxH={{ base: "75px", md: "75px", lg: "150px" }}
                height={{ base: "75px", md: "75px", lg: "150px" }}
                width={{ base: "75px", md: "75px", lg: "150px" }}
                objectFit="cover"
                // onClick={() => navigate(`/product/${product.id}`)}
              />
            </Flex>
            <Flex display={["none", "none", "inherit"]}>
              <Flex
                flexDirection={"column"}
                mt={{ base: "1em", md: "1em", lg: "2.5em" }}
              >
                <Text
                  style={productNameStyle}
                  fontSize={{ base: "0.5rem", md: "0.5rem", lg: "1rem" }}
                >
                  {product?.name}
                </Text>
                <Text
                  // color="#969696"
                  // fontSize="md"
                  mt="0.5em"
                  fontSize={{ base: "0.5rem", md: "0.5rem", lg: "1rem" }}
                  style={priceStyle}
                >
                  {storedValue === "USD" ? "$ " : "IQD "}
                  {product.price ?? "90.00"}
                </Text>
              </Flex>
            </Flex>{" "}
          </Flex>
        </GridItem>

        <GridItem colSpan={1}>
          {/* Content for the second grid item */}
          {/* Add your content here */}
          <Flex justifyContent={"space-around"}>
            <Flex
              flexDirection={"column"}
              mt={{ base: "0.5em", md: "0.5em", lg: "1em" }}
            >
              <Text
                style={titleStyle}
                fontSize={{ base: "0.5rem", md: "0.5rem", lg: "1rem" }}
              >
                {t("totalCart")}
              </Text>
              <Button
                variant="outline"
                width={{ base: "90%", md: "90%", lg: "200px" }}
                height={{ base: "24px", md: "24px", lg: "50px" }}
                mt={{ base: "30px", md: "30px", lg: "60px" }}
                color={"#171717"}
                borderColor={"#171717"}
                fontSize={{ base: "8px", md: "8px", lg: "14px" }}
                // isLoading={loading.includes("remove_products")}
                onClick={() => {
                  if (localStorage.getItem(ACCESS_TOKEN)) {
                    deleteProductCart(product.id);
                    handleCloseNotification();
                  } else {
                    navigate("/login");
                  }
                }}
              >
                {t("delete")}
              </Button>
            </Flex>
            <Flex
              flexDirection={"column"}
              mt={{ base: "0.5em", md: "0.5em", lg: "1em" }}
            >
              <Text
                style={priceStyle}
                fontSize={{ base: "0.5rem", md: "0.5rem", lg: "1rem" }}
              >
                {" "}
                {storedValue === "USD" ? "$ " : "IQD "}
                {product.price ?? "90.00"}
              </Text>
              <Button
                variant="solid"
                bgColor="#171717"
                width={{ base: "90%", md: "90%", lg: "200px" }}
                height={{ base: "24px", md: "24px", lg: "50px" }}
                mt={{ base: "30px", md: "30px", lg: "60px" }}
                color={"white"}
                fontSize={{ base: "8px", md: "8px", lg: "14px" }}
                onClick={() => {
                  localStorage.getItem(ACCESS_TOKEN)
                    ? navigate("/shopping-cart")
                    : navigate("/login");
                }}
              >
                {t("checkout")}
              </Button>
            </Flex>
            <Flex>
              {" "}
              <CloseButton
                onClick={handleCloseNotification}
                color={"#171717"}
              />
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default CartNotificaiton;
