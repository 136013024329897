// RTLWrapper.tsx
import React, { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface RTLWrapperProps {
  children: ReactNode;
}

const RTLWrapper: React.FC<RTLWrapperProps> = ({ children, ...props }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Retrieve the language from local storage
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  return (
    <div
      style={{
        direction:
          i18n.language === "ar" || i18n.language === "ku" ? "rtl" : "ltr",
      }}
    >
      {children}
    </div>
  );
};

export default RTLWrapper;
