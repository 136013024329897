import React, { useEffect } from "react";
import ProductCard from "../Components/product-card/ProductCard";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import {
  getFavoriteProducts,
  Action,
} from "../../../store/actions/productActions";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { pageView } from "../../../config/FirebaseConfig";

interface StateProps {
  products: any | null;
  spinner: string | null;
  productSpinner: string | null;
}

interface DispatchProps {
  onGetFavoriteProducts: () => void;
}
interface IProps {}

const Favorite: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const { t } = useTranslation();
  const getTemplateColumns = (numItems: number) => {
    switch (numItems) {
      case 1:
        return "1fr";
      case 2:
        return "1fr 1fr";
      case 3:
        return "1fr 1fr 1fr";
      default:
        return "1fr 1fr 1fr 1fr";
    }
  };
  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetFavoriteProducts();
  }, []);

  useEffect(() => {
    if (props.productSpinner === "removed") props.onGetFavoriteProducts();
  }, [props.productSpinner]);

  const headingsStyle = {
    fontSize: "1rem",
    color: "#171717",
    fontWeight: "bold",
  };
  const spinnerLoading = () => {
    return (
      <Grid templateColumns={["1fr"]} width="100%">
        <Center height="100vh" width={"100%"}>
          <Spinner size="xl" />
        </Center>
      </Grid>
    );
  };
  if (props.productSpinner && props.productSpinner === "loading") {
    // Display a spinner while loading
    return spinnerLoading();
  }

  if (props.products)
    if (props.products.length > 0)
      return (
        <Box p={{ base: "10px", md: "10px", lg: "37px" }} bgColor={"#FFFFFF"}>
          <Heading
            as="h5"
            // color="gray.700"
            // mb="0.5rem"
            style={headingsStyle}
          >
            {t("fav")}
          </Heading>
          <Grid
            templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
            width={"100%"}
            maxWidth={"100%"}
            gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
            mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
            justifyItems={"center"}
          >
            {props.products &&
              props.products.map((product: any) => {
                return (
                  <GridItem
                    key={product.id}
                    width={{ base: "90%", md: "100%", lg: "100%" }}
                  >
                    <ProductCard
                      url={product.thumbnail}
                      id={product.product_id}
                      name={product.name}
                      discountPrice={product.discount_price}
                      price={product.price}
                    />
                  </GridItem>
                );
              })}
          </Grid>
        </Box>
      );
    else
      return (
        <Grid templateColumns={["1fr"]} width="100%" mt="50px">
          <Center width={"100%"}>
            <img src="/images/empty/emptyFavorite.png" />
          </Center>{" "}
          <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
            <Text fontSize={"16px"} fontWeight={"bold"}>
              {t("emptyFavorite")}
            </Text>
          </Flex>
        </Grid>
      );
  else return spinnerLoading();
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    products: state.products.favoriteProducts,
    productSpinner: state.products.spinner,
    spinner: state.user.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetFavoriteProducts: () => dispatch(getFavoriteProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);
