import {
  Grid,
  GridItem,
  Flex,
  Text,
  Link,
  MenuItem,
  useColorModeValue,
  Input,
  Select,
  Divider,
  InputGroup,
  IconButton,
  InputRightElement,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Box,
  Center,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { connect } from "react-redux";
import {
  Action,
  getMaxPrice,
  searchProducts,
} from "../../store/actions/productActions";
import { RootState } from "../../store/reducers/rootReducer";
import { ThunkDispatch } from "redux-thunk";
import CategoriesContext from "../../context/categories/context";
import ProductCard from "../../components/general/product-card/ProductCard";
import CartNotificaiton from "../shopping-cart/CartNotification";
import { removeLoadingElements } from "../../store/actions/orderActions";
import { useTranslation } from "react-i18next";
import RTLWrapper from "../../RTLWrapper";
import { pageView } from "../../config/FirebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import ShopCategoryFilter from "./ShopCategoryFilter";
import useInfiniteScroll from "../../hooks/useInfifniteScroll";

interface StateProps {
  spinner: string | null;
  products: any | null;
  cartSpinner: string | null;
  maxPrice: any;
  showNotification: boolean;
  totalPages: number;
}

interface DispatchProps {
  onSearchProducts: (params: any) => void;
  onRemoveLoadingElements: () => void;
  onGetMaxPrice: () => void;
}

interface MenuLinkProps {
  name: string;
  path: string;
  onClose: () => void;
}

interface IProps {}

const ShopPage: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const {
    subCategoriesList,
    actions: { getSubCategories },
  } = useContext(CategoriesContext);
  const location = useLocation();
  const storedValue = localStorage.getItem("currency");
  const [params, setParams] = useState({
    from_price: 0,
    to_price: 0,
    search: undefined,
    category_id: undefined,
    per_page: 10, // Default items per page
    subCategory_id: undefined,
    page: 1, // Current page
  });
  const [isInitialSetupComplete, setIsInitialSetupComplete] = useState(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(false);
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  const handleInputChange = (e: any, fieldName: string) => {
    const value =
      e.target.value === null || e.target.value === ""
        ? undefined
        : e.target.value;

    // If the field is a checkbox, toggle its value
    if (fieldName === "category_id" || fieldName === "subCategory_id") {
      const fieldValue = params[fieldName] === value ? undefined : value;
      setParams((prevData) => ({
        ...prevData,
        page: 1,
        [fieldName]: fieldValue,
      }));
    } else {
      // For other fields, update the value as usual
      setParams((prevData) => ({
        ...prevData,
        page: 1,
        [fieldName]: value,
      }));
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetMaxPrice();
  }, []);

  const isLargeScreen = useBreakpointValue({
    base: false,
    md: true,
    lg: true,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryQuery = searchParams.get("c");
    const subCategoryQuery = searchParams.get("s");

    if (categoryQuery) {
      handleInputChange({ target: { value: categoryQuery } }, "category_id");
    } else if (subCategoryQuery) {
      handleInputChange(
        { target: { value: subCategoryQuery } },
        "subCategory_id"
      );
    }
    setIsInitialSetupComplete(true);
  }, [location.search]);

  const fetchMoreProducts = () => {
    if (params.page < props.totalPages)
      setParams((prevParams) => ({
        ...prevParams,
        page: prevParams.page + 1,
      }));
  };

  const [lastElementRef, isFetchingMore] = useInfiniteScroll(fetchMoreProducts);

  useEffect(() => {
    if (isInitialSetupComplete) props.onSearchProducts(params);
  }, [params, isInitialSetupComplete]);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <RTLWrapper>
      {showNotification ? (
        <CartNotificaiton
          message="Item added to cart!"
          product={product}
          handleCloseNotification={handleCloseNotification}
        />
      ) : null}
      <Box
        mt={{ base: "5em", md: "5em", lg: "6em" }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ShopCategoryFilter params={params} />
      </Box>
      <Flex
        alignItems={"flex-start"}
        flexDirection={isLargeScreen ? "row" : "column"}
        gap={"20px"}
        px={{ base: "1rem", md: "1rem", lg: "120px" }}
      >
        {props.products && props.products.length > 0 ? (
          <Grid
            templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
            width={"100%"}
            gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
            justifyItems={"center"}
          >
            {props.products.map((product: any, index: number) => {
              if (index === props.products.length - 1) {
                return (
                  <GridItem
                    ref={lastElementRef}
                    width={{ base: "90%", md: "100%", lg: "100%" }}
                    key={product.id}
                  >
                    <ProductCard
                      url={product.thumbnail}
                      product={product}
                      selectProduct={() => {}}
                      handleChooseProduct={handleChooseProduct}
                    />
                  </GridItem>
                );
              }
              return (
                <GridItem
                  width={{ base: "90%", md: "100%", lg: "100%" }}
                  key={product.id}
                >
                  <ProductCard
                    url={product.thumbnail}
                    product={product}
                    selectProduct={() => {}}
                    handleChooseProduct={handleChooseProduct}
                  />
                </GridItem>
              );
            })}
          </Grid>
        ) : props.spinner === "loading" ? (
          <Grid templateColumns={["1fr"]} width="100%" mt="50px">
            <Center width={"100%"}>
              <Spinner size="xl" />
            </Center>{" "}
          </Grid>
        ) : props.products && props.products.length === 0 ? (
          <Grid templateColumns={["1fr"]} width="100%" mt="50px">
            <Center width={"100%"}>
              <img src="/images/empty/emptySearch.png" />
            </Center>{" "}
            <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
              <Text fontSize={"16px"} fontWeight={"bold"}>
                {t("emptySearch")}
              </Text>
            </Flex>
          </Grid>
        ) : null}
      </Flex>
    </RTLWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.products.spinner,
    cartSpinner: state.orders.spinner,
    products: state.products.searchProducts,
    maxPrice: state.products.maxPrice,
    showNotification: state.orders.showNotification,
    totalPages: state.products.totalPages,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onSearchProducts: (params: any) => dispatch(searchProducts(params)),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
  onGetMaxPrice: () => dispatch(getMaxPrice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
