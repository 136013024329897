import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import NavbarCover from "../../components/main-layout/navbar-cover/NavbarCover";
import { Box, Flex, Grid, GridItem, Spinner } from "@chakra-ui/react";
import ProductContainer from "../../components/main-layout/flash-deal/product-container/ProductContainer";
import "./style.css";
import ProductCard from "../../components/general/product-card/ProductCard";
import {
  getOffers,
  Action,
  getFlashDeal,
} from "../../store/actions/productActions";
import { RootState } from "../../store/reducers/rootReducer";
import {
  getFlashSliders,
  getNavCoverImages,
} from "../../store/actions/homeActions";
import { removeLoadingElements } from "../../store/actions/orderActions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pageView } from "../../config/FirebaseConfig";
import FlashBanner from "../../components/main-layout/flash-deal/flashBanner";
import CartNotificaiton from "../shopping-cart/CartNotification";
interface StateProps {
  sliders: any | null;
  products: any | null;
  flashDealProducts: any | null;
  spinner: any | null;
  cartSpinner: string | null;
  currentPage: number;
  totalPages: number;
  showNotification: boolean;
  flashSliders: { action: string | null; image: string; id: number }[] | null;
}

interface DispatchProps {
  onGetOffers: (page: number) => void;
  onRemoveLoadingElements: () => void;
  onGetNavCoverImages: () => void;
  onGetFlashProducts: () => void;
  onFlashSliders: () => void;
}

interface IProps {}
const OfferPage: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetOffers(1);
    props.onGetFlashProducts();
    props.onGetNavCoverImages();
    props.onFlashSliders();
  }, []);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);
  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <>
      {showNotification ? (
        <CartNotificaiton
          message="Item added to cart!"
          product={product}
          handleCloseNotification={handleCloseNotification}
        />
      ) : null}
      <NavbarCover images={props.sliders} />
      <Box
        mt={{ base: "1em", md: "1em", lg: "2em" }}
        px={{ base: "1rem", md: "1rem", lg: "120px" }}
      >
        {/* Flash Deal */}
        <div className="lg:mt-[30px] w-full flex-col flex justify-center items-center xs:mt-[10px]">
          <FlashBanner
            flashDeals={props.flashDealProducts}
            sliders={props.flashSliders}
          />
          {/* Summer */}
          <div className="w-full md:flex-col md:flex md:justify-center md:items-center">
            <div className="flex w-full md:w-[100%] flex-col md:flex-row justify-between lg:gap-[50px] xs:gap-[20px]">
              <div className="mt-[40px]  md:h-[400px] md:mt-0 items-center flex justify-center flex-col gap-[10px]">
                <div className="relative lg:text-[32px] font-bold before:absolute before:bottom-0 before:content-[''] whitespace-nowrap  before:w-[100%] before:h-[2px] before:mb-[-15px] tracking-[2px] before:bg-black xs:text-[16px]">
                  {t("saleOff")}
                </div>
              </div>

              <ProductContainer
                relatedProducts={props.flashDealProducts}
                handleChooseProduct={handleChooseProduct}
              />
            </div>
          </div>

          <div className="flex w-full md:w-[100%] flex-col md:flex-row justify-between lg:gap-[50px] lg:mt-[20px] xs:gap-[20px]">
            <div className="mt-[40px]  md:h-[100px] md:mt-0 items-center flex justify-center flex-col gap-[10px]">
              <div className="relative lg:text-[32px] font-bold before:absolute before:bottom-0 before:content-[''] whitespace-nowrap  before:w-[100%] before:h-[2px] before:mb-[-15px] tracking-[2px] before:bg-black xs:text-[16px]">
                {t("offer")}
              </div>
            </div>
          </div>
        </div>

        <Flex justifyContent="center" alignItems="center">
          <Grid
            templateColumns={{
              base: "1fr",
              md: "1fr 1fr 1fr",
              lg: `1fr 1fr 1fr`,
            }}
            width={"100%"}
            gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
            mt={{ base: "3em", md: "3em", lg: "2em" }}
            justifyItems="center" // Center items horizontally
          >
            {props.products &&
              props.products.map((product: any) => {
                return (
                  <GridItem
                    width={{ base: "90%", md: "100%", lg: "100%" }}
                    key={product.id}
                  >
                    <ProductCard
                      url="/images/offer/table.png"
                      product={product}
                      selectProduct={() => {}}
                      handleChooseProduct={handleChooseProduct}
                    />
                  </GridItem>
                );
              })}
          </Grid>
          {props.spinner === "loading" && !props.products ? (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Spinner size="xl" />
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    sliders: state.home.sliders,
    flashDealProducts: state.products.flashDealProducts,
    products: state.products.offersProducts,
    spinner: state.products.spinner,
    cartSpinner: state.orders.spinner,
    currentPage: state.products.currentPage,
    totalPages: state.products.totalPages,
    showNotification: state.orders.showNotification,
    flashSliders: state.home.flashDealSliders,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetNavCoverImages: () => dispatch(getNavCoverImages()),
  onGetOffers: (page: number) => dispatch(getOffers(page)),
  onGetFlashProducts: () => dispatch(getFlashDeal()),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
  onFlashSliders: () => dispatch(getFlashSliders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferPage);
