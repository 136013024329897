import {
  InputGroup,
  Input,
  Button,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useRadioGroup,
  Icon,
} from "@chakra-ui/react";
import { GrMail } from "react-icons/gr";

import React, { useContext, useEffect, useState } from "react";
import { RadioCard } from "./Components/RadioCart";
import AuthContext from "../../context/auth/context";
import { EmailIcon } from "@chakra-ui/icons";
import { FaPhoneAlt } from "react-icons/fa";
import RTLWrapper from "../../RTLWrapper";
import { useTranslation } from "react-i18next";
interface IProps {
  onContinue: (value: number) => void;
}
const VerificationType = ({ onContinue }: IProps) => {
  const options = ["2"];
  const [radiosValue, setRadiosValue] = useState("2");
  const {
    email,
    loading,
    actions: { resetLinkCode },
  } = useContext(AuthContext);
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: "2",
    // onChange: (v) => setRadiosValue(v),
  });
  const group = getRootProps();
  const { t } = useTranslation();
  const onClick = async (type?: number) => {
    await resetLinkCode({ email_or_phone: email ?? "" });
    onContinue(1);
  };
  return (
    <RTLWrapper>
      <div className=" flex flex-col  gap-[50px] ">
        <div>
          <div
            className=" text-[#545454] leading-[30px] whitespace-normal lg:w-[461px] md:w-[350px]lg:w-[461px] md:w-[350px] "
            style={{ fontWeight: "normal" }}
          >
            {/* {t("confirmMethod")} */}
          </div>
          {
            <div className="flex flex-col gap-[40px]">
              <HStack {...group}>
                <div className=" flex flex-col gap-[20px] ">
                  {options.map((value) => {
                    const radio = getRadioProps({ value });

                    return (
                      <RadioCard key={value} {...radio}>
                        {
                          <div className=" h-full w-full flex  p-3 gap-[15px]  content-center justify-center ">
                            <img
                              width={"24px"}
                              src={
                                radiosValue == "2"
                                  ? "/images/login/selectedRadio.png"
                                  : "/images/login/circleIcon.png"
                              }
                              className="h-[24px] mt-2 "
                              alt=""
                            />
                            <div className="flex flex-col gap-[5px] ">
                              <div
                                className=""
                                style={{ fontWeight: "bold", fontSize: "24px" }}
                              >
                                {t("sendToPhone")}
                              </div>
                              <div
                                className=""
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "16px",
                                }}
                              >
                                Get A Password Reset code
                              </div>
                            </div>
                            <FaPhoneAlt
                              color={radiosValue !== "1" ? "white" : "black"}
                              className=" h-[34px] w-[34px]"
                            />
                          </div>
                        }
                      </RadioCard>
                    );
                  })}
                </div>
              </HStack>
            </div>
          }
        </div>
        <div className="flex flex-col gap-[70px]">
          <Button
            backgroundColor={"black"}
            _hover={{ bgColor: "#333131" }}
            borderRadius={"0px"}
            color={"white"}
            className="lg:w-[461px] md:w-[350px]"
            isLoading={loading.includes("send_to_email")}
            onClick={() => {
              onClick();
            }}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </RTLWrapper>
  );
};
export default VerificationType;
