import React, { useRef, useContext, useEffect } from "react";
import styles from "./style.module.css";
import { useDraggable } from "react-use-draggable-scroll";
import {
  useCategoryParamsId,
  useParamsId,
} from "../../utils/hooks/useParamsId";
import CategoriesContext from "../../context/categories/context";
import CategoryCard from "../main-layout/categoris-slider/category-card/CategoryCard";
const SubCategoryContainer = () => {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  const id = useCategoryParamsId();

  const {
    list,
    subCategoriesList,
    actions: { getSubCategories },
  } = useContext(CategoriesContext);

  useEffect(() => {
    //@ts-ignore
    id && getSubCategories(id);
  }, [id]);
  if (subCategoriesList)
    if (subCategoriesList.data)
      if (subCategoriesList.data.length > 0)
        return (
          <div className={styles.productsContainer} {...events} ref={ref}>
            {Array.isArray(subCategoriesList?.data) &&
              subCategoriesList?.data?.map((spCate) => (
                <CategoryCard
                  key={id}
                  navigateUrl={`/${id}` + `/${spCate?.id}`}
                  category={spCate}
                />
              ))}
          </div>
        );
      else return <div {...events} ref={ref}></div>;
    else return <div {...events} ref={ref}></div>;
  else return <div {...events} ref={ref}></div>;
};

export default SubCategoryContainer;
