import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Box,
  Text,
  List,
  ListItem,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import ProductsContext from "../../../../../context/products/context";

const SearchBox = () => {
  const {
    list: productList,
    actions: productActions,
    loading,
  } = useContext(ProductsContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [inputWidth, setInputWidth] = useState<number | undefined>(undefined);
  const [showResults, setShowResults] = useState<boolean>(false);

  const handleSearch = () => {
    // Implement your search logic here
    // For demonstration purposes, we'll just set some sample results
    setSearchResults([
      "Result 1",
      "Result 2",
      "Result 3",
      // Add more results as needed
    ]);
    setShowResults(true); // Show search results when clicking search button
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const resultsBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        resultsBoxRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        !resultsBoxRef.current.contains(event.target as Node)
      ) {
        setShowResults(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <Box position="relative">
      <InputGroup>
        <Input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="md"
          border={"none"}
          ref={inputRef}
          // borderColor="gray.300"
          _focus={{ borderColor: "teal.500" }}
        />
        <InputRightElement width="3.5rem">
          <IconButton
            aria-label="Search"
            icon={<SearchIcon />}
            size="sm"
            onClick={handleSearch}
            colorScheme="teal"
          />
        </InputRightElement>
      </InputGroup>

      {/* Display search results */}
      {showResults && searchResults.length > 0 && (
        <Box
          ref={resultsBoxRef}
          position="absolute"
          // top="100%"
          // left="0"
          zIndex="999"
          bg="white"
          p="2"
          mt="2px"
          borderRadius="md"
          boxShadow="md"
          width="20em"
        >
          <Text fontSize="sm" fontWeight="bold" color="teal.500">
            Search Results:
          </Text>
          <List spacing={0} mt={2}>
            {searchResults.map((result, index) => (
              <ListItem key={index} fontSize="sm">
                <a href={`/`}>{result}</a>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SearchBox;
