import { Avatar, Button, Flex } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  image: any | null;
  onUpload?: () => void;
  isEditable: boolean;
  handleDeleteImage: () => void;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => void;
}

const ProfileImage = ({
  image,
  onUpload,
  isEditable,
  handleInputChange,
  handleDeleteImage,
}: IProps) => {
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation();
  useEffect(() => {
    // Update the image preview when the image prop changes
    if (image) {
      if (typeof image === "string") {
        // If image is a URL, set it as the preview directly
        setImagePreview(image);
      } else {
        // If image is a File, use FileReader to read it and set as preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(image);
      }
    } else {
      // If image is null, reset the preview
      setImagePreview(undefined);
    }
  }, [image]);

  const handleImageClick = () => {
    // Trigger the hidden file input when the image is clicked
    const fileInput = document.getElementById("file-upload");
    fileInput?.click();
  };

  const handleRemove = () => {
    setImagePreview(undefined);
    handleDeleteImage();
  };

  return (
    <div
    // style={{ borderRadius: "100px" }}
    // className=" w-[150px] h-[150px] absolute -bottom-[60px] right-[60px] border border-solid border-[12px] border-white "
    >
      {/* <div
        style={{
          backgroundImage: `url(${imagePreview ?? ""})`,
          backgroundSize: "cover",
        }}
        className="w-[130px] h-[130px] rounded-full relative"
        onClick={isEditable ? handleImageClick : undefined}
      >
        {isEditable && (
          <img
            src={"/images/user/uploadImageIcon.png"}
            alt=""
            className="cursor-pointer w-[28px] h-[28px] rounded-full absolute bottom-1 right-1 border-solid border-[3px] border-white bg-white"
          />
        )}
      </div>
      */}
      <Flex
        justifyContent={{
          base: "center",
          md: "space-start",
          lg: "space-start",
        }}
        alignItems="center"
        // mb="4"
        py={7}
        width={"100%"}
        flexDirection="row"
        flexWrap={"wrap"}
      >
        <Avatar
          size={{ base: "md", md: "lg", lg: "2xl" }}
          name="Profile"
          src={imagePreview}
        />
        <Button
          variant="outline"
          marginInlineStart={3}
          mt={{ base: "1em" }}
          fontWeight={"normal"}
          size={{ base: "xs", md: "xs", lg: "md" }}
          onClick={isEditable ? handleImageClick : undefined}
        >
          {t("updateImage")}
        </Button>
        <Button
          fontWeight={"normal"}
          marginInlineStart={3}
          mt={{ base: "1em" }}
          size={{ base: "xs", md: "xs", lg: "md" }}
          onClick={handleRemove}
        >
          {t("delete")}
        </Button>
        <input
          type="file"
          id="file-upload"
          style={{ display: "none" }}
          onChange={(e) => handleInputChange(e, "avatar")}
        />
      </Flex>
    </div>
  );
};

export default ProfileImage;
