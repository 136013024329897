import React, { useContext, useEffect, useReducer, useRef } from "react";
import AuthContext, { internalState } from "./context";
import reducer from "./reducer";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { execute } from "../../utils/helpers/execute";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import { AuthService, ProfileService } from "../../api/gen";
import {
  IAddPhonePayload,
  ILoginPayload,
  ILoginSocialMediaPayload,
  IReSendPayload,
  IRegisterCodePayload,
  IRegisterPayload,
  IResetCodePayload,
  IResetPasswordPayload,
  ISendLinkPayload,
} from "../../api/gen/models/AuthService/models";
import eventManager, {
  EVENT_ERROR,
  EVENT_FORBIDDEN,
  EVENT_SUCCESS,
  EVENT_UNAUTHORIZED,
} from "../../utils/events";
import { useToast } from "@chakra-ui/react";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../utils/Notifications/notifications";

interface AuxProps {
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<AuxProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const effectCalled = useRef(false);
  const language: any = localStorage.getItem("language");

  /**
   * Check if there is access token
   */

  useEffect(() => {
    const token =
      localStorage.getItem(ACCESS_TOKEN) ||
      sessionStorage.getItem(ACCESS_TOKEN);
    if (token) {
      me();
    } else {
      // navigate("/login");
    }
  }, []);

  /**
   *  Get Roles & Permissions
   */
  useEffect(() => {
    if (state.isAuthenticated) {
    }
  }, [state.isAuthenticated]);
  useEffect(() => {
    if (!effectCalled.current) {
      effectCalled.current = true;
      eventManager.on(EVENT_SUCCESS, (message?: string) => {
        SuccessNotification(message ?? t("operationDoneSuccessfully"));
      });
      eventManager.on(EVENT_ERROR, (message) => {
        ErrorNotification(message);
      });
      eventManager.on(EVENT_UNAUTHORIZED, () => {
        navigate("/login", { replace: true });
      });
      eventManager.on(EVENT_FORBIDDEN, () => {
        navigate("/403", { replace: true });
      });
    }
  }, []);
  // Me
  const me = async () => {
    await execute({
      callback: async () => {
        const { data } = await ProfileService.getProfile();
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            user: {
              ...state.userDetails,
              //@ts-ignore
              data: {
                ...state.userDetails?.data,
                email_or_phone: data.email_or_phone,
                avatar: data.avatar,
                name: data.name,
              },
            },
          },
        });
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => {},
      throwException: false,
    });
  };

  // Login
  const login = async (request: ILoginPayload) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });
        try {
          await AuthService.postLogin(language, request).then(async (data) => {
            await dispatch({ type: "LOGIN_SUCCESS", payload: { user: data } });
            localStorage.setItem(ACCESS_TOKEN, data?.token);
            localStorage.setItem("loginInfo", "true");
            await dispatch({ type: "LOADING", payload: { loading: "login" } });

            // eventManager.emit(EVENT_SUCCESS);
            window.location.href = "/";
          });
        } catch (e) {}
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });
      },
      throwException: true,
    });
  };
  // Login
  const loginWithSocialMedia = async (request: ILoginSocialMediaPayload) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });
        try {
          await AuthService.socialMediaLogin(language, request).then(
            async (data) => {
              await dispatch({
                type: "LOGIN_SUCCESS",
                payload: { user: data },
              });

              localStorage.setItem(ACCESS_TOKEN, data?.token);
              localStorage.setItem("loginInfo", "true");
              await dispatch({
                type: "LOADING",
                payload: { loading: "login" },
              });

              // eventManager.emit(EVENT_SUCCESS);
              window.location.href = "/";
            }
          );
        } catch (e) {
          dispatch({ type: "LOGOUT_SUCCESS" });
        }
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });
      },
      throwException: true,
    });
  };
  const register = async (request: IRegisterPayload) => {
    await execute({
      callback: async () => {
        try {
          await dispatch({ type: "LOADING", payload: { loading: "register" } });

          const data = await AuthService.postRegister(language, request);
          dispatch({ type: "SET_REGISTER_DATA", payload: { data: data } });
          await dispatch({
            type: "SET_OTP",
            payload: { otp: data?.data?.otp_code },
          });

          dispatch({ type: "LOADING", payload: { loading: "register" } });

          navigate("/code-check");
        } catch (e) {}
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "register" } });
      },
      throwException: false,
    });
  };

  const changePassword = async (request: IResetPasswordPayload) => {
    await execute({
      callback: async () => {
        try {
          await AuthService.postPasswordReset(language, request);

          navigate("/login");
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });
      },
      throwException: false,
    });
  };

  // Logout
  const logout = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });

        await AuthService.postLogout();

        dispatch({ type: "LOGOUT_SUCCESS" });

        localStorage.removeItem(ACCESS_TOKEN);
        sessionStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem("loginInfo");

        window.location.href = "/";
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });
      },
      throwException: false,
    });
  };
  // Delete Account
  const deleteAccount = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "deleteAccount" } });
        try {
          await AuthService.postDeleteAccount().then(async (data) => {
            dispatch({ type: "DELETE_ACCOUNT_SUCCESS" });
            // eventManager.emit(EVENT_SUCCESS);
            await logout();

            window.location.href = "/";
          });
        } catch (e) {}
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });
      },
      throwException: true,
    });
  };
  // Reset Code
  const checkPasswordResetCode = async (
    request: IResetCodePayload,
    onSuccess: () => void
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "verify_password_code" },
        });

        try {
          const { message } = await AuthService.postPasswordCheck(
            language,
            request
          );
          console.log("SUCCESS");
          console.log(message);
          dispatch({
            type: "LOADING",
            payload: { loading: "verify_password_code" },
          });
          onSuccess();
          SuccessNotification(message);
        } catch (error) {
          dispatch({
            type: "LOADING",
            payload: { loading: "verify_password_code" },
          });
        }
      },
      fallback: (error) => {
        throw error;
      },
      finallyCallback: () => {},
      throwException: false,
    });
  };
  // Reset Code
  const resetLinkCode = async (request: ISendLinkPayload) => {
    await execute({
      callback: async () => {
        try {
          dispatch({
            type: "LOADING",
            payload: { loading: "send_to_email" },
          });

          const { data } = await AuthService.postPasswordEmail(
            language,
            request
          );

          dispatch({ type: "SET_OTP", payload: { otp: data.otp_code } });
          dispatch({
            type: "LOADING",
            payload: { loading: "send_to_email" },
          });
          navigate("/forgotpassword");
        } catch (e) {
          dispatch({
            type: "LOADING",
            payload: { loading: "send_to_email" },
          });
        }
      },
      fallback: (error) => {
        throw error;
      },
      finallyCallback: () => {},
      throwException: false,
    });
  };
  // Add Phone
  const addPhoneNumber = async (request: IAddPhonePayload) => {
    await execute({
      callback: async () => {
        try {
          dispatch({
            type: "LOADING",
            payload: { loading: "send_to_email" },
          });

          const { data } = await AuthService.addPhoneNumber(language, request);

          dispatch({ type: "SET_OTP", payload: { otp: data.otp_code } });
          dispatch({
            type: "LOADING",
            payload: { loading: "send_to_email" },
          });
        } catch (e) {
          dispatch({
            type: "LOADING",
            payload: { loading: "send_to_email" },
          });
          navigate(-1);
        }

        // message.success(t("success"));
      },
      fallback: (error) => {
        throw error;
      },
      finallyCallback: () => {},
      throwException: false,
    });
  };
  // Verify Phone
  const verifyPhone = async (request: IResetCodePayload) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "verify_password_code" },
        });

        try {
          const { message } = await AuthService.postPasswordCheck(
            language,
            request
          );
        } catch {
          await dispatch({
            type: "LOADING",
            payload: { loading: "verify_password_code" },
          });
          navigate(-1);
        }
        // message.success(t("success"));
      },
      fallback: (error) => {
        throw error;
      },
      finallyCallback: () => {},
      throwException: false,
    });
  };
  const resendCode = async (request: IReSendPayload) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });

        const { data } = await AuthService.postOptResend(language, request);

        dispatch({ type: "SET_OTP", payload: { otp: data.otp_code } });

        // message.success(t("success"));
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
        throw error;
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });
      },
      throwException: false,
    });
  };
  const registerCode = async (request: IRegisterCodePayload) => {
    await execute({
      callback: async () => {
        try {
          dispatch({
            type: "LOADING",
            payload: { loading: "change_password" },
          });

          const data = await AuthService.postOptCheck(language, request);

          dispatch({ type: "LOGIN_SUCCESS", payload: { user: data } });
          localStorage.setItem(ACCESS_TOKEN, data?.token);
          localStorage.setItem("loginInfo", "true");
          window.location.href = "/";
        } catch (e) {}
        // message.success(t("success"));
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
        throw error;
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });
      },
      throwException: false,
    });
  };
  const setEmail = (email: string) => {
    dispatch({ type: "SET_EMAIL", payload: { email: email } });
  };

  const setPhone = (phone: string) => {
    dispatch({ type: "SET_PHONE", payload: { phone: phone } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        actions: {
          login,
          loginWithSocialMedia,
          logout,
          deleteAccount,
          changePassword,
          register,
          checkPasswordResetCode,
          registerCode,
          resetLinkCode,
          addPhoneNumber,
          verifyPhone,
          resendCode,
          setEmail,
          setPhone,
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
