import React, { useState } from "react";
import {
  Flex,
  Box,
  Card,
  CardBody,
  Image,
  Text,
  CardFooter,
  Button,
  SimpleGrid,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
  Input,
  CardHeader,
  Square,
  Center,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { FaStar, FaHeart } from "react-icons/fa"; // Import your desired icons
import { FaChevronDown, FaChevronUp, FaMinus, FaPlus } from "react-icons/fa6";

interface IProps {}

const OrderCard: React.FC<IProps> = (props) => {
  const titleStyle = {
    fontSize: "20px",
    color: "#171717",
  };

  const descriptionStyle = {
    fontSize: "10px",
  };

  const priceStyle = {
    fontSize: "15px",
    color: "#171717",
  };
  const priceFontSize = [null, "16px", "20px"];

  return (
    <Flex justifyContent="center" alignItems="center">
      <Flex
        alignItems="center"
        mb="4"
        padding={5}
        bgColor={"#ECECEC"}
        width={"38em"}
      >
        {/* Image */}
        <Image
          src={
            "https://sadaf-group.s3.me-south-1.amazonaws.com/products/5084889108/IzgmZYVvQ3aTpNvTlZMlPOZYtlrQi1NWvakPGMI2.jpg"
          }
          //   alt={itemName}
          boxSize="100px"
          maxHeight={"65px"}
          maxWidth={"65px"}
          objectFit="cover"
        />

        {/* Text */}
        <Box marginInlineStart="4" width="15em" maxWidth={"15em"}>
          <Flex flexDirection={"column"}>
            <Text style={titleStyle} noOfLines={1}>
              Chair
            </Text>
            <Flex flexDirection={"row"} mt="0.5em">
              <Text style={descriptionStyle}>Kitchen</Text>
              <Text marginInlineStart={"5em"} style={descriptionStyle}>
                Brown
              </Text>
            </Flex>
          </Flex>
        </Box>

        {/* Quantity Controls */}
        <Flex alignItems="center" p={4} width="5em" maxWidth={"5em"}>
          <IconButton
            icon={<FaMinus />}
            aria-label="Decrease Quantity"
            // onClick={decreaseQuantity}
            size="sm"
            variant="outline"
            color="white"
            bgColor="#969696"
            marginInlineEnd="1em"
          />
          <Text mx="2">2</Text>
          <IconButton
            icon={<FaPlus />}
            aria-label="Increase Quantity"
            // onClick={increaseQuantity}
            size="sm"
            variant="outline"
            color="white"
            bgColor="#969696"
            marginInlineStart="1em"
          />
        </Flex>
        <Box marginInlineStart="auto">
          <Flex flexDirection={"column"}>
            <Text marginInlineEnd={"2em"}>100</Text>
            <Flex flexDirection={"row"}>
              <Text style={priceStyle}>200000</Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default OrderCard;
