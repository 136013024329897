import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import ReactGA from "react-ga";

const firebaseConfig = {
  apiKey: "AIzaSyDIBFqUp5XjAjvSRqunPQS417LOFrLurBI",
  authDomain: "sadaf-app.firebaseapp.com",
  projectId: "sadaf-app",
  storageBucket: "sadaf-app.appspot.com",
  messagingSenderId: "758020317658",
  appId: "1:758020317658:web:b1b779e1b84f5645ec20e3",
  measurementId: "G-PRQ8BE3H7M",
};
ReactGA.initialize("G-PRQ8BE3H7M");

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
// whenever a user interacts with the provider, we force them to select an account
googleProvider.setCustomParameters({
  prompt: "select_account ",
});
export const auth = getAuth(firebaseApp);
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);

export const signInWithFacebookPopup = () =>
  signInWithPopup(auth, facebookProvider);

export function pageView(pathname: string) {
  ReactGA.pageview(pathname);
}

export function pageEvent(category: string, action: string) {
  ReactGA.event({
    category: category,
    action: action,
  });
}
