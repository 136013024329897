import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
// import ProductContainer from "../../components/main-layout/flash-deal/product-container/ProductContainer";
import ProductCard from "../../../components/general/product-card/ProductCard";
// import { useParamsId } from "../../utils/hooks/useParamsId";
// import CategoriesContext from "../../context/categories/context";
// import ProductsContext from "../../context/products/context";
import SubCategoryContainer from "../../../components/sub-category-container/SubCategoryContainer";
import {
  getNewProducts,
  Action,
  getFlashDeal,
} from "../../../store/actions/productActions";
import { RootState } from "../../../store/reducers/rootReducer";
import { Box3 } from "three";
import { removeLoadingElements } from "../../../store/actions/orderActions";
import CartNotificaiton from "../../shopping-cart/CartNotification";
import PaginationButtons from "../../../components/pagination-buttons/PaginattioButtons";
import { useLocation } from "react-router-dom";
import BreadCrumbComponent from "../../../components/bread-crumb/BreadCrumbComponent";
import { pageView } from "../../../config/FirebaseConfig";

interface StateProps {
  products: any | null;
  spinner: any | null;
  cartSpinner: string | null;
  currentPage: number;
  totalPages: number;
  showNotification: boolean;
}

interface DispatchProps {
  onGetFlashProducts: () => void;
  onRemoveLoadingElements: () => void;
}

interface IProps {}
const FlashDealPage: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  //   const id = useParamsId();
  //   const { list } = useContext(CategoriesContext);
  //   const {
  //     list: products,
  //     actions: { getProducts, getProductById },
  //   } = useContext(ProductsContext);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(false);
  const { pathname } = useLocation();

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetFlashProducts();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  if (props.products)
    return (
      <Box
        px={{ base: "1rem", md: "none", lg: "120px" }}
        mt={{ base: "4em", md: "4em", lg: "9.375em" }}
      >
        {showNotification ? (
          <CartNotificaiton
            message="Item added to cart!"
            product={product}
            handleCloseNotification={handleCloseNotification}
          />
        ) : null}
        {/* <div className="pl-[140px] mt-[150px]"> */}
        {/* <BreadCrumbComponent /> */}
        {/* </div> */}
        {/* <div className=" w-full flex-col flex justify-center items-center p-[140px] pt-[0px] pb-[0px]"> */}
        {/* <ProductContainer /> */}
        {/* <SubCategoryContainer /> */}
        {/* <Flex justifyContent="center" alignItems="center"> */}
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: `1fr 1fr 1fr`,
          }}
          width={"100%"}
          gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
          mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
          justifyItems={"center"}
        >
          {props.products &&
            props.products.map((product: any) => {
              return (
                <GridItem
                  width={{ base: "90%", md: "100%", lg: "100%" }}
                  key={product.id}
                >
                  <ProductCard
                    url={product.thumbnail}
                    product={product}
                    selectProduct={() => {}}
                    handleChooseProduct={handleChooseProduct}
                  />
                </GridItem>
              );
            })}
        </Grid>
      </Box>
    );
  else return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    products: state.products.flashDealProducts,
    spinner: state.products.spinner,
    cartSpinner: state.orders.spinner,
    currentPage: state.products.currentPage,
    totalPages: state.products.totalPages,
    showNotification: state.orders.showNotification,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetFlashProducts: () => dispatch(getFlashDeal()),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashDealPage);
