import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
// import ProductContainer from "../../components/main-layout/flash-deal/product-container/ProductContainer";
import ProductCard from "../../../components/general/product-card/ProductCard";
// import { useParamsId } from "../../utils/hooks/useParamsId";
// import CategoriesContext from "../../context/categories/context";
// import ProductsContext from "../../context/products/context";
import SubCategoryContainer from "../../../components/sub-category-container/SubCategoryContainer";
import { getNewProducts, Action } from "../../../store/actions/productActions";
import { RootState } from "../../../store/reducers/rootReducer";
import CartNotificaiton from "../../shopping-cart/CartNotification";
import { removeLoadingElements } from "../../../store/actions/orderActions";
import { useLocation } from "react-router-dom";
import PaginationButtons from "../../../components/pagination-buttons/PaginattioButtons";
import BreadCrumbComponent from "../../../components/bread-crumb/BreadCrumbComponent";
import { pageView } from "../../../config/FirebaseConfig";

interface StateProps {
  products: any | null;
  spinner: any | null;
  cartSpinner: string | null;
  currentPage: number;
  totalPages: number;
  showNotification: boolean;
}

interface DispatchProps {
  onGetNewProducts: (page: number) => void;
  onRemoveLoadingElements: () => void;
}

interface IProps {}
const NewArrivalPage: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  const didMountRef = useRef(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(false);
  const [totalProducts, setTotalProducts] = useState<any>(0);
  const { pathname } = useLocation();

  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollPosition = scrollTop + windowHeight;

      // You can adjust the threshold value as needed
      const threshold = 0.5;

      if (scrollPosition >= documentHeight * threshold) {
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      // Call your pagination function here
      // For example: fetchMoreData();
      handleChangePage(props.currentPage + 1);
    }
  }, [shouldLoadMore]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  const handleChangePage = (page: number) => {
    if (page > 0 && page <= props.totalPages) props.onGetNewProducts(page);
  };

  useEffect(() => {
    if (!didMountRef.current) {
      pageView(window.location.pathname);
      props.onGetNewProducts(1);
      didMountRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  if (props.products)
    return (
      <Box
        px={{ base: "1rem", md: "none", lg: "120px" }}
        mt={{ base: "4em", md: "4em", lg: "9.375em" }}
      >
        {showNotification ? (
          <CartNotificaiton
            message="Item added to cart!"
            product={product}
            handleCloseNotification={handleCloseNotification}
          />
        ) : null}
        {/* <div className="pl-[140px] mt-[150px]"> */}
        {/* <BreadCrumbComponent /> */}
        {/* </div> */}
        {/* <div className=" w-full flex-col flex justify-center items-center p-[120px]"> */}
        {/* <ProductContainer /> */}

        {/* <SubCategoryContainer /> */}
        {/* <Flex justifyContent="center" alignItems="center"> */}
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: `1fr 1fr 1fr`,
          }}
          width={"100%"}
          gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
          mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
          justifyItems={"center"}
        >
          {props.products &&
            props.products.map((product: any) => {
              return (
                <GridItem
                  width={{ base: "90%", md: "100%", lg: "100%" }}
                  key={product.id}
                >
                  <ProductCard
                    url={product.thumbnail}
                    product={product}
                    selectProduct={() => {}}
                    handleChooseProduct={handleChooseProduct}
                  />
                </GridItem>
              );
            })}
        </Grid>
        {/* </Flex> */}
        {/* </div> */}
        {props.spinner === "loading" ? (
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Spinner size="xl" />
          </Flex>
        ) : null}
        {/* <Flex justifyContent={"center"} alignItems={"center"}>
          <PaginationButtons
            currentPage={props.currentPage}
            totalPages={props.totalPages}
            handleChangePage={handleChangePage}
          />
        </Flex> */}

        {/* </div> */}
      </Box>
    );
  else return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    products: state.products.newArrivalsProducts,
    spinner: state.products.spinner,
    cartSpinner: state.orders.spinner,
    currentPage: state.products.currentPage,
    totalPages: state.products.totalPages,
    showNotification: state.orders.showNotification,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetNewProducts: (page: number) => dispatch(getNewProducts(page)),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewArrivalPage);
