import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { pageView } from "../../../config/FirebaseConfig";

const Terms = () => {
  const { t } = useTranslation();
  useEffect(() => {
    pageView(window.location.pathname);
  }, []);
  return (
    <Box
      style={{ backgroundColor: "#FFFFFF" }}
      p={{ base: "10px", md: "10px", lg: "37px" }}
    >
      <Text
        style={{ fontWeight: "bold" }}
        fontSize={{ base: "17px", md: "17px", lg: "24px" }}
      >
        {" "}
        {t("policy")}
      </Text>
      <Text
        color="#959595"
        fontSize={{ base: "15px", md: "15px", lg: "17px" }}
        mt={"30px"}
      >
        his summary is provided only for convenience. please review the terms of
        service below in their entirety for important information and legal
        conditions that apply to your use of the platform for the sharing and
        viewing of online for mobile devices and personal computers.W
      </Text>
      <Text
        style={{ fontWeight: "bold" }}
        justifyContent={{ base: "center", md: "center", lg: "flex-start" }}
        alignItems={"center"}
        fontSize={{ base: "17px", md: "17px", lg: "24px" }}
        mt={"30px"}
      >
        {t(`postingUserContent`)}
      </Text>{" "}
      <Text
        color="#959595"
        fontSize={{ base: "15px", md: "15px", lg: "17px" }}
        mt={"30px"}
      >
        his summary is provided only for convenience. please review the terms of
        service below in their entirety for important information and legal
        conditions that apply to your use of the platform for the sharing and
        viewing of online for mobile deviees and personal computers.his summary
        is provided only for convenience. please review the terms of service
        below in their entirety for important information and legal conditions
        that apply to your use of the platform for the sharing and viewing of
        online for mobile deviees and personal computers.his summary is provided
        only for convenience. please review the terms of service below in their
        entirety for important information and legal conditions that apply to
        your use of the platform for the sharing and viewing of online for
        mobile deviees and personal computers.his summary is provided only for
        convenience. please review the terms of service below in their entirety
        for important information and legal conditions that apply to your use of
        the platform for the sharing and viewing of online for mobile deviees
        and personal computers.
      </Text>
    </Box>
  );
};

export default Terms;
