import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const TestImageBack = () => {
  const infoBackground = "url(/images/flash.png)"; // Replace with your image path

  return (
    // <Flex
    //   direction="column"
    //   justifyContent="center"
    //   alignItems="center"
    //   h="100vh" // Adjust the height as needed
    // >
    //   <img
    //     src="/images/flash.png"
    //     alt="Your Image Alt Text"
    //     width="400"
    //     height="400"
    //   />

    //   <Text
    //     fontWeight="bold"
    //     fontSize="lg"
    //     style={{ position: "absolute", padding: "-70px" }}
    //   >
    //     Your Text Here
    //   </Text>
    // </Flex>
    <Box
      backgroundImage={infoBackground}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      h="18.5em" // Adjust the height as needed
      w="100%" // Replace with your className or add styling directly
    >
      <div>test</div>
      {/* Your content goes here */}
    </Box>
  );
};

export default TestImageBack;
