import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Select,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { Switch } from "@chakra-ui/react";
import { MiniDrawer } from "../components/Drawer/Drawer";
import NotificationCard from "../components/NotificationCard/NoteficationCard";
import AuthContext from "../../../../context/auth/context";
import { ACCESS_TOKEN } from "../../../../utils/helpers/constants";
import { useTranslation } from "react-i18next";
import SearchBox from "../components/SearchBox/SearchBox";
import { FaSearch } from "react-icons/fa";
import {
  FaArrowRight,
  FaBell,
  FaCarTunnel,
  FaCartShopping,
  FaLanguage,
} from "react-icons/fa6";
import { FiSun } from "react-icons/fi";
import { css } from "@emotion/react";
import { AddIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import RTLWrapper from "../../../../RTLWrapper";
import NavBarSearch from "./NavBarSearch";
import { GrLanguage } from "react-icons/gr";
import { IoMdArrowDropright, IoMdMenu } from "react-icons/io";

interface IProps {
  notifications: any | null;
  cartDetails: any | null;
  isBlack: any;
}

const Actions = (props: IProps) => {
  const [isChecked, setChecked] = React.useState<boolean>(false);
  const [language, setLanguage] = React.useState<string>(
    localStorage.getItem("language") === "en"
      ? "english"
      : localStorage.getItem("language") === "ar"
      ? "arabic"
      : localStorage.getItem("language") === "ku"
      ? "kurdish"
      : "english"
  );
  const [currency, setCurrency] = React.useState<string>(
    localStorage.getItem("currency") === "USD"
      ? "USD"
      : localStorage.getItem("currency") === "IQD"
      ? "IQD"
      : "USD"
  );
  const isLargeScreen = useBreakpointValue({ base: false, md: true, lg: true });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSideNav,
    onOpen: onOpenSideNav,
    onClose: onCloseSideNav,
  } = useDisclosure();
  const {
    userDetails,
    actions: { logout },
  } = useContext(AuthContext);
  const {
    isOpen: isOpenSidebar,
    onOpen: onOpenSidebar,
    onClose: onCloseSidebar,
  } = useDisclosure();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    // Logic to retrieve the switch value from local storage
    const storedValue = localStorage.getItem("currency");
    setChecked(storedValue === "USD" ? true : false);
  }, []);
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  const navLinks = [
    { name: t("home"), path: "/" },
    { name: t("shop"), path: "/shop" },
    {
      name: t("magazine"),
      path: `https://magazine.sadafiq.com/?lang=${i18n.language}`,
    },
    { name: t("about"), path: "https://about.sadafiq.com/" },
  ];

  const handleChange = () => {
    // Toggle the switch state
    setChecked((prevChecked) => !prevChecked);
    // Update the local storage with the new switch state
    localStorage.setItem(
      "currency",
      localStorage.getItem("currency") === "USD" ? "IQD" : "USD"
    );
    window.location.reload();
  };

  const handleChangeCurrency = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    // Toggle the switch state

    setCurrency(e.target.value);
    if (e.target.value === "USD") {
      localStorage.setItem("currency", "USD");
      // i18n.changeLanguage("en");
    }
    if (e.target.value === "IQD") {
      localStorage.setItem("currency", "IQD");
    }

    // Update the local storage with the new switch state
    // localStorage.setItem(
    //   "currency",
    //   localStorage.getItem("currency") === "USD" ? "IQD" : "USD"
    // );
    window.location.reload();
  };

  const handleChangeLanguage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any
  ) => {
    navigate({ pathname: pathname, search: `` });
    setLanguage(e.target.value);
    if (e.target.value === "english") {
      localStorage.setItem("language", "en");
      localStorage.setItem("dir", "ltr");
      i18n.changeLanguage("en");
    }
    if (e.target.value === "arabic") {
      localStorage.setItem("language", "ar");
      localStorage.setItem("dir", "rtl");
      i18n.changeLanguage("ar");
    }
    if (e.target.value === "kurdish") {
      localStorage.setItem("language", "ku");
      localStorage.setItem("dir", "rtl");
      i18n.changeLanguage("ku");
    }
    window.location.reload();
  };

  return (
    <Box display={{ base: "inherit", md: "inherit", lg: "inherit" }}>
      <Flex
        align="center"
        justify="space-between"
        ml="auto"
        gap={"5px"}
        //   padding="4"
        //   borderBottom="1px"
        //   borderColor="gray.200"
      >
        {" "}
        <Box>
          {!isLargeScreen ? (
            <Flex alignItems="center">
              <IconButton
                size={{ base: "xs", md: "sm", lg: "sm" }}
                aria-label="Search"
                icon={<SearchIcon color={props.isBlack ? "#171717" : "#fff"} />}
                background={"none"}
                _hover={{
                  backgroundColor: "none",
                }}
                onClick={() => navigate("/search")}
              />
            </Flex>
          ) : (
            <NavBarSearch />
          )}
        </Box>
        <IconButton
          size={{ base: "xs", md: "sm", lg: "sm" }}
          css={css`
            position: relative !important;
          `}
          background={"none"}
          _hover={{
            backgroundColor: "none",
          }}
          aria-label="Notifications"
          onClick={
            localStorage.getItem(ACCESS_TOKEN)
              ? onOpen
              : () => navigate("/login")
          }
          _active={{ backgroundColor: "none" }}
          icon={
            <>
              <FaBell
                color={props.isBlack ? "#171717" : "#fff"}
                style={{ width: "18px", height: "18px" }}
              />
              {localStorage.getItem(ACCESS_TOKEN) &&
              props.notifications &&
              props.notifications.length > 0 ? (
                <Box
                  as="span"
                  color="white"
                  position="absolute"
                  top="6px"
                  right="4px"
                  fontSize={{ base: "0.3rem", md: "0.5rem", lg: "0.5rem" }}
                  bgColor="red"
                  borderRadius="full" // Updated to "full" for a circular badge
                  // zIndex={9999}
                  p="1px"
                  textAlign="center"
                  minWidth={{ base: "8px", md: "12px", lg: "14px" }} // Adjust as needed
                >
                  {props.notifications ? props.notifications.length : 0}
                </Box>
              ) : null}
            </>
          }
        />
        <IconButton
          size={{ base: "xs", md: "sm", lg: "sm" }}
          css={css`
            position: relative !important;
          `}
          background={"none"}
          _hover={{
            backgroundColor: "none",
          }}
          aria-label="Notifications"
          onClick={() => {
            localStorage.getItem(ACCESS_TOKEN)
              ? navigate("/shopping-cart")
              : navigate("/login");
          }}
          _active={{ backgroundColor: "none" }}
          icon={
            <>
              <FaCartShopping
                style={{ width: "18px", height: "18px" }}
                color={props.isBlack ? "#171717" : "#fff"}
              />
              {props.cartDetails && localStorage.getItem(ACCESS_TOKEN) ? (
                props.cartDetails.products ? (
                  props.cartDetails.products.length > 0 ? (
                    <Box
                      as="span"
                      color="white"
                      position="absolute"
                      top="6px"
                      right="4px"
                      fontSize={{ base: "0.3rem", md: "0.5rem", lg: "0.5rem" }}
                      bgColor="red"
                      borderRadius="full" // Updated to "full" for a circular badge
                      // zIndex={9999}
                      p="1px"
                      textAlign="center"
                      minWidth={{ base: "8px", md: "12px", lg: "14px" }} // Adjust as needed
                    >
                      {props.cartDetails
                        ? props.cartDetails.products.length
                        : null}
                    </Box>
                  ) : null
                ) : null
              ) : null}
            </>
          }
        />
        <Flex px={"0.75rem"} alignItems={"center"} gap={2}>
          <span
            style={{
              cursor: "pointer",
              color: props.isBlack ? "#171717" : "#fff",
            }}
            onClick={() => {
              localStorage.getItem(ACCESS_TOKEN)
                ? navigate("/user")
                : navigate("/login");
            }}
          >
            {userDetails?.data?.name
              ? userDetails.data.name.substring(0, 13).split(" ")[0]
              : t("guest")}
          </span>

          {/* <Avatar
            size={{ base: "xs", lg: "sm" }}
            name="John Doe"
            src={
              userDetails?.data?.avatar
                ? userDetails?.data?.avatar
                : "/images/ivana.png"
            }
            onClick={() => {
              localStorage.getItem(ACCESS_TOKEN)
                ? navigate("/user")
                : navigate("/login");
            }}
          /> */}
        </Flex>
        <Menu size={"xs"}>
          <MenuButton
            as={IconButton}
            size={{ base: "xs", lg: "sm" }}
            bgColor={"none"}
            background={"none"}
            _hover={{ background: "none" }}
            aria-label="Open submenu"
            variant="ghost"
          >
            <Text
              color={props.isBlack ? "#171717" : "#fff"}
              style={{ textTransform: "uppercase" }}
            >
              {localStorage.getItem("language")}
            </Text>
          </MenuButton>
          <MenuList>
            {["english", "arabic", "kurdish"].map((language: string) => {
              return (
                <MenuItem
                  closeOnSelect={false}
                  key={language}
                  value={language}
                  data-value={language}
                  onClick={(e) => handleChangeLanguage(e)}
                  justifyContent={i18n.dir() == "ltr" ? "left" : "right"}
                >
                  {language === "english"
                    ? "EN"
                    : language === "arabic"
                    ? "AR"
                    : "KU"}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Flex>
      <IconButton
        display={["inherit", "inherit", "none"]}
        size={{ base: "xs", lg: "sm" }}
        bgColor={"none"}
        background={"none"}
        _hover={{ background: "none" }}
        aria-label="Open submenu"
        icon={
          <IoMdMenu
            style={{ width: "18px", height: "18px" }}
            color={props.isBlack ? "#171717" : "#fff"}
          />
        }
        variant="ghost"
        onClick={onOpenSideNav}
      />
      <Drawer
        isOpen={isOpenSideNav}
        placement={i18n.language === "en" ? "right" : "left"}
        onClose={onCloseSideNav}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent background={"#000"}>
          <DrawerCloseButton
            background={"#000"}
            color={"#fff"}
            position="absolute"
            top="0"
            left="0"
            borderRadius={"0"}
            padding={"33px"}
          />

          <DrawerBody p={"180px 0px 0px 30px"}>
            <Text
              color={"#fff"}
              fontSize={"14px"}
              mb={"1rem"}
              lineHeight={"1.8em"}
            >
              {t("sideNavText")}
            </Text>
            {navLinks.map((link, index) => (
              <Box key={index} onClick={onCloseSideNav}>
                <Link
                  to={link.path}
                  onClick={onClose}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#fff",
                    padding: "1rem 0",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  <IoMdArrowDropright
                    style={{
                      marginRight: "8px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  />
                  {link.name}
                </Link>
                <Box
                  height="1px"
                  backgroundColor="#A1A1A1"
                  marginY="5px"
                  w={"90%"}
                />
              </Box>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <RTLWrapper>
        <MiniDrawer
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          header={
            <RTLWrapper>
              <div className="flex justify-between items-center">
                <div className=" tracking-[1px]">
                  <Text fontSize={{ base: "10px", md: "10px", lg: "20px" }}>
                    {t("notification")}
                  </Text>
                </div>{" "}
              </div>
            </RTLWrapper>
          }
        >
          {props.notifications && props.notifications.length > 0 ? (
            <RTLWrapper>
              {props.notifications &&
                props.notifications.map((notification: any) => {
                  return (
                    <NotificationCard
                      key={notification.id}
                      isToday
                      notification={notification}
                    />
                  );
                })}
            </RTLWrapper>
          ) : (
            <RTLWrapper>
              <Grid templateColumns={["1fr"]} width="100%" mt="50px">
                <Center width={"100%"}>
                  <img src="/images/empty/emptyNotification.png" />
                </Center>{" "}
                <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
                  <Text fontSize={"16px"} fontWeight={"bold"}>
                    {t("emptyNotifications")}
                  </Text>
                </Flex>
              </Grid>
            </RTLWrapper>
          )}
        </MiniDrawer>
      </RTLWrapper>
    </Box>
    // </HStack>
  );
};

export default Actions;
