import React, { useContext, useEffect, useReducer, useRef } from "react";
import AuthContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import ProductsContext from "./context";
import { ProductsService } from "../../api/gen";
import { IProductsParams } from "../../api/gen/models/ProductsService/listModels";

interface AuxProps {
  children: React.ReactNode;
}

const ProductsContextProvider: React.FC<AuxProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getProducts = async (params: IProductsParams) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "products" } });
        try {
          await ProductsService.getProducts("", params).then((data) => {
            dispatch({ type: "SET_LIST", payload: { data: data } });
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "products" } });
      },
      throwException: true,
    });
  };
  const getProductById = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "products" } });
        const storedValue = localStorage.getItem("currency");

        try {
          await ProductsService.getProducts1(
            "en",
            id,
            storedValue ? storedValue : "IQD"
          ).then((data) => {
            dispatch({
              type: "SET_PRODUCT_DETAILS",
              payload: { data: data },
            });
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "products" } });
      },
      throwException: true,
    });
  };
  return (
    <ProductsContext.Provider
      value={{
        ...state,
        actions: { getProducts, getProductById },
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsContextProvider;
