import { Box, Flex, Grid, GridItem, Text, Image } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "../../general/countdown/Countdown";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination]);

const textStyle = {
  color: "#171717",
  fontWeight: "bold",
};

type Props = {
  flashDeals:
    | {
        id: number;
        name: string;
        thumbnail: string;
        in_stock: boolean;
        category: {
          id: number;
          name: string;
          image: string;
          icon: string;
        };
        price: string;
        discount_price: string;
        discount_end_at: string;
        is_favorite: false;
        currency: string;
        created_at: string;
      }[]
    | null;
  sliders: { action: string | null; image: string; id: number }[] | null;
};

function FlashBanner({ flashDeals, sliders }: Props) {
  const { t } = useTranslation();
  const swiperRef = useRef<any>(null);

  const handleNext = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 7000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Flex
      gap={"36px"}
      mt={{ base: "20px", md: "20px", lg: "38px" }}
      width={"100%"}
      flexDirection={{ base: "column", md: "column", lg: "row" }}
    >
      <Box
        bgColor={"#F9F9F9"}
        width={{ base: "100%", md: "100%", lg: "35%", xl: "30%" }}
      >
        <Box width="100%" bgColor={"#F9F9F9"}>
          <Flex justifyContent={"center"} alignItems={"center"}>
            <Text
              style={textStyle}
              fontSize={{ base: "1rem", md: "1rem", lg: "1.5rem" }}
              mt={{ base: "30px", md: "30px", lg: "47px" }}
            >
              {t("flash_deal")}
            </Text>
          </Flex>
          <Flex
            mt="2em"
            mb={{ base: "2em" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {flashDeals && (
              <Countdown endDate={flashDeals[0].discount_end_at} />
            )}
          </Flex>
        </Box>
      </Box>
      <Box width={{ base: "100%", md: "100%", lg: "65%", xl: "70%" }}>
        <Box width="100%">
          <Swiper
            loop={true}
            autoplay={{ delay: 7000 }}
            className="mySwiper w-full"
            ref={swiperRef}
            navigation={false}
            pagination={{ clickable: true }}
          >
            {sliders?.map((image: any, index: number) => (
              <SwiperSlide key={index}>
                <Box
                  position="relative"
                  aspectRatio={4 / 1.3}
                  cursor="pointer"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src={image.image}
                    alt="Background Image"
                    height="100%"
                    width="100%"
                    loading="lazy"
                    borderRadius="20px"
                    border="0.5px solid #A5A5A5"
                    onClick={
                      image.action
                        ? () => {
                            window.open(image.action);
                          }
                        : () => {}
                    }
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Flex>
  );
}

export default FlashBanner;
