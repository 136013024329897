import { createContext } from "react";

import {
  IProducts,
  IProductsParams,
} from "../../api/gen/models/ProductsService/listModels";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";
import { IProductResponse } from "../../api/gen/models/ProductsService/product";

export type AuthLoading = "products";

export interface IInternalState {
  loading: AuthLoading[];
  list?: IProducts;
  products: any | null;
  currentPage: any;
  totalPages: any;
  productDetails?: IProductResponse;
}

export const internalState: IInternalState = {
  loading: [],
  products: null,
  currentPage: 0,
  totalPages: 0,
};

export interface IExternalState extends IInternalState {
  actions: {
    getProducts: (params: IProductsParams) => void;
    getProductById: (id: number) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getProducts: DEFAULT_FUNCTION,
    getProductById: DEFAULT_FUNCTION,
  },
};

const ProductsContext = createContext(externalState);

export default ProductsContext;
