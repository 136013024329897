import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Spinner, Center, Flex, Box, Link, Image } from "@chakra-ui/react";
import NavbarCover from "../../components/main-layout/navbar-cover/NavbarCover";
import FlashDeal from "../../components/main-layout/flash-deal/FlashDeal";
import NewArrival from "../../components/main-layout/new-arrival/NewArrival";
import Offer from "../../components/main-layout/offer/Offer";
import ProductVideos from "../../components/main-layout/product-videos.tsx/ProductVideos";
import CategoryContainer from "../../components/main-layout/categoris-slider/CategoryContainer";
import { CiCircleInfo } from "react-icons/ci";
import {
  getHomePage,
  Action,
  getVideos,
  getNavCoverImages,
  getHomeBanners,
  getFlashSliders,
} from "../../store/actions/homeActions";
import { RootState } from "../../store/reducers/rootReducer";
import RTLWrapper from "../../RTLWrapper";
import { removeLoadingElements } from "../../store/actions/orderActions";
import CartNotificaiton from "../shopping-cart/CartNotification";
import { useLocation } from "react-router-dom";
import "./styles.css"; // Import your CSS file
import VideoPlayer from "../../components/videos/VideoPlayer";
import ImageSlider from "../../components/general/image-slider/ImageSlider";

import HomeFloatingButtons from "./HomeFloatingButtons";

interface StateProps {
  home: any | null;
  videos: any | null;
  sliders: any | null;
  spinner: string | null;
  cartSpinner: string | null;
  showNotification: boolean;
  banners: { id: number; image: string }[] | null;
  flashSliders: any | null;
}

interface DispatchProps {
  onGetHomePage: () => void;
  onGetVideos: () => void;
  onRemoveLoadingElements: () => void;
  onGetNavCoverImages: () => void;
  onGetBanners: () => void;
  onFlashSliders: () => void;
}

interface IProps {}

const Home: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(false);
  const [images, setImages] = useState<any>([]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isVisibleNewArrival, setIsVisibleNewArrival] =
    useState<boolean>(false);
  const [isVisibleOffer, setIsVisibleOffer] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const targetPosition = 250; // Change this value according to your needs
      const targetPositionNewArrival = 1500;
      const targetPositionOffer = 2000;
      if (scrollTop > targetPosition) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (scrollTop > targetPositionNewArrival) {
        setIsVisibleNewArrival(true);
      } else {
        setIsVisibleNewArrival(false);
      }
      if (scrollTop > targetPositionOffer) {
        setIsVisibleOffer(true);
      } else {
        setIsVisibleOffer(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);
  useEffect(() => {
    props.onGetNavCoverImages();
    props.onGetHomePage();
    props.onGetVideos();
    props.onGetBanners();
    props.onFlashSliders();
  }, []);

  if (props.spinner === "loading") {
    // Display a spinner while loading
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }
  if (props.home && props.videos && props.sliders) {
    return (
      <RTLWrapper>
        {showNotification ? (
          <CartNotificaiton
            message="Item added to cart!"
            product={product}
            handleCloseNotification={handleCloseNotification}
          />
        ) : null}
        <NavbarCover images={props.sliders} />

        <Flex justifyContent={"center"}>
          <CategoryContainer navigateUrl="/category" />
        </Flex>
        <Box px={{ base: "1rem", md: "1rem", lg: "120px" }}>
          {props.home.flash_deals ? (
            props.home.flash_deals.length > 0 ? (
              <div>
                <FlashDeal
                  home={props.home}
                  videos={props.videos}
                  handleChooseProduct={handleChooseProduct}
                  sliders={props.flashSliders}
                />
              </div>
            ) : // </div>
            null
          ) : null}
          {props.home.new_products ? (
            props.home.new_products.length > 0 ? (
              <div>
                <NewArrival
                  home={props.home}
                  videos={props.videos}
                  handleChooseProduct={handleChooseProduct}
                />
              </div>
            ) : // </div>
            null
          ) : null}
          <Flex mt={5} width={"100%"}>
            <VideoPlayer
              height={"450px"}
              url={props.videos[0].action}
              width="100%"
            />
          </Flex>

          {props.home.offers ? (
            props.home.offers.length > 0 ? (
              <div>
                <Offer
                  home={props.home}
                  images={images}
                  handleChooseProduct={handleChooseProduct}
                />
              </div>
            ) : null
          ) : null}
          {props.banners && props.banners.length > 0 ? (
            <Flex
              mt={5}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              <ImageSlider
                subTitle="With 10% off"
                heightImage="315px"
                images={props.banners}
              />
            </Flex>
          ) : null}
          <Box>
            <ProductVideos videos={props.videos} />
          </Box>
        </Box>
      </RTLWrapper>
    );
  } else {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    sliders: state.home.sliders,
    home: state.home.home,
    videos: state.home.videos,
    spinner: state.home.spinner,
    cartSpinner: state.orders.spinner,
    showNotification: state.orders.showNotification,
    banners: state.home.banners,
    flashSliders: state.home.flashDealSliders,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetNavCoverImages: () => dispatch(getNavCoverImages()),
  onGetHomePage: () => dispatch(getHomePage()),
  onGetVideos: () => dispatch(getVideos()),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
  onGetBanners: () => dispatch(getHomeBanners()),
  onFlashSliders: () => dispatch(getFlashSliders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
