import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Image, useBreakpointValue } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css"; // Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination]);

interface Props {
  images: any | null;
}

const NavbarCover = (props: Props) => {
  const swiperRef = useRef<any>(null);

  const direction = localStorage.getItem("dir");

  const handleClick = (index: number) => {
    window.location.href = props.images[index].action;
  };

  const handleNext = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 7000);
    return () => clearInterval(interval);
  }, []);

  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });

  if (props.images)
    return (
      <Box
        position="relative"
        cursor={"pointer"}
        height={{ base: "50vh", lg: "100vh" }}
      >
        <Swiper
          ref={swiperRef}
          navigation={false}
          loop={true}
          autoplay={{ delay: 7000 }}
          className="mySwiper h-[50vh] lg:h-[100vh]"
        >
          {props.images.map((image: any, index: number) => (
            <SwiperSlide key={index}>
              <Image
                src={image.image}
                alt="Background Image"
                height="100%"
                width="100%"
                loading="lazy"
                onClick={() => handleClick(index)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {isLargeScreen && (
          <Button
            position="absolute"
            bottom="20%"
            insetEnd="0"
            width={{ base: "30px", md: "50px", lg: "90px" }}
            transform="translateY(50%)"
            height={{ base: "30px", md: "33px", lg: "67px" }}
            borderRadius={"0px"}
            onClick={handleNext}
            zIndex={5}
          >
            <img
              className="w-[full] h-[full]"
              src={
                direction === "rtl" ? "/images/nextRTL.png" : "/images/next.png"
              }
              alt="next"
            />
          </Button>
        )}
      </Box>
    );
  else return null;
};

export default NavbarCover;
