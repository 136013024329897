import { useEffect, useState } from "react";
import Navbar from "./navbar";
import Footer from "./footer/Footer";
import { Outlet, useLocation } from "react-router-dom";
import RTLWrapper from "../../RTLWrapper";
import { Flex } from "@chakra-ui/react";

const MainLayout = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 4) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <RTLWrapper>
      <Flex
        display={"flex"}
        position={"fixed"}
        top={0}
        zIndex={10}
        width={"100%"}
        backgroundColor={
          pathname != "/"
            ? "rgba(0, 0, 0, 0.5)"
            : isScrolled
            ? "rgba(0, 0, 0, 0.5)"
            : "transparent"
        } // Conditional background color
        boxShadow={
          pathname != "/"
            ? "0px 4px 6px rgba(0, 0, 0, 0.1)"
            : isScrolled
            ? "0px 4px 6px rgba(0, 0, 0, 0.1)"
            : "none"
        } // Optional shadow
        transition={pathname == "/" ? "all 0.5s ease-in-out" : "none"} // Smooth transition
      >
        <Navbar />
      </Flex>
      <Flex display={"flex"} flexDirection={"column"} minHeight={"100vh"}>
        <Outlet />
      </Flex>
      <Footer />
    </RTLWrapper>
  );
};

export default MainLayout;
