import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { thunk } from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./context/auth/provider";
import { Toaster } from "react-hot-toast";
import rootReducer from "./store/reducers/rootReducer";
import i18n from "./i18n"; // Import the i18n file
import { I18nextProvider } from "react-i18next";

const store = createStore(rootReducer, applyMiddleware(thunk));
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
console.log(i18n.language);
const theme = extendTheme({
  direction: localStorage.getItem("dir"),
  breakpoints: {
    base: "0px",
    sm: "30em", // 480px
    md: "48em", // 768px
    lg: "62em", // 992px
    xl: "80em", // 1280px
    "2xl": "96em", // 1536px
  },
  fonts: {
    heading:
      i18n.language == "Arabic"
        ? "NotoKufiArabic, sans-serif"
        : "Makro XM, sans-serif",
    body:
      i18n.language == "Arabic"
        ? "NotoKufiArabic, sans-serif"
        : "Makro XM, sans-serif",
  },
});

root.render(
  <BrowserRouter>
    <Toaster />
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AuthContextProvider>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </AuthContextProvider>
      </ChakraProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
