/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import { IUserDataResponse } from "../models/AuthService/AuthMe";
const language = localStorage.getItem("language");

export class ProfileService {
  /**
   * GET - Profile Data
   * @param lang ar,en,ku
   * @returns any Successful response
   * @throws ApiError
   */
  public static getProfile(
    lang?: string
  ): CancelablePromise<IUserDataResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/profile",
      headers: {
        lang: language,
      },
    });
  }

  /**
   * POST - Update Profile
   * @param lang ar,en,ku
   * @param formData
   * @returns any OK
   * @throws ApiError
   */
  public static postProfileUpdate(
    lang?: string,
    formData?: {
      name?: string;
      email_or_phone?: string;
      map_address?: string;
      governor_id?: number;
      address?: string;
      receiver_phone?: number;
      avatar?: Blob;
    }
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/profile/update",
      headers: {
        lang: language,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * POST - Update Password
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postProfilePassword(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/profile/password",
      headers: {
        lang: language,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
