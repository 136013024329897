import { Dispatch } from "redux";
import { OpenAPI } from "../../api/gen/core/OpenAPI";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { ApiRequestOptions } from "../../api/gen/core/ApiRequestOptions";
import { RootState } from "../reducers/rootReducer";
import { pageEvent } from "../../config/FirebaseConfig";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../utils/Notifications/notifications";

export interface Action {
  type: string;
  res?: any;
  err?: any;
}

const storedValue = localStorage.getItem("currency");
const language = localStorage.getItem("language");
// export const createOrder = (): ThunkAction<
//   void,
//   RootState,
//   unknown,
//   Action
// > => {
//   return (dispatch: Dispatch<Action>) => {
//     type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
//     const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
//     dispatch({ type: "CREATE_ORDER_SPINNER" });

//     axios
//       .post(
//         `${OpenAPI.BASE}/checkout/cash`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "My-Custom-Header": "foobar",
//             lang: "en",
//           },
//         }
//       )
//       .then((res) => {
//         dispatch({ type: "CREATE_ORDER_SUCCESS", res });
//       })
//       .catch((err) => {
//         dispatch({ type: "CREATE_ORDER_ERROR", err });
//       });
//   };
// };

export const getFavoriteProducts = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_FAVORITE_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/favorites`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_FAVORITE_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_FAVORITE_ERROR", err });
      });
  };
};

export const getProduct = (
  productId: Record<number, any>
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_PRODUCT_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/products/${productId}`, {
        headers: {
          /*Authorization: `Bearer ${token}`,*/
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_PRODUCT_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_PRODUCT_ERROR", err });
      });
  };
};

export const getMaxPrice = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_MAX_PRICE_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/products/max-price`, {
        headers: {
          /*Authorization: `Bearer ${token}`,*/
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_MAX_PRICE_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_MAX_PRICE_ERROR", err });
      });
  };
};

export const getReviews = (
  id: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_REVIEWS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/reviews/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_REVIEWS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_REVIEWS_ERROR", err });
      });
  };
};

export const getNewProducts = (
  page: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_NEW_ARRIVALS_SPINNER" });
    if (token === null)
      axios
        .get(`${OpenAPI.BASE}/products/new-arrivals?page=${page}`, {
          headers: {
            /*Authorization: `Bearer ${token}`,*/
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_NEW_ARRIVALS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_NEW_ARRIVALS_ERROR", err });
        });
    else
      axios
        .get(`${OpenAPI.BASE}/products/new-arrivals?page=${page}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_NEW_ARRIVALS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_NEW_ARRIVALS_ERROR", err });
        });
  };
};

export const getOffers = (
  page: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_OFFERS_SPINNER" });
    if (token != "null")
      axios
        .get(`${OpenAPI.BASE}/products/offers?page=${page}`, {
          headers: { Authorization: `Bearer ${token}`, lang: language },
        })
        .then((res) => {
          dispatch({ type: "GET_OFFERS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_OFFERS_ERROR", err });
        });
    else
      axios
        .get(`${OpenAPI.BASE}/products/offers?page=${page}`, {
          headers: { /*Authorization: `Bearer ${token}`,*/ lang: language },
        })
        .then((res) => {
          dispatch({ type: "GET_OFFERS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_OFFERS_ERROR", err });
        });
  };
};

export const getFlashDeal = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_FLASH_DEAL_SPINNER" });
    if (token != "null")
      axios
        .get(`${OpenAPI.BASE}/products/flash-deals`, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_FLASH_DEAL_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_FLASH_DEAL_ERROR", err });
        });
    else
      axios
        .get(`${OpenAPI.BASE}/products/flash-deals`, {
          headers: {
            // Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_FLASH_DEAL_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_FLASH_DEAL_ERROR", err });
        });
  };
};

export const searchProducts = (
  params: any,
  has_offer?: boolean,
  new_arrivals?: boolean
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    const {
      from_price,
      to_price,
      search,
      category_id,
      per_page,
      subCategory_id,
      page,
    } = params;
    pageEvent("Search", "Search Products");
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_SEARCH_PRODUCTS_SPINNER" });
    const queryParams = new URLSearchParams();
    if (from_price !== undefined && from_price != 0)
      queryParams.append("from_price", from_price);
    if (to_price !== undefined && to_price != 0)
      queryParams.append("to_price", to_price);
    if (search !== undefined) queryParams.append("search", search);
    if (category_id !== undefined)
      queryParams.append("category_id", category_id);
    if (per_page !== undefined) queryParams.append("per_page", per_page);
    if (subCategory_id !== undefined)
      queryParams.append("sub_category_id", subCategory_id);
    if (has_offer) queryParams.append("has_offer", "true");
    if (new_arrivals) queryParams.append("new_arrivals", "true");
    if (page !== undefined) queryParams.append("page", page);

    const apiUrl = `${OpenAPI.BASE}/products?${queryParams.toString()}`;
    if (token != "null")
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_SEARCH_PRODUCTS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_SEARCH_PRODUCTS_ERROR", err });
        });
    else
      axios
        .get(apiUrl, {
          headers: {
            // Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_SEARCH_PRODUCTS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_SEARCH_PRODUCTS_ERROR", err });
        });
  };
};

export const getFilteredProducts = (
  params: any
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    const {
      from_price,
      to_price,
      search,
      category_id,
      per_page,
      subCategory_id,
    } = params;
    pageEvent("Search", "Search Products");
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_FILTERED_PRODUCTS_SPINNER" });
    const queryParams = new URLSearchParams();
    if (from_price !== undefined && from_price !== 0)
      queryParams.append("from_price", from_price);
    if (to_price !== undefined && to_price !== 0)
      queryParams.append("to_price", to_price);
    if (search !== undefined) queryParams.append("search", search);
    if (category_id !== undefined)
      queryParams.append("category_id", category_id);
    if (per_page !== undefined) queryParams.append("per_page", per_page);
    if (subCategory_id !== undefined)
      queryParams.append("sub_category_id", subCategory_id);

    const apiUrl = `${OpenAPI.BASE}/products?${queryParams.toString()}`;
    if (token != "null")
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_FILTERED_PRODUCTS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_FILTERED_PRODUCTS_ERROR", err });
        });
    else
      axios
        .get(apiUrl, {
          headers: {
            // Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_FILTERED_PRODUCTS_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_FILTERED_PRODUCTS_ERROR", err });
        });
  };
};

export const addReview = (
  id: number,
  review: any,
  success?: () => void
): any => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "CREATE_REVIEW_SPINNER" });
    pageEvent("Review", "Add Review");
    axios
      .post(
        `${OpenAPI.BASE}/reviews/${id}`,
        { reviews: review },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "CREATE_REVIEW_SUCCESS", res });
        SuccessNotification(res?.data?.message);
        if (success) success();
      })
      .catch((err) => {
        dispatch({ type: "CREATE_REVIEW_ERROR", err });
        ErrorNotification(err?.response?.data?.message ?? "Error");
      });
  };
};

export const addFavorite = (
  productId: number | undefined
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "CREATE_FAVORITE_SPINNER" });

    axios
      .post(
        `${OpenAPI.BASE}/favorites`,
        { product_id: productId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "CREATE_FAVORITE_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_FAVORITE_ERROR", err });
      });
  };
};

export const deleteFavorite = (
  productId: number | undefined
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "DELETE_FAVORITE_SPINNER" });
    axios
      .delete(`${OpenAPI.BASE}/favorites`, {
        data: {
          product_id: productId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({ type: "DELETE_FAVORITE_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "DELETE_FAVORITE_ERROR", err });
      });
  };
};

export const updateFavorite = (
  isFavorite: boolean | null
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: "UPDATE_FAVORITE_VARIABLE", isFavorite });
  };
};

// export const getOrder = (
//   id: Record<number, any>
// ): ThunkAction<void, RootState, unknown, Action> => {
//   return (dispatch: Dispatch<Action>) => {
//     type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
//     // Assuming you have some way to get the token
//     const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
//     // Replace this with your actual token retrieval
//     dispatch({ type: "GET_ORDER_SPINNER" });
//     axios
//       .get(`${OpenAPI.BASE}/orders/${id}`, {
//         headers: { Authorization: `Bearer ${token}`, lang: "en" },
//       })
//       .then((res) => {
//         dispatch({ type: "GET_ORDER_SUCCESS", res });
//       })
//       .catch((err) => {
//         dispatch({ type: "GET_ORDER_ERROR", err });
//       });
//   };
// };
