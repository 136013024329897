// MapComponent.tsx
import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import "./styles.css";

interface MapComponentProps {
  profile: any;
  formData: any;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => void;
}

const containerStyle: React.CSSProperties = {
  width: "100%",
  height: "400px",
};

const MapComponent: React.FC<MapComponentProps> = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDIBFqUp5XjAjvSRqunPQS417LOFrLurBI",
  });
  const defaultCenter = {
    lat: props.profile?.map_address
      ? parseFloat(props.profile?.map_address?.latitude)
      : 36.1911,
    lng: props.profile?.map_address
      ? parseFloat(props.profile?.map_address?.longitude)
      : 44.0094,
  };
  const [map, setMap] = useState<any>(null);
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);

  const onLoad = (mapInstance: any) => {
    setMap(mapInstance);
  };

  const onUnmount = () => {
    setMap(null);
  };

  const handleMapClick = (e: any) => {
    const clickedPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    // Set the marker position
    setMarkerPosition(clickedPosition);
    const formattedPosition = {
      latitude: clickedPosition.lat,
      longitude: clickedPosition.lng,
    };

    // Convert the object to a string
    const formattedString = JSON.stringify(formattedPosition);
    // Pass the marker position to the handleInputChange function
    props.handleInputChange(
      {
        target: { value: formattedString },
      } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      "map_address"
    );
  };
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={defaultCenter}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={handleMapClick}
    >
      {/* Add a marker at the chosen location */}
      <Marker position={markerPosition} />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(MapComponent);
