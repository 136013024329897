// YourCartComponent.tsx
import { useState } from "react";
import { Button } from "@chakra-ui/react";
import Notification from "./NotificationsTest";

const CartComponentTest: React.FC = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const handleAddToCart = () => {
    // Additional logic for adding to cart

    // Display notification for 5 seconds
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 500000);
  };

  return (
    <>
      <Button onClick={handleAddToCart}>Add to Cart</Button>
      {showNotification && <Notification message="Item added to cart!" />}
    </>
  );
};

export default CartComponentTest;
