// Import necessary components and libraries
import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";

// Define interface for props
interface IProp {
  image: string;
  typeName: string;
  color: string;
  categoryName: string;
  price: string;
  subPrice: string;
  quantity: number;
  decreaseProductCart: () => void;
  increaseProductCart: () => void;
  deleteProductCart: () => void;
}

// Define styles
const titleStyle = {
  fontSize: "1rem",
  color: "#171717",
};

const descriptionStyle = {
  fontSize: "10px",
};

const subPriceStyle = {
  fontSize: "0.7rem",
  fontWeight: "regular",
};

const priceStyle = {
  fontSize: "0.7rem",
  fontWeight: "reqular",
  color: "#DF0000",
};

// Define CheckoutCart component
const CheckoutCart = ({
  categoryName,
  color,
  image,
  price,
  subPrice,
  typeName,
  quantity,
  decreaseProductCart,
  increaseProductCart,
  deleteProductCart,
}: IProp) => {
  // Retrieve stored currency value
  const storedValue = localStorage.getItem("currency");

  // Return JSX
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      maxWidth={"100%"}
      mt={{ base: "1em", md: "1em", lg: "2em" }}
    >
      <Flex
        width={"100%"}
        justifyContent="space-between"
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Box>
          <Image
            src={image}
            boxSize="100px"
            maxHeight={"65px"}
            maxWidth={"65px"}
            objectFit="cover"
          />
        </Box>
        {/* Text */}
        <Box>
          <Flex flexDirection={"column"}>
            <Text style={titleStyle} noOfLines={1}>
              {typeName}
            </Text>
            <Text
              style={descriptionStyle}
              // maxW="5em"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {categoryName}
            </Text>
            <Text
              style={descriptionStyle}
              // maxW="5em"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {color}
            </Text>
          </Flex>
        </Box>
        {/* Quantity Controls */}
        <Box mt={{ lg: "5px" }}>
          <Flex flexDirection={"column"}>
            <Text marginInlineEnd={"0em"} style={subPriceStyle}>
              {storedValue === "USD" ? "$" : "IQD"} {subPrice}
            </Text>
            <Flex flexDirection={"row"} mt="1em">
              {price != "0" && (
                <Text style={priceStyle}>
                  {storedValue === "USD" ? "$" : "IQD"} {price}
                </Text>
              )}
            </Flex>
          </Flex>
        </Box>{" "}
        <Flex alignItems="center" p={{ base: 0, md: 1, lg: 2 }}>
          <IconButton
            icon={<FaMinus />}
            aria-label="Decrease Quantity"
            onClick={decreaseProductCart}
            size={{ base: "xs", md: "xs", lg: "sm" }}
            variant="outline"
            color="white"
            bgColor="#969696"
            marginInlineEnd={{ base: 0, md: "1em", lg: "1em" }}
            width={{ base: "20%", md: "20%", lg: "35%" }}
            _hover={{ bgColor: "#969696", color: "#FFFFFF" }}
          />
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            // mx="2px"
            width={"30%"}
          >
            <Text
              fontSize={"12px"}
              // minWidth={"18px"}

              justifyContent={"center"}
              alignItems={"center"}
              // width={"30%"}
            >
              {quantity}
            </Text>
          </Flex>
          <IconButton
            icon={<FaPlus />}
            aria-label="Increase Quantity"
            onClick={increaseProductCart}
            size={{ base: "xs", md: "xs", lg: "sm" }}
            variant="outline"
            color="white"
            bgColor="#969696"
            marginInlineStart={{ base: 0, md: "1em", lg: "1em" }}
            width={{ base: "20%", md: "20%", lg: "35%" }}
            _hover={{ bgColor: "#969696", color: "#FFFFFF" }}
          />
        </Flex>
        <Flex
          alignItems="center"
          p={2}
          // width="5em"
          // maxWidth={"7em"}
          // marginInlineStart="2em"
        >
          <IconButton
            icon={<img src="/images/shoppingCart/x.png" alt="" />}
            aria-label="Delete Product"
            size="sm"
            // variant="outline"
            color="#969696"
            bgColor="white"
            // m="1em"
            onClick={deleteProductCart}
          />
        </Flex>
      </Flex>{" "}
    </Flex>
  );
};

export default CheckoutCart;
