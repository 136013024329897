import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import { getOrder, Action } from "../../../store/actions/orderActions";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  Box,
  Flex,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useDisclosure,
} from "@chakra-ui/react";
import OrderChat from "../../../pages/user/pages/OrderChat";
import { useTranslation } from "react-i18next";
import StepperTest from "../order-stepper/OrderStepper";
import { useNavigate } from "react-router-dom";
import { useParamsId } from "../../../utils/hooks/useParamsId";
import TrackingMap from "../tracking-map/TrackingMap";
import { EmailIcon } from "react-share";
import RTLWrapper from "../../../RTLWrapper";
import { BsChatLeftTextFill } from "react-icons/bs";
import { MdChat, MdLocationPin } from "react-icons/md";
import { Rating } from "react-simple-star-rating";
import RateProducts from "./RateProducts";

enum OrderStatus {
  PENDING = "قيد المراجعة",
  PROCESSING = "جاري التجهيز",
  READY = "جاهز للتوصيل",
  SHIPPING = "جاري التوصيل",
  COMPLETED = "تم التسليم",
  CANCELED = "ملغي",
  RETURNED = "تم الارجاع",
}

const statusToEnum: Record<number, OrderStatus> = {
  1: OrderStatus.PENDING,
  2: OrderStatus.PROCESSING,
  3: OrderStatus.READY,
  4: OrderStatus.SHIPPING,
  5: OrderStatus.COMPLETED,
  6: OrderStatus.CANCELED,
  8: OrderStatus.RETURNED,
};

interface StateProps {
  myOrder: any | null;
  spinner: string | null;
}

interface DispatchProps {
  onGetOrder: (id: number) => void;
}
interface IProps {}
const headingsStyle = {
  fontSize: "1rem",
  color: "#171717",
  fontWeight: "bold",
};

const textStyle = {};

const alertStyle = {
  color: "#d0342c ",
  fontSize: "16px",
  fontWeight: "bold",
};

const MyOrderItemsTest: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  const [myOrder, setMyOrder] = React.useState<any | null>(null);
  const { t } = useTranslation();
  const steps = [
    { title: t("pending") },
    {
      title: t("processing"),
    },
    {
      title: t("ready"),
    },
    {
      title: t("shipping"),
    },
    {
      title: t("completed"),
    },
    // Add more steps as needed
  ];
  const [orderStatus, setOrderStatus] = useState<any>(null);
  const orderId = useParamsId();
  const navigate = useNavigate();
  const {
    isOpen: isChatModalOpen,
    onOpen: onChatModalOpen,
    onClose: onChatModalClose,
  } = useDisclosure();

  const {
    isOpen: isMapModalOpen,
    onOpen: onMapModalOpen,
    onClose: onMapModalClose,
  } = useDisclosure();

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const [scrollBehavior, setScrollBehavior] = React.useState<any>("inside");

  const btnRef = React.useRef(null);
  const storedValue = localStorage.getItem("currency");

  useEffect(() => {
    if (orderId) props.onGetOrder(orderId);
  }, []);

  useEffect(() => {
    if (props.myOrder)
      if (props.myOrder.id == orderId) {
        setMyOrder(props.myOrder);
        setActiveStep(props.myOrder.status - 1 || 0);
        setOrderStatus(statusToEnum[props.myOrder.status]);
      }
  }, [props.myOrder]);

  if (props.myOrder)
    return (
      <Box
        width={"100%"}
        maxWidth={"100%"}
        p={{ base: "10px", md: "10px", lg: "37px" }}
        style={{ backgroundColor: "white" }}
      >
        <Heading as="h5" style={headingsStyle}>
          {t("orderDetails")}
        </Heading>
        <Flex flexDirection="column" mt="3em">
          <Box>
            {orderStatus === OrderStatus.CANCELED ? (
              <Text style={alertStyle}>{t("orderCancelled")}</Text>
            ) : orderStatus === OrderStatus.RETURNED ? (
              <Text style={alertStyle}>{t("orderReturned")}</Text>
            ) : (
              <StepperTest steps={steps} activeStep={activeStep} />
            )}
          </Box>
        </Flex>{" "}
        <Grid templateColumns={["1fr"]} width={"100%"} mt="65px">
          <GridItem colSpan={1}>
            {orderStatus != OrderStatus.COMPLETED ? (
              <Grid templateColumns={["1fr", "1.5fr 1fr"]} gap={2} width="100%">
                <GridItem colSpan={1}>
                  <Grid
                    templateColumns={["1fr"]}
                    gap={{ base: 1, md: 1, lg: 5 }}
                  >
                    {props.myOrder &&
                      props.myOrder.products.map((product: any) => {
                        return (
                          <GridItem colSpan={1} key={product.id}>
                            <Grid
                              templateColumns={["20% 40% 40%"]}
                              gap={0}
                              width={"100%"}
                            >
                              {/* Image */}
                              <GridItem colSpan={1}>
                                <Box>
                                  <Image
                                    src={product.thumbnail}
                                    boxSize="100px"
                                    maxHeight={"65px"}
                                    maxWidth={"65px"}
                                    objectFit="cover"
                                  />
                                </Box>
                              </GridItem>
                              {/* Text */}
                              <GridItem colSpan={1}>
                                <Box>
                                  <Flex flexDirection={"column"}>
                                    <Text
                                      fontWeight={"normal"}
                                      fontSize={{
                                        base: "12px",
                                        md: "12px",
                                        lg: "20px",
                                      }}
                                      noOfLines={1}
                                    >
                                      {product.name}
                                    </Text>
                                  </Flex>
                                </Box>
                              </GridItem>
                              {/* Quantity Controls */}
                              <GridItem colSpan={1}>
                                <Box>
                                  <Flex flexDirection={"column"}>
                                    <Text
                                      marginInlineEnd={"0em"}
                                      fontWeight={"normal"}
                                      fontSize={{
                                        base: "10px",
                                        md: "10px",
                                        lg: "16px",
                                      }}
                                    >
                                      {storedValue === "USD" ? "$" : "IQD"}{" "}
                                      {product.price}
                                    </Text>
                                    <Flex flexDirection={"row"} mt="1em">
                                      {product.discount_price != 0 && (
                                        <Text
                                          fontWeight={"normal"}
                                          fontSize={{
                                            base: "10px",
                                            md: "10px",
                                            lg: "16px",
                                          }}
                                        >
                                          {storedValue === "USD" ? "$" : "IQD"}{" "}
                                          {product.discount_price}
                                        </Text>
                                      )}
                                    </Flex>
                                  </Flex>
                                </Box>{" "}
                              </GridItem>
                            </Grid>{" "}
                          </GridItem>
                        );
                      })}
                  </Grid>
                </GridItem>

                <GridItem colSpan={1}>
                  <Card
                    width={"100%"}
                    boxShadow={"none"}
                    p={0}
                    mt={{ base: "10px", md: "10px", lg: 0 }}
                  >
                    <CardBody padding={{ base: 0 }}>
                      <Stack spacing="3">
                        <Box>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width={"100%"}
                            mb="13.5px"
                          >
                            <Text
                              style={textStyle}
                              color="#8F8F8F"
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {t("subtotal")}
                            </Text>
                            <Text
                              style={textStyle}
                              color="#171717"
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {props.myOrder.subtotal}
                            </Text>
                          </Flex>
                          <Divider borderColor={"#171717"} />
                          {props.myOrder.coupon_code && (
                            <>
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                width={"100%"}
                                // height={"55px"}
                                // padding={5}
                                mt="42.5px"
                                mb="13.5px"
                              >
                                <Text
                                  style={textStyle}
                                  color="#8F8F8F"
                                  fontSize={{
                                    base: "8px",
                                    md: "8px",
                                    lg: "16px",
                                  }}
                                >
                                  {t("coupon_code")}
                                </Text>
                                <Text
                                  style={textStyle}
                                  color="#171717"
                                  fontSize={{
                                    base: "8px",
                                    md: "8px",
                                    lg: "16px",
                                  }}
                                >
                                  {props.myOrder.coupon_code}
                                </Text>
                              </Flex>
                              <Divider borderColor={"#171717"} />
                            </>
                          )}
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width={"100%"}
                            mt="42.5px"
                            mb="13.5px"
                          >
                            <Text
                              style={textStyle}
                              color="#8F8F8F"
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {t("deliveryPrice")}
                            </Text>
                            <Text
                              style={textStyle}
                              color="#171717"
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {props.myOrder.governor.price}
                            </Text>
                          </Flex>
                          <Divider borderColor={"#171717"} />
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width={"100%"}
                            mt="42.5px"
                            mb="13.5px"
                          >
                            <Text
                              style={textStyle}
                              color="#8F8F8F"
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {t("totalPrice")}
                            </Text>
                            <Text
                              style={textStyle}
                              color="#171717"
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {props.myOrder.total}
                            </Text>
                          </Flex>{" "}
                          <Divider borderColor={"#171717"} />
                        </Box>
                      </Stack>
                    </CardBody>
                  </Card>
                </GridItem>
              </Grid>
            ) : (
              <RateProducts
                storedValue={storedValue!}
                myOrder={props.myOrder}
              />
            )}
          </GridItem>

          {orderStatus == OrderStatus.SHIPPING && (
            <>
              <GridItem colSpan={1}>
                <Box p="2" mt="40px">
                  <Heading size={{ base: "sm", md: "sm", lg: "md" }} mb={4}>
                    {t("driver")}
                  </Heading>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                {props.myOrder && props.myOrder.driver && (
                  <Box
                    height={["100%", "100%"]} // Set different heights for small and larger screens
                    width={"100%"}
                    position="relative" // Add position relative to position the button inside
                  >
                    <Flex gap={"20px"} alignItems={"center"}>
                      <Avatar
                        src={props.myOrder.driver.avatar}
                        name="driver image"
                        width={"80px"}
                        h={"80px"}
                      />
                      <Button
                        leftIcon={<MdLocationPin fontSize={"large"} />}
                        color={"#171717"}
                        bg={"none"}
                        fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                        borderRadius={"100px"}
                        fontWeight={"normal"}
                        variant="outline"
                        onClick={() => onMapModalOpen()} // Add onClick handler if needed
                      >
                        {t("trackOrder")}
                      </Button>
                      <Button
                        leftIcon={<MdChat fontSize={"large"} />}
                        bg={"none"}
                        color={"#171717"}
                        fontWeight={"normal"}
                        fontSize={{ base: "10px", md: "14px", lg: "14px" }}
                        borderRadius={"100px"}
                        variant="outline"
                        onClick={() => onChatModalOpen()} // Add onClick handler if needed
                      >
                        {t("chatWithDriver")}
                      </Button>
                    </Flex>
                    <Flex mb={2} mt={4} flexDirection={"column"} width={"100%"}>
                      <Text
                        fontSize={{ base: "10px", md: "10px", lg: "18px" }}
                        color={"#0C141D"}
                        mb={2}
                      >
                        {t("name")}:
                      </Text>
                      <Input
                        isReadOnly
                        defaultValue={props.myOrder.driver.name}
                        size={{ base: "sm", md: "md", lg: "lg" }}
                      />
                    </Flex>
                    <Flex gap={"20px"}>
                      <Flex
                        mb={4}
                        mt={4}
                        flexDirection={"column"}
                        width={"100%"}
                      >
                        <Text
                          fontSize={{ base: "10px", md: "10px", lg: "18px" }}
                          color={"#0C141D"}
                          mb={2}
                        >
                          {t("phone")}:
                        </Text>
                        <Input
                          isReadOnly
                          defaultValue={props.myOrder.driver.phone}
                          size={{ base: "sm", md: "md", lg: "lg" }}
                        />
                      </Flex>
                      <Flex
                        mb={4}
                        mt={4}
                        flexDirection={"column"}
                        width={"100%"}
                      >
                        <Text
                          fontSize={{ base: "10px", md: "10px", lg: "18px" }}
                          color={"#0C141D"}
                          mb={2}
                        >
                          {t("truckNumber")}:
                        </Text>
                        <Input
                          isReadOnly
                          defaultValue={props.myOrder.driver.track_number}
                          size={{ base: "sm", md: "md", lg: "lg" }}
                        />
                      </Flex>
                    </Flex>
                    {/* <TrackingMap order={props.myOrder} /> */}
                  </Box>
                )}
              </GridItem>
            </>
          )}

          {/* Chat Modal */}
          <Modal
            isOpen={isChatModalOpen}
            onClose={onChatModalClose}
            size="md"
            finalFocusRef={btnRef}
            scrollBehavior={scrollBehavior}
          >
            <OrderChat orderId={orderId} myOrder={props.myOrder} />
          </Modal>
          <Modal
            isOpen={isMapModalOpen}
            onClose={onMapModalClose}
            size="full"
            // finalFocusRef={btnRef}
            // scrollBehavior={scrollBehavior}
          >
            <RTLWrapper>
              <ModalOverlay />{" "}
              <ModalContent>
                <ModalHeader>
                  <Flex>
                    <Text>{t("track")}</Text>
                    {/* <Text marginInlineStart={1}>{props?.orderId}</Text> */}
                  </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <TrackingMap order={props.myOrder} height={"100vh"} />
                </ModalBody>
              </ModalContent>
            </RTLWrapper>
            {/* <OrderChat orderId={orderId} myOrder={props.myOrder} /> */}
          </Modal>
        </Grid>
      </Box>
    );
  else return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    myOrder: state.orders.order,
    spinner: state.user.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetOrder: (id: number) => dispatch(getOrder(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrderItemsTest);
