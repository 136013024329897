/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AuthService {
  /**
   * POST - Login
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postLogin(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/login",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Login
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static socialMediaLogin(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/social/login",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Register
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postRegister(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/register",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Check OTP Code
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postOptCheck(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/opt-check",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Resend OTP Code
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postOptResend(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/opt-resend",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Reset Password => Send Link
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postPasswordEmail(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/password/email",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Reset Password => Check OTP
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postPasswordCheck(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/password/check",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Reset Password => Check OTP
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static addPhoneNumber(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/social/add-phone",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Reset Password => Reset
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postPasswordReset(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/password/reset",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Verify phone
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postVerifyPhone(
    lang?: string,
    requestBody?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/social/verify-phone",
      headers: {
        lang: lang,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Logout
   * @param lang
   * @param formData
   * @returns any Successful response
   * @throws ApiError
   */
  public static postLogout(
    lang?: string,
    formData?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/logout",
      headers: {
        lang: lang,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }
  /**
   * POST - Delete Account
   * @param lang
   * @param formData
   * @returns any Successful response
   * @throws ApiError
   */
  public static postDeleteAccount(
    lang?: string,
    formData?: Record<string, any>
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/profile/delete",
      headers: {
        lang: lang,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }
}
