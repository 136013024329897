import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import RTLWrapper from "../../../../RTLWrapper";

interface IProps {
  spinner: any | null;
  onClose: () => void;
  onClick: () => void;
}
const ConfirmationDialog = (props: IProps) => {
  const { t } = useTranslation();
  return (
    <RTLWrapper>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={{ base: "8px", md: "8px", lg: "16px" }}>
          {t("confirmation")}
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody fontSize={{ base: "8px", md: "8px", lg: "16px" }}>
          {t("confirmProcess")}
        </ModalBody>

        <ModalFooter>
          <Button
            marginInlineEnd={3}
            onClick={props.onClose}
            size={{ base: "xs", md: "xs", lg: "md" }}
          >
            {t("cancel")}
          </Button>
          <Button
            // colorScheme="#171717"
            bgColor={"#171717"}
            color={"#FFFFFF"}
            size={{ base: "xs", md: "xs", lg: "md" }}
            // variant="ghost"
            isLoading={props.spinner}
            onClick={props.onClick}
          >
            {t("ok")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </RTLWrapper>
  );
};

export default ConfirmationDialog;
