import {
  Box,
  Heading,
  Flex,
  List,
  ListItem,
  Link,
  Text,
  Divider,
  Grid,
  GridItem,
  IconButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  FaAppStoreIos,
  FaApple,
  FaGooglePlay,
  FaLocationDot,
} from "react-icons/fa6";
import { SiHuawei } from "react-icons/si";
import { LINKS } from "../../../utils/helpers/constants";

const headingsStyle = {
  fontSize: "12px",
  color: "#FFFFFF",
  fontWeight: "bold",
};

const subHeadingStyle = {
  fontSize: "11px",
  color: "#FFD28E",
};

const text = {
  fontSize: "10px",
  fontWeight: "regular",
  color: "#FFFFFF",
  wordSpacing: "2px", // Adjust the spacing value as needed
};
const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <Box
      as="footer"
      bg="#171717"
      borderTop="1px solid"
      borderColor="gray.300"
      // fontSize="0.875rem"
      style={{ position: "relative" }}
      mt="5em"
    >
      <Box
        px={{ base: "20px", md: "20px", lg: "40px" }}
        py={{ base: "20px", md: "20px", lg: "20px" }}
        maxW="100%"
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        <Grid
          templateColumns={["1fr", "1fr", "1fr 3fr"]}
          gap={"20px"}
          letterSpacing={i18n.language === "ar" ? "0px" : "1px"}
          lineHeight={"2"}
        >
          <GridItem colSpan={1} order={{ base: 2, md: 2, lg: 1 }}>
            <Box>
              <Flex
                flexWrap="wrap"
                alignItems={{ base: "center", md: "center", lg: "start" }}
                justifyContent={{
                  base: "center",
                  md: "center",
                  lg: "space-between",
                }}
              >
                <Heading
                  as="h5"
                  // color="gray.700"
                  mb="0.5rem"
                  style={headingsStyle}
                >
                  {t("authentic")}
                </Heading>
              </Flex>

              <Text
                style={text}
                letterSpacing={i18n.language === "ar" ? "0px" : "1px"}
                mt={{ base: "12px", md: "12px", lg: "25px" }}
                textAlign={{ base: "center", md: "start" }}
              >
                {" "}
                {t("weAreAbleToTranslate")} {t("clientsHaveInMind")}{" "}
                {t("pleaseThemAsMuchAsTheir")}
              </Text>

              <Flex
                flexWrap="wrap"
                alignItems={{ base: "center", md: "center", lg: "start" }}
                justifyContent={{
                  base: "center",
                  md: "center",
                  lg: "space-between",
                }}
                mt={{ base: "20px", md: "20px", lg: "30px" }}
              >
                <Heading as="h5" style={headingsStyle}>
                  {t("download")}
                </Heading>
              </Flex>
              <Flex
                flexWrap="wrap"
                flexDirection={"row"}
                alignItems={{ base: "center", md: "center", lg: "flex-start" }}
                justifyContent={{ base: "center", md: "flex-start" }}
                gap={{ base: "20px" }}
                mt="15px"
              >
                <IconButton
                  icon={<FaGooglePlay fontSize="large" />}
                  aria-label="google-play"
                  borderRadius={"50%"}
                />
                <IconButton
                  icon={<FaApple fontSize="large" />}
                  aria-label="app-store"
                  borderRadius={"50%"}
                />
                <IconButton
                  icon={<SiHuawei fontSize="large" />}
                  aria-label="Huawei"
                  borderRadius={"50%"}
                />
              </Flex>
            </Box>
          </GridItem>
          <GridItem colSpan={1} order={{ base: 1, md: 1, lg: 2 }}>
            <Grid
              templateColumns={["1fr", "1fr", "1fr 1fr 1fr"]}
              gap={"0px"}
              mt={{ base: "12px", md: "12px", lg: "25px" }}
              justifyItems={"center"}
              textAlign={{ base: "center", md: "start" }}
            >
              <GridItem colSpan={1} order={{ base: 3, md: 3, lg: 1 }}>
                <Flex
                  flexWrap="wrap"
                  alignItems={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                  justifyContent={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                >
                  <Heading as="h5" mb="0.5rem" style={headingsStyle}>
                    {t("about")}
                  </Heading>
                </Flex>
                <Flex
                  flexWrap="wrap"
                  flexDirection={"column"}
                  alignItems={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                  justifyContent={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                  gap={"20px"}
                >
                  <Flex flexDirection={"column"} gap="10px">
                    <Text style={subHeadingStyle}>{t("email")} </Text>
                    <Box>
                      <Text style={text}>
                        <a href="mailto:info@sadafiq.com">info@sadafiq.com</a>
                      </Text>
                    </Box>
                  </Flex>
                  <Flex flexDirection={"column"} gap={"10px"}>
                    <Box>
                      <Text style={subHeadingStyle}>{t("contact")}</Text>
                    </Box>
                    <Text style={text}>{t("sales")}</Text>
                    <Text style={text}>+964 750 432 2001</Text>
                    <Text style={text}>{t("socialMedia")}</Text>
                    <Text style={text}>3141 946 0750</Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem colSpan={1} order={{ base: 1, md: 1, lg: 2 }}>
                <Flex
                  flexWrap="wrap"
                  flexDirection={"column"}
                  alignItems={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                  justifyContent={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                  gap={"20px"}
                >
                  <Flex flexDirection={"column"} gap={"10px"}>
                    <Text style={subHeadingStyle}>{t("openingTimes")}</Text>
                    <Box>
                      <Text style={text}>{t("saturdayToThursday")}</Text>
                      <Text style={text}>09AM - 22PM</Text>
                      {/* <Text style={text}>{t("saturdayToThursday")}</Text>
                      <Text style={text}>03PM - 20PM</Text> */}
                    </Box>
                  </Flex>
                  <a
                    href="https://maps.app.goo.gl/igdRAHPEAVsHvGnS8"
                    target="_blank"
                  >
                    <Flex flexDirection={"column"} gap={"10px"}>
                      <Box>
                        <Text style={subHeadingStyle}>
                          {t("headQuarterAnd")}
                          {t("centralShowroom")}
                        </Text>
                      </Box>
                      <Text style={text}>{t("malikMahmoodRoadNear")}</Text>
                      <Text style={text}>{t("kobaniBridgeSulaymaniyah")}</Text>
                    </Flex>
                  </a>
                </Flex>
              </GridItem>
              <GridItem colSpan={1} order={{ base: 2, md: 2, lg: 3 }}>
                <Flex
                  flexWrap="wrap"
                  flexDirection={"column"}
                  alignItems={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                  justifyContent={{
                    base: "center",
                    md: "center",
                    lg: "flex-start",
                  }}
                  mb="0.5rem"
                  mt={{ base: "10px" }}
                >
                  <Heading
                    as="h5"
                    mb="0.5rem"
                    className="fo"
                    style={headingsStyle}
                  >
                    {t("ourBranches")}
                  </Heading>
                  <Heading
                    as="h5"
                    style={subHeadingStyle}
                    mb="0.5rem"
                    mt={{ base: "10px" }}
                  >
                    {t("erbil")}
                  </Heading>
                </Flex>
                <Flex flexDirection={"column"} gap={"10px"}>
                  <Flex gap={"5px"}>
                    <Box>
                      <FaLocationDot color="#FFD28E" size="20" />
                    </Box>
                    <Text style={text}>
                      <a
                        href="https://maps.app.goo.gl/EqoD83U4ZcRGvfoi7"
                        target="_blank"
                      >
                        {t("1Arbil60MStAfterAynkawaBridge")}
                        {/* {t("60MeterStErbilIraq")} */}
                      </a>
                    </Text>
                  </Flex>
                  <Flex
                    flexWrap="wrap"
                    flexDirection={"column"}
                    alignItems={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                    justifyContent={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                    mb="0.5rem"
                    mt={{ base: "10px" }}
                  >
                    <Heading as="h5" style={subHeadingStyle}>
                      {t("sulaymaniyah")}
                    </Heading>
                  </Flex>
                  <Flex
                    alignItems={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                    justifyContent={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                  >
                    <Text style={text}>
                      <Flex gap={"5px"}>
                        <Box>
                          <FaLocationDot color="#FFD28E" size="20" />
                        </Box>
                        <Text>
                          <a
                            href="https://maps.app.goo.gl/A363kudPdyEpZywFA"
                            target="_blank"
                          >
                            {t("malikMahmudSulaymaniyah")}{" "}
                            {/* {t("sulaymaniyahGovernorate46001Iraq")} */}
                          </a>
                        </Text>
                      </Flex>
                    </Text>
                  </Flex>
                  <Flex
                    flexWrap="wrap"
                    flexDirection={"column"}
                    alignItems={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                    justifyContent={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                    mb="0.5rem"
                    mt={{ base: "10px" }}
                  >
                    <Heading as="h5" style={subHeadingStyle}>
                      {t("duhok")}
                    </Heading>
                  </Flex>
                  <Flex
                    alignItems={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                    justifyContent={{
                      base: "center",
                      md: "center",
                      lg: "flex-start",
                    }}
                  >
                    <Text style={text}>
                      <Flex gap={"5px"}>
                        <Box>
                          <FaLocationDot color="#FFD28E" size="20" />
                        </Box>
                        <Text>
                          <a
                            href="https://maps.app.goo.gl/2vCoEoFPpSFFcqRC8"
                            target="_blank"
                          >
                            {t("rxwvF6PDuhokDuhok")}
                            {/* {t("governorateIraq")} */}
                          </a>
                        </Text>
                      </Flex>
                    </Text>
                  </Flex>
                </Flex>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Box>
      <Box>
        <Flex
          maxW="100%"
          p={5}
          flexDir={{ base: "row", md: "row", lg: "row" }}
          justifyContent={{ base: "center", md: "center", lg: "center" }}
          alignItems="center"
          gap={1}
        >
          <Text
            style={text}
            color="gray.600"
            fontSize="12px"
            pl="0.5rem"
            textAlign={{ base: "center", md: "start" }}
          >
            {t("copyRight")}{" "}
            <Text
              color="#FFD28E"
              mt={{ base: "20px", md: "20px", lg: 0 }}
              fontSize={"12px"}
              display={"inline"}
            >
              <a href="https://www.bandtech.co" target="_blank">
                BandTech
              </a>
            </Text>
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

type LinkItemProps = {
  text?: string;
  isTag?: boolean;
  tagText?: string;
};

export default Footer;
