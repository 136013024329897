import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store/reducers/rootReducer";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Input,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCartPlus,
  FaHeart,
  FaStar,
} from "react-icons/fa6";
import { IoHeart } from "react-icons/io5";
import ProductsContext from "../../context/products/context";
import { Rating } from "react-simple-star-rating";
import {
  getProduct,
  Action,
  addFavorite,
  deleteFavorite,
  updateFavorite,
} from "../../store/actions/productActions";
import CartContext from "../../context/cart/context";
import {
  addToCart,
  removeLoadingElements,
} from "../../store/actions/orderActions";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import { useTranslation } from "react-i18next";
import RTLWrapper from "../../RTLWrapper";
import CartNotificaiton from "../../pages/shopping-cart/CartNotification";
import { OpenAPI } from "../../api/gen";
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import QuickViewProduct from "../general/quick-view/QuickViewProduct";
import { pageEvent } from "../../config/FirebaseConfig";
import ProductCardSliderItem from "./productCardSliderItem";

interface StateProps {
  selectedProduct: any | null;
  spinner: string | null;
  cartSpinner: string | null;
}
interface DispatchProps {
  onGetProduct: (productId: Record<number, any>) => void;
  onAddFavorite: (productId: number | undefined) => void;
  onDeleteFavorite: (productId: number | undefined) => void;
  onAddToCart: (productId: number) => void;
  onRemoveLoadingElements: () => void;
}
interface Props {
  id?: number;
  url: string;
  name?: string;
  discountPrice?: string;
  price?: string;
  height?: string;
  dontGrow?: boolean;
  product: any | null;
  selectedProduct: any | null;
  spinner: string | null;
  cartSpinner: string | null;
  selectProduct: () => void;
  onGetProduct: (productId: Record<number, any>) => void;
  onAddFavorite: (productId: number | undefined) => void;
  onDeleteFavorite: (productId: number | undefined) => void;
  onAddToCart: (productId: number) => void;
  onRemoveLoadingElements: () => void;
  handleChooseProduct: (product: any) => void;
}

const ProductsCardSlider: React.FC<StateProps & DispatchProps & Props> = (
  props: Props
) => {
  const {
    url,
    name,
    discountPrice,
    price,
    id,
    height,
    dontGrow,
    product,
    cartSpinner,
    selectProduct,
    spinner,
    handleChooseProduct,
  } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isRead, setIsRead] = useState(false);

  const { productDetails } = useContext(ProductsContext);

  const {
    loading,
    actions: { addProductToCart },
  } = useContext(CartContext);
  const [isFavorite, setIsFavorite] = React.useState<number | undefined>(
    undefined
  );
  const ref = useRef<HTMLDivElement>(null);
  const viewSelectedProduct = (productId: Record<number, any>) => {
    props.onGetProduct(productId);
  };

  const onClick = () => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      pageEvent("Cart", "Add To Cart");
      handleChooseProduct(product);
      props.onAddToCart(product.id);
    } else navigate("/login");
  };

  const [images, setImages] = useState<string | any>([]);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [isMoreThanThreeLines, setIsMoreThanThreeLines] = useState(false);

  useEffect(() => {
    if (descriptionRef.current && props.selectedProduct?.description) {
      const lineHeight = parseInt(
        getComputedStyle(descriptionRef.current).lineHeight
      );
      const descriptionHeight = descriptionRef.current.clientHeight;
      const threeLinesHeight = lineHeight * 3;

      setIsMoreThanThreeLines(descriptionHeight > threeLinesHeight);
    }
  }, [props.selectedProduct?.description]);
  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <>
      <RTLWrapper>
        {showNotification ? (
          <CartNotificaiton
            message="Item added to cart!"
            product={product}
            handleCloseNotification={handleCloseNotification}
          />
        ) : null}
        <ProductCardSliderItem
          onAddFavorite={props.onAddFavorite}
          onDeleteFavorite={props.onDeleteFavorite}
          product={product}
          onClick={onClick}
          cartSpinner={cartSpinner}
          spinner={spinner}
          setIsRead={setIsRead}
          onOpen={onOpen}
          viewSelectedProduct={viewSelectedProduct}
          isOpen={isOpen}
          selectedProduct={props.selectedProduct}
          onClose={onClose}
        />
        {/* Product Drawer */}
      </RTLWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    selectedProduct: state.products.product,
    spinner: state.products.spinner,
    cartSpinner: state.orders.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetProduct: (productId: Record<number, any>) =>
    dispatch(getProduct(productId)),
  onAddToCart: (productId: number) => dispatch(addToCart(productId)),
  onAddFavorite: (productId: number | undefined) =>
    dispatch(addFavorite(productId)),
  onDeleteFavorite: (productId: number | undefined) =>
    dispatch(deleteFavorite(productId)),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsCardSlider);
