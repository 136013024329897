import React from "react";
import ReactPlayer from "react-player";

interface VideoPlayerProps {
  url: string;
  height?: string;
  width?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, height, width }) => {
  return (
    <ReactPlayer
      url={url}
      controls={true}
      height={height}
      width={width}
      style={{ maxWidth: width, borderRadius: "50px" }}
    />
  );
};

export default VideoPlayer;
