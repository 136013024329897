import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
interface IProps {
  endDate?: any;
}
const getTimeLeft = (props: IProps) => {
  let COUNDDOWN_TARGET: Date = new Date(props.endDate);

  let totalTimeLeft: number = (COUNDDOWN_TARGET as any) - new Date().getTime();

  const DAYS = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
  const HOURS = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
  const MINUTES = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
  const SECONDS = Math.floor((totalTimeLeft / 1000) % 60);

  return { DAYS, HOURS, MINUTES, SECONDS };
};

const Countdown = (props: IProps) => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft(props));
  const { t } = useTranslation();
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(props));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [props.endDate]);

  return (
    <div className={styles.countdown}>
      <div className="content w-full flex lg:gap-[50px] md:gap-[30px] xs:gap-[20px]">
        {Object.entries(timeLeft).map((el) => {
          const label = el[0];
          const value = el[1];
          return (
            <div key={label} className={styles.box}>
              <span className="font-bold lg:text-[14px] xs:text-[10px]">
                {t(label.toLowerCase())}
              </span>

              <div className={styles.value}>
                {value}
                <span className={styles.valueAfter}></span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Countdown;
