import {
  Grid,
  GridItem,
  Flex,
  Text,
  Input,
  InputGroup,
  IconButton,
  InputRightElement,
  Box,
  Center,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { connect } from "react-redux";
import {
  Action,
  getMaxPrice,
  searchProducts,
} from "../../store/actions/productActions";
import { RootState } from "../../store/reducers/rootReducer";
import { ThunkDispatch } from "redux-thunk";
import CategoriesContext from "../../context/categories/context";
import ProductCard from "../../components/general/product-card/ProductCard";
import CartNotificaiton from "../shopping-cart/CartNotification";
import { removeLoadingElements } from "../../store/actions/orderActions";
import { useTranslation } from "react-i18next";
import RTLWrapper from "../../RTLWrapper";
import { pageView } from "../../config/FirebaseConfig";
import SearchFilter from "./SearchFilter";
import { useLocation, useNavigate } from "react-router-dom";
import useInfiniteScroll from "../../hooks/useInfifniteScroll";

interface StateProps {
  spinner: string | null;
  products: any | null;
  cartSpinner: string | null;
  maxPrice: any;
  showNotification: boolean;
  totalPages: number;
}

interface DispatchProps {
  onSearchProducts: (
    params: any,
    hasOffer?: boolean,
    newArrivals?: boolean
  ) => void;
  onRemoveLoadingElements: () => void;
  onGetMaxPrice: () => void;
}

interface MenuLinkProps {
  name: string;
  path: string;
  onClose: () => void;
}

interface IProps {}

const SearchComponent: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  const {
    subCategoriesList,
    actions: { getSubCategories },
  } = useContext(CategoriesContext);
  const location = useLocation();
  const storedValue = localStorage.getItem("currency");
  const [params, setParams] = useState({
    from_price: 0,
    to_price: 0,
    search: undefined,
    category_id: undefined,
    per_page: 10, // Items per page
    subCategory_id: undefined,
    page: 1, // Current page
  });
  const [hasOffer, setHasOffer] = useState(false);
  const [newArrivals, setNewArrivals] = useState(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(false);
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    const value =
      e.target.value === null || e.target.value === ""
        ? undefined
        : e.target.value;

    // If the field is a checkbox, toggle its value
    if (fieldName === "category_id" || fieldName === "subCategory_id") {
      const fieldValue = params[fieldName] === value ? undefined : value;
      setParams((prevData) => ({
        ...prevData,
        page: 1,
        [fieldName]: fieldValue,
      }));
    } else {
      // For other fields, update the value as usual
      setParams((prevData) => ({
        ...prevData,
        page: 1,
        [fieldName]: value,
      }));
    }

    // Fetch subcategories if a category is selected
    if (fieldName === "category_id" && value) {
      getSubCategories(parseInt(value));
    }
  };

  const navigate = useNavigate();

  const handleSearch = () => {
    setParams((prevParams) => ({ ...prevParams, page: 1 }));
    props.onSearchProducts(params);
    navigate({
      pathname: "/search",
      search: `?q=${encodeURIComponent(params.search || "")}`,
    });
  };

  const handleFilter = () => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("q");
    props.onSearchProducts(
      { ...params, page: 1, search: searchQuery },
      hasOffer,
      newArrivals
    );
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetMaxPrice();
  }, []);

  const isLargeScreen = useBreakpointValue({
    base: false,
    md: true,
    lg: true,
  });

  //handle filter every time the url search changes
  useEffect(() => {
    handleFilter();
  }, [location.search]);

  const fetchMoreProducts = () => {
    if (params.page < props.totalPages)
      setParams((prevParams) => ({
        ...prevParams,
        page: prevParams.page + 1,
      }));
  };

  const [lastElementRef, isFetchingMore] = useInfiniteScroll(fetchMoreProducts);

  useEffect(() => {
    if (params.page > 1) {
      props.onSearchProducts(params);
    }
  }, [params.page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <RTLWrapper>
      {showNotification ? (
        <CartNotificaiton
          message="Item added to cart!"
          product={product}
          handleCloseNotification={handleCloseNotification}
        />
      ) : null}
      <Box
        mt={{ base: "5em", md: "10em", lg: "10em" }}
        px={{ base: "1rem", md: "1rem", lg: "120px" }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {!isLargeScreen && (
          <InputGroup maxW="100%" mt="0em" mb={4}>
            <Input
              placeholder={t("search")}
              borderRadius="5px"
              value={params.search}
              onChange={(e) => handleInputChange(e, "search")}
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => {
                  handleSearch();
                }}
                borderRadius={
                  direction === "ltr" ? "0 5px 5px 0" : "5px 0px 0px 5px"
                }
                bg="transparent"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                bgColor="#171717"
                color="#FFFFFF"
              />
            </InputRightElement>
          </InputGroup>
        )}
        <Flex
          alignItems={"flex-start"}
          flexDirection={isLargeScreen ? "row" : "column"}
          gap={"20px"}
        >
          <SearchFilter
            params={params}
            hasOffer={hasOffer}
            setHasOffer={setHasOffer}
            newArrivals={newArrivals}
            setNewArrivals={setNewArrivals}
            handleInputChange={handleInputChange}
            handleFilter={handleFilter}
          />
          {props.products && props.products.length > 0 ? (
            <Grid
              templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
              width={"100%"}
              gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
              mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
              justifyItems={"center"}
            >
              {props.products &&
                props.products.map((product: any, index: number) => {
                  if (index === props.products.length - 1) {
                    return (
                      <GridItem
                        width={{ base: "90%", md: "100%", lg: "100%" }}
                        key={product.id}
                        ref={lastElementRef}
                      >
                        <ProductCard
                          url={product.thumbnail}
                          product={product}
                          selectProduct={() => {}}
                          handleChooseProduct={handleChooseProduct}
                        />
                      </GridItem>
                    );
                  } else {
                    return (
                      <GridItem
                        width={{ base: "90%", md: "100%", lg: "100%" }}
                        key={product.id}
                      >
                        <ProductCard
                          url={product.thumbnail}
                          product={product}
                          selectProduct={() => {}}
                          handleChooseProduct={handleChooseProduct}
                        />
                      </GridItem>
                    );
                  }
                })}
            </Grid>
          ) : props.spinner === "loading" ? (
            <Grid templateColumns={["1fr"]} width="100%" mt="50px">
              <Center width={"100%"}>
                <Spinner size="xl" />
              </Center>{" "}
            </Grid>
          ) : props.products && props.products.length === 0 ? (
            <Grid templateColumns={["1fr"]} width="100%" mt="50px">
              <Center width={"100%"}>
                <img src="/images/empty/emptySearch.png" />
              </Center>{" "}
              <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
                <Text fontSize={"16px"} fontWeight={"bold"}>
                  {t("emptySearch")}
                </Text>
              </Flex>
            </Grid>
          ) : null}
        </Flex>
      </Box>
    </RTLWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.products.spinner,
    cartSpinner: state.orders.spinner,
    products: state.products.searchProducts,
    maxPrice: state.products.maxPrice,
    showNotification: state.orders.showNotification,
    totalPages: state.products.totalPages,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onSearchProducts: (params: any, hasOffer?: boolean, newArrivals?: boolean) =>
    dispatch(searchProducts(params, hasOffer, newArrivals)),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
  onGetMaxPrice: () => dispatch(getMaxPrice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
