import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { getDriverPosition } from "../../../store/actions/orderActions";
import { Flex, Spinner } from "@chakra-ui/react";

interface MapComponentProps {
  order?: any;
  height?: any;
}

const TrackingMap: React.FC<MapComponentProps> = (props) => {
  const dispatch = useDispatch();

  // Use useSelector to get the driver's position from the Redux store
  const driverPosition = useSelector(
    (state: any) => state.orders.driverPosition?.coordinate
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDIBFqUp5XjAjvSRqunPQS417LOFrLurBI",
  });

  const userLocation = {
    lat: Number(props.order.address.map_address.latitude),
    lng: Number(props.order.address.map_address.longitude),
  };

  const [map, setMap] = useState<any>(null);

  // Function to fetch driver position and update state
  const fetchDriverPosition = () => {
    dispatch(getDriverPosition({ id: props.order.id }));
  };

  // Fetch driver's position immediately upon mount, then every 10 seconds
  useEffect(() => {
    fetchDriverPosition(); // Fetch the first time immediately
    const intervalId = setInterval(fetchDriverPosition, 10000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch, props.order.id]);

  const onLoad = (mapInstance: any) => {
    setMap(mapInstance);
  };

  const onUnmount = () => {
    setMap(null);
  };

  // Calculate the zoom level based on the distance between user and driver
  const calculateZoom = (distance: number) => {
    if (distance < 1) return 16; // Close distance
    if (distance < 5) return 14; // Medium distance
    return 12; // Far distance
  };

  const haversineDistance = (coords1: any, coords2: any) => {
    const toRad = (x: number) => (x * Math.PI) / 180;
    const R = 6371; // Earth's radius in km

    const dLat = toRad(coords2.latitude - coords1.lat);
    const dLng = toRad(coords2.longitude - coords1.lng);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coords1.lat)) *
        Math.cos(toRad(coords2.latitude)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in km
  };

  useEffect(() => {
    if (driverPosition && map) {
      const distance = haversineDistance(userLocation, driverPosition);
      const newZoom = calculateZoom(distance);

      map.setZoom(newZoom);

      // Animate the driver's movement
      map.panTo({
        lat: driverPosition.latitude,
        lng: driverPosition.longitude,
      });
    }
  }, [driverPosition, map]);

  const pathCoordinates = [
    userLocation,
    driverPosition && {
      lat: driverPosition.latitude,
      lng: driverPosition.longitude,
    },
  ].filter(Boolean); // Ensure no null values are passed

  const isDataReady =
    userLocation &&
    driverPosition &&
    driverPosition.latitude &&
    driverPosition.longitude;

  return isLoaded && isDataReady ? (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: props.height || "400px",
      }}
      center={userLocation}
      zoom={14} // Initial zoom level, will be dynamically adjusted
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* User Marker */}
      <Marker position={userLocation} />

      {/* Driver Marker with animation */}
      {driverPosition && (
        <Marker
          position={{
            lat: driverPosition.latitude,
            lng: driverPosition.longitude,
          }}
          icon={{
            url: "https://static.thenounproject.com/png/509406-200.png",
            scaledSize: new window.google.maps.Size(40, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(20, 20),
          }}
          animation={window.google.maps.Animation.DROP} // Adds a drop animation
        />
      )}

      {/* Polyline between user and driver */}
      {driverPosition && (
        <Polyline
          path={pathCoordinates}
          options={{
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <Flex justifyContent={"center"} alignItems={"center"} h={"70vh"}>
      <Spinner size={"xl"} />
    </Flex>
  );
};

export default React.memo(TrackingMap);
