import { FormProvider, useForm, useWatch } from "react-hook-form";

import {
  InputGroup,
  Input,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import AuthContext from "../../context/auth/context";
import Controller from "../../components/form-components/controller";
import RTLWrapper from "../../RTLWrapper";
import { useTranslation } from "react-i18next";
import {
  signInWithFacebookPopup,
  signInWithGooglePopup,
} from "../../config/FirebaseConfig";

const LogInPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [passwordActive, setPasswordActive] = useState({
    main: false,
    confirm: false,
  });
  const {
    actions: { setEmail, login, loginWithSocialMedia, resetLinkCode },
    loading,
  } = useContext(AuthContext);
  const methods = useForm<{
    email_or_phone: string;
    password: string;
  }>({
    defaultValues: {
      email_or_phone: "",
      password: "",
    },
  });
  const { handleSubmit, control } = methods;
  const email = useWatch({
    control,
    name: "email_or_phone",
  });

  useEffect(() => {
    if (email) {
      setEmail(email);
    }
  }, [email]);
  const onLogin = (data: { email_or_phone: string; password: string }) => {
    login(data);
  };

  const logGoogleUser = async () => {
    try {
      const response = await signInWithGooglePopup();
      var data = {
        provider: "google",
        email: response.user.email,
        name: response.user.displayName,
        provider_id: response.user.uid,
      };
      loginWithSocialMedia(data);
    } catch (error) {
      console.error("Google login error:", error);
    }
  };
  const logFacebookUser = async () => {
    try {
      const response = await signInWithFacebookPopup();
      // console.log(response);
      // var data = {
      //   provider: "facebook",
      //   email: response.user.email,
      //   name: response.user.displayName,
      //   provider_id: response.user.uid,
      // };
      // loginWithSocialMedia(data);
    } catch (error) {
      console.error("Google login error:", error);
    }
  };

  const onClick = async (type?: number) => {
    await resetLinkCode({ email_or_phone: email ?? "" });
    // onContinue(1);
  };
  return (
    <RTLWrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onLogin)}>
          <div className="">
            <div className=" lg:hidden md:flex justify-between p-[15px] bg-black">
              <img
                className="cursor-pointer h-[41px]"
                width={"200px"}
                src="/images/login/logo.png"
                onClick={() => {
                  navigate("/");
                }}
                alt=""
              />
              <img
                className="cursor-pointer h-[32px]"
                width={"122px"}
                onClick={() => {
                  navigate(-1);
                }}
                src="/images/login/backicon.png"
                alt=""
              />
            </div>
            <div className="flex flex-row w-full overflow-y-scroll h-full  ">
              {/* first part */}
              <div
                className="xl:block lg:block md:hidden sm:hidden xs:hidden w-1/2 h-screen  bg-no-repeat bg-cover"
                style={{
                  backgroundImage: "url(/images/login/login.png)",
                  backgroundSize: "cover",
                }}
              >
                <div className="flex justify-between p-[15px]">
                  <img
                    className="cursor-pointer h-[41px]"
                    width={"200px"}
                    src="/images/login/logo.png"
                    onClick={() => {
                      navigate("/");
                    }}
                    alt=""
                  />
                  <img
                    className="cursor-pointer h-[32px]"
                    width={"122px"}
                    onClick={() => {
                      navigate(-1);
                    }}
                    src="/images/login/backicon.png"
                    alt=""
                  />
                </div>
              </div>
              {/* second part */}
              <div className=" lg:w-1/2 md:w-full sm:w-full xs:w-full w-1/2 h-full flex flex-col justify-center content-center items-center lg:px-0 md:px-[0px] sm:px-[0px]  xs:px-[10px] py-[47px]">
                <div className="flex flex-col gap-[60px] xs:gap-[30px]">
                  <div className="flex flex-col gap-[50px] xs:gap-[30px]">
                    <div className="font-bold  p-0 lg:text-[36px] xs:text-[20px] ">
                      {t("sgnIn")}
                    </div>
                    <Controller
                      name="email_or_phone"
                      //@ts-ignore
                      control={control}
                      rules={{ required: t(`fieldIsRequired`) }}
                      render={({ field: { ...field } }) => {
                        return (
                          <InputGroup className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px] ">
                            <img src="/images/login/phoneIcon.png" alt="" />
                            <Input
                              {...field}
                              type="tel"
                              placeholder={t("phone")}
                              border={"none"}
                              _focus={{ border: "none", boxShadow: "none" }}
                            />
                          </InputGroup>
                        );
                      }}
                    />

                    <div className="flex flex-col gap-[29px]">
                      <Controller
                        name="password"
                        //@ts-ignore
                        control={control}
                        rules={{
                          required: t(`fieldIsRequired`),
                          minLength: { message: t("atLeast8Char"), value: 8 },
                        }}
                        render={({ field: { ...field } }) => {
                          return (
                            <InputGroup className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px] ">
                              <img src="/images/login/lockIcon.png" alt="" />
                              <Input
                                {...field}
                                type={
                                  !passwordActive.main ? "password" : "text"
                                }
                                placeholder={t("password")}
                                border={"none"}
                                _focus={{ border: "none", boxShadow: "none" }}
                              />
                              <img
                                className="cursor-pointer"
                                onClick={() => {
                                  setPasswordActive({
                                    ...passwordActive,
                                    main: !passwordActive.main,
                                  });
                                }}
                                src={
                                  !passwordActive.main
                                    ? "/images/login/passwordUnActive.png"
                                    : "/images/login/passwordActive.png"
                                }
                              />
                            </InputGroup>
                          );
                        }}
                      />

                      <div
                        onClick={() => {
                          onClick();
                        }}
                        className=" cursor-pointer leading-[21px]  text-[12px] "
                      >
                        {t("forgetPassword")}
                      </div>
                    </div>
                  </div>
                  <Button
                    borderRadius={"0px"}
                    backgroundColor={"black"}
                    _hover={{ bgColor: "#333131" }}
                    color={"white"}
                    type="submit"
                    isDisabled={loading.includes("login")}
                    isLoading={loading.includes("login")}
                  >
                    {t("login")}
                  </Button>
                  <div className="flex justify-between">
                    {/* <div
                      onClick={() => {
                        navigate("/signup");
                      }}
                      className="text-[10px] cursor-pointer  leading-[21px "
                    >
                      New To Account?
                    </div> */}

                    <div
                      onClick={() => {
                        navigate("/signup");
                      }}
                      className="text-[12px] cursor-pointer font-bold  leading-[21px]"
                    >
                      {t("createAccount")}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className=" border-solid h-0 border-[1px] w-1/3 border-[#C1C1C1] "></div>
                    <div className=" font-bold text-[#C1C1C1] ">{t("or")}</div>
                    <div className=" border-solid h-0 border-[1px] w-1/3 border-[#C1C1C1] "></div>
                  </div>
                  <div className="flex gap-[43px] xs:gap-[20px]">
                    <div
                      className="flex flex-col items-center gap-[5px] cursor-pointer"
                      onClick={logGoogleUser}
                    >
                      <img
                        width={"65px"}
                        height={"65px"}
                        src="/images/login/EmailImage.png"
                        className="lg:w-[65px] lg:h-[65px] xs:w-[30px] xs:h-[30px]"
                        alt=""
                      />
                      <div className="lg:text-[16px] xs:text-[10px]">
                        {t("google")}
                      </div>
                    </div>{" "}
                    <div
                      className="flex flex-col items-center gap-[5px] cursor-pointer"
                      onClick={logFacebookUser}
                    >
                      <img
                        width={"65px"}
                        height={"65px"}
                        src="/images/login/faceImage.png"
                        className="lg:w-[65px] lg:h-[65px] xs:w-[30px] xs:h-[30px]"
                        alt=""
                      />
                      <div className="lg:text-[16px] xs:text-[10px]">
                        {t("facebook")}
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex flex-row content-between justify-between ">
                    <div className="flex gap-[23px]">
                      <div className=""> Help</div>
                      <img
                        width={"19px"}
                        height={"19px"}
                        src="/images/login/helpIcon.png"
                        alt=""
                      />
                    </div>
                    <div className="flex gap-[23px]">
                      <div className=""> Privacy</div>
                      <img
                        width={"19px"}
                        height={"19px"}
                        src="/images/login/privecyIcon.png"
                        alt=""
                      />
                    </div>{" "}
                    <div
                      onClick={onOpen}
                      className=" cursor-pointer flex gap-[23px]"
                    >
                      <div className=""> Terms</div>
                      <img
                        width={"19px"}
                        height={"19px"}
                        src="/images/login/termsIcon.png"
                        alt=""
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>terms of service</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <div className=" flex flex-col content-between gap-5 ">
                    <div>
                      his summary is provided only for convenience. please
                      review the terms of service below in their entirety for
                      important information and legal conditions that apply to
                      your use of the platform for the sharing andviewing of
                      online for mobile deviees and personal computers.
                    </div>
                    {/* <div className="flex justify-between gap-5">
                      <Button
                        backgroundColor={"black"}
                        _hover={{ bgColor: "#333131" }}
                        color={"white"}
                        borderRadius={"0px"}
                        className="lg:w-[461px] md:w-[350px]"
                        onClick={() => {}}
                      >
                        Accept
                      </Button>

                      <Button
                        onClick={onClose}
                        backgroundColor={"white"}
                        color={"black"}
                        borderRadius={"0px"}
                        borderColor={"black"}
                        className=" border border-solid text-[16px] lg:w-[461px] md:w-[350px]   "
                        fontWeight={"normal"}
                      >
                        Decline
                      </Button>
                    </div> */}
                  </div>
                </ModalBody>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        </form>
      </FormProvider>
    </RTLWrapper>
  );
};

export default LogInPage;
