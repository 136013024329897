import React, { useEffect, useState } from "react";
import { ACCESS_TOKEN } from "../../utils/helpers/constants";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Text,
  Image,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import QuickViewProduct from "../general/quick-view/QuickViewProduct";
import { FaEye, FaHeart, FaRegHeart } from "react-icons/fa6";

type Props = {
  onAddFavorite: (productId: number) => void;
  onDeleteFavorite: (productId: number) => void;
  product: any;
  cartSpinner: string | null;
  onClick: any;
  spinner: string | null;
  viewSelectedProduct: any;
  setIsRead: any;
  onOpen: any;
  isOpen: any;
  onClose: any;
  selectedProduct: any;
};

const textStyle = {
  fontWeight: "bold",
};
const smallPrice = {
  fontWeight: "bold",
};

const ProductCardSliderItem = ({
  onAddFavorite,
  onDeleteFavorite,
  product,
  spinner,
  cartSpinner,
  onClick,
  viewSelectedProduct,
  setIsRead,
  onOpen,
  isOpen,
  onClose,
  selectedProduct,
}: Props) => {
  const [isFavorite, setIsFavorite] = React.useState<boolean | undefined>(
    false
  );
  const navigate = useNavigate();
  const direction = localStorage.getItem("dir");
  const { t } = useTranslation();
  const onClickFavorite = (productId: number) => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      if (isFavorite) {
        setIsFavorite(false);
        onDeleteFavorite(productId);
      } else {
        onAddFavorite(productId);
        setIsFavorite(true);
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    setIsFavorite(product?.is_favorite);
  }, [product]);

  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });
  const [isHovered, setIsHovered] = useState(false);

  const storedValue = localStorage.getItem("currency");
  return (
    <>
      <Card
        width={{ base: "280px", md: "320px", lg: "429px" }}
        overflow="hidden"
        aspectRatio={4 / 2.7}
        onMouseEnter={() => (isLargeScreen ? setIsHovered(true) : {})}
        onMouseLeave={() => (isLargeScreen ? setIsHovered(false) : {})}
        onClick={() => navigate(`/product/${product.id}`)}
        cursor={"pointer"}
      >
        <Box aspectRatio={4 / 2.7} width="100%" position="relative">
          <Image
            src={product.thumbnail}
            alt="Green double couch with wooden legs"
            maxHeight="100%"
            maxWidth={"100%"}
            width="100%"
            height={"100%"}
            _hover={isLargeScreen ? { transform: "scale(1.1)" } : {}}
            transition="transform 0.3s ease"
            position="absolute"
            overflow="hidden"
            top={0}
            left={0}
          />
        </Box>
        {(isHovered || !isLargeScreen) && (
          <Box position="absolute" top="0" insetEnd="0" padding="2">
            <Box>
              <Flex flexDirection="row" alignItems="center">
                <Box marginInlineEnd="2">
                  <Box
                    backgroundColor="white"
                    borderRadius="full"
                    padding={{ base: "2", md: "2", lg: "2" }}
                    boxShadow="md"
                    display="inline-block"
                    cursor="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (spinner != "loading") onClickFavorite(product.id);
                    }}
                  >
                    <Box cursor={"pointer"}>
                      {localStorage.getItem(ACCESS_TOKEN) ? (
                        isFavorite ? (
                          <FaHeart
                            fontSize={isLargeScreen ? "large" : "small"}
                          />
                        ) : (
                          <FaRegHeart
                            fontSize={isLargeScreen ? "large" : "small"}
                          />
                        )
                      ) : (
                        <FaRegHeart
                          fontSize={isLargeScreen ? "large" : "small"}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    backgroundColor="white"
                    borderRadius="full"
                    padding={{ base: "2", md: "2", lg: "2" }}
                    boxShadow="md"
                    cursor="pointer"
                    display="inline-block"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSelectedProduct(product.id);
                      setIsRead(false);
                      onOpen();
                    }}
                  >
                    <FaEye fontSize={isLargeScreen ? "large" : "small"} />
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Box>
        )}
        <CardBody
          height={{ base: "100px", md: "180px", lg: "229px" }}
          maxWidth={"100%"}
          bgColor={"#ECECEC"}
          p={0}
        >
          <Grid
            templateColumns={["1fr", "1fr ", "1fr"]}
            width={"100%"}
            gap={{ base: "2px", md: "4px", lg: "8px" }} // Adjust gap between grid items as needed
          >
            <GridItem
              colSpan={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Flex
                justifyContent={"flex-start"}
                alignItems={"center"}
                px={{ base: "7.5px", md: "7.5px", lg: "15px" }}
                pt={{ base: "5px", md: "5px", lg: "10px" }}
              >
                {/* <Box overflow="hidden"> */}
                <Text
                  style={textStyle}
                  fontSize={{ base: "8px", md: "10px", lg: "12px" }}
                  noOfLines={{ base: 1, md: 1, lg: 1 }}
                  cursor="pointer"
                  flexWrap={"wrap"}
                  // width={"9em"}
                  // minWidth={"9em"}
                >
                  {product?.name ?? "table modern"}
                </Text>
                {/* </Box> */}
              </Flex>
            </GridItem>
            <GridItem colSpan={1}>
              <Flex
                justifyContent={{
                  base: "space-between",
                  md: "space-between",
                  lg: "space-between",
                }}
                flexDirection={"column"}
                gap={"10px"}
              >
                <Flex
                  flexDirection={"column"}
                  gap={{ base: "2px", md: "4px", lg: "8px" }}
                  px={{ base: "7.5px", md: "7.5px", lg: "15px" }}
                  pb={{ base: "5px", md: "5px", lg: "10px" }}
                >
                  <Text
                    color="#910202"
                    // fontSize="2xl"
                    style={{ ...textStyle, overflowWrap: "break-word" }} // Add overflowWrap style here
                    flexWrap={"wrap"}
                    fontSize={{ base: "8px", md: "10px", lg: "12px" }}
                  >
                    {/* {"$90.00"} */}
                    {storedValue === "USD" ? "$ " : "IQD "}
                    {product.price ?? null}
                  </Text>
                  {product.discount_price && product.discount_price !== "0" ? (
                    <Text
                      color="#969696"
                      // fontSize="md"
                      style={{ ...smallPrice, overflowWrap: "break-word" }} // Add overflowWrap style here
                      fontSize={{ base: "6px", md: "6px", lg: "10px" }}
                    >
                      {/* {"$90.00"} */}
                      {storedValue === "USD" ? "$ " : "IQD "}
                      {product.discount_price}
                    </Text>
                  ) : null}
                </Flex>
                <Flex
                  justifyContent={{
                    base: "center",
                    md: "center",
                    lg: "center",
                  }}
                  alignItems={"center"}
                  w={"100%"}
                >
                  {isHovered || !isLargeScreen ? (
                    <Button
                      variant="solid"
                      bgColor="#171717"
                      width="100%"
                      color={"white"}
                      isLoading={cartSpinner === "loading" ? true : false}
                      height={{ base: "25px", md: "25px", lg: "30px" }}
                      borderRadius={"0 0 10px 10px"}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                      }}
                      fontSize={{ base: "8px", md: "8px", lg: "9px" }}
                      _hover={{ bgColor: "#171717" }}
                    >
                      {t("add_to_cart")}
                    </Button>
                  ) : (
                    <Box
                      width="100%"
                      height={{ base: "25px", md: "25px", lg: "30px" }}
                    ></Box>
                  )}
                </Flex>
              </Flex>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
      {isOpen && (
        <Drawer
          isOpen={isOpen}
          placement={direction === "rtl" ? "left" : "right"}
          onClose={onClose}
          isFullHeight
          size={{ base: "xs", lg: "sm" }} // Set "sm" size for base (small) screens and "xl" size for large screens
        >
          <DrawerOverlay />
          <QuickViewProduct
            selectedProduct={selectedProduct}
            spinner={spinner}
            productId={selectedProduct?.id}
            cartSpinner={cartSpinner}
            product={product}
            onClick={onClick}
            onClose={onClose}
            onAddFavorite={onAddFavorite}
            onDeleteFavorite={onDeleteFavorite}
            isFavorite={isFavorite!}
            setIsFavorite={setIsFavorite}
          />
        </Drawer>
      )}
    </>
  );
};

export default ProductCardSliderItem;
