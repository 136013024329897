import { SearchIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../../store/reducers/rootReducer";
import {
  Action,
  searchProducts,
} from "../../../../store/actions/productActions";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface DispatchProps {
  onSearchProducts: (params: any) => void;
}

const NavBarSearch = (props: DispatchProps) => {
  const direction = localStorage.getItem("dir");
  const { t } = useTranslation();

  const [params, setParams] = useState({
    from_price: 0,
    to_price: 0,
    search: undefined,
    category_id: undefined,
    per_page: undefined,
    subCategory_id: undefined,
  });
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    setParams((prevData) => ({
      ...prevData,
      [fieldName]:
        e.target.value === null || e.target.value === ""
          ? undefined
          : e.target.value,
    }));
  };
  const location = useLocation();
  const navigate = useNavigate();

  const handleSearch = () => {
    if (location.pathname != "/search") {
      props.onSearchProducts(params);
    }
    navigate({
      pathname: "/search",
      search: `?q=${encodeURIComponent(params.search || "")}`,
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (location.search == "") {
      setParams((prevData) => ({
        ...prevData,
        search: undefined,
      }));
    }
  }, [location]);

  return (
    <InputGroup maxW="100%" mt="0em">
      <Input
        placeholder={t("search")}
        borderRadius="10px"
        value={params.search || ""}
        onChange={(e) => {
          handleInputChange(e, "search");
        }}
        background={"#24272C"}
        color={"#717377"}
        border={"none"}
        _focus={{ boxShadow: "none", border: "none" }}
        onKeyPress={handleKeyPress}
        size={{ sm: "sm", xl: "md" }}
      />
      <InputRightElement>
        <IconButton
          aria-label="Search"
          icon={<SearchIcon />}
          onClick={() => {
            handleSearch();
          }}
          bg="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          color="#FFFFFF"
        />
      </InputRightElement>
    </InputGroup>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onSearchProducts: (params: any) => dispatch(searchProducts(params)),
});

export default connect(() => {
  return {};
}, mapDispatchToProps)(NavBarSearch);
