import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { RootState } from "../../../store/reducers/rootReducer";
import {
  getGovernors,
  Action,
  getProfileData,
  updateProfile,
} from "../../../store/actions/userActions";
import MapComponent from "../../../components/map/MapComponent";
import ProfileImage from "../Components/ProfileImage";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../context/auth/context";
import ConfirmationDialog from "../../shopping-cart/Steps/Components/ConfirmationDialog";
import { pageEvent, pageView } from "../../../config/FirebaseConfig";
interface StateProps {
  governors: any | null;
  profile: any | null;
  spinner: string | null;
}

interface DispatchProps {
  onGetGovernors: () => void;
  onGetProfile: () => void;
  onUpdateProfile: (profile: Record<string, any>, message: boolean) => void;
}

interface Governor {
  id: number;
  name: string;
}

interface IProps {}
const MyInfo: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    name: props ? (props.profile ? props.profile.name : null) : null,
    email_or_phone: props
      ? props.profile
        ? props.profile.email_or_phone
        : null
      : null,
    latitude: props
      ? props.profile
        ? props.profile.map_address
          ? props.profile.map_address.latitude
          : null
        : null
      : null,
    longitude: props
      ? props.profile
        ? props.profile.map_address
          ? props.profile.map_address.longitude
          : null
        : null
      : null,
    address: props ? (props.profile ? props.profile.address : null) : null,
    governor_id: props
      ? props.profile
        ? props.profile.governor
          ? props.profile.governor.id
          : null
        : null
      : null,
    avatar: props
      ? props.profile
        ? props.profile.avatar
          ? props.profile.avatar
          : null
        : null
      : null,
  });
  const {
    actions: { deleteAccount },
    loading,
  } = useContext(AuthContext);
  const [emptyFields, setEmptyFields] = useState<string[]>([]);

  // Function to check for empty fields
  const checkEmptyFields = () => {
    const fields: string[] = [];
    for (const key in formData) {
      if (!formData[key as keyof typeof formData]) {
        fields.push(key);
      }
    }
    setEmptyFields(fields);
    return fields.length === 0;
  };
  // Generic handler for input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    if (fieldName === "avatar") {
      // Cast e.target to HTMLInputElement
      const inputElement = e.target as HTMLInputElement;
      const selectedFile = inputElement.files?.[0];

      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedFile || null,
      }));
    } else if (fieldName === "map_address") {
      setFormData((prevData) => ({
        ...prevData,
        latitude: JSON.parse(e.target.value).latitude.toString(),
        longitude: JSON.parse(e.target.value).longitude.toString(),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]:
          fieldName === "map_address"
            ? JSON.parse(e.target.value)
            : e.target.value,
      }));
    }
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      avatar: null,
    }));
  };
  // Event handler for the Save Changes button
  const handleSaveChanges = () => {
    // Check for empty fields before saving
    // if (checkEmptyFields()) {
    //   if (typeof formData.avatar === "string") {
    //     const { avatar, ...filteredFormData } = formData;
    //     props.onUpdateProfile(filteredFormData);
    //   } else {
    //     props.onUpdateProfile(formData);
    //   }
    // } else {
    //   // Handle empty fields (you can show an error message or use styles)
    // }
    if (!formData.avatar || typeof formData.avatar === "string") {
      const { avatar, ...filteredFormData } = formData;
      props.onUpdateProfile(filteredFormData, false);
    } else {
      props.onUpdateProfile(formData, false);
    }
  };

  const onDeleteProfile = () => {
    deleteAccount();
  };

  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetProfile();
    props.onGetGovernors();
  }, []);

  // Update formData when props.profile changes
  useEffect(() => {
    if (props.profile) {
      setFormData({
        name: props.profile.name,
        email_or_phone: props.profile.email_or_phone,
        latitude: props.profile.map_address
          ? props.profile.map_address.latitude
          : 36.1911,
        longitude: props.profile.map_address
          ? props.profile.map_address.longitude
          : 44.0094,
        address: props.profile.address,
        governor_id: props.profile.governor
          ? props.profile.governor.id
            ? props.profile.governor.id
            : null
          : null,
        avatar: props.profile.avatar ? props.profile.avatar : null,
      });
    }
  }, [props.profile]);
  return (
    <div style={{ backgroundColor: "white" }}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "xs", md: "sm", lg: "md" }}
      >
        <ConfirmationDialog
          onClose={onClose}
          onClick={onDeleteProfile}
          spinner={loading.includes("deleteAccount") ? true : null}
        />
      </Modal>
      <Grid
        templateColumns={["1fr"]}
        p={{ base: "10px", md: "10px", lg: "37px" }}
      >
        <GridItem colSpan={1}>
          <Box>
            <Heading size={{ base: "sm", md: "sm", lg: "md" }}>
              {t("photoProfile")}
            </Heading>
            <Flex width={"100%"} flexDirection="row">
              <ProfileImage
                image={formData.avatar}
                isEditable={true}
                onUpload={() => alert("ok")}
                handleInputChange={handleInputChange}
                handleDeleteImage={handleDeleteImage}
              />
            </Flex>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Box>
            <Heading size={{ base: "sm", md: "sm", lg: "md" }}>
              {t("personalInformation")}
            </Heading>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              // mb="4"
              width={"100%"}
              flexDirection="row"
              flexWrap="wrap"
            >
              <Flex mt={4} mb={4} flexDirection={"column"} width={"100%"}>
                <Text fontSize={{ base: "10px", md: "10px", lg: "16px" }}>
                  {t("fullName")}
                </Text>
                <Input
                  placeholder={t("fullName")}
                  defaultValue={formData.name}
                  onChange={(e) => handleInputChange(e, "name")}
                  size={{ base: "sm", md: "md", lg: "lg" }}
                />
              </Flex>
              {/* <Flex mb={4} flexDirection={"column"}>
              <Text>First Name</Text>
              <Input placeholder="large size"     size={{ base: "sm", md: "md", lg: "lg" }} />
            </Flex>
            <Flex mb={4} flexDirection={"column"}>
              <Text>Gender</Text>
              <Input placeholder="large size"     size={{ base: "sm", md: "md", lg: "lg" }} />
            </Flex>
            <Flex mb={4} flexDirection={"column"}>
              <Text>Date of birth</Text>
              <Input placeholder="large size"     size={{ base: "sm", md: "md", lg: "lg" }} />
            </Flex> */}
            </Flex>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Box>
            <Heading size={{ base: "sm", md: "sm", lg: "md" }}>
              {t("contactPersonal")}
            </Heading>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mb="4"
              // padding={7}
              width={"100%"}
              flexDirection="row"
              flexWrap="wrap"
            >
              <Flex mt={4} mb={4} width={"100%"} flexDirection={"column"}>
                <Text fontSize={{ base: "10px", md: "10px", lg: "16px" }}>
                  {t("phone")}
                </Text>
                <Input
                  placeholder={t("phone")}
                  defaultValue={formData.email_or_phone}
                  size={{ base: "sm", md: "md", lg: "lg" }}
                  onChange={(e) => handleInputChange(e, "email_or_phone")}
                />
              </Flex>
            </Flex>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Box>
            <Heading size={{ base: "sm", md: "sm", lg: "md" }}>
              {t("location")}
            </Heading>
            <Flex mb={4} mt={4} flexDirection={"column"} width={"100%"}>
              <Text fontSize={{ base: "10px", md: "10px", lg: "16px" }}>
                {t("governor")}
              </Text>
              <Select
                placeholder={t("governor")}
                size={{ base: "sm", md: "md", lg: "lg" }}
                // className="w-[440px]"
                value={formData.governor_id}
                onChange={(e) => handleInputChange(e, "governor_id")}
              >
                {props.governors &&
                  props.governors.map((governor: Governor) => {
                    return (
                      <option key={governor.id} value={governor.id}>
                        {governor.name}
                      </option>
                    );
                  })}

                {/* Add more options as needed */}
              </Select>
            </Flex>
            <Flex mb={4} flexDirection={"column"} width={"100%"}>
              <Text fontSize={{ base: "10px", md: "10px", lg: "16px" }}>
                {t("homeAddress")}
              </Text>
              <Input
                placeholder={t("homeAddress")}
                defaultValue={formData.address}
                onChange={(e) => handleInputChange(e, "address")}
                size={{ base: "sm", md: "md", lg: "lg" }}
              />
            </Flex>
            <Flex mb={4} flexDirection={"column"} width={"100%"}>
              {formData.name ? (
                <div>
                  <MapComponent
                    profile={props.profile}
                    formData={formData}
                    handleInputChange={handleInputChange}
                  />
                </div>
              ) : null}
            </Flex>
          </Box>
        </GridItem>
        <GridItem colSpan={1} justifyContent={"center"} alignItems={"center"}>
          <Flex justifyContent="center" alignItems="center">
            <Button
              backgroundColor={"black"}
              color={"white"}
              borderRadius={"0px"}
              width={{ base: "100%", md: "100%", lg: "438px" }}
              // className="mt-[30px] lg:w-[100%] md:w-[100%] sm:w-[100%] m-3"
              _hover={{ bgColor: "#333131" }}
              isLoading={props.spinner === "loading" ? true : false}
              onClick={() => handleSaveChanges()}
            >
              {t("saveChange")}
            </Button>
          </Flex>
        </GridItem>{" "}
        <GridItem colSpan={1}>
          <Flex
            justifyContent="space-start"
            alignItems="center"
            mt={8}
            mb={8}
            // padding={7}
            flexDirection="row"
          >
            <Button
              colorScheme="red"
              variant="outline"
              onClick={onOpen}
              size={{ base: "xs", md: "sm", lg: "md" }}
              // fontSize={"5px"}
            >
              {t("deleteAccount")}
            </Button>
          </Flex>
        </GridItem>
        {/* </Flex> */}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    governors: state.user.governors,
    spinner: state.user.spinner,
    profile: state.user.profile,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetGovernors: () => dispatch(getGovernors()),
  onGetProfile: () => dispatch(getProfileData()),
  onUpdateProfile: (profile: Record<string, any>, message: boolean) =>
    dispatch(updateProfile(profile, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyInfo);
