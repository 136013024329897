import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
interface IProps {
  isToday: boolean;
  notification: any | null;
}
const NotificationCard = ({ isToday, notification }: IProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex flex-col gap-[21px]  ${
        isToday ? "bg-[#F9F9F9] px-[20px] " : "bg-[#ffffff] border-b-[1px] "
      }  py-[10px]  tracking-[2px] border-solid  border-b-[#D2D2D2] `}
    >
      <div className="flex gap-4">
        <Text
          style={{ fontWeight: "bold" }}
          fontSize={{ base: "8px", md: "8px", lg: "15px" }}
          letterSpacing={"0px"}
        >
          {" "}
          {notification.notification.title}
        </Text>
        <Text
          style={{ fontWeight: "normal" }}
          color={"#7B7B7B"}
          fontSize={{ base: "8px", md: "8px", lg: "15px" }}
          letterSpacing={"0px"}
        >
          {t("posted")}
        </Text>
      </div>
      <div className="flex gap-4">
        <Text
          style={{ fontWeight: "normal" }}
          color={"#171717"}
          fontSize={{ base: "6px", md: "6px", lg: "12px" }}
          letterSpacing={"0px"}
        >
          {notification.notification.body}
        </Text>
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
        }}
        className="flex flex-nowrap gap-4 text-[#7B7B7B] "
      >
        <Box
          height={{ base: "10px", md: "10px", lg: "17px" }}
          width={{ base: "10px", md: "10px", lg: "17px" }}
        >
          <img
            src="/images/navbar/timeIcon.png"
            className="w-[100%] h-[100%]"
            alt=""
          />
        </Box>
        <Text
          fontSize={{ base: "6px", md: "6px", lg: "12px" }}
          letterSpacing={"0px"}
        >
          {" "}
          {notification.created_at}
        </Text>
      </div>
    </div>
  );
};

export default NotificationCard;
