import { IInternalState, AuthLoading } from "./context";
import toggleLoading from "../../utils/helpers/xor";
import {
  ILoginResponse,
  IRegisterPayload,
  IRegisterResponse,
} from "../../api/gen/models/AuthService/models";

type Action =
  | { type: "LOADING"; payload: { loading: AuthLoading | AuthLoading[] } }
  | { type: "IS_AUTHENTICATED"; payload: { isAuthenticated: boolean } }
  | { type: "SET_USER_DETAILS"; payload: { user?: any } }
  | {
      type: "SET_GRANTED_PERMISSIONS";
      payload: { permissions: string[] };
    }
  | { type: "LOGOUT_SUCCESS" }
  | { type: "DELETE_ACCOUNT_SUCCESS" }
  | { type: "LOGIN_SUCCESS"; payload: { user?: ILoginResponse } }
  | { type: "SET_OTP"; payload: { otp?: number } }
  | { type: "SET_EMAIL"; payload: { email?: string } }
  | { type: "SET_PHONE"; payload: { phone?: string } }
  | { type: "SET_REGISTER_DATA"; payload: { data: IRegisterResponse } };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "SET_GRANTED_PERMISSIONS":
      return { ...state, grantedPermissions: action.payload.permissions };
    case "IS_AUTHENTICATED":
      return { ...state, isAuthenticated: action.payload.isAuthenticated };
    case "LOADING":
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        userDetails: undefined,
        isAuthenticated: false,
        grantedPermissions: [],
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        userDetails: action.payload.user,
        isAuthenticated: true,
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload.user,
      };
    case "SET_OTP":
      return {
        ...state,
        otpCode: action.payload.otp,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload.email,
      };
    case "SET_PHONE":
      return {
        ...state,
        phone: action.payload.phone,
      };
    case "SET_REGISTER_DATA":
      return {
        ...state,
        registerData: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
