import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import {
  Box,
  Button,
  Card,
  CardBody,
  Drawer,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaHeart, FaRegHeart } from "react-icons/fa6";
import {
  getProduct,
  Action,
  addFavorite,
  deleteFavorite,
} from "../../../store/actions/productActions";
import {
  addToCart,
  removeLoadingElements,
} from "../../../store/actions/orderActions";
import { ACCESS_TOKEN } from "../../../utils/helpers/constants";
import { useTranslation } from "react-i18next";
import RTLWrapper from "../../../RTLWrapper";
import CartNotificaiton from "../../../pages/shopping-cart/CartNotification";
import QuickViewProduct from "../quick-view/QuickViewProduct";

interface StateProps {
  selectedProduct: any | null;
  spinner: string | null;
  cartSpinner: string | null;
}
interface DispatchProps {
  onGetProduct: (productId: Record<number, any>) => void;
  onAddFavorite: (productId: number | undefined) => void;
  onDeleteFavorite: (productId: number | undefined) => void;
  onAddToCart: (productId: number) => void;
  onRemoveLoadingElements: () => void;
}
interface Props {
  id?: number;
  url: string;
  name?: string;
  discountPrice?: string;
  height?: string;
  price?: string;
  dontGrow?: boolean;
  product: any | null;
  spinner: string | null;
  cartSpinner: string | null;
  selectedProduct: any | null;
  selectProduct: () => void;
  onGetProduct: (productId: Record<number, any>) => void;
  onAddFavorite: (productId: number | undefined) => void;
  onDeleteFavorite: (productId: number | undefined) => void;
  onAddToCart: (productId: number) => void;
  onRemoveLoadingElements: () => void;
  handleChooseProduct: (product: any) => void;
}
const textStyle = {
  fontWeight: "bold",
};

const smallPrice = {
  fontWeight: "bold",
};
const ProductCard: React.FC<StateProps & DispatchProps & Props> = (
  props: Props
) => {
  const { product, cartSpinner, spinner, handleChooseProduct } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const direction = localStorage.getItem("dir");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });
  const [isRead, setIsRead] = useState(false);

  const [isFavorite, setIsFavorite] = React.useState<boolean>(false);
  const viewSelectedProduct = (productId: Record<number, any>) => {
    props.onGetProduct(productId);
  };

  const onClickFavorite = (productId: number) => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      if (isFavorite) {
        setIsFavorite(false);
        props.onDeleteFavorite(productId);
      } else {
        props.onAddFavorite(productId);
        setIsFavorite(true);
      }
    } else {
      navigate("/login");
    }
  };

  const onClick = () => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      handleChooseProduct(product);
      props.onAddToCart(product.id);
    } else navigate("/login");
  };
  const storedValue = localStorage.getItem("currency");
  const [isHovered, setIsHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };
  useEffect(() => {
    setIsFavorite(product.is_favorite);
  }, [product]);

  return (
    <>
      <RTLWrapper>
        {showNotification ? (
          <CartNotificaiton
            message="Item added to cart!"
            product={product}
            handleCloseNotification={handleCloseNotification}
          />
        ) : null}
        <Card
          width="100%"
          overflow="hidden"
          aspectRatio={4 / 2.7}
          onMouseEnter={() => (isLargeScreen ? setIsHovered(true) : {})}
          onMouseLeave={() => (isLargeScreen ? setIsHovered(false) : {})}
          onClick={() => navigate(`/product/${product.id}`)}
          cursor={"pointer"}
        >
          <Box h={"60%"} width="100%" position="relative">
            <Image
              src={
                product.images && product.images.length > 0
                  ? product.images[currentImageIndex]
                  : product.thumbnail
              }
              alt="Green double couch with wooden legs"
              maxHeight="100%"
              maxWidth={"100%"}
              width="100%"
              height={"100%"}
              _hover={isLargeScreen ? { transform: "scale(1.1)" } : {}}
              transition={isLargeScreen ? "transform 0.3s ease" : {}}
              position="absolute"
              overflow="hidden"
              top={0}
              left={0}
              objectFit="cover"
            />
          </Box>
          {(isHovered || !isLargeScreen) && (
            <Box position="absolute" top="0" insetEnd="0" padding="2">
              <Box>
                <Flex flexDirection="row" alignItems="center">
                  <Box marginInlineEnd="2">
                    <Box
                      backgroundColor="white"
                      borderRadius="full"
                      padding={{ base: "2", md: "2", lg: "2" }}
                      boxShadow="md"
                      display="inline-block"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (spinner != "loading") onClickFavorite(product.id);
                      }}
                    >
                      <Box cursor={"pointer"}>
                        {localStorage.getItem(ACCESS_TOKEN) ? (
                          isFavorite ? (
                            <FaHeart
                              fontSize={isLargeScreen ? "large" : "small"}
                            />
                          ) : (
                            <FaRegHeart
                              fontSize={isLargeScreen ? "large" : "small"}
                            />
                          )
                        ) : (
                          <FaRegHeart
                            fontSize={isLargeScreen ? "large" : "small"}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      backgroundColor="white"
                      borderRadius="full"
                      padding={{ base: "2", md: "2", lg: "2" }}
                      boxShadow="md"
                      cursor="pointer"
                      display="inline-block"
                      onClick={(e) => {
                        e.stopPropagation();
                        viewSelectedProduct(product.id);
                        setIsRead(false);
                        onOpen();
                      }}
                    >
                      <FaEye fontSize={isLargeScreen ? "large" : "small"} />
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Box>
          )}
          <CardBody h={"40%"} maxWidth={"100%"} p={0} bgColor={"#ECECEC"}>
            <Flex
              width={"100%"}
              gap={{ base: "2px", md: "4px", lg: "8px" }} // Adjust gap between grid items as needed
              h={"100%"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Flex
                justifyContent={{
                  base: "flex-start",
                  md: "flex-start",
                  lg: "flex-start",
                }}
                px={{ base: "7.5px", md: "7.5px", lg: "15px" }}
                pt={{ base: "5px", md: "5px", lg: "10px" }}
                flexDirection={"column"}
              >
                <Flex overflow="hidden" flexDirection={"column"} gap={"0.4em"}>
                  <Text
                    style={textStyle}
                    fontSize={{ base: "8px", md: "10px", lg: "12px" }}
                    noOfLines={1}
                    cursor="pointer"
                  >
                    {product?.name ?? null}
                  </Text>

                  <Text
                    color="#910202"
                    style={textStyle}
                    fontSize={{ base: "8px", md: "10px", lg: "12px" }}
                  >
                    {" "}
                    {storedValue === "USD" ? "$ " : "IQD "}
                    {product.price ?? null}
                  </Text>
                  {product.discount_price && product.discount_price !== "0" ? (
                    <Text
                      color="#969696"
                      style={smallPrice}
                      fontSize={{ base: "6px", md: "6px", lg: "10px" }}
                    >
                      {storedValue === "USD" ? "$ " : "IQD "}
                      {product.discount_price}
                    </Text>
                  ) : null}
                </Flex>
              </Flex>
              <Flex
                justifyContent={{
                  base: "center",
                  md: "center",
                  lg: "center",
                }}
                alignItems={"center"}
                w={"100%"}
              >
                {isHovered || !isLargeScreen ? (
                  <Button
                    variant="solid"
                    bgColor="#171717"
                    width="100%"
                    height={{ base: "25px", md: "25px", lg: "30px" }}
                    color={"white"}
                    borderRadius={"0 0 10px 10px"}
                    isLoading={cartSpinner === "loading" ? true : false}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick();
                    }}
                    fontSize={{ base: "8px", md: "8px", lg: "9px" }}
                    _hover={{ bg: "#171717" }}
                  >
                    {t("add_to_cart")}
                  </Button>
                ) : (
                  <Box
                    width="100%"
                    height={{ base: "25px", md: "25px", lg: "30px" }}
                  ></Box>
                )}
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        {/* Product Drawer */}
        {isOpen && (
          <Drawer
            isOpen={isOpen}
            placement={direction === "rtl" ? "left" : "right"}
            onClose={() => {
              setIsRead(false);
              onClose();
            }}
            isFullHeight
            size={{ base: "xs", lg: "sm" }}
          >
            <DrawerOverlay />
            <QuickViewProduct
              selectedProduct={props.selectedProduct}
              isFavorite={isFavorite}
              spinner={props.spinner}
              productId={props?.selectedProduct?.id}
              cartSpinner={props.cartSpinner}
              product={product}
              onClick={onClick}
              onClose={onClose}
              onAddFavorite={props.onAddFavorite}
              onDeleteFavorite={props.onDeleteFavorite}
              setIsFavorite={setIsFavorite}
            />
          </Drawer>
        )}
      </RTLWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    selectedProduct: state.products.product,
    spinner: state.products.spinner,
    cartSpinner: state.orders.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetProduct: (productId: Record<number, any>) =>
    dispatch(getProduct(productId)),
  onAddToCart: (productId: number) => dispatch(addToCart(productId)),
  onAddFavorite: (productId: number | undefined) =>
    dispatch(addFavorite(productId)),
  onDeleteFavorite: (productId: number | undefined) =>
    dispatch(deleteFavorite(productId)),
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
