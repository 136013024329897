import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ProductCard from "../../components/general/product-card/ProductCard";
import ProductsContext from "../../context/products/context";
import CategoriesContext from "../../context/categories/context";
import {
  useCategoryParamsId,
  useParamsId,
} from "../../utils/hooks/useParamsId";
import { useLocation, useParams } from "react-router-dom";
import {
  Action,
  removeLoadingElements,
} from "../../store/actions/orderActions";
import { RootState } from "../../store/reducers/rootReducer";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import CartNotificaiton from "../shopping-cart/CartNotification";
import { getVideos } from "../../store/actions/homeActions";
import BreadCrumbComponent from "../../components/bread-crumb/BreadCrumbComponent";
import { useTranslation } from "react-i18next";
import { pageView } from "../../config/FirebaseConfig";
interface StateProps {
  cartSpinner: string | null;
  showNotification: boolean;
}

interface DispatchProps {
  onRemoveLoadingElements: () => void;
}

interface IProps {}
const SubCategory: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const subCategoryId = useParamsId();
  const didMountRef = useRef(false);
  const categoryId = useCategoryParamsId();
  const { t } = useTranslation();
  // const params = useParams();
  const { list: categoryList } = useContext(CategoriesContext);
  const {
    subCategoriesList: subCategoriesList,
    actions: { getSubCategories },
  } = useContext(CategoriesContext);
  const {
    products: productList,
    currentPage: currentPage,
    totalPages: totalPages,
    actions: productActions,
    loading,
  } = useContext(ProductsContext);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(false);
  const [totalProducts, setTotalProducts] = useState<any>(0);
  const { pathname } = useLocation();
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollPosition = scrollTop + windowHeight;

      // You can adjust the threshold value as needed
      const threshold = 0.5;

      if (scrollPosition >= documentHeight * threshold) {
        setShouldLoadMore(true);
      } else {
        setShouldLoadMore(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (shouldLoadMore) {
      // Call your pagination function here
      // For example: fetchMoreData();
      handleChangePage(currentPage + 1);
    }
  }, [shouldLoadMore]);

  useEffect(() => {
    if (categoryId) {
      getSubCategories(categoryId);
    }
  }, [categoryId]);

  const handleChangePage = (page: number) => {
    if (productList)
      if (page > 0 && page <= totalPages)
        productActions.getProducts({
          sub_category_id: subCategoryId,
          page: page,
        });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };

  useEffect(() => {
    if (!didMountRef.current) {
      productActions.getProducts({ sub_category_id: subCategoryId });
      didMountRef.current = true;
    }
  }, [subCategoryId, productActions]);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  }, [props.showNotification]);

  const renderProducts = () => {
    if (!productList || loading.includes("products")) {
      return (
        <Center height="100vh">
          <Spinner size="xl" />
        </Center>
      );
    }
    if (productList && productList.length > 0)
      return (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: `1fr 1fr 1fr`,
          }}
          width={"100%"}
          gap={{ base: 1, md: 1, lg: 3 }} // Adjust gap between grid items as needed
          mt={{ base: "25px", md: "25px", lg: "3em" }}
          justifyItems={"center"}
        >
          {showNotification ? (
            <CartNotificaiton
              message="Item added to cart!"
              product={product}
              handleCloseNotification={handleCloseNotification}
            />
          ) : null}
          {productList.map((product: any) => (
            <GridItem
              width={{ base: "90%", md: "100%", lg: "100%" }}
              key={product.id}
            >
              <ProductCard
                key={product.id}
                id={product.id}
                url={product.thumbnail}
                name={product.name}
                discountPrice={product.discount_price}
                price={product.price}
                product={product}
                selectProduct={() => productActions.getProductById(product.id)}
                handleChooseProduct={handleChooseProduct}
              />
            </GridItem>
          ))}
        </Grid>
      );
    else
      return (
        <Grid templateColumns={["1fr"]} width="100%" mt="50px">
          <Center width={"100%"}>
            <img src="/images/empty/empty-products.png" />
          </Center>{" "}
          <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
            <Text fontSize={"16px"} fontWeight={"bold"}>
              {t("emptyProducts")}
            </Text>
          </Flex>
        </Grid>
      );
  };
  return (
    <Box
      px={{ base: "1rem", md: "none", lg: "120px" }}
      mt={{ base: "4em", md: "4em", lg: "9.375em" }}
    >
      {/* <div className="pl-[140px] mt-[150px]"> */}
      <BreadCrumbComponent
        categoryList={categoryList}
        subCategoryList={subCategoriesList}
      />

      {/* </div> */}

      {/* <div className=" w-full flex-col flex justify-center items-center p-[140px] pt-[0px] pb-[0px]"> */}
      {/* <ProductContainer /> */}

      {/* <Flex justifyContent="center" alignItems="center"> */}
      {renderProducts()}
      {/* </Flex> */}

      {/* <div className="flex items-center mt-[70px] gap-[40px]">
          <Button width={"55px"} height={"25px"} variant={"outline"}>
            <img src="/images/category/left-arrow.png" alt="left" />
          </Button>

          <div className="flex  gap-[20px]">
            <Button
              fontSize={"13px"}
              width={"22px"}
              height={"25px"}
              bg={"black"}
              textColor={"white"}
              fontWeight={"normal"}
              variant={"outline"}
              _hover={{ bg: "rgba(0, 0, 0, 0.748)" }}
            >
              1
            </Button>
            <Button
              fontSize={"13px"}
              width={"22px"}
              height={"25px"}
              fontWeight={"normal"}
              variant={"outline"}
            >
              2
            </Button>
            <Button
              fontSize={"13px"}
              width={"22px"}
              height={"25px"}
              fontWeight={"normal"}
              variant={"outline"}
            >
              3
            </Button>
          </div>

          <Button width={"55px"} height={"25px"} variant={"outline"}>
            <img src="/images/category/right-arrow.png" alt="left" />
          </Button>
        </div> */}
      {/* </div> */}
      {/* <div className="p-[140px] "> */}
    </Box>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    cartSpinner: state.orders.spinner,
    showNotification: state.orders.showNotification,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);
