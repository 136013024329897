import { createContext } from "react";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";
import {
  ILoginPayload,
  ILoginResponse,
  IReSendPayload,
  IRegisterCodePayload,
  IRegisterPayload,
  IRegisterResponse,
  IResetCodePayload,
  IResetPasswordPayload,
  ISendLinkPayload,
  IAddPhonePayload,
  ILoginSocialMediaPayload,
} from "../../api/gen/models/AuthService/models";

export type AuthLoading =
  | "login"
  | "loginSocialMedia"
  | "register"
  | "logout"
  | "deleteAccount"
  | "addPhoneNumber"
  | "change_password"
  | "send_to_email"
  | "verify_password_code"
  | "roles";

export interface IInternalState {
  loading: AuthLoading[];

  isAuthenticated?: boolean;
  otpCode?: number;
  email?: string;
  phone?: string;
  userDetails?: ILoginResponse;
  grantedPermissions: string[];
  registerData?: IRegisterResponse;
}

export const internalState: IInternalState = {
  loading: [],
  isAuthenticated: true,
  grantedPermissions: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    login: (request: ILoginPayload) => void;
    loginWithSocialMedia: (request: ILoginSocialMediaPayload) => void;
    logout: () => void;
    deleteAccount: () => void;
    changePassword: (request: IResetPasswordPayload) => void;
    checkPasswordResetCode: (
      request: IResetCodePayload,
      onSuccess: () => void
    ) => void;
    registerCode: (request: IRegisterCodePayload) => void;
    verifyPhone: (request: IRegisterCodePayload) => void;
    register: (request: IRegisterPayload) => void;
    resetLinkCode: (request: ISendLinkPayload) => void;
    addPhoneNumber: (request: IAddPhonePayload) => void;
    resendCode: (request: IReSendPayload) => void;
    setEmail: (request: string) => void;
    setPhone: (request: string) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    login: DEFAULT_FUNCTION,
    loginWithSocialMedia: DEFAULT_FUNCTION,
    changePassword: DEFAULT_FUNCTION,
    logout: DEFAULT_FUNCTION,
    deleteAccount: DEFAULT_FUNCTION,
    checkPasswordResetCode: DEFAULT_FUNCTION,
    registerCode: DEFAULT_FUNCTION,
    register: DEFAULT_FUNCTION,
    resetLinkCode: DEFAULT_FUNCTION,
    addPhoneNumber: DEFAULT_FUNCTION,
    verifyPhone: DEFAULT_FUNCTION,
    resendCode: DEFAULT_FUNCTION,
    setEmail: DEFAULT_FUNCTION,
    setPhone: DEFAULT_FUNCTION,
  },
};

const AuthContext = createContext(externalState);

export default AuthContext;
