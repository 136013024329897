/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { ACCESS_TOKEN } from "../../../utils/helpers/constants";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import { IProductResponse } from "../models/ProductsService/product";
const language = localStorage.getItem("language");

export class ProductsService {
  /**
   * GET - Get Products
   * @param lang
   * @returns any Successful response
   * @throws ApiError
   */
  public static getProducts(
    lang?: string,
    params?: {
      from_price?: number;
      to_price?: number;
      category_id?: number;
      per_page?: number;
      page?: number;
      search?: string;
      sub_category_id?: number;
    }
  ): CancelablePromise<any> {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      const { TOKEN, ...restOfOpenAPI } = OpenAPI;
      const modifiedOpenAPI = { ...restOfOpenAPI };

      return __request(modifiedOpenAPI, {
        method: "GET",
        url: "/products",
        headers: {
          lang: language,
        },

        query: params,
      });
    } else {
      return __request(OpenAPI, {
        method: "GET",
        url: "/products",
        headers: {
          lang: language,
        },

        query: params,
      });
    }
  }

  /**
   * GET -  New Arrival
   * @param lang
   * @param currency
   * @returns any Successful response
   * @throws ApiError
   */
  public static getProductsNewArrival(
    lang?: string,
    currency?: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/products/new-arrival",
      headers: {
        lang: language,
        currency: currency,
      },
    });
  }

  /**
   * GET - Get Product
   * @param lang
   * @param currency
   * @returns any OK
   * @throws ApiError
   */
  public static getProducts1(
    lang?: string,
    id?: number,
    currency?: string
  ): CancelablePromise<IProductResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: `/products/${id}`,
      headers: {
        lang: language,
        currency: currency,
      },
    });
  }
}
