import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { Fab, Action as FabAction } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { LINKS } from "../../utils/helpers/constants";
import { useEffect, useState } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { CiCircleInfo } from "react-icons/ci";

type Props = {};

const HomeFloatingButtons = (props: Props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });
  return (
    <>
      {scrollPosition > 500 && (
        <Fab
          mainButtonStyles={{
            backgroundColor: "#000",
            color: "#fff",
          }}
          style={{ right: "20px", bottom: "30px" }}
          icon={<MdKeyboardDoubleArrowUp fontSize={"25px"} />}
          alwaysShowTitle={true}
          event={"click"}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        ></Fab>
      )}
      <Fab
        mainButtonStyles={{
          backgroundColor: "#fff",
          color: "#000",
        }}
        style={{ left: "20px", bottom: "30px" }}
        icon={<CiCircleInfo fontSize={"25px"} />}
        alwaysShowTitle={true}
        event={isLargeScreen ? "hover" : "click"}
      >
        <FabAction style={{ backgroundColor: "#fff", color: "#000" }}>
          <a href={LINKS.facebook} target="_blank">
            <FaFacebookF />
          </a>
        </FabAction>
        <FabAction style={{ backgroundColor: "#fff", color: "#000" }}>
          <a href={LINKS.instagram} target="_blank">
            <FaInstagram />
          </a>
        </FabAction>
        <FabAction style={{ backgroundColor: "#fff", color: "#000" }}>
          <a href={LINKS.x} target="_blank">
            <FaXTwitter />
          </a>
        </FabAction>
        <FabAction style={{ backgroundColor: "#fff", color: "#000" }}>
          <a href={LINKS.youtube} target="_blank">
            <FaYoutube />
          </a>
        </FabAction>
        <FabAction style={{ backgroundColor: "#fff", color: "#000" }}>
          <a href={LINKS.tiktok} target="_blank">
            <FaTiktok />
          </a>
        </FabAction>
      </Fab>
    </>
  );
};

export default HomeFloatingButtons;
