import { createContext } from "react";

import {
  IProducts,
  IProductsParams,
} from "../../api/gen/models/ProductsService/listModels";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";
import { IProductResponse } from "../../api/gen/models/ProductsService/product";
import { ICartDetails } from "../../api/gen/models/Carts/cartDetails";

export type AuthLoading =
  | "add_products"
  | "get_cart"
  | "add_coupon"
  | "success";

export interface IInternalState {
  loading: AuthLoading[];

  cartDetails?: ICartDetails;
}

export const internalState: IInternalState = {
  loading: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    getCart: () => void;
    decreaseProductCart: (productId: number) => void;
    increaseProductCart: (productId: number) => void;
    addProductToCart: (productId: number) => void;
    deleteProductCart: (productId: number) => void;
    addCouponToCart: (coupon: string) => void;
    removeCouponFromCart: () => void;
    removeLoadingElements: () => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getCart: DEFAULT_FUNCTION,
    addProductToCart: DEFAULT_FUNCTION,
    decreaseProductCart: DEFAULT_FUNCTION,
    deleteProductCart: DEFAULT_FUNCTION,
    addCouponToCart: DEFAULT_FUNCTION,
    removeCouponFromCart: DEFAULT_FUNCTION,
    increaseProductCart: DEFAULT_FUNCTION,
    removeLoadingElements: DEFAULT_FUNCTION,
  },
};

const CartContext = createContext(externalState);

export default CartContext;
