import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { signInWithGooglePopup } from "../src/config/FirebaseConfig";

const GoogleLogin: React.FC = () => {
  const logGoogleUser = async () => {
    try {
      const response = await signInWithGooglePopup();
    } catch (error) {
      console.error("Google login error:", error);
    }
  };

  return <button onClick={logGoogleUser}>Login with Google</button>;
};

export default GoogleLogin;
