import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";

interface Props {
  product?: any;
  imagesArray?: any;
  setSelectedProduct: any;
  setSelectedImage: any;
  setSelectedVideo: any;
}

const ProductImages = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null!);
  const { events } = useDraggable(ref);
  return (
    <div
      className="flex mt-[20px] scroll overflow-x-scroll gap-[30px] grid-container width-[100%] xs:gap-[15px] xs:mt-[5px]"
      {...events}
      ref={ref}
    >
      {props.product?.data?.video_links &&
        props.product?.data?.video_links.map((video: any) => {
          return (
            <img
              onClick={() => {
                const updatedUrl = video.includes("watch?v=")
                  ? video.replace("watch?v=", "embed/")
                  : video;
                props.setSelectedVideo(video);
                props.setSelectedProduct(props.product?.data?.id);
              }}
              className="w-[80px] h-[80px] cursor-pointer object-contain"
              src={"/images/product/video-img.png"}
              alt=""
            />
          );
        })}
      <img
        onClick={() => {
          props.setSelectedProduct(props.product?.data?.id);
          props.setSelectedImage(-1);
          props.setSelectedVideo(null);
        }}
        className="w-[80px] h-[80px] cursor-pointer object-contain"
        src={props.product?.data?.thumbnail}
        alt=""
      />
      {props.product?.data?.images &&
        props.product?.data?.images.map((image: any, index: number) => {
          return (
            <img
              onClick={() => {
                props.setSelectedProduct(props.product?.data?.id);
                props.setSelectedImage(index);
                props.setSelectedVideo(null);
              }}
              className="w-[80px] h-[80px] cursor-pointer object-contain"
              src={image}
              alt=""
            />
          );
        })}
    </div>
  );
};

export default ProductImages;
