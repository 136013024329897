import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  Modal,
  Select,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import {
  getGovernors,
  Action,
  getProfileData,
  updateProfile,
} from "../../../store/actions/userActions";
import {
  createAccountOrder,
  createOrder,
} from "../../../store/actions/orderActions";
import MapComponent from "../../../components/map/MapComponent";
import ConfirmationDialog from "./Components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface IProps {
  onNext: () => void;
  selectedPaymentMethod: string;
}

interface StateProps {
  governors: any | null;
  profile: any | null;
  paymentUrl: string | null;
  spinner: string | null;
  orderSpinner: string | null;
  orderStatus: string | null;
  error: any | null;
}

interface DispatchProps {
  onGetGovernors: () => void;
  onGetProfile: () => void;
  onUpdateProfile: (profile: Record<string, any>, message: boolean) => void;
  onCreateOrder: () => void;
  onCreateAccountOrder: () => void;
}

interface Governor {
  id: number;
  name: string;
}

const titleStyle = {
  color: "#171717",
  fontWeight: "bold",
};

const descriptionStyle = {
  color: "#171717",
  fontWeight: "bold",
};

const ConfirmAddress: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: props ? (props.profile ? props.profile.name : null) : null,
    email_or_phone: props
      ? props.profile
        ? props.profile.username
        : null
      : null,
    latitude: props
      ? props.profile
        ? props.profile.map_address
          ? props.profile.map_address.latitude
          : null
        : null
      : null,
    longitude: props
      ? props.profile
        ? props.profile.map_address
          ? props.profile.map_address.longitude
          : null
        : null
      : null,
    address: props ? (props.profile ? props.profile.address : null) : null,
    receiver_phone: props
      ? props.profile
        ? props.profile.receiver_phone
        : null
      : null,
    governor_id: props
      ? props.profile
        ? props.profile.governor
          ? props.profile.governor.id
          : null
        : null
      : null,
  });

  // Generic handler for input changes
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    fieldName: string
  ) => {
    if (fieldName === "map_address") {
      setFormData((prevData) => ({
        ...prevData,
        latitude: JSON.parse(e.target.value).latitude.toString(),
        longitude: JSON.parse(e.target.value).longitude.toString(),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]:
          fieldName === "map_address"
            ? JSON.parse(e.target.value)
            : e.target.value,
      }));
    }
  };

  // Event handler for the Save Changes button
  const handleSaveChanges = async () => {
    try {
      await props.onUpdateProfile(formData, true);

      // Now, the profile has been successfully updated
      // if (props.selectedPaymentMethod === "cash") {
      // props.onCreateOrder();
      // } else {
      //   props.onCreateAccountOrder();
      // }
    } catch (error) {
      // Handle any errors that may occur during profile update
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    props.onGetProfile();
    props.onGetGovernors();
  }, []);

  // Update formData when props.profile changes
  useEffect(() => {
    if (props.profile) {
      setFormData({
        name: props.profile.name,
        email_or_phone: props.profile.email_or_phone,
        latitude: props.profile.map_address
          ? props.profile.map_address.latitude
          : 36.1911,
        longitude: props.profile.map_address
          ? props.profile.map_address.longitude
          : 44.0094,
        address: props.profile.address,
        receiver_phone: props.profile.receiver_phone,
        governor_id: props.profile.governor.id,
      });
    }
    window.scrollTo(0, 0);
  }, [props.profile]);

  useEffect(() => {
    if (props.spinner === "success")
      if (props.profile) {
        if (!props.profile.otp_verified_at) navigate("/numberverification");
        else {
          if (props.selectedPaymentMethod === "cash") {
            props.onCreateOrder();
          } else {
            props.onCreateAccountOrder();
          }
        }
      }
  }, [props.spinner]);

  useEffect(() => {
    if (
      props.error === null &&
      props.orderStatus === "created" &&
      props.selectedPaymentMethod === "cash"
    ) {
      props.onNext();
    }
  }, [props.orderStatus]);

  useEffect(() => {
    if (props.error === null && props.orderStatus === "created") {
      if (props.paymentUrl !== null) window.location.href = props.paymentUrl;
    }
  }, [props.orderStatus]);
  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "xs", md: "sm", lg: "md" }}
      >
        <ConfirmationDialog
          onClose={onClose}
          onClick={handleSaveChanges}
          spinner={
            props.orderSpinner === "loading" || props.spinner === "loading"
              ? true
              : false
          }
        />
      </Modal>
      {/* first part */}
      <Grid
        templateColumns={["1fr", "1fr 1fr"]}
        gap={6}
        minWidth={"100%"}
        width={"100%"}
      >
        <GridItem colSpan={1}>
          <Flex flexDirection={"column"} width={"100%"}>
            <Text
              style={titleStyle}
              fontSize={{ base: "0.6rem", md: "0.6rem", lg: "1.2rem" }}
            >
              {t("confirmLocation")}
            </Text>
            <Divider
              width={"30%"}
              mt={{ base: "1em", md: "1em", lg: "2em" }}
              borderColor={"#171717"}
            />
            <Text
              style={descriptionStyle}
              fontSize={{ base: "0.4rem", md: "0.4rem", lg: "0.8rem" }}
              mt={{ base: "1em", md: "1em", lg: "2em" }}
            >
              {t("selectSiteCarefully")}
            </Text>
            <Select
              placeholder="Select option"
              size={{ base: "sm", md: "md", lg: "lg" }}
              mt={{ base: "1em", md: "1em", lg: "2em" }}
              width="100%"
              value={formData.governor_id}
              onChange={(e) => handleInputChange(e, "governor_id")}
            >
              {props.governors &&
                props.governors.map((governor: Governor) => {
                  return (
                    <option key={governor.id} value={governor.id}>
                      {governor.name}
                    </option>
                  );
                })}

              {/* Add more options as needed */}
            </Select>{" "}
            <Textarea
              placeholder="Address"
              size={{ base: "sm", md: "md", lg: "md" }}
              mt={{ base: "1em", md: "1em", lg: "2em" }}
              width="100%"
              defaultValue={formData.address}
              onChange={(e) => handleInputChange(e, "address")}
            />
            <Button
              display={["none", "none", "inherit"]}
              backgroundColor={"black"}
              color={"white"}
              width={"100%"}
              _hover={{ bgColor: "#333131" }}
              borderRadius={"0px"}
              mt={{ base: "1em", md: "1em", lg: "3.2em" }}
              onClick={onOpen}
            >
              {t("continueTo")}
            </Button>
          </Flex>
        </GridItem>
        {/* second part */}
        <GridItem
          colSpan={1}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          <Flex flexDirection={"column"}>
            {formData.name ? (
              <MapComponent
                profile={props.profile}
                formData={formData}
                handleInputChange={handleInputChange}
              />
            ) : null}
            <Button
              display={["inherit", "inherit", "none"]}
              backgroundColor={"black"}
              color={"white"}
              _hover={{ bgColor: "#333131" }}
              borderRadius={"0px"}
              mt="2em"
              onClick={onOpen}
            >
              {t("continueTo")}
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    governors: state.user.governors,
    spinner: state.user.spinner,
    error: state.user.err,
    orderSpinner: state.orders.spinner,
    orderStatus: state.orders.orderStatus,
    profile: state.user.profile,
    paymentUrl: state.orders.paymentUrl,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetGovernors: () => dispatch(getGovernors()),
  onGetProfile: () => dispatch(getProfileData()),
  onCreateOrder: () => dispatch(createOrder()),
  onCreateAccountOrder: () => dispatch(createAccountOrder()),
  onUpdateProfile: (profile: Record<string, any>, message: boolean) =>
    dispatch(updateProfile(profile, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAddress);
