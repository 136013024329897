import { FormProvider, useForm, useWatch } from "react-hook-form";

import {
  InputGroup,
  Input,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import AuthContext from "../../context/auth/context";
import Controller from "../../components/form-components/controller";
import RTLWrapper from "../../RTLWrapper";
import { useTranslation } from "react-i18next";
interface IProps {
  onContinue: (value: number) => void;
}
const PhoneNumber = ({ onContinue }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    actions: { setPhone, addPhoneNumber },
    loading,
  } = useContext(AuthContext);
  const methods = useForm<{
    phone: string;
  }>({
    defaultValues: {
      phone: "",
    },
  });
  const { handleSubmit, control } = methods;
  const phone = useWatch({
    control,
    name: "phone",
  });

  useEffect(() => {
    if (phone) {
      setPhone(phone);
    }
  }, [phone]);
  const onClick = async (data: { phone: string }) => {
    await addPhoneNumber({ phone: phone ?? "" });
    onContinue(1);
  };
  return (
    <RTLWrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onClick)}>
          <div>
            <div
              className=" text-[#545454] leading-[30px] whitespace-normal lg:w-[461px] md:w-[350px]lg:w-[461px] md:w-[350px] "
              style={{ fontWeight: "normal" }}
            >
              Phone Number
            </div>
            <Controller
              name="phone"
              //@ts-ignore
              control={control}
              rules={{ required: "Field is Required`," }}
              render={({ field: { ...field } }) => {
                return (
                  <InputGroup className=" w-[800px] flex justify-center items-center bg-[#F8F8F8] py-[14px] pl-[33px] mt-[30px] ">
                    <img src="/images/login/massageIcon.png" alt="" />
                    <Input
                      {...field}
                      type="tel"
                      placeholder="Phone number"
                      border={"none"}
                      _focus={{ border: "none", boxShadow: "none" }}
                    />
                  </InputGroup>
                );
              }}
            />
          </div>{" "}
          <div className="flex flex-col gap-[70px] mt-[30px]">
            <Button
              borderRadius={"0px"}
              backgroundColor={"black"}
              _hover={{ bgColor: "#333131" }}
              color={"white"}
              className="lg:w-[461px] md:w-[350px]"
              type="submit"
              isDisabled={loading.includes("login")}
              isLoading={loading.includes("login")}
            >
              Next
            </Button>
          </div>
        </form>
      </FormProvider>
    </RTLWrapper>
  );
};

export default PhoneNumber;
