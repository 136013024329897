import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import WaveSurfer from "wavesurfer.js";

interface AudioPlayerProps {
  src: string;
  id: number;
  setPlay: (val: boolean) => void;
}

export interface AudioPlayerHandlers {
  togglePlayPause: () => void;
}

const AudioPlayer = forwardRef<AudioPlayerHandlers, AudioPlayerProps>(
  ({ src, id, setPlay }, ref) => {
    const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlayPause = () => {
      if (wavesurfer) {
        if (isPlaying) {
          wavesurfer.pause();
        } else {
          wavesurfer.play();
        }
        setIsPlaying(!isPlaying);
      }
    };
    useEffect(() => {
      setPlay(isPlaying);
    }, [isPlaying]);

    useImperativeHandle(ref, () => ({
      togglePlayPause,
    }));

    useEffect(() => {
      const ws = WaveSurfer.create({
        container: `#waveform${id}`,
        waveColor: "black",
        progressColor: "gray",
        barWidth: 3,
        height: 40,
        barGap: 3,
        barRadius: 8,
        cursorWidth: 0,
      });

      ws.load(src);
      setWavesurfer(ws);

      return () => {
        ws.destroy();
      };
    }, [src]);

    return (
      <div>
        <div id={`waveform${id}`} style={{ width: "124px" }} />
      </div>
    );
  }
);

export default AudioPlayer;
