import {
  useDisclosure,
  RadioGroup,
  Stack,
  Radio,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import "./Drawer.css";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RTLWrapper from "../../../../../RTLWrapper";
interface IProps {
  isOpen: boolean;
  side?: string;
  onOpen: () => void;
  onClose: () => void;
  children: JSX.Element;
  header?: JSX.Element;
}
export function MiniDrawer(props: IProps) {
  let { isOpen, onClose, header } = props;
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  const [placement, setPlacement] = React.useState<string | any>(
    direction === "rtl" ? "left" : "right"
  );
  useEffect(() => {
    if (direction) {
      setPlacement(direction === "rtl" ? "left" : "right");
    }
  }, [direction]);
  return (
    <RTLWrapper>
      {/* <span className="mini-actions"> */}
      <Drawer
        placement={placement}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "xs", md: "xs", lg: "sm" }}
      >
        <DrawerOverlay />
        <DrawerContent style={{ height: "600px" }}>
          <DrawerHeader borderBottomWidth="1px">{header}</DrawerHeader>
          <DrawerBody>{props?.children}</DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* </span> */}
    </RTLWrapper>
  );
}
