import React, { useState } from "react";
import {
  Flex,
  Box,
  Card,
  CardBody,
  Image,
  Text,
  CardFooter,
  Button,
  SimpleGrid,
  Stack,
  Heading,
  Divider,
  ButtonGroup,
  Input,
  CardHeader,
  Square,
  Center,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { FaStar, FaHeart, FaMapMarkerAlt } from "react-icons/fa"; // Import your desired icons
import {
  FaAngleDown,
  FaAngleUp,
  FaChevronDown,
  FaChevronUp,
  FaMinus,
  FaPlus,
} from "react-icons/fa6";
import SearchBox from "./components/main-layout/navbar/components/SearchBox/SearchBox";

interface IProps {}

const MyOrderTest: React.FC<IProps> = (props) => {
  const [show] = React.useState(false);
  const titleStyle = {
    fontSize: "16px",
    color: "#171717",
  };

  const descriptionStyle = {
    fontSize: "10px",
  };

  const priceStyle = {
    fontSize: "10px",
    color: "#171717",
  };
  const priceFontSize = [null, "16px", "20px"];

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      {/* first part*/}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        // mb="4"
        padding={5}
        bgColor={"#ECECEC"}
        width={"50em"}
      >
        <Flex>
          {" "}
          <Box marginInlineStart="4" width="20em" maxWidth={"16em"}>
            <Flex flexDirection={"column"}>
              <Text style={titleStyle} noOfLines={1}>
                Order Placed
              </Text>
              <Text style={titleStyle} noOfLines={1}>
                28 October 2019
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width="20em" maxWidth={"16em"}>
            <Flex flexDirection={"column"}>
              <Text style={titleStyle} noOfLines={1}>
                Order Id
              </Text>
              <Text style={titleStyle} noOfLines={1}>
                Od11-6918-9139-3124
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width="5em" maxWidth={"8em"}>
            <Flex flexDirection={"column"}>
              <FaAngleUp
                size={24}
                className="cursor-pointer hover:text-[#6E6E6E]"
                // onClick={handleClickShow}
              />
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width="5em" maxWidth={"8em"}>
            <Flex flexDirection={"column"}>
              <Button
                /*leftIcon={<MdBuild />}*/ variant="solid"
                color="#FFFFFF"
                bgColor={"#171717"}
              >
                Track
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Divider width={"50em"} />
      {/* second part*/}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        // mb="4"
        padding={5}
        bgColor={"#ECECEC"}
        width={"50em"}
      >
        <SimpleGrid
          bgColor={"#ECECEC"}
          columns={2}
          spacingX="40px"
          spacingY="20px"
          width={"100%"}
          maxWidth={"100%"}
          padding={2}
        >
          {[0, 1, 2].map(() => (
            <Box bg="tomato" height="5em" width="22em">
              <Flex justifyContent="center" alignItems="center" width="100%">
                <Flex
                  alignItems="center"
                  mb="4"
                  padding={5}
                  bgColor={"#ECECEC"}
                  width={"22em"}
                >
                  {/* Image */}
                  <Image
                    src={
                      "https://sadaf-group.s3.me-south-1.amazonaws.com/products/5084889108/IzgmZYVvQ3aTpNvTlZMlPOZYtlrQi1NWvakPGMI2.jpg"
                    }
                    //   alt={itemName}
                    boxSize="100px"
                    maxHeight={"55px"}
                    maxWidth={"55px"}
                    objectFit="cover"
                  />

                  {/* Text */}
                  <Box marginInlineStart="2" width="8em" maxWidth={"8em"}>
                    <Flex flexDirection={"column"}>
                      <Text style={titleStyle} noOfLines={1}>
                        Chair
                      </Text>
                      <Flex flexDirection={"row"} mt="0.5em">
                        <Text style={descriptionStyle}>Kitchen</Text>
                        <Text
                          marginInlineStart={"2em"}
                          style={descriptionStyle}
                        >
                          Brown
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>

                  {/* Quantity Controls */}
                  <Flex alignItems="center" p={4} width="5em" maxWidth={"5em"}>
                    <Text mx="2">2</Text>
                  </Flex>
                  <Box marginInlineStart="auto" width="7em" maxWidth={"7em"}>
                    <Flex flexDirection={"column"}>
                      <Text marginInlineEnd={"2em"} style={priceStyle}>
                        100
                      </Text>
                      <Flex flexDirection={"row"}>
                        <Text style={priceStyle}>200000</Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
      <Divider width={"50em"} />
      {/* third part */}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        // mb="4"
        padding={7}
        bgColor={"#ECECEC"}
        width={"50em"}
      >
        <Flex flexDirection={"column"}>
          <Text style={titleStyle}>Final Amount</Text>
          <Text color="#969696" fontSize="md" mt="0.5em" style={priceStyle}>
            $45000
          </Text>
        </Flex>
        <Flex>
          <Text
            color="#969696"
            fontSize="md"
            style={{
              textDecoration: "underline",
            }}
          >
            Chat Delivery
          </Text>
        </Flex>
      </Flex>
      <SearchBox />
    </Flex>
  );
};

export default MyOrderTest;
