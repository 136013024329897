import React from "react";
interface IProps {
  icon: string;
  title: string;
  onClick?: () => void;
  selected: boolean;
}
const NavCart = ({ icon, title, onClick, selected }: IProps) => {
  return (
    <div
      className={` cursor-pointer flex justify-center items-center content-center gap-[20px] py-[14.5px] ${
        selected ? "bg-black" : "bg-white"
      } `}
      onClick={onClick}
    >
      <div className=" flex w-[189px] items-center content-center gap-[20px] ">
        <img
          src={icon}
          alt=""
          className="lg:w-[30px] lg:h-[30px] xs: w-[20px] h-[20px]"
        />
        <div
          style={{ fontWeight: "bold" }}
          className={`   ${
            selected ? "text-white " : "text-black "
          } lg:text-[16px] xs:text-[10px] `}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default NavCart;
