import { useParams } from "react-router-dom";

export const useParamsId = (): number | undefined => {
  const { id } = useParams();

  if (id) {
    if (typeof id === "string" || /^\d+$/.test(id)) {
      return parseInt(id);
    }
    return id;
  } else {
    return undefined;
  }
};

export const useCategoryParamsId = (): number | undefined => {
  const { categoryId } = useParams();

  if (categoryId) {
    if (typeof categoryId === "string" || /^\d+$/.test(categoryId)) {
      return parseInt(categoryId);
    }
    return categoryId;
  } else {
    return undefined;
  }
};
