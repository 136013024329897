import React, { useContext, useEffect, useReducer, useRef } from "react";
import AuthContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import ProductsContext from "./context";
import { CartsService, ProductsService } from "../../api/gen";
import { IProductsParams } from "../../api/gen/models/ProductsService/listModels";
import CartContext from "./context";

interface AuxProps {
  children: React.ReactNode;
}

const CartContextProvider: React.FC<AuxProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getCart = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "get_cart" } });
        try {
          await CartsService.getCarts("").then((data) => {
            dispatch({ type: "SET_CART_DETAILS", payload: { data: data } });
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "get_cart" } });
      },
      throwException: true,
    });
  };

  const addProductToCart = async (productId: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "add_products" } });
        try {
          await CartsService.postCarts("", {
            product_id: productId,
            from: "web",
          }).then((data) => {
            dispatch({ type: "LOADING", payload: { loading: "success" } });
            getCart();
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "add_products" } });
      },
      throwException: true,
    });
  };
  const increaseProductCart = async (productId: number) => {
    await execute({
      callback: async () => {
        // dispatch({ type: "LOADING", payload: { loading: "add_products" } });
        try {
          await CartsService.postCartsProducts1QuantityIncrease(
            "",
            productId
          ).then((data) => {
            getCart();
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        // dispatch({ type: "LOADING", payload: { loading: "add_products" } });
      },
      throwException: true,
    });
  };
  const decreaseProductCart = async (productId: number) => {
    await execute({
      callback: async () => {
        // dispatch({ type: "LOADING", payload: { loading: "add_products" } });
        try {
          await CartsService.postCartsProducts1QuantityDecrease(
            "",
            productId
          ).then((data) => {
            getCart();
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        // dispatch({ type: "LOADING", payload: { loading: "add_products" } });
      },
      throwException: true,
    });
  };
  const deleteProductCart = async (productId: number) => {
    await execute({
      callback: async () => {
        // dispatch({ type: "LOADING", payload: { loading: "add_products" } });
        try {
          await CartsService.deleteCartsProducts1("", productId).then(
            (data) => {
              getCart();
            }
          );
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        // dispatch({ type: "LOADING", payload: { loading: "add_products" } });
      },
      throwException: true,
    });
  };
  const addCouponToCart = async (coupon: string) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "add_coupon" } });
        try {
          await CartsService.postCartsRedeemCoupon("", {
            coupon_code: coupon,
          }).then((data) => {
            getCart();
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "add_coupon" } });
      },
      throwException: true,
    });
  };

  const removeCouponFromCart = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "add_coupon" } });
        try {
          await CartsService.postCartsRemoveCoupon("", {}).then((data) => {
            getCart();
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "add_coupon" } });
      },
      throwException: true,
    });
  };

  const removeLoadingElements = () => {
    dispatch({ type: "CLEAR_LOADING", payload: { loading: [] } });
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        actions: {
          getCart,

          decreaseProductCart,
          deleteProductCart,
          addCouponToCart,
          removeCouponFromCart,
          increaseProductCart,
          addProductToCart,
          removeLoadingElements,
        },
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
