import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import arTranslation from "./locales/ar.json";
import kuTranslation from "./locales/ku.json";

// the translations
const resources = {
  en: {
    translation: enTranslation,
  },
  ar: {
    translation: arTranslation,
  },
  ku: {
    translation: kuTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng:
    localStorage.getItem("language") === "en"
      ? "English"
      : localStorage.getItem("language") === "ar"
      ? "Arabic"
      : localStorage.getItem("language") === "ku"
      ? "Kurdish"
      : "en", // default language
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
