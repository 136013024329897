import React from "react";
import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls } from "@react-three/drei";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";

interface ModelProps {
  scale?: number;
  file: string; // Define 'file' prop as a required string
}

function Model({ scale = 1, file }: ModelProps) {
  const { scene, nodes, materials } = useGLTF(file);

  // Check if 'materials' is defined before using it
  console.log("GLTF Loaded:", scene, nodes, materials);

  return <primitive object={scene} scale={[scale, scale, scale]} />;
}

interface IProps {
  file: string;
}

const ThreeDComponent: React.FC<IProps> = (props: IProps) => {
  return (
    // <ModalContent>
    //   <ModalHeader>3D Model</ModalHeader>
    //   <ModalCloseButton />
    //   <ModalBody>
    <Canvas
      dpr={[1, 2]}
      shadows
      camera={{ fov: 45, position: [0, 0, 10] }}
      style={{ width: "100%", height: "100%" }}
    >
      <PresentationControls
        speed={1.5}
        global
        zoom={0.5}
        polar={[-0.1, Math.PI / 4]}
      >
        <Stage environment={null}>
          {/* Pass the 'file' prop to the Model component */}
          <Model scale={0.01} file={props.file} />
        </Stage>
      </PresentationControls>
    </Canvas>
    //   </ModalBody>
    // </ModalContent>
  );
};

export default ThreeDComponent;
