export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_MAXIMUM_PAGE_SIZE = -1;
export const INITIAL_PAGE = 1;

export const ACCESS_TOKEN = "access_token";
export const LANGUAGE_CODE = "i18nextLng";

export const DATE_FORMATE = "DD-MM-YYYY, LT";
export const MAP_DEFAULT_ZOOM = 14;

export const DEFAULT_FUNCTION = () => null;
export const DEFAULT_QUERY = {
  page: INITIAL_PAGE,
  perPage: DEFAULT_PAGE_SIZE,
};

export const MAP_DEFAULT_LOCATION = {
  lat: 33.513872588881014,
  lng: 36.27664584020132,
};

export const LINKS = {
  facebook: "https://www.facebook.com/SadafGroupIQ",
  instagram: "https://www.instagram.com/sadafgroupiq?igsh=MTFudGt6bnB4MW13",
  x: "",
  youtube: "https://www.youtube.com/@sadafgroup2660",
  tiktok: "https://www.tiktok.com/@sadaf_plaza",
};

export const languages = [
  {
    code: "ar",
    name: "العربية",
    isMain: true,
  },
  {
    code: "en",
    name: "English",
    isMain: true,
  },
];

const getDefaultTranslations = () => {
  const obj: any = {};
  languages.forEach((l) => (obj[l.code] = null));
  return obj;
};
export const DEFAULT_TRANSLATIONS = getDefaultTranslations();

export const checkTranslations = (data: any) => {
  const s1 = Object.keys(DEFAULT_TRANSLATIONS);
  const s2 = Object.keys(data);
  let check = true;
  s1.forEach((item) => {
    if (!s2.includes(item)) {
      check = false;
    }
  });
  return check;
};

export const checkObjectTranslations = (data: any) => {
  const s1 = Object.values(data);
  let check = false;

  s1.forEach((item) => {
    if (item) {
      check = true;
    }
  });
  return check;
};

export const checkObjectValuesTranslations = (data: any) => {
  const s1 = Object.values(data);

  let check = true;

  if (checkTranslations(data) === true) {
    s1.forEach((item) => {
      if (!item) {
        check = false;
      }
    });
  } else {
    check = false;
  }

  return check;
};
