import { createContext } from "react";

import {
  ICategories,
  ICategory,
} from "../../api/gen/models/CategoriesServices/listModels";
import { DEFAULT_FUNCTION } from "../../utils/helpers/constants";

export type AuthLoading = "categories";

export interface IInternalState {
  loading: AuthLoading[];
  list?: ICategories;
  subCategoriesList?: ICategories;
  categoryDetails?: ICategory;
}

export const internalState: IInternalState = {
  loading: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    getCategories: () => void;
    getSubCategories: (id: number) => void;
    getCategoryDetails: (id: number) => void;
    getAllSubCategories: () => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getCategories: DEFAULT_FUNCTION,
    getSubCategories: DEFAULT_FUNCTION,
    getCategoryDetails: DEFAULT_FUNCTION,
    getAllSubCategories: DEFAULT_FUNCTION,
  },
};

const CategoriesContext = createContext(externalState);

export default CategoriesContext;
