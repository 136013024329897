import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
interface IProps {
  number?: string;
  question?: string;
  answer?: string;
}
const FAQCard = ({ number, question, answer }: IProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div
      key={number}
      className="  flex flex-row   gap-[20px] justify-between py-[30px] w-full border-solid  border-t-[1px] border-[#D1D1D1] "
    >
      <div className=" flex gap-[20px]  items-start content-start justify-start  ">
        <Text fontSize={{ base: "16px", md: "16px", lg: "32px" }}>
          {number}
        </Text>

        <div className="flex flex-col pt-[5px]  gap-[20px]  ">
          <Text fontSize={{ base: "16px", md: "16px", lg: "32px" }}>
            {question}
          </Text>
          {isExpanded && (
            <Text
              className="text-[#667085]  "
              fontSize={{ base: "9px", md: "9px", lg: "18px" }}
            >
              {answer}
            </Text>
          )}
        </div>
      </div>
      <Box
        width={{ base: "12px", md: "12px", lg: "24px" }}
        height={{ base: "12px", md: "12px", lg: "24px" }}
      >
        <img
          className="w-[100%] h-[100%] cursor-pointer "
          onClick={() => setIsExpanded(!isExpanded)}
          src={
            !isExpanded
              ? "/images/user/plusIcon.png"
              : "/images/user/minusIcon.png"
          }
          alt=""
        />
      </Box>
    </div>
  );
};

export default FAQCard;
