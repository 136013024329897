import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import {
  Action,
  addFavorite,
  deleteFavorite,
} from "../../../../store/actions/productActions";
import { RootState } from "../../../../store/reducers/rootReducer";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import RTLWrapper from "../../../../RTLWrapper";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
interface StateProps {
  spinner: string | null;
}
interface DispatchProps {
  onAddFavorite: (productId: number | undefined) => void;
  onDeleteFavorite: (productId: number | undefined) => void;
}
interface Props {
  url: string;
  id?: number;
  name: string;
  discountPrice: string;
  price: string;
  spinner: string | null;
  onAddFavorite: (productId: number | undefined) => void;
  onDeleteFavorite: (productId: number | undefined) => void;
}
const textStyle = {
  fontWeight: "bold",
};

const smallPrice = {
  fontWeight: "bold",
};
const ProductCard: React.FC<StateProps & DispatchProps & Props> = ({
  url,
  id,
  name,
  discountPrice,
  price,
  spinner,
  onAddFavorite,
  onDeleteFavorite,
}: Props) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isFavorite, setIsFavorite] = React.useState<boolean>(true);
  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });
  const onClickFavorite = () => {
    if (isFavorite) {
      onDeleteFavorite(id);
    } else onAddFavorite(id);
  };
  const storedValue = localStorage.getItem("currency");
  return (
    <>
      <RTLWrapper>
        <Card
          maxWidth={"100%"}
          onMouseEnter={() => (isLargeScreen ? setIsHovered(true) : {})}
          onMouseLeave={() => (isLargeScreen ? setIsHovered(false) : {})}
          width="100%"
          overflow="hidden"
          h={{ base: "190px", md: "200px", lg: "275px" }}
          onClick={() => navigate(`/product/${id}`)}
          cursor={"pointer"}
        >
          <Box
            height={{
              base: "100px",
              md: "150px",
              lg: "200px",
            }}
            width="100%"
            position="relative"
          >
            <Flex
              height={"100%"}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Image
                src={url}
                alt="Green double couch with wooden legs"
                maxHeight="100%"
                maxWidth={"100%"}
                width="100%"
                height={"100%"}
                _hover={isLargeScreen ? { transform: "scale(1.1)" } : {}}
                transition={isLargeScreen ? "transform 0.3s ease" : {}}
                position="absolute"
                overflow="hidden"
                top={0}
                left={0}
              />
            </Flex>
          </Box>
          {(isHovered || !isLargeScreen) && (
            <Box position="absolute" top="0" left="0" padding="2">
              <Box>
                <Flex flexDirection="row" alignItems="center">
                  <Box>
                    <Box
                      backgroundColor="white"
                      borderRadius="full"
                      padding="2"
                      boxShadow="md"
                      display="inline-block"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (spinner != "loading") onClickFavorite();
                      }}
                    >
                      <Box cursor={"pointer"}>
                        {isFavorite ? (
                          <FaHeart
                            fontSize={isLargeScreen ? "large" : "small"}
                          />
                        ) : (
                          <FaRegHeart
                            fontSize={isLargeScreen ? "large" : "small"}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Box>
          )}

          <CardBody
            height={{ base: "100px", md: "180px", lg: "229px" }}
            px={{ base: "7.5px", md: "7.5px", lg: "15px" }}
            py={{ base: "5px", md: "5px", lg: "10px" }}
            maxWidth={"100%"}
            cursor="pointer"
            bgColor={"#ECECEC"}
          >
            <Grid
              templateColumns={["1fr", "1fr", "1fr "]}
              width={"100%"}
              gap={{ base: "2px", md: "4px", lg: "8px" }} // Adjust gap between grid items as needed
            >
              <GridItem colSpan={1}>
                <Flex justifyContent={"flex-start"}>
                  <Box overflow="hidden">
                    <Text
                      style={textStyle}
                      fontSize={{ base: "12px", md: "12px", lg: "12px" }}
                      noOfLines={1}
                    >
                      {name}
                    </Text>
                  </Box>
                </Flex>
              </GridItem>
              <GridItem colSpan={1}>
                <Flex
                  justifyContent={{
                    base: "flex-start",
                    md: "flex-start",
                    lg: "flex-start",
                  }}
                >
                  <Flex
                    flexDirection={"column"}
                    gap={{ base: "2px", md: "4px", lg: "8px" }}
                  >
                    <Text
                      color="#910202"
                      style={textStyle}
                      fontSize={{ base: "10px", md: "10px", lg: "10px" }}
                    >
                      {storedValue === "USD" ? "$ " : "IQD "}
                      {price}
                    </Text>
                    <Text
                      color="#969696"
                      style={smallPrice}
                      fontSize={{ base: "10px", md: "10px", lg: "10px" }}
                    >
                      {storedValue === "USD" ? "$ " : "IQD "}
                      {discountPrice}
                    </Text>
                  </Flex>
                </Flex>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </RTLWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.products.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onAddFavorite: (productId: number | undefined) =>
    dispatch(addFavorite(productId)),
  onDeleteFavorite: (productId: number | undefined) =>
    dispatch(deleteFavorite(productId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
