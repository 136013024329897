import { Box, Button, Image } from "@chakra-ui/react";
import React from "react";

const TestBackground = () => {
  const images = [
    "/images/cover.png",
    "/images/flash.png",
    "/images/bernard.png",
  ];
  return (
    <Box position="relative" height="100vh">
      {/* Image Component */}
      <Image
        src={images[0]}
        alt="Background Image"
        // objectFit="cover"
        height="100%"
        width="100%"
      />

      {/* Button */}
      <Button
        position="absolute"
        bottom="20%"
        right="0"
        transform="translateY(50%)"
        colorScheme="teal"
        size="lg"
        onClick={() => {
          // Button click handler
        }}
      >
        My Button
      </Button>
    </Box>
  );
};

export default TestBackground;
