import {
  Grid,
  GridItem,
  Flex,
  Text,
  Checkbox,
  Input,
  Button,
} from "@chakra-ui/react";
import { ICategories } from "../../api/gen/models/CategoriesServices/listModels";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import CategoriesContext from "../../context/categories/context";

type Props = {
  params: {
    from_price: number;
    to_price: number;
    search: undefined | string;
    category_id: undefined | number;
    per_page: undefined | number;
    subCategory_id: undefined | number;
  };
  handleInputChange: (e: any, value: string) => void;
  handleFilter: Function;
  hasOffer: boolean;
  setHasOffer: Function;
  newArrivals: boolean;
  setNewArrivals: Function;
};

const SearchFilter = ({
  params,
  handleInputChange,
  handleFilter,
  hasOffer,
  setHasOffer,
  newArrivals,
  setNewArrivals,
}: Props) => {
  const { t } = useTranslation();
  const { list: categoryList } = useContext(CategoriesContext);
  return (
    <Grid
      templateColumns={["1fr"]}
      width={{ base: "100%", md: "27%" }}
      background={"#F9F9F9"}
      p={"10px"}
    >
      {categoryList?.data && (
        <GridItem mb={1}>
          <Text
            fontSize={{ base: "16px", md: "16px", lg: "20px" }}
            fontWeight={700}
            whiteSpace={"nowrap"}
            mb={2}
          >
            {t("category")}
          </Text>
          <Flex
            justifyContent={"center"}
            alignItems={"flex-start"}
            flexDirection={"column"}
          >
            <Checkbox
              isChecked={newArrivals}
              onChange={(e) => setNewArrivals(e.target.checked)}
              iconColor="black"
              colorScheme="black"
              fontSize={"24px"}
              fontWeight={400}
            >
              {t("new_arrival")}
            </Checkbox>
            <Checkbox
              isChecked={hasOffer}
              onChange={(e) => setHasOffer(e.target.checked)}
              iconColor="black"
              colorScheme="black"
              fontSize={"24px"}
              fontWeight={400}
            >
              {t("product_offers")}
            </Checkbox>
          </Flex>
        </GridItem>
      )}

      <GridItem mb={1}>
        <Text
          fontSize={{ base: "16px", md: "16px", lg: "20px" }}
          fontWeight={700}
          whiteSpace={"nowrap"}
          mb={2}
          mt={1}
        >
          {t("price")}
        </Text>
        <Flex
          flexDirection="row"
          justifyContent="center"
          alignItems={"flex-end"}
          gap={2}
        >
          <Flex
            justifyContent="space-between"
            width="100%"
            alignItems={"center"}
            gap={2}
          >
            <Text fontSize={"12px"}>{t("min")}</Text>
            <Input
              type="number"
              placeholder={t("min")}
              value={params.from_price}
              onChange={(e) => handleInputChange(e, "from_price")}
              size={"sm"}
              fontSize={"10px"}
            />
          </Flex>
          <Flex
            justifyContent="space-between"
            width="100%"
            alignItems={"center"}
            gap={2}
          >
            <Text fontSize={"12px"}>{t("max")}</Text>
            <Input
              type="number"
              placeholder={t("max")}
              value={params.to_price}
              onChange={(e) => handleInputChange(e, "to_price")}
              size={"sm"}
              fontSize={"10px"}
            />
          </Flex>
        </Flex>
      </GridItem>
      <GridItem paddingY={{ base: 2.5, md: 2.5, lg: 5 }}>
        <Button
          w={"100%"}
          background={"black"}
          color={"white"}
          _hover={{ bg: "#171717" }}
          onClick={() => handleFilter()}
        >
          {t("apply")}
        </Button>
      </GridItem>
    </Grid>
  );
};

export default SearchFilter;
