import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "react-simple-star-rating";
import { addReview } from "../../../store/actions/productActions";
import { useParamsId } from "../../../utils/hooks/useParamsId";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  storedValue: string;
  myOrder: any;
};

const RateProducts = (props: Props) => {
  const orderId = useParamsId();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // Initialize ratings state
  const [ratings, setRatings] = useState<{ [key: number]: number }>({});

  // Handler for rating change
  const handleRating = (productId: number, rating: number) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [productId]: rating,
    }));
  };

  const dispatch = useDispatch();

  const isLoading = useSelector((state: any) => state.products.spinner);

  console.log(useSelector((state: any) => state.products));

  const handleSubmit = () => {
    const myReview = Object.entries(ratings).map(([productId, rating]) => {
      return {
        product_id: Number(productId),
        rating: rating,
      };
    });
    dispatch(addReview(orderId!, myReview, () => navigate("/")));
  };
  return (
    <Flex
      justifyContent={"space-between"}
      flexDirection={"column"}
      width={"100%"}
      gap={10}
      alignItems={"center"}
    >
      <Grid
        templateColumns={["1fr", "1fr 2fr"]}
        gap={10}
        width="100%"
        alignItems={"center"}
      >
        <GridItem colSpan={1} order={1}>
          <Flex flexDirection={"column"} alignItems={"center"}>
            <img src={"/images/completed_image.png"} />
            <Text fontWeight={"bold"} fontSize={"36px"}>
              {t("delivered")}
            </Text>
            <Text
              fontWeight={"normal"}
              fontSize={"16px"}
              whiteSpace={"nowrap"}
              color={"#7A7A7A"}
            >
              {t("pleased_send_your_rating_for_the_product")}
            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1} order={2}>
          <Grid templateColumns={["1fr"]} gap={{ base: 1, md: 1, lg: 5 }}>
            {props.myOrder &&
              props.myOrder.products.map((product: any) => {
                return (
                  <GridItem colSpan={1} key={product.id}>
                    <Grid
                      templateColumns={["20% 30% 30%"]}
                      gap={0}
                      width={"100%"}
                      alignItems={"center"}
                    >
                      {/* Image */}
                      <GridItem colSpan={1}>
                        <Box>
                          <Image
                            src={product.thumbnail}
                            boxSize="100px"
                            maxHeight={"65px"}
                            maxWidth={"65px"}
                            objectFit="cover"
                          />
                        </Box>
                      </GridItem>
                      {/* Text */}
                      <GridItem colSpan={1}>
                        <Box>
                          <Flex flexDirection={"column"}>
                            <Text
                              fontWeight={"bold"}
                              fontSize={{
                                base: "12px",
                                md: "12px",
                                lg: "20px",
                              }}
                              noOfLines={1}
                            >
                              {product.name}
                            </Text>
                            <Flex flexDirection={"column"}>
                              <Text
                                marginInlineEnd={"0em"}
                                fontWeight={"normal"}
                                fontSize={{
                                  base: "10px",
                                  md: "10px",
                                  lg: "14px",
                                }}
                              >
                                {props.storedValue === "USD" ? "$" : "IQD"}{" "}
                                {product.price}
                              </Text>
                              <Flex flexDirection={"row"} mt="1em">
                                {product.discount_price != 0 && (
                                  <Text
                                    fontWeight={"normal"}
                                    fontSize={{
                                      base: "10px",
                                      md: "10px",
                                      lg: "14px",
                                    }}
                                  >
                                    {props.storedValue === "USD" ? "$" : "IQD"}{" "}
                                    {product.discount_price}
                                  </Text>
                                )}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      </GridItem>
                      {/* Review Stars */}
                      <GridItem colSpan={1}>
                        <Box
                          display={"flex"}
                          justifyContent={{
                            base: "center",
                            md: "space-between",
                          }}
                          alignItems={"flex-end"}
                          gap={5}
                        >
                          <Rating
                            initialValue={ratings[product.id] || 0} // Use stored rating or default to 0
                            fillColor="#171717"
                            size={22}
                            onClick={(rate) => handleRating(product.id, rate)} // Handle rating change
                            SVGstrokeColor={"#171717"}
                            SVGstorkeWidth={1.5}
                            emptyColor="#fff"
                            rtl={i18n.language != "en" ? true : false}
                          />
                          <Text whiteSpace={"nowrap"} fontSize={"12px"}>{`${
                            product.rating
                          }/${product.count_rating} ${t("review")}`}</Text>
                        </Box>{" "}
                      </GridItem>
                    </Grid>{" "}
                  </GridItem>
                );
              })}
          </Grid>
        </GridItem>
      </Grid>
      <Button
        mt={"30px"}
        backgroundColor={"black"}
        _hover={{ bgColor: "#333131" }}
        borderRadius={"0px"}
        color={"white"}
        className="lg:w-[461px] md:w-[350px]"
        isLoading={isLoading == "loading"}
        onClick={handleSubmit}
      >
        {t("done")}
      </Button>
    </Flex>
  );
};

export default RateProducts;
