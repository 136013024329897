import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ProductCard from "../../general/product-card/ProductCard";
import ImageSlider from "../../general/image-slider/ImageSlider";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface Product {
  id: number;
  thumbnail: string;
  is_favorite: boolean;
}
interface IProps {
  home: any | null;
  videos: any | null;
  handleChooseProduct: (product: any) => void;
}
const NewArrival = (props: IProps) => {
  const [urls, setUrls] = React.useState<any | []>([]);
  const navigate = useNavigate();
  const convertToEmbedUrl = (url: string) => {
    // Replace "watch?v=" with "embed/"
    urls.push(url.replace("watch?v=", "embed/"));
  };

  useEffect(() => {
    if (props.videos && urls.length === 0)
      for (let i = 0; i < props.videos.length; i++)
        convertToEmbedUrl(props.videos[i].action);
  }, []);
  const { t, i18n } = useTranslation();

  if (props?.home?.new_products)
    return (
      <Box mt="40px">
        <Flex justifyContent={"space-between"} padding={"0 20px"}>
          <Text
            fontWeight={700}
            fontSize={{ base: "16px", md: "16px", lg: "40px" }}
            letterSpacing={i18n.language === "ar" ? "0px" : "5px"}
          >
            {t("new_arrival")}
          </Text>
          <Link
            onClick={() => navigate("/new-arrivals")}
            fontSize={{ base: "16px", md: "16px", lg: "24px" }}
            type="true"
            fontWeight={400}
            letterSpacing={i18n.language === "ar" ? "0px" : "5px"}
          >
            {t("see_all")}
          </Link>
        </Flex>

        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: `1fr 1fr 1fr`,
          }}
          width={"100%"}
          gap={{ base: "25px", md: "25px", lg: 3 }} // Adjust gap between grid items as needed
          mt={{ base: "1.5em", md: "1.5em", lg: "3em" }}
          justifyItems="center" // Center items horizontally
        >
          {props.home.new_products &&
            props?.home?.new_products.slice(0, 8).map((product: Product) => {
              return (
                <GridItem
                  key={product.id}
                  width={{ base: "90%", md: "100%", lg: "100%" }}
                >
                  <ProductCard
                    url={product.thumbnail}
                    product={product}
                    height="274px"
                    selectProduct={() => {}}
                    handleChooseProduct={props.handleChooseProduct}
                  />
                </GridItem>
              );
            })}
        </Grid>
      </Box>
    );
  else return null;
};

export default NewArrival;
