// userReducer.ts
export interface MainState {
  favoriteProducts: any | null;
  reviews: any | null;
  spinner: any | null;
  flashDealProducts: any | null;
  offersProducts: any | null;
  newArrivalsProducts: any | null;
  product: any | null;
  isFavorite: boolean | null;
  searchProducts: any | null;
  maxPrice: any;
  products: any | null;
  currentPage: number;
  totalPages: number;
}

interface Action {
  type: string;
  res?: any;
}

const initState: MainState = {
  favoriteProducts: null,
  spinner: null,
  flashDealProducts: null,
  offersProducts: null,
  newArrivalsProducts: null,
  products: null,
  reviews: null,
  product: null,
  isFavorite: null,
  maxPrice: null,
  searchProducts: null,
  currentPage: 0,
  totalPages: 0,
};

const productReducer = (
  state: MainState | undefined = initState,
  action: Action
): MainState => {
  switch (action.type) {
    case "GET_FAVORITE_SUCCESS":
      return {
        ...state,
        spinner: null,
        favoriteProducts: action.res.data.data,
      };
    case "GET_FAVORITE_ERROR":
      return {
        ...state,
      };
    case "GET_FAVORITE_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_REVIEWS_SUCCESS":
      return {
        ...state,
        spinner: null,
        reviews: action.res.data.data,
      };
    case "GET_REVIEWS_ERROR":
      return {
        ...state,
      };
    case "GET_REVIEWS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_PRODUCT_SUCCESS":
      return {
        ...state,
        spinner: null,
        product: action.res.data.data,
      };
    case "GET_PRODUCT_ERROR":
      return {
        ...state,
      };
    case "GET_PRODUCT_SPINNER":
      return {
        ...state,
        spinner: "loading",
        product: null,
      };

    case "GET_NEW_ARRIVALS_SUCCESS":
      return {
        ...state,
        spinner: null,
        newArrivalsProducts: state.newArrivalsProducts
          ? [...state.newArrivalsProducts, ...action.res.data.data]
          : action.res.data.data,
        currentPage: action.res.data.meta.current_page,
        totalPages: action.res.data.meta.last_page,
      };
    case "GET_NEW_ARRIVALS_ERROR":
      return {
        ...state,
      };
    case "GET_NEW_ARRIVALS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_OFFERS_SUCCESS":
      return {
        ...state,
        spinner: null,
        offersProducts: action.res.data.data,
        currentPage: 1,
        totalPages: 1,
      };
    case "GET_OFFERS_ERROR":
      return {
        ...state,
      };
    case "GET_OFFERS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_FLASH_DEAL_SUCCESS":
      return {
        ...state,
        spinner: null,
        flashDealProducts: action.res.data.data,
        currentPage: 1,
        totalPages: 1,
      };
    case "GET_FLASH_DEAL_ERROR":
      return {
        ...state,
      };
    case "GET_FLASH_DEAL_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_SEARCH_PRODUCTS_SUCCESS":
      return {
        ...state,
        spinner: null,
        currentPage: action.res.data.meta.current_page,
        totalPages: action.res.data.meta.last_page,
        searchProducts:
          state.searchProducts?.length > 0 &&
          action.res.data.meta.current_page != 1
            ? [...state.searchProducts, ...action.res.data.data]
            : action.res.data.data,
      };
    case "GET_SEARCH_PRODUCTS_ERROR":
      return {
        ...state,
      };
    case "GET_SEARCH_PRODUCTS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_MAX_PRICE_SUCCESS":
      return {
        ...state,
        spinner: null,
        maxPrice: action.res.data.max_price,
      };
    case "GET_MAX_PRICE_ERROR":
      return {
        ...state,
      };
    case "GET_MAX_PRICE_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_FILTERED_PRODUCTS_SUCCESS":
      return {
        ...state,
        spinner: null,
        products: state.products
          ? [...state.products, ...action.res.data.data]
          : action.res.data.data,
        currentPage: action.res.data.meta.current_page,
        totalPages: action.res.data.meta.last_page,
      };
    case "GET_FILTERED_PRODUCTS_ERROR":
      return {
        ...state,
      };
    case "GET_FILTERED_PRODUCTS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "CREATE_REVIEW_SUCCESS":
      return {
        ...state,
        spinner: null,
        reviews: action.res.data.data,
      };
    case "CREATE_REVIEW_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "CREATE_REVIEW_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "CREATE_FAVORITE_SUCCESS":
      return {
        ...state,
        spinner: "added",
        isFavorite: true,
      };
    case "CREATE_FAVORITE_ERROR":
      return {
        ...state,
        isFavorite: false,
      };
    case "CREATE_FAVORITE_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "DELETE_FAVORITE_SUCCESS":
      return {
        ...state,
        isFavorite: false,
        spinner: "removed",
      };
    case "DELETE_FAVORITE_ERROR":
      return {
        ...state,
        isFavorite: true,
      };
    case "DELETE_FAVORITE_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "UPDATE_FAVORITE_VARIABLE":
      return {
        ...state,
        isFavorite: action.res?.isFavorite ?? false,
      };

    default:
      return state;
  }
};

export default productReducer;
