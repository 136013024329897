/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
const language = localStorage.getItem("language");
export class CartsService {
  /**
   * GET - Get Cart
   * @param lang
   * @returns any Successful response
   * @throws ApiError
   */
  public static getCarts(lang?: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/carts",
      headers: {
        lang: language,
      },
    });
  }

  /**
   * POST - Add Product To Cart
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postCarts(
    lang?: string,
    requestBody?: {
      product_id: number;
      from: string;
    }
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/carts",
      headers: {
        lang: language,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete - Delete Cart
   * @param lang
   * @returns any Successful response
   * @throws ApiError
   */
  public static deleteCarts(lang?: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/carts",
      headers: {
        lang: language,
      },
    });
  }

  /**
   * Delete - Delete Product
   * @param lang
   * @returns any Successful response
   * @throws ApiError
   */
  public static deleteCartsProducts1(
    lang?: string,
    productId?: number
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: `/carts/products/${productId}`,
      headers: {
        lang: language,
      },
    });
  }

  /**
   * POST - Increase Quantity
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postCartsProducts1QuantityIncrease(
    lang?: string,
    requestBody?: number
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: `/carts/products/${requestBody}/quantity/increase`,
      headers: {
        lang: language,
      },
      body: requestBody,
    });
  }

  /**
   * POST - Decrease Quantity
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postCartsProducts1QuantityDecrease(
    lang?: string,
    requestBody?: number
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: `/carts/products/${requestBody}/quantity/decrease`,
      headers: {
        lang: language,
      },
      body: requestBody,
    });
  }

  /**
   * POST - Redeem Coupon Code
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postCartsRedeemCoupon(
    lang?: string,
    requestBody?: {
      coupon_code: string;
    }
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/carts/redeem-coupon",
      headers: {
        lang: language,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * POST - Redeem Coupon Code
   * @param lang
   * @param requestBody
   * @returns any Successful response
   * @throws ApiError
   */
  public static postCartsRemoveCoupon(
    lang?: string,
    requestBody?: {}
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/carts/remove-coupon",
      headers: {
        lang: language,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
