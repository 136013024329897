import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import MyOrderItem from "../../../components/user/my-order-item/MyOrderItem";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stepper,
  Step,
  Flex,
  StepStatus,
  useSteps,
  StepIndicator,
  StepTitle,
  Grid,
  Center,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { getOrders, Action } from "../../../store/actions/orderActions";
import { useTranslation } from "react-i18next";
import { pageView } from "../../../config/FirebaseConfig";

interface StateProps {
  orders: any | null;
  ordersSpinner: string | null;
  spinner: string | null;
}

interface Order {
  order: any | null;
}
interface DispatchProps {
  onGetOrders: () => void;
}
//@ts-ignore
const StepSeparator = ({
  isCompleted,
  isActive,
  id,
}: {
  isCompleted: boolean;
  isActive: boolean;
  id?: string;
}) => {
  return (
    <Flex
      id={id}
      className="w-full"
      direction="column"
      align="center"
      justify="center"
      h="40px"
      flex="1"
    >
      <Box
        h="1px"
        className="w-[100%] border-black"
        bg={"white"}
        borderTopWidth="1px"
        borderTopStyle={isCompleted ? "solid" : "dashed"}
        transform="translateY(-50%)"
      />
    </Flex>
  );
};
interface IProps {}

const MyOrders: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  // const { orders } = props;
  const [orders, setOrders] = useState([]);
  const steps = [
    {
      title: "Order Processing",
    },
    {
      title: "Ship The Order",
    },
    {
      title: "Order Complete",
    },
    // Add more steps as needed
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const { t } = useTranslation();

  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetOrders();
  }, []);

  useEffect(() => {
    setOrders(props.orders);
  }, [props.orders]);
  const spinnerLoading = () => {
    return (
      <Grid templateColumns={["1fr"]} width="100%">
        <Center height="100vh" width={"100%"}>
          <Spinner size="xl" />
        </Center>
      </Grid>
    );
  };

  if (props.ordersSpinner && props.ordersSpinner === "loading") {
    // Display a spinner while loading
    return spinnerLoading();
  }
  if (orders && orders.length > 0)
    return (
      <Box p={{ base: "10px", md: "10px", lg: "37px" }} bgColor={"#FFFFFF"}>
        {orders &&
          orders.map((order: Order) => {
            return <MyOrderItem show order={order} />;
          })}
      </Box>
    );
  else
    return (
      <Grid templateColumns={["1fr"]} width="100%" mt="50px">
        <Center width={"100%"}>
          <img src="/images/empty/empty-orders.png" />
        </Center>{" "}
        <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
          <Text fontSize={"16px"} fontWeight={"bold"}>
            {t("emptyOrders")}
          </Text>
        </Flex>
      </Grid>
    );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    orders: state.orders.orders,
    ordersSpinner: state.orders.spinner,
    spinner: state.user.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetOrders: () => dispatch(getOrders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);
