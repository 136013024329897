import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { CheckIcon } from "@chakra-ui/icons"; // Import CheckIcon from Chakra UI

interface IProps {
  steps: any;
  activeStep: any;
}

const OrderStepper = (props: IProps) => {
  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });

  return (
    <Box overflowY="auto" maxHeight="calc(100vh - 100px)">
      <Stepper index={props.activeStep} color="#171717" width={"100%"}>
        {props.steps.map((step: any, index: number) => (
          <Step key={index}>
            <StepIndicator
              style={
                props.activeStep >= index
                  ? {
                      color: "#FFFFFF",
                      backgroundColor: "#171717",
                      borderColor: "#171717",
                    }
                  : {
                      color: "#171717",
                      backgroundColor: "#FFFFFF",
                      borderColor: "#171717",
                    }
              }
            >
              <StepStatus
                complete={<CheckIcon />} // Use CheckIcon for completed steps
                incomplete={
                  index === 4 && props.activeStep == 4 ? (
                    <CheckIcon />
                  ) : (
                    <StepNumber />
                  )
                }
                active={
                  index === 4 && props.activeStep == 4 ? (
                    <CheckIcon />
                  ) : (
                    <StepNumber />
                  )
                }
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepDescription
                style={
                  isLargeScreen
                    ? {
                        fontSize: "14px",
                        fontWeight: "normal",
                      }
                    : {
                        fontSize: "7px",
                        fontWeight: "normal",
                      }
                }
              >
                {step.description}
              </StepDescription>
              <StepTitle
                style={
                  isLargeScreen
                    ? {
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }
                    : {
                        fontSize: "0.4rem",
                        fontWeight: "bold",
                      }
                }
              >
                {step.title}
              </StepTitle>
            </Box>

            <StepSeparator
              style={{
                color: props.activeStep > index ? "#171717" : "#ECECEC",
                backgroundColor:
                  props.activeStep > index ? "#171717" : "#ECECEC",
              }}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default OrderStepper;
