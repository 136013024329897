/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
const language = localStorage.getItem("language");

export class CategoriesService {
  /**
   * GET - Get Categories
   * @param lang
   * @returns any Successful response
   * @throws ApiError
   */
  public static getCategories(lang?: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/categories",
      headers: {
        lang: language,
      },
    });
  }

  /**
   * GET - Get Sub Category
   * @param lang
   * @returns any Successful response
   * @throws ApiError
   */
  public static getCategories1(
    id: number,

    lang?: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: `/categories/${id}`,
      headers: {
        lang: language,
      },
    });
  }
  public static getSubCategories(
    id: number,

    lang?: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: `/categories/sub/${id}`,
      headers: {
        lang: language,
      },
    });
  }
  public static getAllSubCategories(lang?: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: `/categories/subcategories`,
      headers: {
        lang: language,
      },
    });
  }
}
