import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { FaMapMarkerAlt } from "react-icons/fa";
import MyOrderProductItem from "../my-order-product-item/MyOrderProductItem";
import { getOrder, Action } from "../../../store/actions/orderActions";
import {
  Button,
  Divider,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  Box,
  Flex,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useDisclosure,
} from "@chakra-ui/react";
import OrderChat from "../../../pages/user/pages/OrderChat";
import { useTranslation } from "react-i18next";
import StepperTest from "../order-stepper/OrderStepper";
import { useNavigate } from "react-router-dom";

interface StateProps {
  myOrder: any | null;
  spinner: string | null;
}

interface DispatchProps {
  onGetOrder: (id: number) => void;
}
interface IProps {
  show: boolean;
  order: any | null;
}
const titleStyle = {
  fontSize: "16px",
  color: "#171717",
};

const descriptionStyle = {
  fontSize: "10px",
};

const priceStyle = {
  fontSize: "10px",
  color: "#171717",
};
//@ts-ignore
const StepSeparator = ({
  isCompleted,
  isActive,
  id,
}: {
  isCompleted: boolean;
  isActive: boolean;
  id?: string;
}) => {
  return (
    <Flex
      id={id}
      className="w-full"
      direction="column"
      align="center"
      justify="center"
      h="40px"
      flex="1"
    >
      <Box
        h="1px"
        className="w-[100%] border-black"
        bg={"white"}
        borderTopWidth="1px"
        borderTopStyle={isCompleted ? "solid" : "dashed"}
        transform="translateY(-50%)"
      />
    </Flex>
  );
};

const MyOrderItem: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const [show, setShow] = React.useState(false);
  const [myOrder, setMyOrder] = React.useState<any | null>(null);
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  const steps = [
    { title: "Pending" },
    {
      title: "Processing",
    },
    {
      title: "Ready",
    },
    {
      title: "Shipping",
    },
    {
      title: "Completed",
    },
    // Add more steps as needed
  ];

  const navigate = useNavigate();
  const {
    isOpen: isChatModalOpen,
    onOpen: onChatModalOpen,
    onClose: onChatModalClose,
  } = useDisclosure();

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  const handleClickShow = () => {
    setShow(!show);
    if (!show) {
      props.onGetOrder(props.order.id);
    }
  };
  const [scrollBehavior, setScrollBehavior] = React.useState<any>("inside");

  const btnRef = React.useRef(null);

  useEffect(() => {
    // if (props.myOrder.length > 0)
    //   for (let i = 0; i < props.myOrder.length; i++) {
    //     if (props.myOrder[i].id == props.order.id) {
    //       setMyOrder(props.myOrder[i]);
    //       const orderStatus = props.myOrder[i].status;
    //       if (orderStatus) {
    //         const statusToStepIndex: Record<number, number> = {
    //           1: 1, //PENDING
    //           2: 2, //PROCESSING
    //           3: 3, //READY
    //           4: 4, //SHIPPING
    //           5: 5, //COMPLETED
    //         };
    //         setActiveStep(statusToStepIndex[orderStatus - 1] || 0);
    //       }
    //     }
    //   }
  }, [props.myOrder]);
  return (
    <Flex
      key={props.order.id}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={2}
      width={"100%"}
      onClick={() => navigate(`/orders/${props.order.id}`)}
      cursor={"pointer"}
    >
      <Grid
        templateColumns={["1fr 1fr", "1fr 1fr"]}
        // mb="4"
        padding={7}
        bgColor={"#ECECEC"}
        width={"100%"}
        gap={{ base: 4, md: 4, lg: 8 }}
      >
        <GridItem colSpan={1}>
          <Flex
            flexDirection={"row"}
            gap={3}
            alignItems={{
              base: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            mt={{ base: "0.5em", md: 0, lg: 0 }}
          >
            <Text style={titleStyle} noOfLines={1}>
              {t("orderID")}
            </Text>
            <Text style={titleStyle} noOfLines={1}>
              {props.order.id}
            </Text>
          </Flex>
        </GridItem>
        <GridItem
          display="flex"
          justifyContent={{ base: "center", md: "center", lg: "flex-end" }}
          alignItems="center"
        >
          {" "}
          <Flex
            justifyContent={"flex-end"}
            alignItems={"center"}
            // mt={{ base: "0.5em", md: 0, lg: 0 }}
          >
            <img
              className="cursor-pointer"
              src={
                direction === "rtl"
                  ? "/images/orders/open-arrow-rtl.png"
                  : "/images/orders/open-arrow.png"
              }
            />
          </Flex>
        </GridItem>
      </Grid>
      <Divider width={"100%"} />
      {show && (
        <>
          <Divider width={"50em"} />
          {/* third part */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            // mb="4"
            padding={7}
            bgColor={"#FFFFFF"}
            width={"100%"}
          >
            <Flex flexDirection={"column"}>
              <Text style={titleStyle}>{t("finalAmount")}</Text>
              <Text color="#969696" fontSize="md" mt="0.5em" style={priceStyle}>
                {props.order.total}
              </Text>
            </Flex>
            <Flex>
              <Text
                color="#969696"
                fontSize="md"
                style={{
                  textDecoration: "underline",
                }}
                onClick={onChatModalOpen}
                _hover={{ cursor: "pointer" }}
              >
                {t("chatDelivery")}
              </Text>
            </Flex>
          </Flex>{" "}
          <Flex bgColor={"#FFFFFF"} width="100%" padding={5}>
            <StepperTest steps={steps} activeStep={activeStep} />
          </Flex>
        </>
      )}
      {/* Chat Modal */}
      <Modal
        isOpen={isChatModalOpen}
        onClose={onChatModalClose}
        size="md"
        finalFocusRef={btnRef}
        scrollBehavior={scrollBehavior}
      >
        <OrderChat orderId={props.order.id} myOrder={myOrder} />
      </Modal>
    </Flex>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    myOrder: state.orders.order,
    spinner: state.user.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetOrder: (id: number) => dispatch(getOrder(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrderItem);
