import { Dispatch, UnknownAction } from "redux";
import { OpenAPI } from "../../api/gen/core/OpenAPI";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { ApiRequestOptions } from "../../api/gen/core/ApiRequestOptions";
import { RootState } from "../reducers/rootReducer";
import { ErrorNotification } from "../../utils/Notifications/notifications";
import { pageEvent } from "../../config/FirebaseConfig";

export interface Action {
  type: string;
  res?: any;
  err?: any;
}

const storedValue = localStorage.getItem("currency");
const language = localStorage.getItem("language");
export const createOrder = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "CREATE_ORDER_SPINNER" });
    pageEvent("Order", "Create Cash Order");
    axios
      .post(
        `${OpenAPI.BASE}/checkout/cash`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "CREATE_ORDER_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "CREATE_ORDER_ERROR", err });
      });
  };
};

export const createAccountOrder = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "CREATE_ACCOUNT_ORDER_SPINNER" });
    pageEvent("Cart", "Create Account Order");
    axios
      .post(
        `${OpenAPI.BASE}/checkout/credit`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "CREATE_ACCOUNT_ORDER_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "CREATE_ACCOUNT_ORDER_ERROR", err });
      });
  };
};

export const getOrders = (): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_ORDERS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_ORDERS_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "GET_ORDERS_ERROR", err });
      });
  };
};

export const getOrder = (
  id: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_ORDER_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_ORDER_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "GET_ORDER_ERROR", err });
      });
  };
};

export const getCart = (): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_CART_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/carts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_CART_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "GET_CART_ERROR", err });
      });
  };
};

export const addToCart = (
  productId: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "ADD_TO_CART_SPINNER" });
    pageEvent("Cart", "Add To Cart");
    axios
      .post(
        `${OpenAPI.BASE}/carts`,
        {
          product_id: productId,
          from: "web",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "ADD_TO_CART_SUCCESS", res });
        dispatch({ type: "GET_CART_SPINNER" });
        axios
          .get(`${OpenAPI.BASE}/carts`, {
            headers: {
              Authorization: `Bearer ${token}`,
              lang: language,
              currency: storedValue,
            },
          })
          .then((res) => {
            dispatch({ type: "GET_CART_SUCCESS", res });
          })
          .catch((err) => {
            dispatch({ type: "GET_CART_ERROR", err });
          });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "ADD_TO_CART_ERROR", err });
      });
  };
};

export const increaseProductCart = (
  productId: number | undefined
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "INCREASE_PRODUCT_SPINNER" });

    axios
      .post(
        `${OpenAPI.BASE}/carts/products/${productId}/quantity/increase`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "INCREASE_PRODUCT_SUCCESS", res });
        dispatch({ type: "GET_CART_SPINNER" });
        axios
          .get(`${OpenAPI.BASE}/carts`, {
            headers: {
              Authorization: `Bearer ${token}`,
              lang: language,
              currency: storedValue,
            },
          })
          .then((res) => {
            dispatch({ type: "GET_CART_SUCCESS", res });
          })
          .catch((err) => {
            dispatch({ type: "GET_CART_ERROR", err });
          });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "INCREASE_PRODUCT_ERROR", err });
      });
  };
};

export const decreaseProductCart = (
  productId: number | undefined
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "DECREASE_PRODUCT_SPINNER" });

    axios
      .post(
        `${OpenAPI.BASE}/carts/products/${productId}/quantity/decrease`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "DECREASE_PRODUCT_SUCCESS", res });
        dispatch({ type: "GET_CART_SPINNER" });
        axios
          .get(`${OpenAPI.BASE}/carts`, {
            headers: {
              Authorization: `Bearer ${token}`,
              lang: language,
              currency: storedValue,
            },
          })
          .then((res) => {
            dispatch({ type: "GET_CART_SUCCESS", res });
          })
          .catch((err) => {
            dispatch({ type: "GET_CART_ERROR", err });
          });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "DECREASE_PRODUCT_ERROR", err });
      });
  };
};

export const removeLoadingElements = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "REMOVE_SPINNER_ELEMENTS" });
  };
};

export const deleteProductFromCart = (
  productId: number | undefined
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "DELETE_PRODUCT_CART_SPINNER" });
    pageEvent("Cart", "Delete From Cart");
    axios
      .delete(`${OpenAPI.BASE}/carts/products/${productId}`, {
        data: {
          product_id: productId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({ type: "DELETE_PRODUCT_CART_SUCCESS", res });
        dispatch({ type: "GET_CART_SPINNER" });
        axios
          .get(`${OpenAPI.BASE}/carts`, {
            headers: {
              Authorization: `Bearer ${token}`,
              lang: language,
              currency: storedValue,
            },
          })
          .then((res) => {
            dispatch({ type: "GET_CART_SUCCESS", res });
          })
          .catch((err) => {
            dispatch({ type: "GET_CART_ERROR", err });
          });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "DELETE_PRODUCT_CART_ERROR", err });
      });
  };
};

export const addCouponCode = (
  code: String
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "ADD_COUPON_SPINNER" });
    pageEvent("Cart", "Add Coupon Code");
    axios
      .post(
        `${OpenAPI.BASE}/carts/redeem-coupon`,
        {
          coupon_code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "ADD_COUPON_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "ADD_COUPON_ERROR", err });
      });
  };
};

export const removeCouponCode = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "REMOVE_COUPON_SPINNER" });
    pageEvent("Cart", "Remove Coupon Code");
    axios
      .post(
        `${OpenAPI.BASE}/carts/remove-coupon`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "My-Custom-Header": "foobar",
            lang: language,
          },
        }
      )
      .then((res) => {
        dispatch({ type: "REMOVE_COUPON_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "REMOVE_COUPON_ERROR", err });
      });
  };
};

export const getDriverPosition = ({ id }: { id: number }): any => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_DRIVER_POSITION_SPINNER" });
    if (token != "null")
      axios
        .get(`${OpenAPI.BASE}/orders/coordinate/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: language,
            currency: storedValue,
          },
        })
        .then((res) => {
          dispatch({ type: "GET_DRIVER_POSITION_SUCCESS", res });
        })
        .catch((err) => {
          dispatch({ type: "GET_DRIVER_POSITION_ERROR", err });
        });
  };
};
