import React, { useEffect, useReducer } from "react";
import CategoriesContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import { CategoriesService, ProductsService } from "../../api/gen";

interface AuxProps {
  children: React.ReactNode;
}

const CategoriesContextProvider: React.FC<AuxProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  useEffect(() => {
    getCategories();
  }, []);

  /**
   *  Get Roles & Permissions
   */
  const getCategories = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
        try {
          await CategoriesService.getCategories("").then((data) => {
            dispatch({ type: "SET_LIST", payload: { data: data } });
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
      },
      throwException: true,
    });
  };
  const getSubCategories = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
        try {
          await CategoriesService.getSubCategories(id, "").then((data) => {
            dispatch({ type: "SET_SUB_CATEGORIES", payload: { data: data } });
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
      },
      throwException: true,
    });
  };
  const getAllSubCategories = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
        try {
          await CategoriesService.getAllSubCategories().then((data) => {
            dispatch({ type: "SET_SUB_CATEGORIES", payload: { data: data } });
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
      },
      throwException: true,
    });
  };
  const getCategoryDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
        try {
          await CategoriesService.getCategories1(id, "").then((data) => {
            dispatch({ type: "SET_CATEGORY_DETAILS", payload: { data: data } });
          });
        } catch (e) {}
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "categories" } });
      },
      throwException: true,
    });
  };
  return (
    <CategoriesContext.Provider
      value={{
        ...state,
        actions: {
          getCategories,
          getSubCategories,
          getCategoryDetails,
          getAllSubCategories,
        },
      }}
    >
      {props.children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContextProvider;
