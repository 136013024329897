import React, { useRef, useContext, useEffect, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { ProductsService } from "../../../../api/gen";
import ProductsContext from "../../../../context/products/context";
import ProductsCardSlider from "../../../products-card-slider/ProductsCardSlider";
import { connect } from "react-redux";
import { RootState } from "../../../../store/reducers/rootReducer";
import {
  Action,
  removeLoadingElements,
} from "../../../../store/actions/orderActions";
import { ThunkDispatch } from "redux-thunk";
interface StateProps {
  spinner: string | null;
  cartSpinner: string | null;
  showNotification: boolean;
}

interface DispatchProps {
  onRemoveLoadingElements: () => void;
}

interface Props {
  relatedProducts: any;
  handleChooseProduct: (product: any) => void | null;
}

interface Product {
  id: number;
  thumbnail: string;
  name: string;
  price: string;
  is_favorite: boolean;
  discount_price: string;
  product: any | null;
}
const ProductContainer: React.FC<StateProps & DispatchProps & Props> = (
  props
) => {
  const ref = useRef<HTMLDivElement>(null!);
  const { events } = useDraggable(ref);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [product, setProduct] = useState<any>();
  const handleCloseNotification = () => {
    setShowNotification(false);
  };
  const handleChooseProduct = (product: any) => {
    setProduct(product);
  };
  useEffect(() => {
    // Ensure ref.current is not null before accessing its properties
    if (ref.current) {
      // Set the scroll position to the top when the component mounts
      ref.current.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    if (props.showNotification) {
      props.onRemoveLoadingElements();
      setShowNotification(true);

      setTimeout(() => {
        setShowNotification(false);
        setProduct(null);
      }, 5000);
    }
  }, [props.showNotification]);

  const {
    actions: { getProducts, getProductById },
  } = useContext(ProductsContext);

  const [products, setProducts] = useState<any[]>([]);
  useEffect(() => {
    const getProducts = async () => {
      const { data } = await ProductsService.getProducts();
      setProducts(data);
    };
    getProducts();
  }, []);
  return (
    <div
      className="flex mt-[20px] scroll overflow-x-scroll gap-[30px] grid-container width-[100%] xs:gap-[15px] xs:mt-[5px]"
      {...events}
      ref={ref}
    >
      {/* {showNotification && product ? (
        <CartNotificaiton
          message="Item added to cart!"
          product={product}
          handleCloseNotification={handleCloseNotification}
        />
      ) : null} */}
      {props.relatedProducts?.map((product: Product) => {
        return (
          <ProductsCardSlider
            key={product.id}
            url={product?.thumbnail}
            product={product}
            selectProduct={() => {}}
            handleChooseProduct={props.handleChooseProduct}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.home.spinner,
    cartSpinner: state.orders.spinner,
    showNotification: state.orders.showNotification,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onRemoveLoadingElements: () => dispatch(removeLoadingElements()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
