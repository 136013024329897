import {
  Box,
  Heading,
  Flex,
  List,
  ListItem,
  Link,
  Text,
  Divider,
} from "@chakra-ui/react";
import { FaLocationDot } from "react-icons/fa6";

const headingsStyle = {
  fontSize: "1rem",
  color: "#FFFFFF",
  fontWeight: "bold",
};

const subHeadingStyle = {
  fontSize: "0.875rem",
  color: "#FFD28E",
};

const text = {
  fontSize: "0.875rem",
  color: "#FFFFFF",
};
const TestFooter = () => {
  return (
    <Box
      as="footer"
      bg="#171717"
      borderTop="1px solid"
      borderColor="gray.300"
      py="2.5rem"
      fontSize="0.875rem"
    >
      <Box
        maxW="100%"
        marginX="auto"
        pb="2rem"
        mb="1.5rem"
        px={10}
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        <Flex flexWrap="wrap" alignItems="start" justifyContent="space-between">
          <Box
            w={{ base: "100%", sm: "50%", md: "max-content" }}
            mb={{ base: "1.5rem", lg: "0" }}
          >
            <Heading
              as="h5"
              // color="gray.700"
              mb="0.5rem"
              style={headingsStyle}
            >
              AUTHENTIC
            </Heading>
            <List lineHeight="2" justifyContent="center" mt="2em">
              {/* <LinkItem text="Careers" />
              <LinkItem text="News" />
              <LinkItem text="Policies" />
              <LinkItem text="Help" />
              <LinkItem text="Diversity & Belonging" /> */}
              <Text style={text}>We Are Able To Translate What Our</Text>
              <Text style={text}>Clients Have In Mind Into A Concept</Text>
              <Text style={text}>That Aims To</Text>
              <Text style={text}>Please Them As Much As Their</Text>
              <Flex mt="2em">
                <img
                  className="cursor-pointer"
                  src="/images/social-icons/facebook.png"
                  alt="facebook"
                />
                <img
                  className="cursor-pointer"
                  src="/images/social-icons/instagram.png"
                  alt="instagram"
                />
                <img
                  className="cursor-pointer"
                  src="/images/social-icons/twitter.png"
                  alt="twitter"
                />
                <img
                  className="cursor-pointer"
                  src="/images/social-icons/youtube.png"
                  alt="youtube"
                />
              </Flex>
              <Heading as="h5" mt="1em" style={headingsStyle}>
                Download
              </Heading>
              <Flex
                flexWrap="wrap"
                alignItems="start"
                justifyContent="space-between"
                mt="1em"
              >
                <img
                  // className="h-[44px] w-[100px] cursor-pointer"
                  src="/images/social-icons/download-android.png"
                  alt="download sadaf andorid"
                />
                <img
                  // className="h-[44px] w-[100px] cursor-pointer"
                  src="/images/social-icons/1.png"
                  alt="download sadaf apple"
                />
                <img
                  src="/images/social-icons/huwa.png"
                  alt="download sadaf apple"
                />
              </Flex>
            </List>
          </Box>
          <Box
            w={{ base: "100%", sm: "50%", md: "max-content" }}
            mb={{ base: "1.5rem", lg: "0" }}
          >
            <Heading as="h5" mb="0.5rem" style={headingsStyle}>
              About
            </Heading>
            <List lineHeight="2" mt="2em">
              <Text style={subHeadingStyle} mt="1em">
                Email{" "}
              </Text>
              <Text style={text} mt="1em">
                info@sadafiq.com
              </Text>
              <Text style={text} mt="1em">
                info@sadafiq.com
              </Text>
              <Text style={subHeadingStyle} mt="1em">
                Contact Numbers
              </Text>
              <Text style={text} mt="1em">
                Sales
              </Text>
              <Text style={text}>+964 750 432 2001</Text>
              <Text style={text} mt="1em">
                Social Media
              </Text>
              <Text style={text}>3141 946 0750</Text>
            </List>
          </Box>
          <Box
            w={{ base: "100%", sm: "50%", md: "max-content" }}
            mb={{ base: "1.5rem", lg: "0" }}
          >
            <Heading as="h5" mb="0.5rem" style={headingsStyle}>
              Openning times
            </Heading>
            <List lineHeight="2" mt="2em">
              <Text style={text}>Saturday To Thursday:</Text>
              <Text style={text}>09AM - 22PM</Text>
              <Text style={text}>Saturday To Thursday:</Text>
              <Text style={text}>03PM - 20PM</Text>
            </List>
            <Box mt="1em">
              <Text style={subHeadingStyle}>Head Quarter &</Text>
              <Text style={subHeadingStyle}>Central Showroom</Text>
            </Box>
            <List lineHeight="2">
              <Text style={text} mt="2em">
                Malik Mahmood Road, Near
              </Text>
              <Text style={text} mt="2em">
                Kobani Bridge, Sulaymaniyah
              </Text>
            </List>
          </Box>
          <Box
            w={{ base: "100%", sm: "50%", md: "max-content" }}
            mb={{ base: "1.5rem", lg: "0" }}
          >
            <Heading as="h5" mb="0.5rem" style={headingsStyle}>
              Our Branches
            </Heading>
            <Heading as="h5" mb="0.5rem" style={subHeadingStyle}>
              Erbil
            </Heading>
            <Flex>
              <Box mt="5px">
                <FaLocationDot color="#FFD28E" size="20" />
              </Box>
              <List lineHeight="2">
                <Text style={text}>1- Arbil 60M St. after Aynkawa Bridge،</Text>
                <Text style={text}>60 60 Meter St, Erbil, Iraq</Text>
                <Text style={text}>
                  2- Kasnazan Road, opposite Florya City,
                </Text>
                <Text style={text}>Erbil, Erbil Governorate, 44001, Iraq</Text>
                <Text style={text}>3- 100 Qazi Muhammad, Erbil, Iraq</Text>
                <Text style={text}>Beside the Housing Authority</Text>
              </List>
            </Flex>

            <Heading as="h5" mb="0.5rem" style={subHeadingStyle}>
              Sulaymaniyah
            </Heading>
            <Flex>
              <Box mt="5px">
                <FaLocationDot color="#FFD28E" size="20" />
              </Box>
              <List lineHeight="2">
                <Text style={text}>Malik Mahmud, Sulaymaniyah,</Text>
                <Text style={text}>Sulaymaniyah Governorate,</Text>
                <Text style={text}>46001, Iraq</Text>
              </List>
            </Flex>

            <Heading as="h5" mb="0.5rem" style={subHeadingStyle}>
              Duhok
            </Heading>
            <Flex>
              <Box mt="5px">
                <FaLocationDot color="#FFD28E" size="20" />
              </Box>
              <List lineHeight="2">
                <Text style={text}>RXWV+F6P, Duhok, Duhok</Text>
                <Text style={text}>Governorate, Iraq</Text>
              </List>
            </Flex>

            {/* <Flex justifyContent="start" mb="0.5rem" alignItems="baseline">
              <Link href="#" mr="0.5rem">
                <svg
                  style={{ width: "1rem", height: "1rem" }}
                  fill="#008F94"
                  viewBox="0 0 32 32"
                  role="img"
                  aria-label="Navigate to Facebook"
                  focusable="false"
                >
                  <path
                    d="m8 14.41v-4.17c0-.42.35-.81.77-.81h2.52v-2.08c0-4.84 2.48-7.31 7.42-7.35 1.65 0 3.22.21 4.69.64.46.14.63.42.6.88l-.56 4.06c-.04.18-.14.35-.32.53-.21.11-.42.18-.63.14-.88-.25-1.78-.35-2.8-.35-1.4 0-1.61.28-1.61 1.73v1.8h4.52c.42 0 .81.42.81.88l-.35 4.17c0 .42-.35.71-.77.71h-4.21v16c0 .42-.35.81-.77.81h-5.21c-.42 0-.8-.39-.8-.81v-16h-2.52a.78.78 0 0 1 -.78-.78"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </Link>
              <Link href="#" mr="0.5rem">
                <svg
                  style={{ width: "1rem", height: "1rem" }}
                  fill="#008F94"
                  viewBox="0 0 32 32"
                  role="img"
                  aria-label="Navigate to Twitter"
                  focusable="false"
                >
                  <path
                    d="m31 6.36c-1.16.49-2.32.82-3.55.95 1.29-.76 2.22-1.87 2.72-3.38a13.05 13.05 0 0 1 -3.91 1.51c-1.23-1.28-2.75-1.94-4.51-1.94-3.41 0-6.17 2.73-6.17 6.12 0 .49.07.95.17 1.38-4.94-.23-9.51-2.6-12.66-6.38-.56.95-.86 1.97-.86 3.09 0 2.07 1.03 3.91 2.75 5.06-1-.03-1.92-.3-2.82-.76v.07c0 2.89 2.12 5.42 4.94 5.98-.63.17-1.16.23-1.62.23-.3 0-.7-.03-1.13-.13a6.07 6.07 0 0 0 5.74 4.24c-2.22 1.74-4.78 2.63-7.66 2.63-.56 0-1.06-.03-1.43-.1 2.85 1.84 6 2.76 9.41 2.76 7.29 0 12.83-4.01 15.51-9.3 1.36-2.66 2.02-5.36 2.02-8.09v-.46c-.03-.17-.03-.3-.03-.33a12.66 12.66 0 0 0 3.09-3.16"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </Link>
              <Link href="#" mr="0.5rem">
                <svg
                  style={{ width: "1rem", height: "1rem" }}
                  fill="#008F94"
                  viewBox="0 0 24 24"
                  role="img"
                  aria-label="Navigate to Instagram"
                  focusable="false"
                >
                  <path
                    d="m23.09.91c-.61-.61-1.33-.91-2.17-.91h-17.84c-.85 0-1.57.3-2.17.91s-.91 1.33-.91 2.17v17.84c0 .85.3 1.57.91 2.17s1.33.91 2.17.91h17.84c.85 0 1.57-.3 2.17-.91s.91-1.33.91-2.17v-17.84c0-.85-.3-1.57-.91-2.17zm-14.48 7.74c.94-.91 2.08-1.37 3.4-1.37 1.33 0 2.47.46 3.41 1.37s1.41 2.01 1.41 3.3-.47 2.39-1.41 3.3-2.08 1.37-3.41 1.37c-1.32 0-2.46-.46-3.4-1.37s-1.41-2.01-1.41-3.3.47-2.39 1.41-3.3zm12.66 11.63c0 .27-.09.5-.28.68a.92.92 0 0 1 -.67.28h-16.7a.93.93 0 0 1 -.68-.28.92.92 0 0 1 -.27-.68v-10.13h2.2a6.74 6.74 0 0 0 -.31 2.05c0 2 .73 3.71 2.19 5.12s3.21 2.12 5.27 2.12a7.5 7.5 0 0 0 3.75-.97 7.29 7.29 0 0 0 2.72-2.63 6.93 6.93 0 0 0 1-3.63c0-.71-.11-1.39-.31-2.05h2.11v10.12zm0-13.95c0 .3-.11.56-.31.77a1.05 1.05 0 0 1 -.77.31h-2.72c-.3 0-.56-.11-.77-.31a1.05 1.05 0 0 1 -.31-.77v-2.58c0-.29.11-.54.31-.76s.47-.32.77-.32h2.72c.3 0 .56.11.77.32s.31.47.31.76z"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </Flex>
            <List lineHeight="2">
              <LinkItem text="Terms" />
              <LinkItem text="Privacy" />
              <LinkItem text="Site Map" />
            </List> */}
          </Box>
        </Flex>
      </Box>
      <Box>
        <Flex
          maxW="100%"
          px={10}
          justifyContent={"space-between"}
          alignItems="center"
        >
          {/* <svg
          fill="#008F94"
          style={{ width: "1.25rem", height: "1.25rem" }}
          viewBox="0 0 1000 1000"
          role="presentation"
          aria-hidden="true"
          focusable="false"
        >
          <path d="m499.3 736.7c-51-64-81-120.1-91-168.1-10-39-6-70 11-93 18-27 45-40 80-40s62 13 80 40c17 23 21 54 11 93-11 49-41 105-91 168.1zm362.2 43c-7 47-39 86-83 105-85 37-169.1-22-241.1-102 119.1-149.1 141.1-265.1 90-340.2-30-43-73-64-128.1-64-111 0-172.1 94-148.1 203.1 14 59 51 126.1 110 201.1-37 41-72 70-103 88-24 13-47 21-69 23-101 15-180.1-83-144.1-184.1 5-13 15-37 32-74l1-2c55-120.1 122.1-256.1 199.1-407.2l2-5 22-42c17-31 24-45 51-62 13-8 29-12 47-12 36 0 64 21 76 38 6 9 13 21 22 36l21 41 3 6c77 151.1 144.1 287.1 199.1 407.2l1 1 20 46 12 29c9.2 23.1 11.2 46.1 8.2 70.1zm46-90.1c-7-22-19-48-34-79v-1c-71-151.1-137.1-287.1-200.1-409.2l-4-6c-45-92-77-147.1-170.1-147.1-92 0-131.1 64-171.1 147.1l-3 6c-63 122.1-129.1 258.1-200.1 409.2v2l-21 46c-8 19-12 29-13 32-51 140.1 54 263.1 181.1 263.1 1 0 5 0 10-1h14c66-8 134.1-50 203.1-125.1 69 75 137.1 117.1 203.1 125.1h14c5 1 9 1 10 1 127.1.1 232.1-123 181.1-263.1z"></path>
        </svg> */}
          <Text style={text}></Text>
          <Text style={text} color="gray.600" fontSize="0.875rem" pl="0.5rem">
            All Rights Reserved Sadaf Group l 2023 l Design And Development
          </Text>
          <Text color="#FFD28E">Dev by BandTech</Text>
        </Flex>
      </Box>
    </Box>
  );
};

type LinkItemProps = {
  text?: string;
  isTag?: boolean;
  tagText?: string;
};

const LinkItem = ({ text, isTag = false, tagText }: LinkItemProps) => {
  return (
    <ListItem display="flex">
      <Link
        fontWeight="600"
        href="#"
        color="rgba(113, 128, 150, 1)"
        _hover={{ color: "green.600" }}
      >
        {text}
      </Link>
      {isTag && (
        <Text
          // style={text}
          as="span"
          bg="#008F94"
          px="0.25rem"
          display="inline-flex"
          alignItems="center"
          color="#fff"
          height="1.25rem"
          borderRadius="0.25rem"
          ml="0.25rem"
          mt="0.25rem"
          fontSize="0.75rem"
        >
          {tagText}
        </Text>
      )}
    </ListItem>
  );
};

export default TestFooter;
