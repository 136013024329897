import { useEffect, useState, useRef, useCallback } from "react";

const useInfiniteScroll = (callback: () => void) => {
  const [isFetching, setIsFetching] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: any) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsFetching(true);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isFetching]
  );

  useEffect(() => {
    if (!isFetching) return;
    callback();
  }, [isFetching]);

  useEffect(() => {
    setIsFetching(false);
  }, [isFetching]);

  return [lastElementRef, isFetching] as const;
};

export default useInfiniteScroll;
