import React, { useRef, useState } from "react";
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import AudioPlayer, {
  AudioPlayerHandlers,
} from "./soundMessages/SoundMessages";

interface IProps {
  image?: string;
  text?: string;
  isLeft?: boolean;
  isAudio: boolean;
  id: number;
}

function isLink(text: any) {
  const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/;
  return urlRegex.test(text);
}

const MessageCart = ({ image, text, isLeft, isAudio, id }: IProps) => {
  const [isPlay, setIsPlay] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const audioPlayerRef = useRef<AudioPlayerHandlers | null>(null);

  const handleButtonClick = () => {
    audioPlayerRef.current?.togglePlayPause();
  };

  const checkLink = isLink(text);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <div
      className={`flex  ${
        isLeft ? "" : "flex-row-reverse"
      } gap-[15px] items-center  `}
    >
      <img className="w-[48px] h-[48px] cursor-pointer " src={image} alt="" />
      <div
        className={` bg-[#ECECEC] text-center py-[17px] relative  rounded-t-[20px] ${
          !isLeft
            ? "  rounded-bl-[20px] pl-[25px] pr-[22px] "
            : " rounded-br-[20px] pl-[22px] pr-[25px] "
        }  `}
      >
        {isAudio ? (
          <>
            <div
              className={`flex  ${
                !isLeft ? "flex-row-reverse" : ""
              } items-center gap-[12px]`}
            >
              <img
                onClick={handleButtonClick}
                className="w-[21px] h-[21px] cursor-pointer "
                src={
                  !isPlay
                    ? "/images/user/playIcon.png"
                    : "/images/support/pauseIcon.svg"
                }
                alt=""
              />
              <AudioPlayer
                src="test.ogg"
                id={id}
                ref={audioPlayerRef}
                setPlay={setIsPlay}
              />
            </div>
          </>
        ) : (
          <>
            {checkLink ? (
              text?.includes("jpg" || "png" || "jpeg" || "bmp") ? (
                <Image
                  boxSize="100px"
                  objectFit="cover"
                  src={text}
                  alt="Dan Abramov"
                  cursor="pointer"
                  onClick={handleImageClick}
                />
              ) : (
                <div>
                  <a href={text} download>
                    Click to download the file
                  </a>
                </div>
              )
            ) : (
              text
            )}
          </>
        )}
      </div>

      {/* Full Screen Image Modal */}
      <Modal isOpen={isFullScreen} onClose={handleCloseFullScreen}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Image
              boxSize="100%"
              objectFit="contain"
              src={text}
              alt="Full Screen"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MessageCart;
