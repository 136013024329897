import {
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Text,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Icon,
  IconButton,
  useDisclosure,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Input,
  Badge,
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose, AiTwotoneThunderbolt } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { MdTimeline } from "react-icons/md";
import { BsBook } from "react-icons/bs";
import { FiSun } from "react-icons/fi";
import { IconType } from "react-icons";
import { FaSearch } from "react-icons/fa";
import { FaBell } from "react-icons/fa6";
import { css } from "@emotion/react";

const navLinks = [
  { name: "Home", path: "#" },
  { name: "About", path: "#" },
  { name: "Magazine", path: "#" },
];

const dropdownLinks = [
  {
    name: "Projects",
    path: "#",
    icon: MdTimeline,
  },
  {
    name: "Tech Stack",
    path: "#",
    icon: AiTwotoneThunderbolt,
  },
  {
    name: "Open Source",
    path: "#",
    icon: BsBook,
  },
];

export default function TestNavbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuProps = {
    bg: useColorModeValue("gray.200", "gray.700"),
    color: useColorModeValue("blue.500", "blue.200"),
  };

  return (
    <Box
      pr="15"
      pl={"15"}
      pt={"10"}
      pb="10"
      width="100%"
      bg="rgba(255, 255, 255, 0.5)"
    >
      <Flex
        h={16}
        alignItems="center"
        justifyContent="space-around"
        // maxW={800}

        // mx="auto"
      >
        <Box>
          <IconButton
            size="md"
            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
            aria-label="Open Menu"
            display={["inherit", "inherit", "none"]}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems="center">
            <img src="/images/logo.png" alt="next" />
            <HStack
              as="nav"
              spacing={1}
              display={{ base: "none", md: "flex" }}
              alignItems="center"
            >
              {navLinks.map((link, index) => (
                <NavLink key={index} {...link} onClose={onClose} />
              ))}
              {/* Dropdown Menu */}
              {/* <Menu autoSelect={false} isLazy>
                {({ isOpen, onClose }) => (
                  <>
                    <MenuButton
                      as={Button}
                      variant="ghost"
                      size="sm"
                      px={3}
                      py={1}
                      lineHeight="inherit"
                      fontSize="1em"
                      fontWeight="normal"
                      rounded="md"
                      height="auto"
                      _hover={{ color: "blue.400", bg: menuProps.bg }}
                    >
                      <Flex alignItems="center">
                        <Text>Links</Text>
                        <Icon
                          as={BiChevronDown}
                          h={5}
                          w={5}
                          ml={1}
                          transition="all .25s ease-in-out"
                          transform={isOpen ? "rotate(180deg)" : ""}
                        />
                      </Flex>
                    </MenuButton>
                    <MenuList
                      zIndex={5}
                      // bg={useColorModeValue(
                      //   "rgb(255, 255, 255)",
                      //   "rgb(26, 32, 44)"
                      // )}
                      border="none"
                      // boxShadow={useColorModeValue(
                      //   "2px 4px 6px 2px rgba(160, 174, 192, 0.6)",
                      //   "2px 4px 6px 2px rgba(9, 17, 28, 0.6)"
                      // )}
                    >
                      {dropdownLinks.map((link, index) => (
                        <MenuLink
                          key={index}
                          name={link.name}
                          path={link.path}
                          icon={link.icon}
                          onClose={onClose}
                        />
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu> */}
            </HStack>
          </HStack>
        </Box>
        <Box display={{ base: "none", md: "none", lg: "inherit" }}>
          <Flex
            align="center"
            justify="space-between"
            ml="auto"
            //   padding="4"
            //   borderBottom="1px"
            //   borderColor="gray.200"
          >
            {" "}
            <Box>
              <Flex alignItems="center">
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FaSearch color="gray.300" />
                  </InputLeftElement>
                  <Input type="text" placeholder="Search..." />
                </InputGroup>
              </Flex>
            </Box>
            <IconButton
              css={css`
                position: relative !important;
              `}
              bgColor={"#FFFFFF"}
              aria-label="Notifications"
              icon={
                <>
                  <FaBell />
                  <Box
                    as="span"
                    color="white"
                    position="absolute"
                    top="6px"
                    right="4px"
                    fontSize="0.7rem"
                    bgColor="red"
                    borderRadius="full" // Updated to "full" for a circular badge
                    zIndex={9999}
                    p="1px"
                    textAlign="center"
                    minWidth="16px" // Adjust as needed
                  >
                    5
                  </Box>
                </>
              }
            />
            <IconButton
              aria-label="Notifications"
              icon={
                <img
                  alt="cart"
                  className=" w-4 h-4 cursor-pointer"
                  src="/images/cart.png"
                  // onClick={() => {
                  //   localStorage.getItem(ACCESS_TOKEN)
                  //     ? navigate("/shopping-cart")
                  //     : navigate("/login");
                  // }}
                />
              }
              variant="ghost"
            />
            <span style={{ marginRight: "0.5rem" }}>John Doe</span>
            <Avatar size="md" name="John Doe" src="avatar-url.jpg" />
            <IconButton
              aria-label="Notifications"
              icon={
                <img
                  className="cursor-pointer"
                  src="/images/menu.png"
                  // onClick={onOpenSidebar}
                />
              }
              variant="ghost"
            />
          </Flex>
        </Box>
        <IconButton
          display={{ base: "inherit", md: "inherit", lg: "none" }}
          aria-label="Color Switcher"
          icon={<FiSun />}
        />
      </Flex>

      {/* Mobile Screen Links */}
      {isOpen ? (
        <Box pb={4} display={["inherit", "inherit", "none"]}>
          <Stack as="nav" spacing={2}>
            {navLinks.map((link, index) => (
              <NavLink key={index} {...link} onClose={onClose} />
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}

// NavLink Component
interface NavLinkProps {
  name: string;
  path: string;
  onClose: () => void;
}

const NavLink = ({ name, path, onClose }: NavLinkProps) => {
  const link = {
    bg: useColorModeValue("gray.200", "gray.700"),
    color: useColorModeValue("blue.500", "blue.200"),
  };

  return (
    <Link
      href={path}
      px={3}
      py={1}
      lineHeight="inherit"
      rounded="md"
      _hover={{
        textDecoration: "none",
        bg: link.bg,
        color: link.color,
      }}
      onClick={() => onClose()}
    >
      {name}
    </Link>
  );
};

// Dropdown MenuLink Component
interface MenuLinkProps {
  name: string;
  path: string;
  icon: IconType;
  onClose: () => void;
}

const MenuLink = ({ name, path, icon, onClose }: MenuLinkProps) => {
  return (
    <Link href={path} onClick={() => onClose()}>
      <MenuItem
        _hover={{
          color: "blue.400",
          bg: useColorModeValue("gray.200", "gray.700"),
        }}
      >
        <HStack>
          <Icon as={icon} size={18} color="blue.400" />
          <Text>{name}</Text>
        </HStack>
      </MenuItem>
    </Link>
  );
};
