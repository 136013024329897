import axios from "axios";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { OpenAPI } from "../../api/gen/core/OpenAPI";
import { ApiRequestOptions } from "../../api/gen/core/ApiRequestOptions";
import { RootState } from "../reducers/rootReducer";
import { createOrder, Action as orderActions } from "./orderActions";
import eventManager, { EVENT_ERROR } from "../../utils/events";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../utils/Notifications/notifications";
import { pageEvent } from "../../config/FirebaseConfig";

export interface Action {
  type: string;
  res?: any;
  err?: any;
}

const language = localStorage.getItem("language");

export const getGovernors = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_GOVERNORS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/governors`, {
        headers: { Authorization: `Bearer ${token}`, lang: language },
      })
      .then((res) => {
        dispatch({ type: "GET_GOVERNORS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_GOVERNORS_ERROR", err });
      });
  };
};

export const getProfileData = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_PROFILE_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/profile`, {
        headers: { Authorization: `Bearer ${token}`, lang: language },
      })
      .then((res) => {
        dispatch({ type: "GET_PROFILE_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_PROFILE_ERROR", err });
      });
  };
};

function objectToFormData(obj: Record<string, any>): FormData {
  const formData = new FormData();

  for (const [key, value] of Object.entries(obj)) {
    formData.append(key, value);
  }

  return formData;
}
export const updateProfile = (
  profile: Record<string, any>, // Assuming car is an object, adjust the type accordingly
  message: boolean
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // dispatch({ type: "UPDATE_PROFILE_LOADING" });
    const myFormData = objectToFormData(profile);
    dispatch({ type: "UPDATE_PROFILE_LOADING" });
    pageEvent("User", "Update Profile");
    axios
      .post(`${OpenAPI.BASE}/profile/update`, myFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "My-Custom-Header": "foobar",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        SuccessNotification(message ? "Location has updated" : "Success");
        dispatch({ type: "UPDATE_PROFILE_SUCCESS", res });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "UPDATE_PROFILE_ERROR", err });
      });
  };
};

export const getQuestions = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_FAQ_QUESTIONS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/questions`, {
        headers: { Authorization: `Bearer ${token}`, lang: language },
      })
      .then((res) => {
        dispatch({ type: "GET_FAQ_QUESTIONS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_FAQ_QUESTIONS_ERROR", err });
      });
  };
};
