import { Box, Flex, useSteps } from "@chakra-ui/react";
import "./Stepper.css";
import Checkout from "./Steps/Checkout";
import ConfirmAddress from "./Steps/ConfirmAddress";
import RequestIsDone from "./Steps/RequestIsDone";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import StepperTest from "../../components/user/order-stepper/OrderStepper";
import { useTranslation } from "react-i18next";
import BreadCrumbComponent from "../../components/bread-crumb/BreadCrumbComponent";
import ConfirmPayment from "./Steps/ConfirmPayment";

interface IProps {
  index: number;
}
const ShoppingCart = (props: IProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("cash");
  const { t } = useTranslation();
  const steps = [
    {
      title: t("checkout"),
      description: t("firstStep"),
      component: (
        <Checkout
          onNext={() => setActiveStep(activeStep + 1)}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
      ),
    },
    {
      title: t("confirmAddress"),
      description: t("secondStep"),
      component: (
        <ConfirmAddress
          onNext={() => setActiveStep(activeStep + 1)}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      ),
    },
    // {
    //   title: "Confirm Payment",
    //   component: (
    //     <ConfirmPayment onNext={() => setActiveStep(activeStep + 1)} />
    //   ),
    // },
    {
      title: t("requestIsDone"),
      description: t("thirdStep"),
      component: <RequestIsDone onNext={() => setActiveStep(activeStep + 1)} />,
    },
    // Add more steps as needed
  ];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get("order_id");
  const status = searchParams.get("status");

  const { activeStep, setActiveStep } = useSteps({
    index: props.index ? props.index : 0,
    count: steps.length,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      px={{ base: "1rem", md: "none", lg: "9rem" }}
      mt={{ base: "4em", md: "4em", lg: "9.375em" }}
    >
      <BreadCrumbComponent />
      <Flex
        flexDirection="column"
        // justifyContent={"center"}
        // alignItems={"center"}
        // mx="10em"
        mt={{ base: "1em", md: "1em", lg: "3em" }}
      >
        <StepperTest steps={steps} activeStep={activeStep} />
        <Box mt="2em" display={""}>
          {steps[activeStep].component}
        </Box>
      </Flex>
      {/* <Flex justifyContent={"center"} alignItems={"center"} mt="2em"></Flex> */}
    </Box>
  );
};

const StepSeparator = ({
  isCompleted,
  isActive,
  id,
}: {
  isCompleted: boolean;
  isActive: boolean;
  id?: string;
}) => {
  return (
    <Flex
      id={id}
      className="w-full"
      direction="column"
      align="center"
      justify="center"
      h="40px"
      flex="1"
    >
      <Box
        h="1px"
        className="w-[100%] border-black"
        bg={"white"}
        borderTopWidth="1px"
        borderTopStyle={isCompleted ? "solid" : "dashed"}
        transform="translateY(-50%)"
      />
    </Flex>
  );
};
export default ShoppingCart;
