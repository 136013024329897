import { IInternalState, AuthLoading } from "./context";
import toggleLoading from "../../utils/helpers/xor";

import { IProducts } from "../../api/gen/models/ProductsService/listModels";
import { IProductResponse } from "../../api/gen/models/ProductsService/product";

type Action =
  | { type: "LOADING"; payload: { loading: AuthLoading | AuthLoading[] } }
  | { type: "SET_LIST"; payload: { data: IProducts } }
  | { type: "SET_PRODUCT_DETAILS"; payload: { data: IProductResponse } };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "SET_LIST":
      return {
        ...state,
        list: action.payload.data,
        products:
          !state.products || action.payload.data.meta.current_page === 1
            ? action.payload.data.data
            : [...state.products, action.payload.data.data],
        currentPage: action.payload.data.meta.current_page,
        totalPages: action.payload.data.meta.last_page,
      };
    case "SET_PRODUCT_DETAILS":
      return { ...state, productDetails: action.payload.data };

    case "LOADING":
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };

    default:
      return state;
  }
};

export default reducer;
