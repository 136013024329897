import { InputGroup, Input, Button } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import AuthContext from "../../context/auth/context";
import { assert } from "console";
import { Await } from "react-router-dom";
import RTLWrapper from "../../RTLWrapper";
interface IProps {
  onContinue?: (value: number) => void;
}
const NewPasswordPage = ({ onContinue }: IProps) => {
  const [passwordActive, setPasswordActive] = useState({
    main: false,
    confirm: false,
  });
  const [passwords, setPasswords] = useState({
    main: "",
    confirm: "",
  });
  const [samePasswords, setSamePasswords] = useState(true);
  const {
    email,
    otpCode,
    actions: { changePassword },
  } = useContext(AuthContext);
  const onClick = async () => {
    if (passwords?.main === passwords.confirm) {
      await changePassword({
        email_or_phone: email ?? "",
        otp_code: otpCode ?? 123456,
        password: passwords?.main,
        password_confirmation: passwords?.confirm,
      });
    } else {
      setSamePasswords(false);
    }
  };
  return (
    <RTLWrapper>
      <div className=" flex flex-col  gap-[50px] ">
        <div>
          <div
            className=" text-[#545454] leading-[30px] whitespace-normal lg:w-[461px] md:w-[350px]lg:w-[461px] md:w-[350px] "
            style={{ fontWeight: "normal" }}
          >
            Code Sent! If Needed, We Can Resend A Code Upto 2 More Times.
          </div>
          {
            <div className="flex flex-col gap-[40px] mt-[10px]">
              <InputGroup className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px]  ">
                <Input
                  type={!passwordActive.main ? "password" : "text"}
                  placeholder="password"
                  border={"none"}
                  _focus={{ border: "none", boxShadow: "none" }}
                  onChange={(v) => {
                    setSamePasswords(true);
                    setPasswords({
                      main: v.target.value,
                      confirm: passwords?.confirm,
                    });
                  }}
                />
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    setSamePasswords(true);

                    setPasswordActive({
                      ...passwordActive,
                      main: !passwordActive.main,
                    });
                  }}
                  src={
                    !passwordActive.main
                      ? "/images/login/passwordUnActive.png"
                      : "/images/login/passwordActive.png"
                  }
                  alt=""
                />
              </InputGroup>{" "}
              <InputGroup className=" w-[461px] flex justify-center items-center bg-[#F8F8F8] py-[14px] px-[30px] content-center ">
                <Input
                  type={!passwordActive.confirm ? "password" : "text"}
                  placeholder="password"
                  border={"none"}
                  _focus={{ border: "none", boxShadow: "none" }}
                  onChange={(v) => {
                    setPasswords({
                      confirm: v.target.value,
                      main: passwords?.main,
                    });
                  }}
                />
                <img
                  className="cursor-pointer "
                  onClick={() => {
                    setPasswordActive({
                      ...passwordActive,
                      confirm: !passwordActive.confirm,
                    });
                  }}
                  src={
                    !passwordActive.confirm
                      ? "/images/login/passwordUnActive.png"
                      : "/images/login/passwordActive.png"
                  }
                  alt=""
                />
              </InputGroup>
              {!samePasswords && (
                <p
                  style={{
                    content: "⚠ ",
                  }}
                  className="text-[red] "
                >
                  كلمات السر غير متطابقة
                </p>
              )}
            </div>
          }
        </div>
        <div className="flex flex-col gap-[70px]">
          <Button
            backgroundColor={"black"}
            color={"white"}
            borderRadius={"0px"}
            className="lg:w-[461px] md:w-[350px]"
            _hover={{ bgColor: "#333131" }}
            onClick={() => {
              onClick();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </RTLWrapper>
  );
};

export default NewPasswordPage;
