// userReducer.ts
export interface MainState {
  spinner: string | null;
  orders: any | null;
  order: any | null;
  cart: any | null;
  err: any | null;
  orderStatus: string | null;
  paymentUrl: string | null;
  showNotification: boolean;
  driverPosition: any | null;
}

interface Action {
  type: string;
  res?: any;
}

const initState: MainState = {
  spinner: null,
  orders: null,
  order: null,
  cart: null,
  paymentUrl: null,
  err: null,
  showNotification: false,
  orderStatus: null,
  driverPosition: null,
};

const orderReducer = (
  state: MainState = initState,
  action: Action
): MainState => {
  switch (action.type) {
    case "CREATE_ORDER_SUCCESS":
      return {
        ...state,
        spinner: "success",
        orderStatus: "created",
      };
    case "CREATE_ORDER_ERROR":
      return {
        ...state,
        spinner: "error",
        err: action?.res?.data?.errors[0] ?? "error",
      };
    case "CREATE_ORDER_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "CREATE_ACCOUNT_ORDER_SUCCESS":
      return {
        ...state,
        paymentUrl: action.res.data.data.payment_url,
        orderStatus: "created",
        spinner: "success",
      };
    case "CREATE_ACCOUNT_ORDER_ERROR":
      return {
        ...state,
        spinner: "error",
        err: action?.res?.data?.errors[0] ?? "error",
      };
    case "CREATE_ACCOUNT_ORDER_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "ADD_TO_CART_SUCCESS":
      return {
        ...state,
        spinner: "added_cart",
        showNotification: true,
      };
    case "ADD_TO_CART_ERROR":
      return {
        ...state,
        spinner: null,
        showNotification: false,
      };
    case "ADD_TO_CART_SPINNER":
      return {
        ...state,
        spinner: "loading",
        showNotification: false,
      };
    case "REMOVE_SPINNER_ELEMENTS":
      return {
        ...state,
        showNotification: false,
      };

    case "ADD_COUPON_SUCCESS":
      return {
        ...state,
        spinner: "success",
      };
    case "ADD_COUPON_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "ADD_COUPON_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "INCREASE_PRODUCT_SUCCESS":
      return {
        ...state,
        spinner: "success",
      };
    case "INCREASE_PRODUCT_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "INCREASE_PRODUCT_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "DECREASE_PRODUCT_SUCCESS":
      return {
        ...state,
        spinner: "success",
      };
    case "DECREASE_PRODUCT_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "DECREASE_PRODUCT_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "DELETE_PRODUCT_CART_SUCCESS":
      return {
        ...state,
        spinner: "success",
      };
    case "DELETE_PRODUCT_CART_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "DELETE_PRODUCT_CART_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "REMOVE_COUPON_SUCCESS":
      return {
        ...state,
        spinner: "success",
      };
    case "REMOVE_COUPON_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "REMOVE_COUPON_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_ORDERS_SUCCESS":
      return {
        ...state,
        spinner: "success",
        orders: action.res?.data?.data,
      };
    case "GET_ORDERS_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "GET_ORDERS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_CART_SUCCESS":
      return {
        ...state,
        spinner: "success",
        cart: action.res?.data?.data,
      };
    case "GET_CART_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "GET_CART_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_ORDER_SUCCESS":
      // const existingOrder = state.order || [];

      // const orderExists = existingOrder.some(
      //   (item: any) => item.id === action.res?.data?.data.id
      // );
      return {
        ...state,
        spinner: "success",
        order: action.res?.data?.data,
      };

    // if (orderExists) {
    //   return {
    //     ...state,
    //     spinner: "success",
    //   };
    // } else {
    //   return {
    //     ...state,
    //     spinner: "success",
    //     order: [...existingOrder, action.res?.data?.data],
    //   };
    // }
    case "GET_ORDER_ERROR":
      return {
        ...state,
        spinner: null,
      };
    case "GET_ORDER_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    case "GET_DRIVER_POSITION_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_DRIVER_POSITION_SUCCESS":
      return {
        ...state,
        spinner: "success",
        driverPosition: action.res?.data?.data,
      };

    case "GET_DRIVER_POSITION_ERROR":
      return {
        ...state,
        spinner: null,
      };
    default:
      return state;
  }
};

export default orderReducer;
