import {
  Center,
  Flex,
  IconButton,
  Image,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import MessageCart from "../Components/MessageCart";
import {
  getConversation,
  getConversations,
  Action,
  getMessages,
  sendMessage,
  getOrderMessages,
  sendOrderMessage,
} from "../../../store/actions/messageActions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { RootState } from "../../../store/reducers/rootReducer";
import { useTranslation } from "react-i18next";
import RTLWrapper from "../../../RTLWrapper";

interface StateProps {
  spinner: string | null;
  messages: any | null;
}

interface DispatchProps {
  onGetOrderMessages: (id: number) => void;
  onSendMessage: (formData: any, orderId: number) => void;
}

interface IProps {
  orderId: any | null;
  myOrder: any | null;
}
const OrderChat: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const [selectedMessage, setSelectedMessage] = useState<any | null>(null);
  const [formData, setFormData] = useState<{
    message: string;
    conversation_id: null | number;
    file: File | null;
  }>({
    message: "",
    conversation_id: null,
    file: null,
  });
  const { t } = useTranslation();

  // Generic handler for input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    if (fieldName === "file") {
      // Cast e.target to HTMLInputElement
      const inputElement = e.target as HTMLInputElement;
      const selectedFile = inputElement.files?.[0];

      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedFile || null,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: e.target.value,
      }));
    }
  };

  // Event handler for the Save Changes button
  const handleSend = () => {
    const nonEmptyFormData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== "" && value !== null
      )
    );

    setFormData((prevData) => ({
      ...prevData,
      message: "",
      file: null,
    }));
    // Add your logic to save changes
    // For now, let's just log the values
    props.onSendMessage(nonEmptyFormData, props.orderId);
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    props.onGetOrderMessages(props.orderId);
  }, []);

  useEffect(() => {
    if (!formData.conversation_id && props.messages)
      setFormData((prevData) => ({
        ...prevData,
        conversation_id: props.messages
          ? props.messages.length > 0
            ? props.messages[0].conversation_id
            : null
          : null,
      }));
    scrollToBottom();
  }, [props.messages]);
  // if (props.messages)
  return (
    <RTLWrapper>
      <ModalOverlay />{" "}
      <ModalContent>
        <ModalHeader>
          <Flex>
            <Text>Order ID</Text>
            <Text marginInlineStart={1}>{props?.orderId}</Text>
          </Flex>

          {/* Search Input */}
          {/* <div className="flex items-center">
            <Input
              type="text"
              placeholder="Search for ID"
              bgColor="white"
              border="none"
              _focus={{ border: "none", boxShadow: "none" }}
            />
            <img
              className="w-[56px] h-[41px] cursor-pointer"
              src="/images/user/searchIcon.svg"
              alt=""
            />
          </div> */}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody ref={messagesEndRef}>
          {/* Chat Messages */}
          {props.messages && props.messages.length > 0 ? (
            <div
              className="flex-1 "
              style={{ height: "403px", maxHeight: "403px" }}
              // ref={messagesEndRef}
            >
              {props.messages &&
                props.messages.map((message: any) => (
                  <div
                    key={message.id}
                    className={`flex w-full ${
                      message.sender_type !== "user"
                        ? "justify-start"
                        : "justify-end"
                    } `}
                    // ref={messagesEndRef}
                  >
                    <div className="py-4">
                      <MessageCart
                        isLeft={message.sender_type === "user" ? false : true}
                        image={
                          message.user
                            ? message.user.avatar
                              ? message.user.avatar
                              : null
                            : null
                        }
                        text={message.body}
                        isAudio={false}
                        id={message.id}
                      />
                    </div>
                  </div>
                ))}{" "}
              {/* <div ref={messagesEndRef} /> */}
            </div>
          ) : (
            <Center height="403px">
              {props.spinner === "loading" ? (
                <Spinner size="xl" />
              ) : (
                <Text> {t("chatEmpty")}</Text>
              )}
            </Center>
          )}
        </ModalBody>
        <ModalFooter>
          {/* Send Messages */}
          <div className="flex items-center justify-between border-t border-[#F2F2F2] p-4">
            <Input
              placeholder={t("typeMessage")}
              border="none"
              borderBottomColor="white"
              _placeholder={{
                textSizeAdjust: "4px",
                fontSize: "16px",
              }}
              _focus={{
                border: "none",
                boxShadow: "none",
                borderBottomColor: "white",
              }}
              value={formData.message}
              onChange={(e) => handleInputChange(e, "message")}
            />
            {formData.file && (
              <div
                style={{
                  marginLeft: "8px",
                  fontSize: "14px",
                  color: "#4CAF50",
                }}
              >
                {t("fileUploaded")}
              </div>
            )}
            <div className="flex gap-4">
              <IconButton
                colorScheme="white"
                borderRadius="100px"
                aria-label="Send message"
                icon={<img src="/images/support/sendMessageIcon.png" alt="" />}
                onClick={() => handleSend()}
              />
              <IconButton
                colorScheme="white"
                borderRadius="100px"
                cursor={"pointer"}
                aria-label="Record audio"
                icon={
                  <label htmlFor="file-upload">
                    <img
                      className="h-[39px] w-[39px] cursor-pointer"
                      src="/images/support/upload.png"
                      alt=""
                    />
                  </label>
                }
              />
              <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                onChange={(e) => handleInputChange(e, "file")}
              />
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </RTLWrapper>
  );
  // else return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.messages.spinner,
    messages: state.messages.messages,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetOrderMessages: (id: number) => dispatch(getOrderMessages(id)),
  onSendMessage: (formData: any, orderId: number) =>
    dispatch(sendOrderMessage(formData, orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderChat);
