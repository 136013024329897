// rootReducer.ts
import { combineReducers, Reducer } from "redux";
import userReducer, { MainState } from "./userReducer";
import orderReducer, { MainState as OrderState } from "./orderReducer";
import productReducer, { MainState as ProductState } from "./productReducer";
import homeReducer, { MainState as HomeState } from "./homeReducer";
import messageReducer, { MainState as MessageState } from "./messageReducer";

// Define the root state type
export interface RootState {
  user: MainState;
  orders: OrderState;
  products: ProductState;
  home: HomeState;
  messages: MessageState;
}

const rootReducer: Reducer<RootState> = combineReducers({
  user: userReducer,
  orders: orderReducer,
  products: productReducer,
  home: homeReducer,
  messages: messageReducer,
  // Add other reducers as needed
});

export default rootReducer;
