import React, { useContext, useEffect, useState } from "react";
import NavCart from "../Components/NavCart";
import ProfileImage from "../Components/ProfileImage";
import MyInfo from "./MyInfo";
import Favorite from "./Favorite";
import MyOrders from "./MyOrders";
import Support from "./Support";
import Chat from "./Chat";
import Terms from "./Terms";
import Help from "./Help";
import FAQ from "./FAQ";
import AuthContext from "../../../context/auth/context";
import RTLWrapper from "../../../RTLWrapper";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa6";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MyOrderItemsTest from "../../../components/user/my-order-items-test/MyOrderItemsTest";
import { pageView } from "../../../config/FirebaseConfig";

const OrderDetails = () => {
  const {
    actions: { logout },
  } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const direction = localStorage.getItem("dir");
  useEffect(() => {
    pageView(window.location.pathname);
  }, []);
  const [page, setPage] = useState([
    {
      image: ["/images/user/wmyIcon.png", "/images/user/myIcon.png"],
      title: t("myInfo"),
      selected: false,
      page: <MyInfo />,
      to: "/user",
    },
    {
      image: ["/images/user/worderIcon.png", "/images/user/orderIcon.png"],
      title: t("myOrders"),
      selected: true,
      page: <MyOrders />,
      to: "/orders",
    },
    {
      image: ["/images/user/wfavIcon.png", "/images/user/favIcon.png"],
      title: t("fav"),
      selected: false,
      page: <Favorite />,
      to: "/favorites",
    },
    // {
    //   image: ["/images/user/whelpIcon.png", "/images/user/helpIcon.png"],
    //   title: "Help",
    //   selected: false,
    //   page: <Help />,
    // },
    // {
    //   image: ["/images/user/wchatIcon.png", "/images/user/chatIcon.png"],
    //   title: t("support"),
    //   selected: false,
    //   page: <Chat />,
    //   to: "/orders",
    // },
    {
      image: ["/images/user/wfaqIcon.png", "/images/user/faqIcon.png"],
      title: t("faq"),
      selected: false,
      page: <FAQ />,
      to: "/faq",
    },
    {
      image: ["/images/user/wtermsIcon.png", "/images/user/termsIcon.png"],
      title: t("termsAndConditions"),
      selected: false,
      page: <Terms />,
      to: "/terms",
    },
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState<string | any>(
    direction === "rtl" ? "right" : "left"
  );

  return (
    <RTLWrapper>
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex justifyContent={"space-between"}>
              <img
                className="h-[35px] cursor-pointer"
                src="/images/logo.png"
                alt="Sadaf logo"
                onClick={() => {
                  navigate("/");
                }}
              />
              <IconButton
                size={"sm"}
                icon={<CloseIcon bgColor={"#FFFFFF"} />}
                display={{ base: "block", md: "none" }}
                background={"none"}
                onClick={onClose}
                aria-label="Drawer"
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <div>
              {page.map((n, index) => (
                <NavCart
                  key={index}
                  icon={n.selected ? n.image[0] : n.image[1]}
                  title={t(n.title)}
                  selected={n.selected}
                  onClick={() => {
                    let tempValue = page;
                    tempValue = tempValue.map((t, index) => {
                      t.selected = false;
                      return t;
                    });
                    tempValue[index].selected = true;
                    setPage(tempValue);
                    navigate(n.to);
                  }}
                />
              ))}
              <div
                className={` cursor-pointer flex justify-center items-center content-center gap-[20px] py-[14.5px] bg-white`}
                onClick={() => {
                  logout();
                }}
              >
                <div className=" flex w-[189px] items-center content-center gap-[20px] ">
                  <img
                    src={"/images/user/logoutIcon.png"}
                    alt=""
                    className="lg:w-[30px] lg:h-[30px] xs: w-[20px] h-[20px]"
                  />
                  <div
                    style={{ fontWeight: "bold" }}
                    className={` text-black lg:text-[16px] xs:text-[10px]`}
                  >
                    {t("logout")}
                  </div>
                </div>
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
        {/* <div className=" lg:flex flex-col w-1/3  gap-[10px] pt-[10px] overflow-y-auto ">
            {page.map((n, index) => (
              <NavCart
                key={index}
                icon={n.selected ? n.image[0] : n.image[1]}
                title={n.title}
                selected={n.selected}
                onClick={() => {
                  let tempValue = page;
                  tempValue = tempValue.map((t, index) => {
                    t.selected = false;
                    return t;
                  });
                  tempValue[index].selected = true;
                  setPage(tempValue);
                }}
              />
            ))}
            <div
              className={` cursor-pointer flex justify-center items-center content-center gap-[20px] py-[14.5px] bg-white`}
              onClick={() => {
                logout();
              }}
            >
              <div className=" flex w-[189px] items-center content-center gap-[20px] ">
                <img
                  src={"/images/user/logoutIcon.png"}
                  alt=""
                  className="w-[30px] h-[30px]"
                />
                <div style={{ fontWeight: "bold" }} className={` text-black `}>
                  Logout
                </div>
              </div>
            </div>
          </div> */}
      </Drawer>
      <div className=" flex justify-center content-center w-full h-screen lg:mt-[100px] xs:mt-[50px]">
        {/* nav */}
        <div className=" lg:flex flex-col w-1/3 sm:hidden xs:hidden gap-[10px] py-[50px] px-[20px] overflow-y-auto ">
          {page.map((n, index) => (
            <NavCart
              key={index}
              icon={n.selected ? n.image[0] : n.image[1]}
              title={t(n.title)}
              selected={n.selected}
              onClick={() => {
                let tempValue = page;
                tempValue = tempValue.map((t, index) => {
                  t.selected = false;
                  return t;
                });
                tempValue[index].selected = true;
                setPage(tempValue);
                navigate(n.to);
              }}
            />
          ))}
          <div
            className={` cursor-pointer flex justify-center items-center content-center gap-[20px] py-[14.5px] bg-white`}
            onClick={() => {
              logout();
            }}
          >
            <div className=" flex w-[189px] items-center content-center gap-[20px] ">
              <img
                src={"/images/user/logoutIcon.png"}
                alt=""
                className="lg:w-[30px] lg:h-[30px] xs: w-[20px] h-[20px]"
              />
              <div
                style={{ fontWeight: "bold" }}
                className={` text-black lg:text-[16px] xs:text-[10px]`}
              >
                {t("logout")}
              </div>
            </div>
          </div>
        </div>

        {/* second part */}
        <div className="h-full w-full">
          <Box
            padding={{ base: "0px", md: "10px", lg: "64px" }}
            className=" mt-[0px] "
            bgColor={{ base: "#FFFFFF", md: "#FFFFFF", lg: "#F9F9F9" }}
          >
            <Box style={{ backgroundColor: "#white" }}>
              <Flex
                style={{ backgroundColor: "#white" }}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <IconButton
                  icon={<FaBars color="#959595" />}
                  display={{ base: "inherit", md: "none" }}
                  variant="ghost"
                  color={"none"}
                  backgroundColor={"none"}
                  onClick={onOpen}
                  aria-label="Open Drawer"
                />
              </Flex>
              <MyOrderItemsTest />
            </Box>
          </Box>
        </div>
      </div>
    </RTLWrapper>
  );
};

export default OrderDetails;
