import { IInternalState, AuthLoading } from "./context";
import toggleLoading from "../../utils/helpers/xor";

import { IProducts } from "../../api/gen/models/ProductsService/listModels";
import { ICartDetails } from "../../api/gen/models/Carts/cartDetails";

type Action =
  | { type: "LOADING"; payload: { loading: AuthLoading | AuthLoading[] } }
  | { type: "SET_LIST"; payload: { data: IProducts } }
  | { type: "SET_CART_DETAILS"; payload: { data: ICartDetails } }
  | { type: "CLEAR_LOADING"; payload: { loading: AuthLoading[] } };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "SET_CART_DETAILS":
      return { ...state, cartDetails: action.payload.data };

    case "LOADING":
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };

    case "CLEAR_LOADING":
      return {
        ...state,
        loading: [],
      };

    default:
      return state;
  }
};

export default reducer;
