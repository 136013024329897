import { LANGUAGE_CODE } from "../helpers/constants";
import { toast } from "react-hot-toast";

export const SuccessNotification = (message: string, description?: string) => {
  toast.success(message, {
    style: {
      direction:
        localStorage.getItem(LANGUAGE_CODE) === "ar" ||
        localStorage.getItem(LANGUAGE_CODE) === "ku"
          ? "rtl"
          : "ltr",
    },
    position:
      localStorage.getItem(LANGUAGE_CODE) === "ar" ||
      localStorage.getItem(LANGUAGE_CODE) === "ku"
        ? "bottom-left"
        : "bottom-right",
    duration: 4500,
  });
};
export const ErrorNotification = (message: string, description?: string) => {
  toast.error(message, {
    style: {
      direction:
        localStorage.getItem(LANGUAGE_CODE) === "ar" ||
        localStorage.getItem(LANGUAGE_CODE) === "ku"
          ? "rtl"
          : "ltr",
    },
    position:
      localStorage.getItem(LANGUAGE_CODE) === "ar" ||
      localStorage.getItem(LANGUAGE_CODE) === "ku"
        ? "bottom-left"
        : "bottom-right",
    duration: 4500,
  });
};
